import React from 'react';

const HuiPanelChild = React.forwardRef((props, ref) => {
  const { id, className } = props;

  const huiPanelClass = `hui-panel-child ${className || ``}`;

  return (
    <div
      className={huiPanelClass}
      id={id}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

export default HuiPanelChild;
