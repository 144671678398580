const HuiTabs = (props) => {
  const { hidden, className } = props;

  const huiTabsClass = `hui-tabs ${className || ``}`;

  return (
    <div
      className={huiTabsClass}
      hidden={hidden}
    >
      {props.children}
    </div>
  );
};

export default HuiTabs;
