import HistoryWaivers from './HistoryWaivers';

const HistoryWaiversPage = () => {
  return (
    <div className="p-4 hui-dashboard flex-grow-1 overflow-hidden">
      <h1 className="hui-title mb-2">Waiver History</h1>

      <HistoryWaivers />
    </div>
  );
};

export default HistoryWaiversPage;
