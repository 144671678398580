import { axiosInstance } from '../../utils/utilsAxios';

export async function getUser() {
  const url = `v1/users/me`;

  try {
    const response = await axiosInstance.get(url);
    const user = response.data;
    return user;
  } catch (e) {
    const { response: { data: { message } } } = e;
    if (message === 'User not found') {
      return { userNotFound: true };
    } else {
      throw new Error(e.message);
    }
  }
}


