import { HuiIcon } from 'handle-ui';
import React from 'react';

const SubmitPaymentMessage = () => {
  return (
    <div className="text-center d-flex align-items-center justify-content-center mt-2">
      <div className="d-flex me-2">
        <HuiIcon size="sm" name="lock" />
      </div>
      <div className="hui-text-lg hui-color-default">Payments are secure and encrypted</div>
    </div>
  );
};

export default SubmitPaymentMessage;
