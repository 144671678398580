import { HuiBadge } from 'handle-ui';
import { toUpper } from 'lodash';
import { getBadgeColorByWaiverCode } from '../../utils/utilsColors';

const HuiTableWaiverCode = ({ value }) => {
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();

  const badgeColor = getBadgeColorByWaiverCode[toUpper(value)] || {};

  return (
    <div className={classNames} title={value} hidden={!value}>
      <HuiBadge value={value} bgColor={badgeColor?.bg} textColor={badgeColor?.text} uppercase fullWidth>
        {value}
      </HuiBadge>
    </div>
  );
};

export default HuiTableWaiverCode;
