import { axiosInstance } from '../../utils/utilsAxios';

export async function getPortalInfo(id) {
	const url = `v1/portals/${id}`;

	console.log('fetching: ', url);

	try {
		const response = await axiosInstance.get(url);
		const portal = response.data;
		return portal;
	} catch (e) {
    throw new Error(e.message);
	}
}


