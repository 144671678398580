import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../redux/AuthSlice';
import Header from '../../../components/layout/header/Header';
import { selectUser } from '../../../redux/UserSlice';

const PendingApproval = () => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const handleUser = useSelector(selectUser);

  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    async function fetchUser() {
      if (handleUser?.isLogged) {
        return navigate('/dashboard')
      }
      const user = await auth || {};
      const userName = await user.displayName || '';
      const userEmail = await user.email || '';

      setDisplayName(userName);
      setEmail(userEmail);
    }

    fetchUser();
  }, [auth, setDisplayName, handleUser, navigate]);

  return (
    <>
      <Header onlyLogo />
      <div className="hui-header-padding position-relative">
        <div className="p-5 text-center">
          <h1 className="text-center hui-title mb-4">
            Waiting for Approval
          </h1>

          <div className="w-50 mx-auto mb-5">

            Hi{displayName ? ` ${displayName}` : ''}! Your account is now waiting for approval.
            <br />
            <br />
            <strong> Note: You'll be notified through your email ({email}) once the request is approved.
            </strong>
            <br />
            <br />


            <div>


              Issues with the verification process or entered the wrong email?
              Please sign up with <Link className="" to="/logout">another</Link> email address.

            </div>

          </div>

        </div>
      </div>
    </>
  );
};

export default PendingApproval;
