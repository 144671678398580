import { HuiIcon } from 'handle-ui';
import { startCase, toLower, toUpper } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { deleteCardOnFile } from '../../../../actions/payment/deleteCardOnFile';
import { getCardOnFile } from '../../../../actions/payment/getCardOnFile';
import HuiTable from '../../../../components/table/HuiTable';
import HuiTableCell from '../../../../components/table/HuiTableCell';
import HuiTablePaymentMethod from '../../../../components/table/HuiTablePaymentMethod';
import HuiTableFooter from '../../../../components/table/components/HuiTableFooter';
import HuiTableHeader from '../../../../components/table/components/HuiTableHeader';
import { closeModal, openModal } from '../../../../redux/ModalSlice';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { paymentTypeFilter } from '../../../../utils/utilsFilters';
import PaymentMethodType from './PaymentMethodType';

const getColumns = (paymentType, onChange = () => {}) => {
  const isAll = paymentType === 'ALL';
  const isCreditCard = UtilPaymentMethods.isCreditCard(paymentType);
  let columns = [
    {
      field: 'paymentType',
      label: 'Type',
      width: '150px',
      getValue: ({ value }) => toUpper(startCase(toLower(value))),
      cellRenderer: (props) => <HuiTablePaymentMethod {...props} />,
    },
    {
      field: '',
      label: 'Brand/Account Type',
      width: '150px',
      sortable: false,
      cellRenderer: (props) => <PaymentMethodType {...props} />,
    },
    {
      field: 'last4',
      label: 'Ending in',
      width: '150px',
      sortable: false,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'expiry',
      label: 'Expiry',
      width: '150px',
      visible: isCreditCard || isAll,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      pinned: true,
      field: 'actions',
      label: '',
      width: '60px',
      sortable: false,
      cellRenderer: (props) => (
        <div className="cursor-pointer">
          <HuiIcon name="x" size="sm" onClick={() => onChange(props.data)} />
        </div>
      ),
    },
  ];
  columns = columns.map((column, i) => {
    return { ...column, id: i };
  });

  return columns;
};

const PaymentMethodsTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const rowsRef = useRef([]);

  const [allRows, setAllRows] = useState(rowsRef.current);
  const [totalRows, setTotalRows] = useState(null);
  const [tableManager, setTableManager] = useState({});
  const [load, setLoad] = useState(null);
  const [paymentType, setPaymentType] = useState('ALL');

  const onRowsReset = () => {
    rowsRef.current = [];
    setAllRows(rowsRef.current);
    setTotalRows(null);
  };

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  useEffect(() => {
    const isPaymentMethods = location.pathname.includes('/payment-methods');
    const findPayments = async () => {
      const paymentType = searchParams.get('paymentType') || 'ALL';
      const result = await getCardOnFile(paymentType);
      setPaymentType(paymentType);

      rowsRef.current = tableManager.asyncApi.mergeRowsAt(
        rowsRef.current,
        result.rows,
        (searchParams.get('page') - 1) * 100,
      );
      setAllRows(rowsRef.current);
      setTotalRows(result.count);
    };
    if (tableManager.asyncApi && isPaymentMethods) {
      findPayments();
    }
  }, [dispatch, searchParams, tableManager, load, setLoad]);

  const isLoading = totalRows == null;

  const paymentTokensFilters = {
    filters: [paymentTypeFilter],
  };

  return (
    <HuiTable
      additionalProps={{
        header: {
          showTableCountInfo: true,
          availableFilters: paymentTokensFilters,
        },
      }}
      onRowClick={({ data }) => {}}
      components={{ Header: HuiTableHeader, Footer: HuiTableFooter }}
      className="hui-table"
      isLoading={isLoading}
      isPaginated={false}
      columns={getColumns(paymentType, openConfirmDeleteModal)}
      rows={allRows}
      totalRows={totalRows}
      contentName="Payment Methods"
      onLoad={onLoadTableManager}
      onRowsRequest={() => {}}
      onRowsReset={onRowsReset}
      pageSize={100}
    />
  );

  async function openConfirmDeleteModal(data) {
    const modal = {
      type: 'alerts',
      title: 'Confirm delete',
      message: 'Are you sure you want to delete this payment method?',
      confirmLabel: 'Confirm',
      confirmAction: () => handleDeleteCardOnFile(data),
      showConfirmButton: true,
      cancelLabel: 'Cancel',
      cancelAction: () => dispatch(closeModal()),
      showCancelButton: true,
      hideClose: true,
      isOpen: true,
    };
    dispatch(openModal(modal));
  }

  async function handleDeleteCardOnFile(data) {
    dispatch(closeModal());
    const { id } = data;
    try {
      const token = await deleteCardOnFile(id);
      if (token.isDeleted) {
        toastr.success('Payment method removed.');
      }
    } catch (error) {
      toastr.error(error?.message);
    } finally {
      setLoad(id);
    }
  }
};

export default PaymentMethodsTable;
