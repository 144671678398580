import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NavPanel from '../../../components/layout/nav/NavPanel';
import PortalCopy from '../../../components/portal/PortalCopy';
import PortalLogo from '../../../components/portal/PortalLogo';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const portalInfo = useSelector(selectPortalInfo);
  const { name } = portalInfo || {};

  return (
    <>
      <div className="hui-main d-flex pb-0">
        <NavPanel position="left">
          <div className="d-flex flex-column p-5 my-auto w-100">
            <div className="mb-2 text-center mx-auto hui-portal-logo">
              <Link to="/login" title={name} className="hui-portal-link">
                <PortalLogo noName />
              </Link>
            </div>

            <div hidden={portalInfo.logoUrl}>
              <h2 className="hui-title text-center mb-4">{name}</h2>
            </div>

            <div className="mb-3 text-center">
              Already a customer? <Link to="/login">Click here to login</Link>
            </div>

            <ForgotPasswordForm />
          </div>
        </NavPanel>

        <PortalCopy />
      </div>
    </>
  );
};

export default ForgotPassword;
