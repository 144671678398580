import { isEmail } from '../../utils/utilsEmail';
import { getFirebaseMessage } from '../../utils/utilsFirebaseException';
import { firebase } from './firebase';

export async function signInWithEmailAndPassword(email, password) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (response) => {
      const user = response.user;
      return user;
    })
    .catch((error) => {
      throw new Error(getFirebaseMessage(error));
    });
}

export async function signInWithLink() {
  const storageEmail =  isEmail(localStorage.getItem('signInWithLinkEmail')) ? localStorage.getItem('signInWithLinkEmail') : null;
  const email = storageEmail || prompt('Please type your email:'); // TODO: might want to add the email in the url
  return firebase.auth().signInWithEmailLink(email);
}

export async function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });

  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (response) => {
      const user = response.user;
      return user;
    })
    .catch((error) => {
      throw new Error(getFirebaseMessage(error));
    });
}

export async function signInByProvider(signInProvider) {
  const provider = new firebase.auth.SAMLAuthProvider(signInProvider);
  provider.setCustomParameters({ prompt: 'select_account' });

  return firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (response) => {
      const user = response.user;
      return user;
    })
    .catch((error) => {
      throw new Error(getFirebaseMessage(error));
    });
}

export async function signInWithRedirectByProvider(signInProvider) {
  const provider = new firebase.auth.SAMLAuthProvider(signInProvider);
  provider.setCustomParameters({ prompt: 'select_account' });
  return await firebase
      .auth()
      .signInWithRedirect(provider);
}

export async function getRedirectCredential(){
  console.log('Calling getRedirectSignInWithSSO');
  return await firebase.auth().getRedirectResult();
}