import { FormControl } from '@mui/material';
import { HuiInput, HuiSelect } from 'handle-ui';
import { size as loSize } from 'lodash';
import { useState } from 'react';
import { states } from '../../utils/utilsStatesUS';
import GoogleAddressInput from './GoogleAddressInput';

const MAP_FIELDS = {
  'hui-city': 'city',
  'hui-state': 'state',
  'hui-postal-code': 'postalCode',
  'hui-addr33ss2': 'address2',
  'hui-addr33ss': 'address',
};

const GoogleAddressForm = (props) => {
  const [state, setState] = useState({});

  const {
    id = 'hui-addr33ss',
    onChange,
    required,
    hideRequired,
    value: model = {},
    compacted = false,
    topLabel,
    label = 'Address',
    hidden,
    size = 'large',
    requireAddress2 = required,
    inputProps,
    placeholder,
    zipOnly = false,
    countryList = 'us',
  } = props;

  const isRequired = zipOnly ? false : required;
  const isRequiredAddress2 = zipOnly ? false : requireAddress2;

  const countries = loSize(countryList) >= 2 ? countryList : 'us';

  const handleOnChange = (event) => {
    const { target: { name, value } = {} } = event || {};
    const _name = MAP_FIELDS[name] || name;
    const newState = { ...state, [_name]: value };
    setState((state) => ({ ...state, ...newState }));
    const { name: formName = 'addressForm' } = props;
    onChange?.({
      target: {
        name: formName,
        value: newState,
      },
    });
  };

  const handleGoogleAddressChange = (event) => {
    const { target: { name, value } = {} } = event || {};
    const _name = MAP_FIELDS[name] || name;
    const newState = { ...state, ...value, [_name]: value };
    setState((state) => ({ ...state, ...newState }));
    const { name: formName = 'addressForm' } = props;
    onChange?.({
      target: {
        name: formName,
        value: newState,
      },
    });
  };

  const isFormattedAllowed = compacted;

  if (hidden) {
    return <></>;
  }

  return (
    <FormControl>
      <div className="col-12">
        <GoogleAddressInput
          label={label}
          placeholder={placeholder}
          id={id}
          name="hui-addr33ss"
          formatted={isFormattedAllowed}
          required={isRequired}
          hideRequired={hideRequired}
          onChange={handleGoogleAddressChange}
          value={model.street || state.street}
          topLabel={topLabel}
          resetOnChange={reset}
          size={size}
          inputProps={inputProps}
          hidden={zipOnly}
          countryList={countries}
        />
      </div>
      {renderForm()}
    </FormControl>
  );

  function reset() {
    const { name: formName = 'addressForm' } = props;
    setState({});
    onChange?.({
      target: {
        name: formName,
        value: '',
      },
    });
  }

  function renderForm() {
    const { compacted = false, hiddenAddress2 = false } = props;

    if (compacted) {
      return <></>;
    }

    return (
      <>
        <div className="col-12" hidden={zipOnly || hiddenAddress2}>
          <HuiInput
            id="hui-addr33ss2"
            label="Apartment/Suite/Floor"
            name="hui-addr33ss2"
            size={size}
            topLabel={topLabel}
            required={isRequiredAddress2}
            value={model.address2 || state.address2}
            onChange={handleOnChange}
            inputProps={inputProps}
          />
        </div>
        <div className="row">
          <div className="col-4" hidden={zipOnly}>
            <HuiInput
              id="hui-city"
              label="City"
              name="hui-city"
              size={size}
              topLabel={topLabel}
              required={isRequired}
              value={model.city || state.city}
              onChange={handleOnChange}
              inputProps={inputProps}
            />
          </div>
          <div className="col-4" hidden={zipOnly}>
            <HuiSelect
              id="hui-state"
              label="State"
              name="hui-state"
              size={size}
              topLabel={topLabel}
              required={isRequired}
              value={model.state || state.state}
              onChange={handleOnChange}
              options={states}
              inputProps={inputProps}
            />
          </div>
          <div className={`${zipOnly ? 'col-12' : 'col-4'}`}>
            <HuiInput
              id="hui-postal-code"
              label="ZIP Code"
              name="hui-postal-code"
              size={size}
              topLabel={topLabel}
              required={required}
              value={model.postalCode || state.postalCode}
              onChange={handleOnChange}
              inputProps={inputProps}
            />
          </div>
        </div>
      </>
    );
  }
};

export default GoogleAddressForm;
