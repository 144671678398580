import { createSlice } from '@reduxjs/toolkit';
import { formatNumber } from '../utils/utilsMath';

const initialState = {
  value: {},
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openSidebar: (state, action) => {
      const invoice = action.payload;
      const invoiceHasError = checkIfInvoiceHasError(invoice);
      state.value = { ...invoice, invoiceHasError };
      state.value.totalToPay = invoice.amount;
    },
    updateSidebar: (state, action) => {
      const invoice = action.payload;
      const invoiceHasError = checkIfInvoiceHasError(invoice);
      state.value = { ...invoice, invoiceHasError };
    },
    closeSidebar: (state, action) => {
      return initialState;
    },
    updateAmountWithCreditApplied: (state, action) => {
      const creditApplied = action.payload || 0;
      const { balance = 0 } = state.value;
      const totalToPay = formatNumber(balance - creditApplied, 2);
      state.value.creditApplied = creditApplied;
      setTotalToPay(state, totalToPay);
      setPaymentTypeInfo(state, balance, totalToPay, creditApplied);
    },
    updateTotalToPay: (state, action) => {
      const { creditApplied = 0, balance = 0 } = state.value;
      const newAmount = action.payload;
      setTotalToPay(state, newAmount);
      setPaymentTypeInfo(state, balance, newAmount, creditApplied);
    },
    updatePaymentReason: (state, action) => {
      const paymentReasonId = action.payload;
      state.value.paymentReasonId = paymentReasonId;
    },
  },
});

function checkIfInvoiceHasError(invoice) {
  const { totalToPayHasError, shortPayHasError, paymentTypeError, creditToAppliedHasError, hasInvoiceError } =
    invoice || {};
  return [totalToPayHasError, shortPayHasError, paymentTypeError, creditToAppliedHasError, hasInvoiceError].some(
    (error) => error,
  );
}

function setTotalToPay(state, amount) {
  state.value.totalToPay = amount;
  state.value.amount = amount;
}

function setPaymentTypeInfo(state, balance, amount, creditApplied) {
  const paymentType = getPaymentType(balance, amount, creditApplied);
  state.value.paymentType = paymentType;

  if (paymentType === 'complete') {
    state.value.paymentReasonId = undefined;
  }
}

function getPaymentType(balance, amount, creditApplied) {
  const total = formatNumber(amount + creditApplied, 2);

  if (total > balance) {
    return 'overpay';
  }

  if (total < balance) {
    return 'shortpay';
  }
  return 'complete';
}

export const {
  openSidebar,
  closeSidebar,
  updateSidebar,
  updateAmountWithCreditApplied,
  updateTotalToPay,
  updatePaymentReason,
} = sidebarSlice.actions;

export const selectSidebar = (state) => state.sidebar.value;

export default sidebarSlice.reducer;
