import { HuiInput, HuiLoading } from 'handle-ui';
import { pick, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { updateUserInfo } from '../../../../actions/user/updateUserInfo';
import { selectUser, updateUser } from '../../../../redux/UserSlice';

const ProfileInfo = (props) => {
  const { hidden } = props;
  const dispatch = useDispatch();
  const [form, setForm] = useState({ name: '', title: '' });
  const [isLoading, setIsIsLoading] = useState(false);
  const user = useSelector(selectUser);
  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);

  useEffect(() => {
    const name = user?.name || '';
    setForm({ ...form, name });
  }, [user]);

  if (hidden) {
    return <></>;
  }

  return (
    <>
      <form className="d-flex  justify-content-center" onSubmit={handleSubmit}>
        <HuiLoading isOpen={isLoading} />
        <div className="col-6">
          <h2 className="hui-title mb-4 mt-4">Basic Info</h2>
          <div className="col-12">
            <HuiInput
              label="Name"
              id="profile-name"
              name="name"
              type="text"
              value={form.name}
              onChange={handleChange}
              inputProps={{ minLength: 6 }}
              size="large"
              required
              topLabel
            />
          </div>

          <div className="col-12 d-flex flex-column mt-3">
            <button type="submit" className="hui-btn hui-btn-xl hui-btn-green" disabled={(!form.name)}>
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );

  function handleChange(e) {
    const name = e.target.name;
    const fields = uniq([...fieldsToUpdate, name], n => n);
    setFieldsToUpdate(fields);
    setForm({ ...form, [name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { name } = form;
    if (!name) {
      return;
    }
    
    try {
      setIsIsLoading(true);
      const { id } = user;
      const body = pick(form, fieldsToUpdate);
      const updated = await updateUserInfo(body);
      toastr.success('User updated successfully.');
      dispatch((updateUser(updated)));
    } catch (error) {
      toastr.error(error.message || 'Something went wrong, try again or contact support.');
    } finally {
      setIsIsLoading(false);
    }
  }
};

export default ProfileInfo;
