import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import { getRedirectCredential, signInWithRedirectByProvider } from '../../../actions/auth/signIn';
import { TRY_SIGN_WITH_SSO } from '../../../redux/AuthSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const SignInWithSSO = (props) => {
  const { signInProvider, onChange } = props || {};
  const portalInfo = useSelector(selectPortalInfo);
  const { settings } = portalInfo || {};
  const { isPortalSingleSignOnEnabled = false, portalSignInProvider } = settings || {};

  const isSingleSignOnEnabled = portalSignInProvider && isPortalSingleSignOnEnabled;

  const signInWithRedirect = useCallback(async () => {
    sessionStorage.setItem(TRY_SIGN_WITH_SSO, true);
    signInWithRedirectByProvider(signInProvider);
  }, [signInProvider]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const isRedirect = pathname === '/auth/sso';
    const signInWithSSO = sessionStorage.getItem(TRY_SIGN_WITH_SSO);
    const shouldRedirect = isSingleSignOnEnabled && isRedirect && !signInWithSSO;

    async function signIn() {
      console.log('SignInWithRedirect UseEffect');
      await signInWithRedirect();
      onChange?.(false);
      console.log('SignInWithRedirect UseEffect 2');
    }

    if (shouldRedirect) {
      signIn();
    }
  }, [isSingleSignOnEnabled, signInProvider, signInWithRedirect, onChange]);

  useEffect(() => {
    const pathname = window.location.pathname;
    const isReturn = ['/auth/sso', '/login'].includes(pathname);
    const signInWithSSO = sessionStorage.getItem(TRY_SIGN_WITH_SSO);
    const shouldRun = isSingleSignOnEnabled && signInWithSSO && isReturn;

    async function getCredential() {
      console.log('getRedirect Credential UseEffect');
      await getRedirectCredential();
      onChange?.(false);
      console.log('getRedirect Credential UseEffect2');
    }

    if (shouldRun) {
      getCredential();
    }
  }, [isSingleSignOnEnabled, signInProvider, onChange]);

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit}>
      <div className="col-12 d-flex flex-column mt-3">
        <button type="submit" className="hui-btn hui-btn-xl hui-btn-icon">
          Single Sign On
        </button>
      </div>
    </form>
  );

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      signInWithRedirect();
    } catch (error) {
      toastr.error(error);
      sessionStorage.removeItem(TRY_SIGN_WITH_SSO);
      onChange?.(false);
    }
  }
};

export default SignInWithSSO;
