import { addIdempotencyKeyHeader, axiosInstance } from '../../utils/utilsAxios';

export async function createPrepay(form, idempotencyKey) {
  const url = `/v1/payments/prepay`;

  try {
    const response = await axiosInstance.post(url, form, addIdempotencyKeyHeader(idempotencyKey));
    const payment = response.data;
    return payment;
  } catch (error) {
    const messageResponse = error.response?.data?.message;
    const apiMessageError = error.response?.data?.error?.message;

    const e = new Error(apiMessageError ? apiMessageError : messageResponse);
    e.error = error.response?.data?.error;
    throw e;
  }
}
