import { isEmpty, size } from 'lodash';
import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { signInHandleAndIdentifyUser } from '../actions/auth/signInHandleAndIdentifyUser';
import { getPaymentReasons } from '../actions/payment/getPaymentReasons';
import { getPortalInfo } from '../actions/portal/getPortalInfo';
import { getPortals } from '../actions/portal/getPortals';
import { selectAuth } from '../redux/AuthSlice';
import { updatePaymentReasons } from '../redux/PaymentReasonsSlice';
import { selectPortalInfo, updateInfoPortal } from '../redux/PortalInfoSlice';
import { selectPortals, updatePortals } from '../redux/PortalsSlice';
import { selectUser, updateUser } from '../redux/UserSlice';
import Templates from './Templates';

const Containers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const portalInfo = useSelector(selectPortalInfo);
  const userPortals = useSelector(selectPortals);

  useEffect(() => {
    async function portalRedirects() {
      const { id } = portalInfo;
      if (user.id && !size(userPortals)) {
        if (id) {
          try {
            const updatePortal = await getPortalInfo(id);
            const userPortals = await getPortals();
            const paymentReasons = await getPaymentReasons().catch(console.error);

            if (!size(userPortals)) {
              toastr.error('User without permission to access the portal!');
              navigate('/logout');
            }
            batch(() => {
              dispatch(updateInfoPortal(updatePortal));
              dispatch(updatePortals(userPortals));
              dispatch(updatePaymentReasons(paymentReasons));
            });
          } catch (e) {
            console.log('Error getting portal info');
          }
        }
      }

      if (user.isEmailVerified === false) {
        navigate('/confirm-email');
      } else if (user.isInviteApproved === false) {
        navigate('/pending-approval');
      } else if (user.userNotFound || user.noCustomerAccount) {
        navigate('/logout');
      }
    }

    if (!isEmpty(auth) && !isEmpty(user)) {
      portalRedirects();
    }

    async function loadUserForAdmin(portalInfo, auth) {
      const handleUser = await signInHandleAndIdentifyUser(portalInfo, auth, true);
      dispatch(updateUser(handleUser));
    }

    //Admin bypass
    const isWithinIframe = window.parent !== window;

    if (!isEmpty(auth) && isEmpty(user) && isWithinIframe) {
      loadUserForAdmin(portalInfo, auth);
    }
  }, [dispatch, navigate, auth, user, userPortals, portalInfo]);

  return <Templates />;
};

export default Containers;
