import { addDays, addMonths, isToday } from 'date-fns';
import { HuiCalendar, HuiInput, HuiInputAmount, HuiSelect } from 'handle-ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleAddressForm from '../../../components/google-maps/GoogleAddressForm';
import { addPrepay, resetPrepay } from '../../../redux/PaymentDataSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const defaultScheduledDate = {
  dateSelected: new Date(),
  value: true,
};

const defaultCalendar = {
  dateSelected: null,
  value: false,
};

const getFrequencyOptions = (isRecurrencePaymentEnabled) => {
  const options = [
    {
      title: 'One time',
      id: 'ONE_TIME',
    },
  ];

  if (isRecurrencePaymentEnabled) {
    options.push({
      title: 'Monthly',
      id: 'MONTHLY',
    });
  }
  return options;
};

const Prepay = (props) => {
  const dispatch = useDispatch();
  const portalInfo = useSelector(selectPortalInfo);

  // reset prepay data when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetPrepay({}));
    };
  }, [dispatch]);

  const {
    settings: {
      isPrePayReferenceRequired,
      isPrePayProjectRequired,
      isPrePayNotesRequired,
      isScheduledPaymentEnabled,
      isRecurrencePaymentEnabled,
      maximumRecurrenceInstallments = 6,
    },
  } = portalInfo;

  const { onClick } = props;

  const [fields, setFields] = useState({
    total: 0,
    frequency: 'ONE_TIME',
    paymentTypeError: false,
    reference: '',
    projectName: '',
    projectAddress: '',
    notes: '',
    scheduledAt: null,
  });

  const [scheduledDate, setScheduledDate] = useState(defaultScheduledDate);
  const [startDate, setStartDate] = useState(defaultCalendar);
  const [endDate, setEndDate] = useState(defaultCalendar);
  const [isRecurrenceDatesRequired, setIsRecurrenceDatesRequired] = useState(false);

  const showFrequency = isRecurrencePaymentEnabled || isScheduledPaymentEnabled;

  const frequencyOptions = getFrequencyOptions(isRecurrencePaymentEnabled);

  const paymentTypeError =
    !(
      fields.total >= 1 &&
      (fields.reference || !isPrePayReferenceRequired) &&
      ((fields.projectName && fields.projectAddress) || !isPrePayProjectRequired) &&
      (fields.notes || !isPrePayNotesRequired)
    ) || isRecurrenceDatesRequired;

  useEffect(() => {
    updatePayload();
  }, [fields, startDate, endDate, scheduledDate]);

  const getMinEndDate = () => {
    const { dateSelected } = startDate || {};
    if (dateSelected && startDate.value) {
      return addDays(dateSelected, 1);
    }
    return dateSelected || addDays(new Date(), 1);
  };

  const getMaxEndDate = () => {
    const { dateSelected } = startDate || {};
    const date = new Date(dateSelected);
    const end = addMonths(date, maximumRecurrenceInstallments - 1);
    return end;
  };

  const getMaxStartDate = () => {
    const { dateSelected } = endDate || {};

    if (!dateSelected) {
      return;
    }

    if (dateSelected && endDate.value) {
      return addDays(dateSelected, -1);
    }
    return dateSelected;
  };

  return (
    <div className="d-flex flex-column w-50 mx-auto">
      <div className="row">
        <div className="col-12">
          <HuiInputAmount
            label="Add Total"
            prefix="$"
            id="total"
            name="total"
            placeholder="$"
            value={fields.total}
            onChange={handleChange}
            topLabel
            required
            size="large"
            allowNegative={false}
            defaultValue={0}
          />
        </div>

        <div className="col-12" hidden={!showFrequency}>
          <HuiSelect
            topLabel
            required={showFrequency}
            size="large"
            value={fields.frequency}
            id="frequency"
            name="frequency"
            label="Frequency"
            onChange={handleSelect}
            options={frequencyOptions}
          />
        </div>

        <div className="col-12" hidden={!showFrequency || fields.frequency !== 'ONE_TIME'}>
          <HuiCalendar
            onChange={(item) => {
              setScheduledDate({ dateSelected: item, value: true });
              setFields({ ...fields, scheduledAt: item.toISOString() });
            }}
            onClear={() => {
              setScheduledDate(defaultCalendar);
            }}
            handleOnClick={() => {}}
            onClickDisabled={true}
            size="large"
            topLabel
            id="scheduledDate"
            name="scheduledDate"
            date={scheduledDate}
            label="Transfer Date"
            minDate={new Date()}
            prefix=""
            required
          />
        </div>

        <div className="col-12 col-lg-6" hidden={fields.frequency !== 'MONTHLY'}>
          <HuiCalendar
            onChange={(item) => {
              setStartDate({ dateSelected: item, value: true });
            }}
            onClear={() => {
              setStartDate(defaultCalendar);
            }}
            handleOnClick={() => {}}
            onClickDisabled={true}
            size="large"
            topLabel
            id="startDate"
            name="startDate"
            date={startDate}
            label="Start Date"
            prefix=""
            minDate={new Date()}
            maxDate={getMaxStartDate()}
            required
          />
        </div>

        <div className="col-12 col-lg-6" hidden={fields.frequency !== 'MONTHLY'}>
          <HuiCalendar
            onChange={(item) => {
              setEndDate({ dateSelected: item, value: true });
            }}
            onClear={() => {
              setEndDate(defaultCalendar);
            }}
            handleOnClick={() => {}}
            onClickDisabled={true}
            size="large"
            topLabel
            id="endDate"
            name="endDate"
            date={endDate}
            label={`End Date (up to ${maximumRecurrenceInstallments} months)`}
            prefix=""
            minDate={getMinEndDate()}
            maxDate={(startDate?.dateSelected && getMaxEndDate()) || null}
            required
          />
        </div>

        <div className="col-12">
          <HuiInput
            label="Invoice Number / Sales Order Number / PO Number"
            id="reference"
            name="reference"
            type="text"
            value={fields.reference}
            onChange={handleChange}
            size="large"
            topLabel
            required={isPrePayReferenceRequired}
          />
        </div>

        <div className="col-12 col-lg-6">
          <HuiInput
            label="Project Name"
            id="projectName"
            name="projectName"
            type="text"
            value={fields.projectName}
            onChange={handleChange}
            size="large"
            topLabel
            required={isPrePayProjectRequired}
          />
        </div>

        <div className="col-12 col-lg-6">
          <GoogleAddressForm
            label="Project Address"
            id="projectAddress"
            name="projectAddress"
            onChange={onChangeAddress}
            required={isPrePayProjectRequired}
            compacted
            size="large"
            topLabel
            inputProps={{
              role: 'presentation',
              autoComplete: 'nope',
            }}
          />
        </div>

        <div className="col-12">
          <HuiInput
            multiline
            maxRows={3}
            minRows={3}
            label="Notes"
            id="notes"
            name="notes"
            type="text"
            value={fields.notes}
            onChange={handleChange}
            size="large"
            required={isPrePayNotesRequired}
            topLabel
            placeholder="Please provide information for this payment "
          />
        </div>

        <div className="col-12 d-flex flex-column mt-3">
          <button onClick={handleSubmit} className="hui-btn hui-btn-xl hui-btn-green" disabled={paymentTypeError}>
            Select the Payment Method
          </button>
        </div>
      </div>
    </div>
  );

  async function handleSelect(e) {
    e.stopPropagation();
    const frequency = e.target.value;
    setFields({ ...fields, frequency });
    if (frequency === 'MONTHLY') {
      setScheduledDate(defaultScheduledDate);
      setIsRecurrenceDatesRequired(true);
    } else {
      setStartDate(defaultCalendar);
      setEndDate(defaultCalendar);
      setIsRecurrenceDatesRequired(false);
    }
  }

  function handleChange(e) {
    setFields({ ...fields, [e.target.name]: e.target.value });
  }

  function onChangeAddress(event) {
    const { target: { value, name } = {} } = event;
    const { formattedAddress, street } = value;
    handleChange({
      target: {
        name,
        value: formattedAddress ?? street ?? '',
      },
    });
  }

  async function updatePayload() {
    const { total, reference, projectName, projectAddress, notes, frequency } = fields;

    const paymentForm = {
      prepayTotal: parseFloat(total),
      info: {
        reference,
        projectName,
        projectAddress,
        notes,
      },
    };

    if (frequency === 'MONTHLY') {
      paymentForm.recurrence = { startDate: startDate.dateSelected, endDate: endDate.dateSelected, frequency };
      const isRequired = isRecurrencePaymentEnabled && (!startDate.dateSelected || !endDate.dateSelected);
      setIsRecurrenceDatesRequired(isRequired);
    } else if (!isToday(scheduledDate.dateSelected)) {
      paymentForm.scheduledAt = scheduledDate.dateSelected;
    }

    await dispatch(addPrepay(paymentForm));
  }

  function handleSubmit(e) {
    onClick();
  }
};
export default Prepay;
