import { getInvoice } from '../../actions/invoices/getInvoice';
import { closeSidebar, openSidebar } from '../../redux/SidebarSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedInvoiceClass } from '../../utils/utilsTable';
import { setCreditsFromInvoice } from '../../redux/CreditDataSlice';
import { selectCredits } from '../../redux/CreditsSlice';
import { HuiBadge, HuiIcon } from 'handle-ui';

const ViewInvoiceCredit = (props) => {
  const {
    id,
    invoice,
    rowIndex,
    icon,
    hidden,
    label,
    className,
    preview,
    type,
  } = props;

  const { rows: allCredits } = useSelector(selectCredits);
  const dispatch = useDispatch();

  const creditType = {
    'button': <button onClick={handleChance} role="button" className={className}>{label}</button>,
    'badge': <HuiBadge bgColor="green-light"
                       textColor="green"
                       onClick={handleChance}
                       type="button"
                       icon={icon}> {label} </HuiBadge>,
    'icon': <span onClick={handleChance}> <HuiIcon name="credit" size="xs" /> </span>,
  };

  const Credit = creditType[type];

  return !hidden && Credit;

  async function handleChance() {
    if (preview) return false;

    if (rowIndex) {
      addSelectedInvoiceClass(rowIndex);
    }

    await dispatch(openSidebar({ loading: true }));

    try {
      const invoiceData = await getInvoice(id ? id : invoice.id);
      await dispatch(setCreditsFromInvoice({ creditInvoice: invoice, allCredits }));
      await dispatch(openSidebar({ ...invoiceData, ...invoice, type: 'credit', loading: false }));
    } catch (e) {
      await dispatch(closeSidebar({}));
      console.log('Error loading invoice modal');
    }
  }
};

export default ViewInvoiceCredit;
