const HuiTablePaymentMethod = (props) => {
  const { value } = props;

  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();

  return (
    <div className={classNames} title={value}>
      {value}
    </div>
  );
};

export default HuiTablePaymentMethod;
