import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { HuiLoading } from 'handle-ui';
import { signInWithLink } from '../../../actions/auth/signIn';

const SignInWithLink = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [verify, setVerify] = useState(false);

  useEffect(() => {
    async function doWork() {
      try {
        const auth = await signInWithLink();
        if (auth?.user?.emailVerified) {
          return navigate('/pending-approval');
        }
      } catch (e) {
        return navigate('/login');
      } finally {
        toastr.success('Your email has been successfully verified!');
        setLoading(false);
      }
    }
    if (!verify) {
      doWork();
      setVerify(true);
    }
  }, [navigate, setLoading, loading, setVerify, verify]);

  return (
    <>
      <div className="hui-main d-flex pb-0">
        <HuiLoading isOpen={loading} />
      </div>
    </>
  );
};

export default SignInWithLink;
