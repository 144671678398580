import { firebase } from './firebase';

export async function signOut() {
  return firebase
    .auth()
    .signOut()
    .then(async () => {
      return true;
    });
};
