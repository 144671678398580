import { getFirebaseMessage } from '../../utils/utilsFirebaseException';
import { firebase } from './firebase';

export async function changePassword(code, newPassword) {

  return await firebase
    .auth()
    .confirmPasswordReset(code, newPassword)
    .then(() => {
      return true;
    })
    .catch(error => {
      throw new Error(getFirebaseMessage(error));
    });
};
