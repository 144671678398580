import { divide as lDivide, multiply as lMultiply, subtract as lSubtract, sum as lSum } from 'lodash';

export function sum(...numbers) {
  return lSum(numbers.map((n) => n * 100)) / 100;
}

export function subtract(...numbers) {
  return lSubtract(...numbers.map((n) => n * 100)) / 100;
}

export function divide(...numbers) {
  return lDivide(...numbers.map((n) => n * 100)) / 100;
}

export function multiply(...numbers) {
  return lMultiply(...numbers.map((n) => n * 100)) / 100;
}

export function formatNumber(number, precision = 2) {
  return parseFloat(number.toFixed(precision));
}
