import { axiosInstance } from '../../utils/utilsAxios';

export async function updateUserInfo(body) {
  const url = `v1/users`;

  try {
    const response = await axiosInstance.post(url, body);
    const user = response.data;
    return user;
  } catch (e) {
    const { response: { data: { message } } } = e;
    throw new Error(message || e.message);
  }
}