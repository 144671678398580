import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getWaiverRequestsHistory } from '../../../actions/waiver/getWaiversHistory';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableActions from '../../../components/table/HuiTableActions';
import HuiTableAddress from '../../../components/table/HuiTableAddress';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTableWaiverStatus from '../../../components/table/HuiTableWaiverStatus';
import HuiTableWaiverType from '../../../components/table/HuiTableWaiverType';
import HuiTableFooter from '../../../components/table/components/HuiTableFooter';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';

import {
  datePickerWithRangeFilter,
  invoiceFilter,
  jobAddressFilter,
  jobNameFilter,
  submitterEmailFilter,
  submitterNameFilter,
  waiverThroughDateFilter,
  waiverTypeFilter,
} from '../../../utils/utilsFilters';

function getColumns(hideProjectColumns, isSortable) {
  let columns = [
    {
      field: 'waiverRequestStatus',
      label: 'Waiver Status',
      width: '111px',
      sortable: isSortable,
      cellRenderer: (props) => <HuiTableWaiverStatus {...props} />,
    },
    {
      field: 'createdAt',
      label: 'Request Date',
      width: '92px',
      sortable: isSortable,
      getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'throughDate',
      label: 'Through Date',
      width: '92px',
      sortable: isSortable,
      getValue: ({ value }) =>
        (value !== null && moment.tz(value, ['YYYY-MM-DD'], 'America/Los_Angeles').format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'jobName',
      label: 'Project Name',
      width: 'auto',
      sortable: isSortable,
      visible: !hideProjectColumns,
      getValue: (obj) => obj.rowData.project.jobName,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'address',
      label: 'Project Address',
      width: 'auto',
      sortable: isSortable,
      visible: !hideProjectColumns,
      getValue: (obj) => obj.rowData.project.address,
      cellRenderer: (props) => <HuiTableAddress {...props} />,
    },
    {
      field: 'waiverType',
      label: 'Waiver Type',
      width: '200px',
      sortable: isSortable,
      cellRenderer: (props) => <HuiTableWaiverType {...props} />,
    },
    {
      field: 'createdBy',
      label: 'Originator',
      width: 'auto',
      sortable: isSortable,
      visible: !hideProjectColumns,
      getValue: (obj) => (obj.value ? obj.value.name : obj.rowData.waiverRequest?.submitterName),
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'actions',
      label: '',
      pinned: true,
      sortable: false,
      width: '150px',
      cellRenderer: (props) => (
        <HuiTableActions
          {...props}
          disabled={
            !props.data.canBeDownloaded ||
            (props.data.waiverRequest && props.data.waiverRequest?.waiverRequestStatus !== 'confirmed')
          }
        />
      ),
    },
  ];
  columns = columns.map((column, i) => {
    return { ...column, id: i };
  });

  return columns;
}

const HistoryWaivers = (props) => {
  const {
    project,
    hideHeader,
    isPaginated = true,
    hideProjectColumns = false,
    className = 'hui-table hui-table-no-tabs',
    isSortable = false,
  } = props;
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const rowsRef = useRef([]);

  const [allRows, setAllRows] = useState(rowsRef.current);
  const [totalRows, setTotalRows] = useState(null);
  const [tableManager, setTableManager] = useState({});

  const waiversHistoryFilters = {
    filters: [
      {
        name: 'search',
        type: 'search',
        label: 'Search by Project Id, Project Name or Address',
      },
      datePickerWithRangeFilter,
    ],
    advancedFilters: [
      waiverThroughDateFilter,
      waiverTypeFilter,
      invoiceFilter,
      jobNameFilter,
      jobAddressFilter,
      submitterNameFilter,
      submitterEmailFilter,
    ],
  };

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  useEffect(() => {
    async function findWaivers() {
      if (!searchParams.get('size')) {
        searchParams.set('size', tableManager.paginationApi.pageSize);
      }

      const params = Object.fromEntries(searchParams);
      if (project) {
        params.size = 500;
        params.projectId = project.id;
      }

      const result = await getWaiverRequestsHistory(params);

      rowsRef.current = tableManager.asyncApi.mergeRowsAt(
        rowsRef.current,
        result.rows,
        (searchParams.get('page') - 1) * tableManager.paginationApi.pageSize,
      );
      setAllRows(rowsRef.current);
      setTotalRows(result.count);
    }
    if (tableManager.asyncApi) {
      findWaivers();
    }
  }, [dispatch, searchParams, tableManager, project]);

  const isLoading = totalRows == null;

  return (
    <HuiTable
      additionalProps={
        hideHeader
          ? {}
          : {
              header: {
                showTableCountInfo: true,
                availableFilters: { ...waiversHistoryFilters },
              },
            }
      }
      components={hideHeader ? {} : { Header: HuiTableHeader, Footer: HuiTableFooter }}
      className={className}
      isLoading={isLoading}
      isPaginated={isPaginated}
      columns={getColumns(hideProjectColumns, isSortable)}
      rows={allRows}
      totalRows={totalRows}
      contentName="Waivers"
      onLoad={onLoadTableManager}
    />
  );
};

export default HistoryWaivers;
