import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';
import { submitPaymentMessage } from '../../utils/utilsMessages';

const SubmitPaymentMessage = (props) => {
  const { type, isPaidOnBehalf } = props;
  const portalInfo = useSelector(selectPortalInfo);
  const { name } = portalInfo || {};

  return (
    <div className="mt-4 hui-text-xs hui-color hui-color-black-light">
      {submitPaymentMessage(isPaidOnBehalf, name, type)}
    </div>
  );
};

export default SubmitPaymentMessage;
