const HuiLinkArrow = (props) => {
  const { label, arrowRight } = props;

  return (
    <div className="hui-btn hui-btn-link hui-btn-icon hui-btn-icon-right fw-bold text-decoration-none hui-color-black">
      {!arrowRight && <i className="hui-i hui-svg-arrow-sm-right"> </i>}
      {label}
      {arrowRight && <i className="hui-i hui-svg-arrow-sm-right"> </i>}
    </div>
  );
};

export default HuiLinkArrow;
