import { HuiInput } from 'handle-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import { forgotPassword } from '../../../actions/auth/forgotPassword';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const ForgotPasswordForm = () => {
  const portalInfo = useSelector(selectPortalInfo);
  const { organization } = portalInfo || {};

  const [form, setForm] = useState({ email: '' });
  const [message, setMessage] = useState('');
  const [userNotFound, setUserNotFound] = useState(false);

  const messageClass = `${userNotFound ? 'hui-color-red' : ''} hui-subtitle pb-4`;

  return (
    <>
      <div className={messageClass} hidden={!message}>
        {message}
      </div>

      <form className="d-flex flex-column" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <HuiInput
              label="Email"
              id="email"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              size="large"
              required
              topLabel
            />
          </div>

          <div className="col-12 d-flex flex-column mt-3">
            <button type="submit" className="hui-btn hui-btn-xl hui-btn-green">
              Reset Password
            </button>
          </div>
        </div>
      </form>
    </>
  );

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { email } = form;

    const submittedForm = {
      email,
      organizationId: organization.id,
    };

    if (email) {
      try {
        const result = await forgotPassword(submittedForm);

        if (result.emailNotFound) {
          setMessage(`The email address ${email} is not linked to any account.`);
          setUserNotFound(true);
        } else {
          setMessage(`We've sent an email to ${email} with instructions on how to reset your password.`);
          toastr.success('Recovery email sent!');
          setUserNotFound(false);
        }
      } catch (error) {
        toastr.error(error);
      }
    }
  }
};

export default ForgotPasswordForm;
