import axios from 'axios';
import { store } from '../store/store';
import { closeLoading, openLoading } from "../redux/LoadingSlice";

export const API_URL = process.env.REACT_APP_MAIN_API_URL;

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL + '/',
});

export const axiosUnauthenticated = axios.create({
  withCredentials: true,
  baseURL: API_URL + '/',
});

axiosInstance.interceptors.request.use(async function(config) {
  store.dispatch(openLoading());

  const { auth, portal } = store.getState();

  const userToken = auth?.value?.getIdToken && await auth?.value?.getIdToken()?.catch(() => '');

  const hasPortalPromise = portal?.promise && !portal?.value?.organization?.id;
  const portalInfo = hasPortalPromise ? await portal?.promise : portal?.value;

  if (userToken && portalInfo?.organization?.id) {
    config.headers.Authorization = `Bearer ${userToken}`;
    config.headers['Handle-Organization'] = portalInfo?.organization?.id;
  }

  return config;
});

function removeLoading() {
  store.dispatch(closeLoading());
}

axiosInstance.interceptors.response.use(function(response) {
  removeLoading();
  return response;
}, function(error) {
  removeLoading();
  return Promise.reject(error);
});
