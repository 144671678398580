import React from 'react';
import currencyFormatter from 'currency-formatter';
import { HuiBadge } from 'handle-ui';

const ModalDebitCard = (props) => {
  const { modal } = props;

  const { message } = modal;

  const icon = (
    <svg height="150" viewBox="0 0 150 150" width="150" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle cx="75" cy="75" fill="#f7f7f8" fillRule="nonzero" r="75" />
        <rect fill="#cacaca" height="57" rx="8" width="84" x="33" y="47" />
        <g fill="#fff" fillRule="nonzero" transform="translate(43 86)">
          <path d="m6.7422 3.7128c0 .5372-.09265 1.03275-.27795 1.48665s-.44625.8466-.78285 1.1781-.74205.5899-1.21635.7752-1.00045.27795-1.57845.27795h-2.8866v-7.4307h2.8866c.578 0 1.10415.0935 1.57845.2805s.87975.4454 1.21635.7752.59755.72165.78285 1.17555.27795.94775.27795 1.48155zm-1.7646 0c0-.3672-.0476-.6987-.1428-.9945s-.23205-.54655-.41055-.75225-.39695-.3638-.65535-.4743-.5525-.16575-.8823-.16575h-1.1526v4.7787h1.1526c.3298 0 .6239-.05525.8823-.16575s.47685-.2686.65535-.4743.31535-.45645.41055-.75225.1428-.629.1428-.9996z" />
          <path d="m12.0822 0v1.326h-3.0498v1.734h2.3358v1.275h-2.3358v1.7697h3.0498v1.326h-4.7838v-7.4307z" />
          <path d="m12.7914 7.4307v-7.4307h2.7336c.5134 0 .9503.0476 1.3107.1428s.6545.2295.8823.4029.39355.3842.49725.6324.15555.5253.15555.8313c0 .1666-.0238.32725-.0714.48195s-.1224.30005-.2244.43605-.2329.25925-.3927.36975-.3519.20655-.5763.28815c.4896.119.8517.31195 1.0863.57885s.3519.60945.3519 1.02765c0 .3162-.0612.6103-.1836.8823s-.30175.50915-.53805.71145-.52785.3604-.87465.4743-.7429.17085-1.1883.17085zm1.7238-3.1518v1.8513h1.2138c.2278 0 .41395-.0289.55845-.0867s.25755-.1326.33915-.2244.1377-.1955.1683-.3111.0459-.2329.0459-.3519c0-.136-.01785-.2584-.05355-.3672s-.0969-.2006-.1836-.2754-.20145-.1326-.34425-.1734-.323-.0612-.5406-.0612zm0-1.1526h.8976c.1904 0 .3621-.0136.5151-.0408s.28305-.0748.39015-.1428.1887-.1615.2448-.2805.08415-.2686.08415-.4488c0-.1768-.0221-.32385-.0663-.44115s-.1122-.21165-.204-.28305-.20825-.1224-.34935-.153-.30855-.0459-.50235-.0459h-1.0098z" />
          <path d="m21.0996 7.4307h-1.734v-7.4307h1.734z" />
          <path d="m27.6024 0v1.3668h-2.091v6.0639h-1.7238v-6.0639h-2.091v-1.3668z" />
        </g>
        <path d="m33 57h84v11h-84z" fill="#24262b" />
        <rect fill="#efefef" height="11" rx="3" width="15" x="94" y="84" />
        <g transform="translate(103 89)">
          <path
            d="m14 28c7.7777778 0 14-6.2222222 14-14 0-7.77777778-6.2222222-14-14-14-7.77777778 0-14 6.22222222-14 14 0 7.7777778 6.22222222 14 14 14z"
            fill="#31a951"
            fillRule="nonzero"
          />
          <path
            d="m10.3148159 20.24444c.3074011.1916213.5667793.3377258.7781345.4383135.8146214.3310886 1.5122715.5527497 2.3258486.5527497v1.2068601c0 .4920692.3989008.89097.89097.89097.4920691 0 .89097-.3989008.89097-.89097 0-.4694956 0-.9389912 0-1.4084868 2.4559917-.5071525 3.6905153-1.7302436 3.799261-4.21663 0-2.6505798-1.7441253-3.5334829-4.3018277-4.3069584-.4657963-.2207258-1.3953002-.6621774-1.3953002-1.5460157 0-.7734755.5806788-1.10362893 1.1624021-1.10362893.4961955 0 1.0054695.11205673 1.527822.33617023l.0000028-.0000068c.6783731.2910536 1.4656588-.0034015 1.7865355-.66818737.3114292-.6452128.0408442-1.4207244-.6043686-1.73215363-.0510049-.02461886-.1035672-.04587109-.1573497-.06362026-1.013609-.33449125-1.6376802-.52378663-1.8722189-.56787007 0-.43384213 0-.86768425 0-1.30152638 0-.47686982-.3865793-.86344919-.8634492-.86344919-.4768698 0-.8634491.38657937-.8634491.86344919v1.23625152c-2.0929504.11036289-3.4882507 2.09783015-3.4882507 4.30602319 0 2.3204265 1.3953003 3.2033296 2.6746736 3.755144 1.1624021.5527498 2.9065274.7734755 2.9065274 1.8780397 0 .8829031-1.3953002 1.3252899-1.9770235 1.3252899-.4780339 0-1.1017009-.2593735-1.8710009-.7781206l.0000078-.0000116c-.6119484-.4126432-1.442544-.2510745-1.85518728.3608739-.01870462.0277389-.03636101.0561704-.05293184.0852344-.43784378.7679454-.19095848 1.7446062.55922052 2.2122371z"
            fill="#fff"
            stroke="#31a951"
          />
        </g>
      </g>
    </svg>
  );

  const totalFormatted = currencyFormatter.format(message, {
    code: 'USD',
  });

  return (
    <>
      <div className="text-center">{icon}</div>
      <div className="text-center mb-3" style={{ marginTop: '-20px' }}>
        <HuiBadge value="No Fee" textColor="white" bgColor="green" size="sm" />
      </div>

      <div className="hui-subtitle fw-bold text-center px-4 mb-3">
        The card type you've selected appears to be a DEBIT CARD<span hidden={!modal.showSurcharge}>, which does not incur a surcharge fee</span>.
      </div>

      <div className=" px-3 mb-3 pb-3 border-bottom">
        <span hidden={!modal.showSurcharge}>
          Please confirm your card type to proceed<span hidden={!modal.showSurcharge}>&nbsp;without any additional charges</span>.
        </span>
      </div>

      <div className="d-flex px-3 mb-3">
        <div className="flex-grow-1 hui-text-lg fw-bold mb-2">Total</div>
        <div className="hui-text-lg">
          <strong>{totalFormatted}</strong>
        </div>
      </div>
    </>
  );
};

export default ModalDebitCard;
