import { HuiLoading } from 'handle-ui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { getPaymentReasons } from '../../../actions/payment/getPaymentReasons';
import { getPortalInfo } from '../../../actions/portal/getPortalInfo';
import { getPortals } from '../../../actions/portal/getPortals';
import NavPanel from '../../../components/layout/nav/NavPanel';
import PortalCopy from '../../../components/portal/PortalCopy';
import PortalLogo from '../../../components/portal/PortalLogo';
import { TRY_SIGN_WITH_SSO } from '../../../redux/AuthSlice';
import { updatePaymentReasons } from '../../../redux/PaymentReasonsSlice';
import { selectPortalInfo, updateInfoPortal } from '../../../redux/PortalInfoSlice';
import { updatePortals } from '../../../redux/PortalsSlice';
import { selectUser } from '../../../redux/UserSlice';
import SignInWithGoogle from '../signInWithGoogle/SignInWithGoogle';
import SignInForm from './SignInForm';
import SignInWithSSO from './SignInWithSSO';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const user = useSelector(selectUser);
  const getTryLoginSSOFromSession = !!sessionStorage.getItem(TRY_SIGN_WITH_SSO);
  const [isLoadingSSO, setIsLoadingSSO] = useState(getTryLoginSSOFromSession);

  const portalInfo = useSelector(selectPortalInfo);
  const { id, name, settings } = portalInfo || {};
  const {
    isLoginWithGoogleOnPortalDisabled = false,
    isPortalSingleSignOnEnabled = false,
    isPortalSignUpEnabled = true,
    portalSignInProvider,
  } = settings || {};

  const isWithinIframe = window.parent !== window;
  const isSingleSignOnEnabled = portalSignInProvider && isPortalSingleSignOnEnabled;

  const loginHandler = useCallback(
    async (auth) => {
      try {
        console.log('Login handler');

        const result = user;

        if (result.isLogged) {
          localStorage.setItem('user', JSON.stringify(result));

          const updatePortal = await getPortalInfo(id);
          const userPortals = await getPortals();
          const paymentReasons = await getPaymentReasons().catch(console.error);

          batch(async () => {
            dispatch(updateInfoPortal(updatePortal));
            dispatch(updatePortals(userPortals));
            dispatch(updatePaymentReasons(paymentReasons));
          });

          const redirect = query.get('redirect') || 'dashboard';
          return navigate(`/${redirect}`);
        } else if (result.isInviteApproved === false) {
          navigate(`/pending-approval`);
        } else if (result.isEmailVerified === false) {
          navigate(`/confirm-email`);
        } else if (result.noCustomerAccount) {
          toastr.error('We could not find any customer account for this user');
          sessionStorage.removeItem(TRY_SIGN_WITH_SSO);
          navigate('/logout');
        } else if (result.userNotFound === true) {
          navigate(`/signup`);
        } else {
          console.log('User', user);
          toastr.error('Error logging in, please review your credentials.');
        }
      } catch (error) {
        console.log('Login error', error);
      }
    },
    [dispatch, id, navigate, query, user],
  );

  useEffect(() => {
    if (isWithinIframe) {
      navigate(`/404`); // TODO: could be a custom 404 for supplier page
    }
  }, [isWithinIframe, navigate]);

  useEffect(() => {
    console.log('Login Handle Effect', isEmpty(user));
    if (!isEmpty(user)) {
      loginHandler(user);
    }
  }, [user, loginHandler]);

  const showLoading = useCallback((e) => {
    setIsLoadingSSO(!!e);
  }, []);

  return (
    <>
      <div className="hui-main d-flex pb-0">
        <HuiLoading isOpen={isLoadingSSO} />
        <NavPanel position="left" hidden={isLoadingSSO}>
          <div className="d-flex flex-column p-5 my-auto w-100 hui-main-form">
            <div className="mb-4 text-center mx-auto hui-portal-logo">
              <Link to="/login" title={name} className="hui-portal-link">
                <PortalLogo noName />
              </Link>
            </div>

            <div hidden={portalInfo.logoUrl}>
              <h2 className="hui-title text-center mb-5">{name}</h2>
            </div>

            {!isLoginWithGoogleOnPortalDisabled && <SignInWithGoogle />}

            {isSingleSignOnEnabled && <SignInWithSSO signInProvider={portalSignInProvider} onChange={showLoading} />}

            {(!isLoginWithGoogleOnPortalDisabled || isSingleSignOnEnabled) && (
              <div className="my-3 text-center hui-color-gray hui-text-sm or-line"> OR</div>
            )}

            <SignInForm loginHandler={loginHandler} isPortalSignUpEnabled={isPortalSignUpEnabled} />
          </div>
        </NavPanel>

        <PortalCopy isLoadingSSO={isLoadingSSO} />
      </div>
    </>
  );

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
};

export default SignIn;
