import React from 'react';
import { HuiBadge, HuiInputAmount, HuiPanel, HuiPanelContent } from 'handle-ui';

const HuiPanelPaymentCustom = (props) => {
  const { fields, handleChange } = props;
  return (
    <>
      <HuiPanel noBorder boxShadow className="overflow-hidden position-relative mb-3">
        <HuiPanelContent className="d-flex align-items-center p-3">
          <div className="hui-text hui-color-black-light me-3 text-nowrap">Type in the amount to be paid:</div>
          <div className="hui-subtitle-lg fw-normal hui-color-black-light me-2 text-nowrap">$</div>
          <div className="w-25 me-2">
            <HuiInputAmount
              inputClassName="otp-custom-amount"
              className="m-0"
              label="Amount"
              id="total"
              name="total"
              placeholder="0.00"
              value={fields?.total}
              onChange={handleChange}
              noLabel
              required
              size="large"
              allowNegative={false}
              variant="standard"
            />
          </div>
          <div>
            <HuiBadge size="sm" value="Type the amount to be paid" arrowLeft />
          </div>
        </HuiPanelContent>
      </HuiPanel>
    </>
  );
};

export default HuiPanelPaymentCustom;
