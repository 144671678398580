import React from 'react';
import HuiCardPointeForm from './HuiCardPointeForm';

const HuiPaymentCard = (props) => {
  const { token, cardOnFileSearchDisabled, zipOnly, small, hidden } = props;
  const className = small && 'credit-card hui-bg-gray-light px-3 my-4 hui-radius-xl';
  return (
    <div>
      <HuiCardPointeForm
        small={small}
        token={token}
        cardOnFileSearchDisabled={cardOnFileSearchDisabled}
        zipOnly={zipOnly}
        className={className}
        hidden={hidden}
      />
    </div>
  );
};

export default HuiPaymentCard;
