import { parse } from 'content-disposition-header';
import currencyFormatter from 'currency-formatter';
import { saveAs } from 'file-saver';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import toastr from 'toastr';
import { downloadInvoicesCsv } from '../../../actions/invoices/downloadInvoicesCsv';
import { getAllInvoices } from '../../../actions/invoices/getAllInvoices';
import ViewInvoice from '../../../components/invoice/ViewInvoice';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTableTotal from '../../../components/table/HuiTableTotal';
import HuiTableFooter from '../../../components/table/components/HuiTableFooter';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';
import { forceCloseLoading } from '../../../redux/LoadingSlice';
import {
  datePickerWithRangeFilter,
  downloadCsvButtonFilter,
  invoiceFilter,
  invoiceTypeFilter,
  jobAddressFilter,
  jobIdFilter,
  jobNameFilter,
  poNumberFilter,
} from '../../../utils/utilsFilters';
import { size, some } from 'lodash';
import HuiTableWaiverCode from '../../../components/table/HuiTableWaiverCode';

function getColumns(hideProjectColumns, isSortable, hasWaiverInfo) {
  let columns = [
    {
      field: 'waiverStateCode',
      label: <InsertDriveFileOutlinedIcon sx={{ fontSize: 15 }} />,
      width: '40px',
      sortable: false,
      visible: hasWaiverInfo,
      cellRenderer: (props) => <HuiTableWaiverCode value={props.value} />,
    },
    {
      field: 'invoiceCreatedAt',
      label: 'Created Date',
      width: '95px',
      sortable: isSortable,
      getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'dueDate',
      label: 'Due Date',
      width: '95px',
      sortable: isSortable,
      getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'type',
      label: 'Type',
      width: 'auto',
      sortable: isSortable,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'invoiceNumber',
      label: 'Invoice Number',
      width: 'auto',
      sortable: isSortable,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'jobId',
      label: 'Job ID',
      width: 'auto',
      sortable: isSortable,
      visible: !hideProjectColumns,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'poNumber',
      label: 'PO Number',
      width: 'auto',
      sortable: isSortable,
      visible: !hideProjectColumns,
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'totalAmount',
      label: 'Total Amount',
      width: 'auto',
      className: 'text-end',
      sortable: isSortable,
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: ({ value }) => currencyFormatter.format(value, { code: 'USD' }),
      cellRenderer: (props) => <HuiTableTotal {...props} />,
    },
    {
      field: 'currentBalance',
      label: 'Balance',
      width: 'auto',
      className: 'text-end',
      sortable: isSortable,
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: ({ value }) => currencyFormatter.format(value, { code: 'USD' }),
      cellRenderer: (props) => <HuiTableTotal {...props} />,
    },
    {
      pinned: true,
      field: 'viewInvoice',
      label: '',
      width: '60px',
      sortable: false,
      cellRenderer: (props) => <ViewInvoice id={props?.data?.id} icon {...props} />,
    },
  ];

  columns = columns.map((column, i) => {
    return { ...column, id: i };
  });

  return columns;
}

const controller = new AbortController();

const HistoryInvoices = (props) => {
  const {
    project,
    endDate,
    hideHeader,
    isPaginated = true,
    hideProjectColumns = false,
    className = 'hui-table',
    isSortable = true,
    invoices,
  } = props;

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const rowsRef = useRef([]);

  const [allRows, setAllRows] = useState(rowsRef.current);
  const [totalRows, setTotalRows] = useState();
  const [tableManager, setTableManager] = useState({});

  const hasWaiverInfo = some(allRows, i => 
    i.conditionalProgressWaiver ||
    i.unconditionalProgressWaiver ||
    i.conditionalFinalWaiver ||
    i.unconditionalFinalWaiver
  );

  const invoicesHistoryFilters = {
    filters: [
      {
        ...downloadCsvButtonFilter,
        onClick: () => handleDownload(),
      },
      {
        name: 'search',
        type: 'search',
        label: 'Search by Invoice Number, Job Id, PO Number or Address',
      },
      datePickerWithRangeFilter,
    ],
    advancedFilters: [invoiceFilter, invoiceTypeFilter, poNumberFilter, jobIdFilter, jobNameFilter, jobAddressFilter],
  };

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  useEffect(() => {
    async function findInvoices() {
      if (!searchParams.get('size')) {
        searchParams.set('size', tableManager.paginationApi.pageSize);
      }

      const params = Object.fromEntries(searchParams);
      if (project) {
        params.size = 500;
        params.projectId = project.id;
      }

      const result = size(invoices) ? { rows: invoices, count: size(invoices) } : await getAllInvoices(params);

      rowsRef.current = tableManager.asyncApi.mergeRowsAt(
        rowsRef.current,
        result.rows,
        (searchParams.get('page') - 1) * tableManager.paginationApi.pageSize,
      );

      setAllRows(rowsRef.current);
      setTotalRows(result.count);
    }
    if (tableManager.asyncApi) {
      findInvoices();
    }
  }, [dispatch, searchParams, tableManager, project, invoices]);

  const isLoading = totalRows == null;

  return (
    <HuiTable
      additionalProps={
        hideHeader
          ? {}
          : {
              header: {
                showTableCountInfo: true,
                availableFilters: invoicesHistoryFilters,
              },
            }
      }
      components={hideHeader ? {} : { Header: HuiTableHeader, Footer: HuiTableFooter }}
      className={className}
      isLoading={isLoading}
      isPaginated={isPaginated}
      contentName="Invoices"
      columns={getColumns(hideProjectColumns, isSortable, hasWaiverInfo)}
      rows={allRows}
      totalRows={totalRows}
      onLoad={onLoadTableManager}
      sortable={false}
      key={hasWaiverInfo}
    />
  );

  async function handleDownload() {
    try {
      let download = await downloadInvoicesCsv(searchParams);
      const headers = download.headers;
      const data = download.data;

      const blob = new Blob([data], { type: 'text/csv' });
      const disposition = parse(headers['content-disposition']);
      const fileName = disposition.parameters.filename;
      saveAs(blob, fileName);
      toastr.success(`Invoice CSV file downloaded successfully`);
    } catch (e) {
      await dispatch(forceCloseLoading({}));
      toastr.error(`Error downloading Invoice CSV file`);
      console.error(`Error downloading Invoice CSV file`, e);
    }
  }
};

export default HistoryInvoices;
