import React from 'react';
import * as PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

const HuiPanelContent = (props) => {
  const { hidden, className, isOpen = true, children } = props;

  const huiPanelClass = `hui-panel-content ${className || `pt-2 pb-3 px-3`}`;

  return (
    <div
      className={huiPanelClass}
      hidden={!isOpen || hidden}
    >
      {children}
    </div>
  );
};

HuiPanelContent.propTypes = propTypes;

export default HuiPanelContent;
