const HuiTableCell = ({ value, className }) => {
  const classNames = `rgt-cell-inner text-truncate hui-text-sm ${className}`.trim();

  return (
    <div className={classNames} title={value}>
      {value}
    </div>
  );
};

export default HuiTableCell;
