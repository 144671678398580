import { Divider } from '@mui/material';
import { HuiCard, HuiCardContent } from 'handle-ui';
import * as React from 'react';
import CancelRecurrence from '../../recurrence/CancelRecurrence';
import RecurrencePayments from '../../recurrence/RecurrencePayments';
import SidebarHeader from './SidebarHeader';
import SidebarRecurrenceBalance from './SidebarRecurrenceBalance';
import SidebarRecurrenceInfo from './SidebarRecurrenceInfo';

const SidebarRecurrence = (props) => {
  const { recurrence, onCancel } = props;
  const { id } = recurrence || {};

  return (
    <>
      <SidebarHeader>
        <div className="flex-grow-1">
          <h2 className="hui-title mb-0">Recurrence #{id}</h2>
        </div>
        <div className="d-flex align-items-center">
          <CancelRecurrence
            className="hui-btn hui-btn-red hui-btn-outline"
            recurrence={recurrence}
            onSuccess={recurrence.onCancel}
          >
            Cancel Recurrence
          </CancelRecurrence>
        </div>
      </SidebarHeader>

      <div className="hui-bg-gray-light p-4">
        <HuiCard boxShadow border className="mb-3">
          <HuiCardContent>
            <SidebarRecurrenceInfo recurrence={recurrence} />
            <Divider className="my-1" />
            <SidebarRecurrenceBalance recurrence={recurrence} />
          </HuiCardContent>
        </HuiCard>

        <RecurrencePayments recurrence={recurrence} />
      </div>
    </>
  );
};

export default SidebarRecurrence;
