import { HuiInput } from 'handle-ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import { signInWithEmailAndPassword } from '../../../actions/auth/signIn';

const SignInForm = (props) => {
  const { loginHandler, isPortalSignUpEnabled } = props || {};

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const passwordLabel = (
    <>
      <span>Password</span>
      <span className="float-end">
        <Link to="/forgot-password" className="hui-color-black" tabIndex={2}>
          Forgot your password?
        </Link>
      </span>
    </>
  );

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <HuiInput
            tabIndex={1}
            inputProps={{ minLength: 9 }}
            label="Email"
            id="email"
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            size="large"
            topLabel
            required
            hideRequired
          />
        </div>

        <div className="col-12">
          <HuiInput
            tabIndex={2}
            inputProps={{ minLength: 6 }}
            label={passwordLabel}
            id="password"
            name="password"
            type="password"
            value={form.password}
            onChange={handleChange}
            size="large"
            required
            hideRequired
            topLabel
          />
        </div>

        <div className="col-12 d-flex flex-column mt-3">
          <button type="submit" className="hui-btn hui-btn-xl hui-btn-green">
            Login
          </button>
        </div>

        <div className="col-12 d-flex flex-column mt-3 text-center" hidden={!isPortalSignUpEnabled}>
          <Link to="/signup" className="hui-btn hui-btn-outline hui-btn-xl hui-btn-black">
            Create an Account
          </Link>
        </div>
      </div>
    </form>
  );

  function handleChange(e) {
    const { name, value: rawValue } = e.target;
    const value = name === 'email' ? rawValue.toLowerCase() : rawValue;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { email, password } = form;

    if (email && password) {
      try {
        await signInWithEmailAndPassword(email, password);
      } catch (error) {
        toastr.error(error);
      }
    }
  }
};

export default SignInForm;
