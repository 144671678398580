import React from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/ModalSlice';

const ButtonEmail = (props) => {

  const { className, id, type } = props;
  const dispatch = useDispatch();

  return (
    <>
      <button
        type="button"
        onClick={handleChance}
        className={className}>
        {props.children}
      </button>
    </>
  );

  async function handleChance(e) {
    e.stopPropagation();
    const modal = {
      title: 'Email a copy',
      type: 'sendEmail',
      sendEmailType: type,
      sendEmailId: id,
      keepOpen: true,
      hideActions: true,
      hideClose: true,
      draggable: true,
      size: 'md',
    };
    await dispatch(openModal(modal));
  }
};

export default ButtonEmail;
