import axios from 'axios';
import { closeLoading, openLoading } from '../redux/LoadingSlice';
import { store } from '../store/store';

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async function (config) {
  store.dispatch(openLoading());

  return config;
});

function removeLoading() {
  store.dispatch(closeLoading());
}

axiosInstance.interceptors.response.use(
  function (response) {
    removeLoading();
    return response;
  },
  function (error) {
    removeLoading();
    return Promise.reject(error);
  },
);
