import HuiTableCell from './HuiTableCell';
import React from 'react';
import { HuiIcon } from 'handle-ui';
import { Tooltip } from '@mui/material';

const HuiTableRecurrenceTotal = (props) => {
  return (
    <div className="d-flex align-items-center w-100">
      <HuiTableCell {...props} />
      <Tooltip
        arrow
        placement="top"
        title="The displayed amount includes taxes and fees.  Click on the view icon to view details."
      >
        <div className="d-flex">
          <HuiIcon size="xs" name="info" />
        </div>
      </Tooltip>
    </div>
  );
};

export default HuiTableRecurrenceTotal;
