import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PortalLogo from '../../portal/PortalLogo';
import Modal from '../modal/Modal';
import Sidebar from '../sidebar/Sidebar';
import HeaderProfile from './HeaderProfile';
import { HuiIcon } from 'handle-ui';

const Header = (props) => {
  const { onlyLogo } = props || {};

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    return () => {
      setIsMenuOpen(false);
      const nav = document.querySelector('.hui-nav');
      if (nav) {
        nav.classList.remove('hui-nav-open');
      }
    };
  }, [location.pathname]);

  const toggleMenu = () => {
    const nav = document.querySelector('.hui-nav');

    if (nav) {
      setIsMenuOpen(!isMenuOpen);
      nav.classList.toggle('hui-nav-open');
    }
  };

  return (
    <>
      <Modal />
      <Sidebar />

      <header className="hui-header position-fixed d-flex align-items-center hui-bg-white border-bottom">
        <div className="hui-header-toggle me-3 me-md-0">
          <button
            className="hui-btn hui-btn-icon hui-btn-transparent p-2"
            aria-label="Toggle Navigation"
            onClick={toggleMenu}
          >
            <HuiIcon name="toggle" size="xs" />
          </button>
        </div>

        <div className="pe-4 hui-header-logo">
          {onlyLogo ? (
            <PortalLogo />
          ) : (
            <Link to="/dashboard">
              <PortalLogo />
            </Link>
          )}
        </div>
        {!onlyLogo && (
          <div className="flex-grow-1 d-flex align-items-center">
            <HeaderProfile />
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
