export const AppTheme = {
  typography: {
    fontFamily: '"Lato", sans-serif',
    fontSize: 15,
  },

  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          '& > .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          '& > .MuiPaper-root': {
            backgroundColor: '#F7F7F8 !important',
            borderRadius: '0 !important',
            borderRight: '0 !important',
            borderTop: '0 !important',
            borderBottom: '0 !important',
            boxShadow: '3px 5px 30px rgba(0, 0, 0, 0.10) !important',
          },
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& > .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
          },
          '& > .MuiBackdrop-invisible': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
        },
        arrow: {
          color: 'rgba(0, 0, 0, 0.65)',
          borderColor: 'rgba(0, 0, 0, 0.65)',
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#E1E1E1',
          position: 'fixed !important',
          zIndex: '99999 !important',
          width: '100%',
          top: '0',
          '& span': {
            backgroundColor: '#8E909E',
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#202020',
          marginBottom: '5px',
          '&.Mui-focused': {
            color: '#367ef9 !important',
          },
          '&.MuiFormLabel-filled + div > input[value]:invalid + fieldset, &.Mui-error fieldset': {
            borderColor: '#E41B13',
            borderRadius: 7,
            borderWidth: 2,
          },
          '&.MuiFormLabel-filled + .Mui-error > fieldset': {
            borderColor: '#E41B13',
            borderRadius: 7,
            borderWidth: 2,
          },
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0',
          backgroundColor: 'white',
          minWidth: 'auto',
          width: '100%',
          label: {
            color: '#202020',
          },
          '&.MuiTextField-root label': {
            color: '#999',
          },
          'label.MuiInputLabel-sizeSmall:not(.Mui-focused):not(.MuiFormLabel-filled)': {
            lineHeight: '20px',
            fontSize: '15px !important',
          },
          '.MuiInputBase-root.MuiInputLabel-sizeSmall': {
            height: '37px',
          },
          '&.otp-custom-amount': {
            '& .MuiInputBase-root': {
              fontSize: '24px',
              fontWeight: 'bold',
              '& input': {
                textAlign: 'right',
                '&:focus': {
                  color: '#202020 !important',
                },
              },
            },
          },
          '.MuiInput-underline': {
            borderBottom: '1px solid #EAEAEA !important',
            '&::after': {
              display: 'none !important',
            },
            '&::before': {
              display: 'none !important',
            },
            '&:hover': {
              borderBottom: '1px solid #202020 !important',
            },
          },
          '.hui-payment-method-group': {
            '& .MuiFormControlLabel-root': {
              color: '#606060',
              height: '58px',
              border: '2px solid transparent',
              borderRadius: '15px',
              paddingLeft: '10px',
              paddingRight: '10px',

              '& .MuiRadio-root': {
                '& .MuiSvgIcon-root': {
                  width: '16px',
                  height: '16px',
                },
                '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                  color: '#ffffff',
                  backgroundColor: '#ffffff',
                  borderRadius: '50%',
                  border: '1px solid #9A9A9A',
                },
              },

              '&.active': {
                color: '#202020',
                border: '2px solid #EEEEEE',
                backgroundColor: 'white !important',
                boxShadow: '3px 5px 30px rgba(0, 0, 0, 0.05)',

                '& .hui-text': {
                  fontWeight: 'bold',
                },

                '& .MuiRadio-root': {
                  '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': {
                    color: '#24835A',
                    backgroundColor: '#24835A',
                    border: '1px solid transparent',
                  },
                  '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                    color: 'white',
                  },
                },
              },
              '& .MuiTypography-root': {
                paddingLeft: '8px',
                paddingRight: 0,
              },
            },
          },
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .Mui-focused': {
            color: '#367ef9',
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-sizeSmall fieldset': {
            height: '42px',
            borderRadius: 4,
            fontSize: '15px',
          },
          '&.MuiInputBase-sizeSmall input': {
            height: '20px',
            fontSize: '15px',
          },
          '&.MuiInputBase-sizeSmall span': {
            fontSize: '15px !important',
          },
          '&.MuiInputBase-multiline': {
            height: 'auto  !important',
          },
          '&.MuiInputBase-multiline fieldset': {
            height: 'auto  !important',
          },
          '&.MuiInputBase-sizeSmall.MuiInputBase-multiline textarea::placeholder': {
            fontSize: '15px  !important',
            lineHeight: '20px  !important',
          },
          '&.MuiInputBase-sizeSmall input::placeholder': {
            fontSize: '15px',
          },
          '&.MuiInputBase-sizeSmall input:focus + fieldset': {
            borderRadius: 4,
          },
          '&.MuiInputBase-sizeSmall input:hover + fieldset': {
            borderRadius: 4,
          },
          '&.Mui-focused fieldset': {
            borderColor: '#367ef9  !important',
          },
          '& input + fieldset': {
            borderColor: '#E1E1E1',
            borderRadius: 7,
          },
          '& input:hover + fieldset': {
            borderColor: '#202020',
            borderRadius: 7,
          },
          '& input:focus + fieldset': {
            borderColor: '#367ef9',
            borderRadius: 7,
          },
        },
        input: {
          width: '100%',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-sizeSmall': {
            height: '37px',
          },
          '&.MuiInputBase-sizeSmall .MuiSelect-select span': {
            fontSize: '13px',
            color: '#9b9b9b',
          },
          '&.MuiInputBase-sizeSmall .MuiSelect-icon.MuiSelect-iconOpen path': {
            fill: '#000000 !important',
          },
          '&.MuiInputBase-sizeSmall fieldset': {
            height: '42px',
            borderRadius: 4,
          },
          '&.MuiInputBase-sizeSmall input': {
            height: '20px',
            fontSize: '15px',
          },
          '&.MuiInputBase-sizeSmall input::placeholder': {
            fontSize: '13px',
          },
          '&.MuiInputBase-sizeSmall input:focus + fieldset': {
            borderRadius: 4,
          },
          '&.MuiInputBase-sizeSmall input:hover + fieldset': {
            borderRadius: 4,
          },
          '&.Mui-focused fieldset': {
            borderColor: '#367ef9  !important',
          },
          '&.Mui-error fieldset': {
            borderColor: '#E41B13 !important',
            borderWidth: 2,
          },
          '& input + fieldset': {
            borderColor: '#E1E1E1',
            borderRadius: 7,
          },
          '& input:hover + fieldset': {
            borderColor: '#202020',
            borderRadius: 7,
          },
          '& input:focus + fieldset': {
            borderColor: '#367ef9',
            borderRadius: 7,
          },
        },
        input: {
          width: '100%',
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          display: 'none',
        },
        root: {
          color: '#202020',
          marginLeft: 0,
          marginRight: 0,
          '&.Mui-focused': {
            color: '#367ef9 !important',
          },
          '&.Mui-disabled a': {
            color: '#606060',
          },
          // '.MuiTypography-root': {
          //   paddingLeft: 15,
          // },
          '&.MuiFormControlLabel-labelPlacementEnd .MuiTypography-root': {
            paddingRight: 16,
            paddingLeft: 10,
            paddingTop: 0,
            paddingBottom: 0,
            margin: 0,
            display: 'block',
          },
          '&.hui-select-payment-method > .MuiTypography-root': {
            paddingRight: 0,
            width: '100%',
            paddingLeft: 10,
            paddingTop: 2,
            paddingBottom: 2,
          },
          '&.hui-select-payment-method > .Mui-checked': {
            backgroundColor: '#edf8f0',
            borderRadius: '10px 0 0 10px',
            boxShadow: '0 0 0 5px #edf8f0, -1px 0 0 6px #e5efe8',
            color: '#202020',
          },
          '&.hui-select-payment-method > .Mui-checked ~ .MuiTypography-root': {
            backgroundColor: '#edf8f0',
            borderRadius: '0 10px 10px 0',
            boxShadow: '0 0 0 5px #edf8f0, 1px 0 0 6px #e5efe8',
          },
          '&.hui-add-payment-method > .MuiTypography-root': {
            paddingRight: 0,
            width: '100%',
            paddingLeft: 10,
          },
          '&.hui-add-payment-method > .Mui-checked': {
            backgroundColor: '#edf8f0',
            borderRadius: '10px 0 0 10px',
            boxShadow: '0 0 0 5px #edf8f0, -1px 0 0 6px #e5efe8',
            color: '#202020',
          },
          '&.hui-add-payment-method > .Mui-checked ~ .MuiTypography-root': {
            backgroundColor: '#edf8f0',
            borderRadius: '0 10px 10px 0',
            boxShadow: '0 0 0 5px #edf8f0, 1px 0 0 6px #e5efe8',
          },
          // '&.MuiFormControlLabel-labelPlacementEnd > span': {
          //   paddingRight: '15px !important',
          // },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-disabled span:before': {
            opacity: 0.1,
          },
          '&.Mui-checked span': {
            borderColor: '#202020',
          },
          span: {
            borderColor: '#8e909e',
            borderWidth: 1,
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
          boxShadow: '3px 5px 10px rgba(0, 0, 0, 0.05)',
          border: '1px solid #E1E1E1',
          borderRadius: 10,
          '&:hover': {
            border: '1px solid #9A9A9A',
            boxShadow: '3px 5px 10px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
  },
};

export const checkoutFields = {
  input: {
    fontSize: '16px',
    margin: '0px',
    padding: '0 8px',
    height: '57px',
    backgroundColor: 'white',
    borderRadius: 7,
    borderWidth: 2,
    border: '2px solid #E1E1E1',
  },
  'input:hover': {
    border: '2px solid #202020',
  },
  'input:focus': {
    border: '2px solid #367ef9',
  },
  '.brand': {
    right: 14,
    top: ' 50%',
    marginTop: '-17px',
    overflow: 'hidden',
    borderRadius: 6,
  },
};
