import { Menu } from '@mui/material';
import { size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { selectPortals } from '../../../redux/PortalsSlice';
import { getPortalUrl } from '../../../utils/utilsDomain';
import PortalLogo from '../../portal/PortalLogo';

const NavPortal = () => {
  const portalInfo = useSelector(selectPortalInfo);
  const userPortals = useSelector(selectPortals);

  const { name } = portalInfo || {};

  const hasPortals = size(userPortals) > 1;

  const [anchorElPortal, setAnchorElPortal] = React.useState(null);

  const handleOpenPortalMenu = (event) => {
    if (!hasPortals) return false;
    setAnchorElPortal(event.currentTarget);
  };

  const handleClosePortalMenu = () => {
    setAnchorElPortal(null);
  };

  const huiNavClass = `hui-nav-portal ${hasPortals ? 'has-portal' : ''} d-flex align-items-center border-bottom p-4`;

  const portalNameComplete = `${name} Portal`;

  const portals =
    hasPortals &&
    userPortals.map((portal, i) => {
      const { name : currentName, domain, organization, logoUrl } = portal;

      const portalUrl = getPortalUrl(domain, 'dashboard');

      return (
        <a key={i} className="list-group-item list-group-item-action d-flex align-items-center" href={portalUrl}>
          <div className="me-3">
            <PortalLogo organization={organization} logoUrl={logoUrl} className="hui-nav-portal-logo" />
          </div>
          <div className="flex-grow-1">{currentName}</div>
        </a>
      );
    });

  return (
    <>
      <div className={huiNavClass} onClick={handleOpenPortalMenu}>
        <div className="flex-grow-1 overflow-hidden">
          <h3 className="hui-text-xs fw-bold text-uppercase hui-color-gray mb-0">Welcome to</h3>
          <h2 className="hui-color-black fw-bold hui-text mb-0">{portalNameComplete}</h2>
        </div>

        <div className="hui-nav-portal-dropdown ms-2" hidden={!hasPortals}>
          <i className="hui-i hui-svg-dropdown"></i>
        </div>
      </div>

      <Menu
        anchorEl={anchorElPortal}
        anchorOrigin={{
          vertical: 95,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElPortal)}
        onClose={handleClosePortalMenu}
      >
        <div className="list-group list-group-flush hui-nav-portal-menu" onClick={handleClosePortalMenu}>
          <div className="mb-2 mx-2 px-3 py-2 rounded-2 hui-bg-gray-light d-flex align-items-center hui-text fw-bold">
            Payment Portal
            <span className="rounded-pill hui-bg-black ms-2 hui-color-white py-1 px-2 hui-text-xs">
              {size(userPortals)}
            </span>
          </div>
          {portals}
        </div>
      </Menu>
    </>
  );
};

export default NavPortal;
