import React from 'react';
import * as PropTypes from 'prop-types';
import { trim } from 'lodash';

const propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  rounded: PropTypes.bool,
  uppercase: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.any,
};

const HuiBadge = (props) => {
  const {
    className,
    bgColor,
    textColor,
    size = 'md',
    value,
    type = 'span',
    onClick,
    rounded,
    uppercase,
    fullWidth,
    icon,
    children,
    arrowLeft,
    arrowRight,
    arrowTop,
    arrowBottom,
  } = props;

  const text = value || children;

  const hasArrowLeft = arrowLeft ? `hui-badge-arrow hui-badge-arrow-left` : ``;
  const hasArrowRight = arrowRight ? `hui-badge-arrow hui-badge-arrow-right` : ``;
  const hasArrowTop = arrowTop ? `hui-badge-arrow hui-badge-arrow-top` : ``;
  const hasArrowBottom = arrowBottom ? `hui-badge-arrow hui-badge-arrow-bottom` : ``;

  const badgeRounded = rounded ? `rounded-pill` : ``;
  const badgeSize = size ? `hui-badge-${size}` : ``;
  const badgeBgColor = bgColor
    ? `hui-badge-bg-${bgColor}`
    : `hui-badge-bg-gray-light`;
  const badgeTextColor = textColor
    ? `hui-badge-text-${textColor}`
    : `hui-badge-text-default`;
  const badgeUppercase = uppercase ? `text-uppercase` : ``;
  const badgeFullWidth = fullWidth
    ? `w-100 text-center d-flex justify-content-center`
    : ` d-inline-flex`;
  const badgeIcon = icon && !text ? `hui-badge-icon px-0` : ``;
  const badgeHasIcon = icon && text ? `hui-badge-has-icon` : ``;

  const badgeClass = (`
    hui-badge
    ${badgeBgColor}
    ${badgeTextColor}
    ${badgeSize}
    ${badgeRounded}
    ${badgeUppercase}
    ${badgeFullWidth}
    ${badgeIcon}
    ${badgeHasIcon}
    ${hasArrowLeft}
    ${hasArrowRight}
    ${hasArrowTop}
    ${hasArrowBottom}
    ${className}
    `.split('\n').map(trim).join(' ')).trim();


  const classNames = badgeClass.trim();

  const badgeType = {
    button: (
      <button
        onClick={onClick}
        role="button"
        className={classNames}
      >
        {renderBadge()}
      </button>
    ),
    span: <span className={badgeClass}>{renderBadge()}</span>,
  };

  const Badge = badgeType[type];

  function renderBadge() {
    let badge;
    const iconClass = `d-flex align-items-center justify-content-center h-100 ${
      text ? '' : 'mx-auto'
    }`;

    if (icon && !text) {
      badge = (
        <>
          <div className={iconClass}>{icon}</div>
        </>
      );
    } else if (icon && text) {
      badge = (
        <>
          <div className={iconClass}>{icon}</div>
          <div className="ms-1">{value || children}</div>
        </>
      );
    } else {
      badge = value || children;
    }
    return badge;
  }

  return Badge;
};

HuiBadge.propTypes = propTypes;

export default HuiBadge;
