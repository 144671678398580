import { axiosInstance } from '../../utils/utilsAxios';

export async function sendEmailVerification() {
  const url = `/v1/auth/email/verification`;

  try {
    await axiosInstance.post(url);
    return { isEmailVerificationSent: true };
  } catch (e) {
    const { response: { data: { message } } } = e;
    if (message === 'Email already verified') {
      return { isEmailVerified: true };
    } else {
      throw new Error(e.message);
    }
  }
}


