import { HuiIcon, HuiLoading, HuiPanel } from 'handle-ui';
import { isNil, size } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllRecurrences } from '../../../actions/recurrence/getAllRecurrences';
import { selectLoading } from '../../../redux/LoadingSlice';
import DashboardRecurrencesItem from './DashboardRecurrencesItem';
import EmptyComponent from '../../../components/general/EmptyComponent';

const DashboardRecurrences = () => {
  const loading = useSelector(selectLoading);
  const [recurrences, setRecurrences] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function findRecurrences() {
      if (isNil(recurrences) && !loading) {
        try {
          const result = await getAllRecurrences({ status: 'PENDING', size: 5 });
          setRecurrences(result?.rows);
          setIsLoading(!size(result));
        } catch (error) {
          console.error(error);
          setRecurrences(['error']);
        }
      }
    }

    findRecurrences();
  }, [loading, recurrences, setRecurrences]);

  const recurringPayments = recurrences?.map((recurrence, i) => {
    return <DashboardRecurrencesItem key={i} recurrence={recurrence} />;
  });

  const isEmpty = size(recurrences) === 0;

  const createRecurrence = (
    <EmptyComponent
      className="pb-3"
      title="Create Recurrence"
      subtitle="Automate Payments with Recurrence Feature"
      buttonTitle="Create Recurrence"
      link="/prepay"
    />
  );

  return (
    <>
      <HuiPanel className="overflow-hidden position-relative pt-3 hui-text-lg d-flex flex-column flex-grow-1">
        <div className="hui-text-lg fw-bold mb-3 d-flex align-items-center px-3">
          <div className="d-flex me-2">
            <HuiIcon name="reload" size="sm" />
          </div>
          Recurring Payments
        </div>

        {isLoading ? (
          <div className="d-flex align-items-center flex-grow-1 justify-content-center pb-4">
            <HuiLoading size="sm" fixed isOpen />
          </div>
        ) : isEmpty ? (
          createRecurrence
        ) : (
          recurringPayments
        )}
      </HuiPanel>
    </>
  );
};

export default DashboardRecurrences;
