import { compact, concat, find, includes, isEmpty, map, reduce, size } from "lodash";
import { getPortalFeature, getTableConfigByPortal } from "./utilsPortal";

export function addSelectedInvoiceClass(rowIndex) {
  document
    .querySelectorAll(`.hui-table .rgt-row-${rowIndex}`)
    .forEach((cell) => cell.classList.add('rgt-row-invoice-selected'));
}

export function removeSelectedInvoiceClass() {
  document
    .querySelectorAll(`.rgt-wrapper .rgt-cell`)
    .forEach((cell) => cell.classList.remove('rgt-row-invoice-selected'));
}

export function getColumnsByPortal(tableName, columns, portal) {
  const isTableCustomizationEnabled = getPortalFeature('isTableCustomizationEnabled', portal);
  if (!isTableCustomizationEnabled || !size(columns) || isEmpty(tableName)) {
    return columns || [];
  }

  const config = getTableConfigByPortal(tableName, portal);

  if (!size(config) || !size(config.columns)) {
    return columns || [];
  }

  const customColumns = compact(map(config.columns, (name) => name));

  const columnsInNewOrder = reduce(
    customColumns,
    (acc, columnName) => {
      const column = find(columns, (column) => column.field === columnName);
      if (!column) {
        return acc;
      }
      column.visible = true;
      acc.push(column);
      return acc;
    },
    [],
  );

  const lastColumns = reduce(
    columns,
    (acc, column) => {
      const isReordering = includes(customColumns, column.field);
      if (isReordering) {
        return acc;
      }
      column.visible = false;
      acc.push(column);
      return acc;
    },
    [],
  );

  return concat(columnsInNewOrder, lastColumns);
}
