import { HuiPanel } from 'handle-ui';
import React from 'react';
import { Link } from 'react-router-dom';

const RequestWaiverConfirmation = () => {
  return (
    <div className="d-flex flex-column hui-thanks-content p-4">
      <HuiPanel className="w-100 mb-4" boxShadow>
        <div className="text-center p-5 hui-bg-green-light">
          <h1 className="text-center hui-color-green hui-title mb-2">Request Sent!</h1>
          <div className="hui-text-lg">Your waiver request has been successfully submitted!</div>
        </div>

        <div className="px-4">
          <div className="mx-auto hui-thanks-info">
            <div className="my-4 hui-bg-gray-light p-3 rounded-3 d-flex align-items-center hui-thanks-info">
              <div className="d-flex me-2">
                <i className="hui-i hui-svg-toastr-success"></i>
              </div>
              The team will be notified that you have requested a waiver.
            </div>
          </div>
        </div>
        <div className="py-4 text-center">
          <Link to="/request-waiver" className="hui-btn hui-btn-green">
            Submit a new request
          </Link>
        </div>
      </HuiPanel>
    </div>
  );
};

export default RequestWaiverConfirmation;
