import { Avatar } from '@mui/material';
import { toUpper } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSidebar } from '../../../redux/SidebarSlice';

const SidebarInvoiceInfo = () => {
  const invoice = useSelector(selectSidebar);

  const {
    invoiceNumber,
    customerId,
    customerName,
    jobId,
    dueDate,
    invoiceCreatedAt,
    jobName,
    memo
  } = invoice;

  return (
    <>
      <div className="d-flex">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center mb-2">
            <div className="me-3">
              <Avatar className="hui-header-avatar">
                {toUpper(customerName && customerName[0])}
              </Avatar>
            </div>
            <div className="hui-text-lg fw-bold">
              {customerName}
            </div>
          </div>
          <div>
            <strong className="hui-color-black-light">Customer ID: </strong>
            <span className="hui-color-gray">
              {customerId}
            </span>
          </div>
          <div>
            <strong className="hui-color-black-light">Project Name: </strong>
            <span className="hui-color-gray">
              {jobName}
            </span>
          </div>
          <div hidden={!memo}>
            <strong className="hui-color-black-light">Memo: </strong>
            <span className="hui-color-gray">
              {memo}
            </span>
          </div>          
        </div>
        <div className="text-end">
          <div className="text-nowrap">
            <strong className="hui-color-black-light">Invoice Number: </strong>
            <span className="hui-color-gray">
              {invoiceNumber}
            </span>
          </div>
          <div className="text-nowrap">
            <strong className="hui-color-black-light">Job ID: </strong>
            <span className="hui-color-gray">
              {jobId}
            </span>
          </div>
          <div className="text-nowrap">
            <strong className="hui-color-black-light">Created at: </strong>
            <span className="hui-color-gray">
              {moment(invoiceCreatedAt).format('MMMM D, YYYY')}
            </span>
          </div>
          <div className="text-nowrap">
            <strong className="hui-color-black-light">Due Date: </strong>
            <span className="hui-color-gray">
              {moment(dueDate).format('MMMM D, YYYY')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};


export default SidebarInvoiceInfo;
