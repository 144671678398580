import { HuiInput, HuiLoading } from 'handle-ui';
import { useEffect, useState } from 'react';
import toastr from 'toastr';
import { reauthenticateWithCredential } from '../../../../actions/auth/reauthenticateWithCredential';
import { updateUserPassword } from '../../../../actions/auth/updateUserPassword';

const ProfilePassword = (props) => {
  const { hidden } = props;
  const [form, setForm] = useState({ password: '', confirmPassword: '', oldPassword: '' });
  const [isPasswordDifferent, setIsPasswordDifferent] = useState(false);
  const [isLoading, setIsIsLoading] = useState(false);

  useEffect(() => {
    async function changePassword() {
      const { password, confirmPassword } = form;

      if (!confirmPassword) return false;

      if (password && confirmPassword && password === confirmPassword) {
        setIsPasswordDifferent(false);
        return true;
      } else {
        setIsPasswordDifferent(true);
        return false;
      }
    }

    changePassword();
  }, [form, setIsPasswordDifferent]);

  if (hidden) {
    return <></>;
  }

  return (
    <>
      <form className="d-flex  justify-content-center" onSubmit={handleSubmit}>
        <HuiLoading isOpen={isLoading} />
        <div className="col-6">
          <h2 className="hui-title mb-4 mt-4">Change Password</h2>
          <div className="col-12">
            <HuiInput
              label="Old Password"
              id="oldPassword"
              name="oldPassword"
              type="password"
              value={form.oldPassword}
              onChange={handleChange}
              inputProps={{ minLength: 6 }}
              size="large"
              required
              topLabel
            />
          </div>
          <div className="col-12">
            <HuiInput
              label="New Password"
              id="password"
              name="password"
              type="password"
              value={form.password}
              onChange={handleChange}
              inputProps={{ minLength: 6 }}
              size="large"
              required
              topLabel
            />
          </div>

          <div className="col-12">
            <HuiInput
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              inputProps={{ minLength: 6 }}
              type="password"
              value={form.confirmPassword}
              onChange={handleChange}
              size="large"
              required
              topLabel
              error={isPasswordDifferent}
            />
            <div className="hui-color-red hui-text" hidden={!isPasswordDifferent}>
              Please make sure your passwords match.
            </div>
          </div>

          <div className="col-12 d-flex flex-column mt-3">
            <button type="submit" className="hui-btn hui-btn-xl hui-btn-green" disabled={isPasswordDifferent || (!form.oldPassword || !form.confirmPassword || !form.password)}>
              Change Password
            </button>
          </div>
        </div>
      </form>
    </>
  );

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { oldPassword, password } = form;
    if (isPasswordDifferent) {
      return;
    }
    
    try {
      setIsIsLoading(true);
      const isValid = await checkIfUserPasswordIsValid(oldPassword);
      if (!isValid) {
        toastr.error('Something went wrong, try again or contact support.');
        return;
      }
      const updated = await updateUserPassword(password);
      if (!updated) {
        toastr.error('Something went wrong, try again or contact support.');
        return;
      }
      setForm({ password: '', confirmPassword: '', oldPassword: '' });
      toastr.success('Your password has been changed successfully.');
    } catch (error) {
      handleFirebaseError(error);
    } finally {
      setIsIsLoading(false);
    }
  }

  async function checkIfUserPasswordIsValid(password) {
    const msgError = 'Something went wrong, try again or contact support.';
    try {
      return await reauthenticateWithCredential(password);
    } catch (error) {
      toastr.error(msgError);
    }
    return false;
  }
  function handleFirebaseError(error) {
    if (error.code === 'auth/requires-recent-login') {
      toastr.error('You need to logout and login again for this operation');
    } else {
      const { message = 'Failed to update your user data' } = error;
      toastr.error(message);
    }
  };
};

export default ProfilePassword;
