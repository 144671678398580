import React from 'react';
import { compact, first, trim } from 'lodash';
import * as FeatherIcon from 'react-feather';
import * as PropTypes from 'prop-types';
import HuiIcon from '../../icons/HuiIcons';


/**
 * @namespace HuiAlert
 * Shows application alert messages
 *
 * @param {event} button
 * @param {string} buttonLabel Button text
 * @param {string} link Specify the URL path
 * @param {string} linkLabel Link text
 * @param {string} title Optional alert title
 * @param {string} text Or children
 * @param {string} type blue || black || gray ||red ||green ||yellow
 * @param {string} icon Use Icons names
 * @param {string} feIcon Use FeatherIcon names
 * @param {string} svg Use SVG icons class
 * @param {boolean} inline Change the style to inline text
 * @param {boolean} iconCenter
 * @param {boolean} noIcon
 * @param {boolean} border
 * @param {boolean} background Add background color to the alert
 * @param {boolean} coloredBorder Add a colored border around the alert
 * @param {boolean} highlight Add a left border
 * @param {string} size The size of the button
 * @param size.sm Small
 * @param size.md Medium || default
 * @param size.lg Large
 * @param {string} radius Border radius
 * @param radius.sm Small
 * @param radius.md Medium || default
 * @param radius.lg Large
 * @param radius.none None *
 * @param shadow.sm Small
 * @param shadow.md Medium || default
 * @param shadow.lg Large
 *
 * @author [Lucas Azevedo](https://github.com/lucasazevedonet)
 */

const propTypes = {
  title: PropTypes.node,
  children: PropTypes.any,
  className: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
  linkLabel: PropTypes.string,
  link: PropTypes.any,
  radius: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  uiIcon: PropTypes.string,
  uiIconSize: PropTypes.string,
  feIcon: PropTypes.string,
  inline: PropTypes.bool,
  svg: PropTypes.string,
  btn: PropTypes.string,
  hidden: PropTypes.bool,
  iconCenter: PropTypes.bool,
  type: PropTypes.string,
  yellow: PropTypes.bool,
  red: PropTypes.bool,
  green: PropTypes.bool,
  gray: PropTypes.bool,
  blue: PropTypes.bool,
  black: PropTypes.bool,
  border: PropTypes.bool,
  noIcon: PropTypes.bool,
  background: PropTypes.bool,
  shadow: PropTypes.bool,
  coloredBorder: PropTypes.bool,
  highlight: PropTypes.bool,
  button: PropTypes.func,
  onClick: PropTypes.func,
};


const color = {
  'blue': 'hui-color-black-light',
  'green': 'hui-color-black-light',
  'red': 'hui-color-black-light',
  'yellow': 'hui-color-black-light',
  'gray': 'hui-color-black-light',
  'black': 'hui-color-white',
};

const boxSize = {
  'sm': 'p-2 hui-text-sm',
  'md': 'p-3 hui-text',
  'lg': 'p-4 hui-text-lg',
};

const boxIconPadding = {
  'sm': 'pe-1',
  'md': 'pe-2',
  'lg': 'pe-3',
};

const shadows = {
  'xl': 'hui-shadow-xl',
  'lg': 'hui-shadow-lg',
  'md': 'hui-shadow',
  true: 'hui-shadow',
  'sm': 'hui-shadow-sm',
  'none': '',
};

const boxRadius = {
  'xl': 'hui-radius-xl',
  'lg': 'hui-radius-lg',
  'md': 'hui-radius',
  'sm': 'hui-radius-sm',
  'none': '',
};

const icons = {
  'warning': 'i-svg-warning-sm',
  'error': 'hui-svg-info-sm',
  'info-blue': 'hui-svg-info-sm',
  'info': 'hui-svg-info-gray-sm',
  'green-check': 'hui-svg-success-sm',
  'white-check': 'hui-svg-success-sm-white',
  'help': 'i-svg-help-sm',
};

const boxBackground = {
  'blue': 'hui-bg-blue-light',
  'red': 'hui-bg-red-light',
  'yellow': 'hui-bg-yellow-light',
  'green': 'hui-bg-green-light',
  'gray': 'hui-bg-gray-light',
  'black': 'hui-bg-black-1',
};

class HuiAlert extends React.PureComponent {
  render() {
    const {
      children,
      text = '',
      title,
      size,
      radius,
      hidden,
      onClick,
      link = '',
      button,
      type,
      btn,
      buttonLabel,
      yellow,
      red,
      green,
      blue,
      gray,
      black,
      background,
      highlight,
      inline,
      svg,
      icon,
      iconCenter,
      feIcon,
      uiIcon,
      uiIconSize,
      noIcon,
      coloredBorder,
      shadow,
      className = '',
      border,
    } = this.props;

    const getSize = boxSize[size || 'md'];
    const getIconPadding = boxIconPadding[size || 'md'];
    const getRadius = boxRadius[radius] || 'hui-radius-lg';

    const getType = type || first(compact([
      yellow && 'yellow',
      red && 'red',
      green && 'green',
      blue && 'blue',
      gray && 'gray',
      black && 'black',
    ]));

    const getColor = color[getType] || '';
    const getBackground = background || getType === 'black' ? boxBackground[getType] : 'hui-bg-white';
    const getShadow = shadows[shadow] || '';

    const getIcon = icons[icon] || '';

    const IconComponent = FeatherIcon[feIcon];

    const hasProps = !!button || !!link || !!title || !!text || !!icon || !!feIcon;

    const boxClass = (`
      hui-alert
      hui-alert-${getType}
      ${highlight ? 'hui-alert-highlight' : ''} 
      ${coloredBorder ? 'hui-alert-color-border' : ''} 
      ${border && !coloredBorder ? 'border' : ''}
      ${coloredBorder ? 'border hui-alert-border' : ''}
      ${getRadius}
      ${getShadow} 
      ${getSize}
      ${getColor}
      ${getBackground} 
      ${iconCenter ? 'align-items-center' : ''}
      d-flex
      clearfix
      ${className}
    `.split('\n').map(trim).join(' ')).trim();


    return (
      <div className={boxClass} hidden={hidden}>
        {
          !noIcon && !!inline && (
            <div className={`d-flex align-items-center ${getIconPadding}`}>
              {
                !!svg && !iconCenter && (
                  <i className={`hui-i ${svg}`}></i>
                )
              }

              {
                !!feIcon && !iconCenter && (
                  <IconComponent size={18} className={getColor} />
                )
              }

              {
                !!uiIcon && !iconCenter && (
                  <HuiIcon name={uiIcon} size={uiIconSize}  />
                )
              }

              {
                !svg && !feIcon && getIcon && !iconCenter && (
                  <i className={`hui-i ${getIcon}`}></i>
                )
              }
            </div>
          )
        }

        {
          !noIcon && !!iconCenter && !inline && (
            <div className={`d-flex align-items-center ${getIconPadding}`}>
              {
                !!svg && (
                  <i className={`hui-i ${svg}`}></i>
                )
              }

              {
                !!feIcon && (
                  <IconComponent size={18} className={getColor} />
                )
              }
              
              {
                !!uiIcon && (
                  <HuiIcon name={uiIcon} size={uiIconSize}  /> 
                )
              }

              {
                !svg && !feIcon && getIcon && (
                  <i className={getIcon}></i>
                )
              }
            </div>
          )
        }

        {
          !noIcon && !!feIcon && !title && (
            <div className={`d-flex align-items-center ${getIconPadding}`}>
              {
                !!svg && (
                  <i className={`hui-i ${svg}`}></i>
                )
              }

              {
                !!feIcon && (
                  <IconComponent size={18} className={getColor} />
                )
              }

{
                !!uiIcon && (
                  <HuiIcon name={uiIcon} size={uiIconSize}  /> 
                )
              }

              {
                !svg && !feIcon && getIcon && (
                  <i className={getIcon}></i>
                )
              }
            </div>
          )
        }

        {
          !noIcon && !children && !!text && (
            <div className={`d-flex align-items-center ${getIconPadding}`}>
              {
                !!svg && <i className={`hui-i ${svg}`}></i>
              }

              {
                !!feIcon && (
                  <IconComponent size={18} className={getColor} />
                )
              }

              {
                !!uiIcon && (
                  <HuiIcon name={uiIcon} size={uiIconSize}  />
                )
              }


              {
                !svg && !feIcon && getIcon && <i className={getIcon}></i>
              }
            </div>
          )
        }

        <div className={`d-flex flex-grow-1 ${inline ? 'flex-row align-items-center' : 'flex-column'}`}>
          {
            !inline && !!title && (
              <div className="d-flex align-items-center justify-content-start">
                {
                  !noIcon && !!svg && !iconCenter && (
                    <div className="d-flex me-1">
                      <i className={`hui-i ${svg}`}></i>
                    </div>
                  )
                }

                {
                  !noIcon && !!feIcon && !uiIcon && !iconCenter && (
                    <div className="d-flex me-1">
                      <IconComponent size={18} className={getColor} />
                    </div>
                  )
                }

                {
                  !noIcon && !!uiIcon && !feIcon && !iconCenter && (
                    <div className="d-flex me-1">
                      <HuiIcon name={uiIcon} size={uiIconSize}  />
                    </div>
                  )
                }

                {
                  !noIcon && !svg && !feIcon && getIcon && !iconCenter && (
                    <div className="d-flex me-1">
                      <i className={`hui-i ${getIcon}`}></i>
                    </div>
                  )
                }

                <div className="hui-bold">
                  {title}
                </div>
              </div>
            )
          }

          {children && hasProps && (<div className="hui-alert-content">
              {
                !!inline && !!title && (
                  <span className="hui-bold pe-2">
                  {title}
                </span>
                )
              }
              {children || text}
            </div>
          )}

          {!hasProps ? children : null}

          {
            !!button && (
              <div className={`d-flex align-items-center ${!!inline && 'ms-auto ps-2'}`}>
                <button
                  type="button"
                  onClick={onClick}
                  className={`hui-btn ${btn ? ` hui-btn-${size || ''}` : 'hui-btn-link no-height'}`}>
                  {buttonLabel}
                </button>
              </div>
            )
          }

          {
            !!link && (
              <div className={`d-flex align-items-center ${!!inline && 'ms-auto ps-2'}`}>
                {link}
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

HuiAlert.propTypes = propTypes;

export default HuiAlert;
