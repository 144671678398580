import { addMonths, differenceInMonths, getDate, isBefore, isSameMonth, setDate } from 'date-fns';

export function calculateRecurrenceInstallments(start, end) {
  if(!start || !end) {
    return null;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);

  if (isBefore(endDate, startDate)) {
    return null;
  }

  const paymentDates = [];
  let lastDate = startDate;
  paymentDates.push(lastDate);
  const diffMonths = differenceInMonths(endDate, startDate);

  for (let currentMonth = 1; currentMonth <= diffMonths; currentMonth++) {
    lastDate = addMonths(lastDate, 1);
    //Try to keep the same day because of the days available in the month
    if (getDate(startDate) !== getDate(lastDate)) {
      //Set the day to the same as startDate if its in the same month
      const newLastDate = setDate(lastDate, getDate(startDate));
      lastDate = isSameMonth(lastDate, newLastDate) ? newLastDate : lastDate;
    }
    paymentDates.push(lastDate);
  }
  return paymentDates;
}
