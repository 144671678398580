export const achSubmitPaymentMessage = `By clicking “Submit Payment” I authorize [Originator’s Name] to debit my bank account for an ACH payment for the amount and date(s) selected. I also authorize [Originator’s Name] to debit or credit my account for refunds or correct any errors. I will not dispute [Originator’s Name] debiting my account so long as the transaction corresponds to the terms in this authorization and my agreement with [Originator’s Name].`;

export const cardSubmitPaymentMessage = `By clicking “Submit Payment,” I authorize [Originator’s Name] to charge my credit/debit card for the payment amount and on the date(s) selected. I agree not to dispute any charges made by [Originator’s Name] to my credit/debit card account, as the transaction corresponds to the terms in this authorization and my agreement with [Originator’s Name]. `;

export const privateAchSubmitPaymentMessage = `By clicking “Submit Payment” I affirm that I have obtained authorization from the customer  to process the ACH payment and debit their bank account for the amount and date(s) selected. By submitting the payment, I acknowledge that I am authorized to conduct this transaction and that this action complies with the terms and conditions agreed upon between the customer and [Supplier Company Name]. `;

export const privateCardSubmitPaymentMessage = `By clicking “Submit Payment” I affirm that I have obtained authorization from the customer to process a payment on their behalf and charge their credit/debit card for the amount and on the date(s) selected. By submitting the payment, I acknowledge that I am authorized to conduct this transaction and that this action complies with the terms and conditions agreed upon between the customer and [Supplier Company Name].`;

export function submitPaymentMessage(isPaidOnBehalf, companyName, type) {
  if (isPaidOnBehalf) {
    const message = type === 'ACH' ? privateAchSubmitPaymentMessage : privateCardSubmitPaymentMessage;
    return message.replaceAll('[Supplier Company Name]', companyName);
  } else {
    const message = type === 'ACH' ? achSubmitPaymentMessage : cardSubmitPaymentMessage;
    return message.replaceAll('[Originator’s Name]', companyName);
  }
}
