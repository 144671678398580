import * as PropTypes from 'prop-types';
import { compact, first, trim } from 'lodash';

const propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  boxShadow: PropTypes.any,
  noBorder: PropTypes.bool,
};

const boxShadows = {
  'sm': 'hui-shadow-sm',
  'md': 'hui-shadow',
  'lg': 'hui-shadow-lg',
  'xl': 'hui-shadow-xl',
};

const HuiPanel = (props) => {
  const { hidden, title, boxShadow, className, noBorder } = props;

  const getShadow = boxShadows[boxShadow] || 'hui-shadow';

  const huiPanelClass = (`
      hui-panel
      ${className || ``}
      ${noBorder ? '' : 'hui-panel-border'}
      ${getShadow}
      ${title ? 'hui-panel-title' : ''}
    `.split('\n').map(trim).join(' ')).trim();

  return (
    <div
      className={huiPanelClass}
      hidden={hidden}>
      {!!title && <div className="px-3 pt-3 hui-text-lg fw-bold">{title}</div>}
      {props.children}
    </div>
  );
};

HuiPanel.propTypes = propTypes;

export default HuiPanel;
