import { HuiCheckbox } from 'handle-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCredit, removeCredit, selectCreditData } from '../../redux/CreditDataSlice';
import { selectSidebar, updateAmountWithCreditApplied } from '../../redux/SidebarSlice';
import { store } from '../../store/store';

const HuiTableCheckBoxCredit = (props) => {
  const { data, tableManager, value } = props;
  const credit = data;

  const creditsData = useSelector(selectCreditData);
  const creditInvoice = useSelector(selectSidebar);

  const { creditOverpay, credits } = creditsData;
  const isAdded = credits.find((c) => c.id === data.id);

  const dispatch = useDispatch();

  useEffect(() => {
    const ids = credits.map((c) => c.id);
    tableManager.rowSelectionApi.setSelectedRowsIds(ids);
  }, [credits, tableManager.rowSelectionApi]);

  return (
    <HuiCheckbox
      className="mb-0"
      onChange={handleChange}
      size="small"
      showLabel
      checked={value}
      disabled={creditOverpay && !isAdded}
    />
  );

  async function handleChange(e) {
    e.stopPropagation();
    tableManager.rowSelectionApi.toggleRowSelection(data.id);

    if (e.target.checked) {
      await addCreditData();
    }

    if (!e.target.checked) {
      await removeCreditData();
    }
  }

  async function addCreditData() {
    await dispatch(addCredit({ creditInvoice, credit }));
    updateInvoiceAmount();
  }

  async function removeCreditData() {
    await dispatch(removeCredit({ creditInvoice, credit }));
    updateInvoiceAmount();
  }

  function updateInvoiceAmount() {
    const { creditApplied } = store.getState().creditData;
    dispatch(updateAmountWithCreditApplied(creditApplied));
  }
};

export default HuiTableCheckBoxCredit;
