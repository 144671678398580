import { size } from 'lodash';
import { axiosInstance } from '../../utils/utilsAxiosMain';

export async function createWaiverRequest(form) {
  const url = `/api/waiver-request/save/t2`;

  try {
    const formData = buildPayload(form);
    const response = await axiosInstance.post(url, formData);
    const data = response.data;
    return data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response?.data);
    } else {
      throw new Error(error.message);
    }
  }
}

function buildPayload(waiver) {
  const requestForm = new FormData();

  if (size(waiver.files)) {
    for (let file of waiver.files) {
      requestForm.append('waiverFiles[]', file);
    }
  }

  Object.keys(waiver).forEach((key) => {
    const isSet = waiver[key] !== undefined && waiver[key] !== 'undefined';
    const isFileUpload = key === 'files' || key === 'inputFile';
    if (isSet && !isFileUpload) {
      requestForm.append(key, waiver[key]);
    }
  });

  return requestForm;
}
