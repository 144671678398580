import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import React from 'react';

const HuiInputField = (props) => {
  const {
    label,
    id,
    name,
    type,
    value,
    size,
    onChange,
    onBlur,
    onKeyDown,
    required,
    placeholder,
    InputProps,
    error,
    disabled,
    topLabel,
    min,
    multiline,
    maxRows,
    variant = 'outlined',
    minRows,
    multiple,
    helperText,
    dataPrivate,
    inputProps,
    inputClassName,
    hidden,
    tabIndex,
  } = props;

  const inputSize = size === 'large' ? '' : 'small';

  return (
    <>
      {!!topLabel && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <TextField
        hidden={hidden}
        sx={
          label && !topLabel
            ? {}
            : {
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiInputBase-sizeSmall fieldset': {
                height: '37px !important',
                fontSize: '15px !important',
              },
              '& .MuiInputBase-sizeSmall label': {
                fontSize: '15px !important',
              },
            }
        }
        variant={variant}
        placeholder={placeholder || ''}
        id={id}
        label={topLabel ? '' : label}
        error={error}
        helperText={helperText}
        name={name}
        className={inputClassName}
        type={type}
        value={value || ''}
        size={inputSize}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        required={required}
        InputProps={InputProps}
        min={min}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        disabled={disabled}
        multiple={multiple}
        data-private={dataPrivate}
        inputProps={inputProps}
        tabIndex={tabIndex}
      />
    </>
  );
};

export default HuiInputField;
