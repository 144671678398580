import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const HuiTableSize = ({
  tableManager,
  value = tableManager.paginationApi.pageSize,
  onChange = tableManager.paginationApi.setPageSize,
  options = tableManager.config.pageSizes,
}) => {
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const [currentSize, setCurrentSize] = useState(value);

  useEffect(() => {
    setCurrentSize(value);
  }, [value]);

  const {
    config: {
      texts: { rowsPerPage: rowsPerPageText },
      additionalProps: { pagination: additionalProps = {} },
    },
  } = tableManager;

  let classNames = ('rgt-footer-pagination d-flex align-items-center mx-3' + (additionalProps.className || '')).trim();

  const handleOnChange = (name, value) => {
    onChange(value);
    urlSearchParams.set(name, value);
    setSearchParams(new URLSearchParams(urlSearchParams.toString()));
  };

  const onSizeChange = (size) => {
    onChange(size);
    if (Number(currentSize) !== Number(size)) {
      urlSearchParams.set('page', 1);
      setSearchParams(new URLSearchParams(urlSearchParams.toString()));
    }
    setCurrentSize(size);
    handleOnChange('size', size);
  };

  return (
    <div {...additionalProps} className={classNames}>
      <div className="hui-text-xs fw-bold text-uppercase hui-color-gray me-2"> {rowsPerPageText} </div>

      <select
        value={currentSize}
        onChange={(event) => {
          onSizeChange(event.target.value);
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default HuiTableSize;
