import * as React from 'react';
import { useEffect, useState } from 'react';
import toastr from 'toastr';
import { cancelRecurrence } from '../../actions/recurrence/cancelRecurrence';
import { getRecurrence } from '../../actions/recurrence/getRecurrence';
import Modal from '../layout/modal/Modal';
import RecurrencePayments from './RecurrencePayments';

const CancelRecurrence = (props) => {
  const { className, recurrence, onSuccess } = props;
  const { id, status } = recurrence;

  const [isOpen, setIsOpen] = useState(false);
  const [newRecurrence, setNewRecurrence] = useState([]);

  const isCanceled = status === 'CANCELED' || newRecurrence.status === 'CANCELED';

  useEffect(() => {
    async function loadPayments() {
      const recurrence = await getRecurrence(id);
      setNewRecurrence(recurrence);
    }

    if (isOpen) {
      loadPayments();
    }
  }, [id, setNewRecurrence, isOpen]);

  return (
    <>
      {!!isOpen && (
        <Modal isOpen={isOpen} hideClose keepOpen title="Confirm Cancel Recurrence" size="md">
          <h3 className="my-3 flex-grow-1 fw-bold hui-text-lg">Confirm Cancel Recurrence</h3>
          <div className="pb-3">Are you sure you want to cancel this payment?</div>
          <RecurrencePayments recurrence={newRecurrence} hideButton alert />

          <div className="hui-bg-gray-light rounded-3 p-2 hui-text-sm mb-2 d-flex align-items-center my-3">
            <div className="d-flex me-1">
              <i className="hui-i hui-svg-info-gray-sm"></i>
            </div>
            <div className="flex-grow-1">
              Press "Confirm" to proceed with the cancellation or "Close" to return to the payments.
            </div>
          </div>

          <div className="d-flex justify-content-between mt-3">
            <button className="hui-btn hui-btn-outline" onClick={() => toggleCancel()}>
              Close
            </button>

            <button className="hui-btn hui-btn-red " onClick={() => handleCancelRecurrence()}>
              Confirm
            </button>
          </div>
        </Modal>
      )}
      <button className={className} disabled={isCanceled} onClick={() => toggleCancel()}>
        {props.children}
      </button>
    </>
  );

  function toggleCancel() {
    setIsOpen(!isOpen);
  }

  async function handleCancelRecurrence() {
    try {
      const recurrence = await cancelRecurrence(id);
      if (recurrence.status === 'CANCELED') {
        toastr.success(`Recurrence Payment Canceled Successfully`);
        setNewRecurrence(recurrence);
        onSuccess(id);
      }
    } catch (e) {
      toastr.error(`An error occurred during canceling the recurrence payment`);
      console.error(`An error occurred during canceling the recurrence payment`, e);
    } finally {
      toggleCancel();
    }
  }
};

export default CancelRecurrence;
