import { HuiLoading } from 'handle-ui';
import { isEmpty, size } from 'lodash';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectAuth } from '../redux/AuthSlice';
import { selectUser } from '../redux/UserSlice';

function Loading() {
  return <HuiLoading isOpen />
}

export function PrivateRoute(props) {
  const { allowFirebaseUser, children } = props;

  const auth = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const isWithinIframe = window.parent !== window;

  if (!size(auth) && auth !== null && !isWithinIframe) {
    return <Navigate to="/login" />;
  }

  const isUserLoaded = allowFirebaseUser ? !isEmpty(auth?.uid) : !isEmpty(user?.email);  

  return (
    <>
      {
        isUserLoaded ? children : <Loading />
      }
    </>
  );
}
