import { Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './Routes';
import { PrivateRoute } from './PrivateRoute';
import Layout from './Layout';
import LayoutSlim from './LayoutSlim';

const Templates = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      {PrivateRoutes.map((route) => {
        const { blank, name, path, slim, allowFirebaseUser } = route;
        const SelectedLayout = slim ? LayoutSlim : Layout;
        const TemplateLayout = blank ? Fragment : SelectedLayout;

        return (
          <Route
            key={name}
            path={path}
            element={
              <PrivateRoute allowFirebaseUser={allowFirebaseUser}>
                <TemplateLayout>
                  <route.template />
                </TemplateLayout>
              </PrivateRoute>
            }
          />
        );
      })}
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};
export default Templates;
