import { HuiAlert, HuiIcon } from 'handle-ui';
import React from 'react';

const BusinessDayMessage = ({ isBusinessDay }) => {
  return (
    !isBusinessDay && (
      <HuiAlert 
        title="Info:" 
        radius="xs" 
        iconCenter 
        uiIcon="calendar" 
        uiIconSize="xs" 
        className="mb-2" 
        background 
        yellow 
        border>
          Payments submitted on <strong>weekends</strong> or <strong>federal holidays</strong> will be processed on the next business day.
      </HuiAlert>
    )
  );
};

export default BusinessDayMessage;
