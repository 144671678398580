import React from 'react';
import * as PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  fixed: PropTypes.bool,
};

const huiLoadingSizes = {
  'lg': 'hui-loading-lg',
  'md': 'hui-loading-md',
  'sm': 'hui-loading-sm',
};
const HuiLoading = (props) => {
  const { className, isOpen, size = 'md', fixed } = props;

  const getLoadingSize = huiLoadingSizes[size];

  const isFixed = fixed ? 'hui-loading-fixed' : '';

  const loadingClass = `hui-loading-wrap ${className ?? 'hui-theme-default'} ${getLoadingSize} ${isFixed}`;

  return (
    <div
      className={loadingClass}
      hidden={!isOpen}
    >
      <div className="hui-loading"></div>
    </div>
  );
};

HuiLoading.propTypes = propTypes;

export default HuiLoading;
