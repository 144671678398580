// All values needs to be in lowerCase

export const getBadgeColorByStatus = {
  started: { bg: 'blue-light', text: 'black' },
  requested: { bg: 'gray-light', text: 'black' },
  approved: { bg: 'green-light', text: 'green-dark' },
  canceled: { bg: 'red-light', text: 'red-dark' },
  refunded: { bg: 'gray-light', text: 'default' },
  voided: { bg: 'gray-light', text: 'default' },
  executed: { bg: 'green-light', text: 'black' },
  scheduled: { bg: 'yellow-light', text: 'black' },
};

export const getBadgeColorByRecurrenceStatus = {
  pending: { bg: 'yellow-light', text: 'black' },
  completed: { bg: 'green-light', text: 'black' },
  canceled: { bg: 'gray-light', text: 'default' },
};

export const getBadgeColorByWaiverStatus = {
  new: { bg: 'gray-light', text: 'black' },
  'requested-approval': { bg: 'gray-light', text: 'black' },
  confirmed: { bg: 'blue-light', text: 'black' },
  'pending-notarization': { bg: 'blue-light', text: 'black' },
  notarized: { bg: 'green-light', text: 'black' },
  ready: { bg: 'green-light', text: 'black' },
  rejected: { bg: 'red-light', text: 'default' },
};

export const getBadgeColorByWaiverType = {
  'conditional.progress': { bg: 'blue-light', text: 'black' },
  'conditional.final': { bg: 'blue-light', text: 'black' },
  'unconditional.progress': { bg: 'green-light', text: 'black' },
  'unconditional.final': { bg: 'green-light', text: 'black' },
  other: { bg: 'gray-light', text: 'default' },
};

export const getBadgeColorByWaiverCode = {
  CP: { bg: 'blue-light', text: 'black' },
  CF: { bg: 'blue-light', text: 'black' },
  UP: { bg: 'green-light', text: 'black' },
  UF: { bg: 'green-light', text: 'black' },
};
