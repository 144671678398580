import { getFirebaseMessage } from '../../utils/utilsFirebaseException';
import { firebase } from './firebase';

export async function verifyEmail(actionCode) {
  return firebase
    .auth()
    .applyActionCode(actionCode)
    .then(() => {
      return true;
    })
    .catch(error => {
      throw new Error(getFirebaseMessage(error));
    });
}

