import * as React from 'react';
import SidebarControl from './SidebarControl';

const SidebarHeader = (props) => {
  const { children, hideClose, closeSidebar } = props;
  return (
    <div className="hui-header d-flex align-items-center hui-bg-white border-bottom">
      {children}
      {!hideClose && <SidebarControl icon button forceCloseSidebar={closeSidebar} />}
    </div>
  );
};

export default SidebarHeader;
