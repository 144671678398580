import { axiosInstance } from '../../utils/utilsAxios';

export async function forgotPassword(form) {
  const url = `/v1/auth/password/recovery`;

  try {
    const response = await axiosInstance.post(url, form);
    const password = response.data;
    return password;
  } catch (e) {
    const { response: { data: { message } } } = e;
    if (message === 'User not found') {
      return { emailNotFound: true };
    } else {
      throw new Error(e.message);
    }
  }
}


