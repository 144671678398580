import { isEmpty } from 'lodash';

export const FIELDS_TO_REDACT = ['cvv', 'token', 'account', 'expiry', 'password'];

export function sanitizeRequest(request, fieldsToRemove) {
  if (!request || !fieldsToRemove) {
    return request;
  }

  try {
    if (!isEmpty(request.body)) {
      const bodyJson = JSON.parse(request.body);
      if (bodyJson) {
        const redactedObj = redactJson(bodyJson, fieldsToRemove);
        request.body = JSON.stringify(redactedObj);
      }
    }
  } catch (error) {
    console.log(error);
  }

  return request;
}

export function redactJson(obj, fields) {
  if (!obj) {
    return null;
  }

  for (let field of fields) {
    if (Object.keys(obj).includes(field)) {
      obj[field] = '*REDACTED*';
    }
  }

  return obj;
}
