import { HuiCalendar, HuiInput, HuiInputAmount, HuiSelect } from 'handle-ui';
import React, { useEffect, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from '../../../components/localStorage/HuiLocalStorage';
import { localStoragePrefix } from './RequestWaiver';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';

const paymentDetailTypeOptions = [
  {
    title: 'Branch',
    id: 'branch',
  },
  {
    title: 'Mailed',
    id: 'mailed',
  },
];

const paymentMethodOptions = [
  {
    title: 'ACH',
    id: UtilPaymentMethods.ACH,
  },
  {
    title: 'Credit Card',
    id: UtilPaymentMethods.CREDIT_CARD,
  },
  {
    title: 'Check',
    id: 'CHECK',
  },
];

const defaultCalendar = {
  dateSelected: null,
  value: false,
};

const RequestWaiverPaymentDetail = (props) => {
  const { isRestore, fields, setFields, handleChange } = props;

  const [paymentMailedDate, setPaymentMailedDate] = useState(() => {
    const saved = isRestore ? getLocalStorageItem('paymentMailedDate', localStoragePrefix) : null;
    const savedObj = JSON.parse(saved);
    if (savedObj?.dateSelected) {
      savedObj.dateSelected = new Date(savedObj.dateSelected);
    }
    return savedObj || defaultCalendar;
  });

  const [paymentDate, setPaymentDate] = useState(() => {
    const saved = isRestore ? getLocalStorageItem('paymentDate', localStoragePrefix) : null;
    const savedObj = JSON.parse(saved);
    if (savedObj?.dateSelected) {
      savedObj.dateSelected = new Date(savedObj.dateSelected);
    }
    return savedObj || defaultCalendar;
  });

  useEffect(() => {
    setLocalStorageItem('paymentDate', JSON.stringify(paymentDate), localStoragePrefix);
    setLocalStorageItem('paymentMailedDate', JSON.stringify(paymentMailedDate), localStoragePrefix);
  }, [paymentDate, paymentMailedDate]);

  return (
    <div className="mb-3">
      <div className="col-xl-6">
        <HuiSelect
          topLabel
          required
          size="small"
          value={fields.paymentDetailType}
          id="paymentDetailType"
          name="paymentDetailType"
          label="Payment Detail Type"
          onChange={handleChangeLocal}
          options={paymentDetailTypeOptions}
        />
      </div>
      <div className="row" hidden={fields.paymentDetailType !== 'branch'}>
        <div className="col-12">
          <HuiInput
            label="Branch Location"
            id="paymentBranchLocation"
            name="paymentBranchLocation"
            value={fields.paymentBranchLocation}
            onChange={handleChangeLocal}
            topLabel
            required={fields.paymentDetailType === 'branch'}
          />
        </div>
        <div className="col-xl-6">
          <HuiCalendar
            onChange={(item) => {
              setPaymentDate({ dateSelected: item, value: true });
              setFields({ ...fields, paymentDate: item.toISOString() });
            }}
            className="mb-0 hui-calendar"
            size="small"
            label="Payment Date"
            id="paymentDate"
            name="paymentDate"
            topLabel
            date={paymentDate}
            required={fields.paymentDetailType === 'branch'}
            maxDate={new Date()}
            hideActions
          />
        </div>
        <div className="col-xl-6">
          <HuiSelect
            topLabel
            required={fields.paymentDetailType === 'branch'}
            size="small"
            value={fields.paymentMethod}
            id="paymentMethod"
            name="paymentMethod"
            label="Method of Payment"
            onChange={handleChangeLocal}
            options={paymentMethodOptions}
          />
        </div>
      </div>

      <div className="row" hidden={fields.paymentDetailType !== 'mailed'}>
        <div className="col-6">
          <HuiInput
            label="Check Number"
            id="paymentCheckNumber"
            name="paymentCheckNumber"
            value={fields.paymentCheckNumber}
            onChange={handleChangeLocal}
            topLabel
            required={fields.paymentDetailType === 'mailed'}
          />
        </div>

        <div className="col-6">
          <HuiInputAmount
            label="Amount of Check"
            prefix="$"
            id="paymentCheckAmount"
            name="paymentCheckAmount"
            placeholder="$"
            value={fields.paymentCheckAmount}
            onChange={handleChangeLocal}
            topLabel
            required={fields.paymentDetailType === 'mailed'}
            size="small"
            allowNegative={false}
          />
        </div>
        <div className="col-6">
          <HuiCalendar
            onChange={(item) => {
              setPaymentMailedDate({ dateSelected: item, value: true });
              setFields({ ...fields, paymentMailedDate: item.toISOString() });
            }}
            className="mb-0 hui-calendar"
            size="small"
            label="Date Mailed"
            id="paymentMailedDate"
            name="paymentMailedDate"
            topLabel
            date={paymentMailedDate}
            required={fields.paymentDetailType === 'mailed'}
            maxDate={new Date()}
            hideActions
          />
        </div>
      </div>
    </div>
  );

  function handleChangeLocal(e) {
    if (e.target.name === 'paymentDetailType') {
      if (e.target.value === 'branch') {
        setPaymentMailedDate(defaultCalendar);
      } else if (e.target.value === 'mailed') {
        setPaymentDate(defaultCalendar);
      }
    }
    handleChange(e);
  }
};

export default RequestWaiverPaymentDetail;
