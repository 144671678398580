import HuiTableCell from './HuiTableCell';
import HuiPaymentMethodIcon from '../payment/HuiPaymentMethodIcon';
import { Tooltip } from '@mui/material';
import { HuiIcon } from 'handle-ui';
import React from 'react';
import UtilPaymentMethods from '../../utils/utilPaymentMethods';

const HuiTableRecurrenceAmount = (props) => {
  const { data } = props;
  const { paymentType, brand } = data;

  const cardBrand = UtilPaymentMethods.isACH(paymentType) ? paymentType : brand || paymentType;

  return (
    <div className="d-flex align-items-center w-100">
      <HuiTableCell {...props} className="fw-bold" />
      <div className="d-flex align-items-center">
        <HuiPaymentMethodIcon className="me-2" brand={cardBrand} />
        <Tooltip
          arrow
          placement="top"
          title="The displayed amount does not include taxes and fees. Click on the view icon to view details."
        >
          <div>
            <HuiIcon size="xs" name="info" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default HuiTableRecurrenceAmount;
