import React, { Fragment } from 'react';
import { HuiIcon } from 'handle-ui';

const projectIcon = <HuiIcon name="dashboard" size="sm" />;
const invoiceIcon = <HuiIcon name="reports" size="sm" />;
const jobIcon = <HuiIcon name="waiver" size="sm" />;
const noneIcon = <HuiIcon name="pay" size="sm" />;

const ShowRestriction = (props) => {
  const { icon, title } = props;
  return (
    <div className="d-flex align-items-center">
      <div className="me-1">{icon}</div>
      <div>{title}</div>
    </div>
  );
};

const getRestrictionsType = {
  PROJECT: <ShowRestriction icon={projectIcon} title="Project" />,
  INVOICE: <ShowRestriction icon={invoiceIcon} title="Invoice" />,
  JOB: <ShowRestriction icon={jobIcon} title="Job" />,
  NONE: <ShowRestriction icon={noneIcon} title="None" />,
};

const HuiTableCreditRestriction = ({ data, value }) => {
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();
  const restrictionType = getRestrictionsType[value] || Fragment;

  return (
    <div className={classNames} title={value}>
      {restrictionType}
    </div>
  );
};

export default HuiTableCreditRestriction;
