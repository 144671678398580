import queryString from 'query-string';
import { Navigate, useLocation } from 'react-router-dom';
import ChangePassword from '../changePassword/ChangePassword';
import SignInWithLink from '../signInWithLink/SignInWithLink';
import ConfirmEmail from '../../private/confirmEmail/ConfirmEmail';

const modeLookup = (mode, newUser = false) => {
  switch (mode) {
    case 'resetPassword':
      return <ChangePassword newUser={newUser} />;
    case 'verifyEmail':
      return <ConfirmEmail />;      
    case 'signIn':
      return <SignInWithLink />;
    default:
      return null;
  }
};

const Action = () => {
  const { search } = useLocation();
  const query = queryString.parse(search);

  const { mode, newUser } = query || '';

  const action = modeLookup(mode, newUser) || <Navigate to="/login" />;

  return action;
};

export default Action;
