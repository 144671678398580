import { parse } from 'content-disposition-header';
import { saveAs } from 'file-saver';
import { capitalize } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { downloadDocument } from '../../actions/document/downloadDocument';
import { downloadInvoice } from '../../actions/invoices/downloadInvoice';
import { getInvoiceEvents } from '../../actions/invoices/getInvoiceEvents';
import { downloadPayment } from '../../actions/payment/downloadPayment';
import { downloadWaiver } from '../../actions/waiver/downloadWaiver';
import { updateInvoiceEvents } from '../../redux/InvoiceEventsSlice';
import { forceCloseLoading } from '../../redux/LoadingSlice';
import { addSelectedInvoiceClass, removeSelectedInvoiceClass } from '../../utils/utilsTable';

const ButtonDownload = (props) => {
  const dispatch = useDispatch();

  const { className, rowIndex, id, type, disabled = false, params, hidden } = props;
  const typeName = capitalize(type);

  return (
    !hidden && (
      <>
        <button type="button" disabled={disabled} onClick={handleDownload} className={className}>
          {props.children}
        </button>
      </>
    )
  );

  async function handleDownload() {
    if (rowIndex) {
      addSelectedInvoiceClass(rowIndex);
    }

    try {
      let download;

      switch (type) {
        case 'invoice':
          download = await downloadInvoice(id);
          break;
        case 'payment':
          download = await downloadPayment(id);
          break;
        case 'waiver':
          download = await downloadWaiver(id, params);
          break;
        case 'document':
          download = await downloadDocument(id, params);
          break;
        default:
          break;
      }

      const headers = download.headers;
      const data = download.data;

      const blob = new Blob([data], { type: 'application/pdf' });
      const disposition = parse(headers['content-disposition'] || '');
      const fileName = disposition.parameters.filename;
      saveAs(blob, fileName);
      toastr.success(`${typeName} downloaded successfully`);

      if (type === 'invoice') {
        const invoiceEvents = await getInvoiceEvents(id);
        await dispatch(updateInvoiceEvents(invoiceEvents));
      }
    } catch (e) {
      await dispatch(forceCloseLoading({}));
      toastr.error(`Error downloading ${typeName}`);
      console.error(`Error downloading ${typeName}`, e);
    } finally {
      removeSelectedInvoiceClass();
    }
  }
};

export default ButtonDownload;
