import { axiosInstance } from '../../utils/utilsAxiosMain';

export async function downloadDocument(id, params) {
  const url = `/api/pdf/document/${id}/download/t2`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
      params,
    });
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
}
