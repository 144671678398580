import { axiosInstance } from '../../utils/utilsAxios';

export async function updateEmailInHandle(params) {
  const url = `/v1/auth/change-email`;

  try {
    const response = await axiosInstance.post(url, params);
    const handleUser = response.data;
    return handleUser;
  } catch (e) {
    const { response: { data: { message } } } = e;
    console.log(`API Error Update Email In: ${message}`);
    throw new Error(e.message);
  }
}