import { Divider } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSidebar } from '../../../redux/SidebarSlice';

const SidebarInvoiceAddresses = () => {
  const creditInvoice = useSelector(selectSidebar);

  const { shipToAddress, billToAddress } = creditInvoice;

  return (
    <div>
      <div>
        <h3 className="hui-text fw-bold mb-0 hui-color-black-light">Bill to:</h3>
        <div className="hui-color-gray">{billToAddress}</div>
      </div>
      <Divider className="my-1" />
      <div>
        <h3 className="hui-text fw-bold mb-0 hui-color-black-light">Ship to:</h3>
        <div className="hui-color-gray">{shipToAddress}</div>
      </div>
    </div>
  );
};

export default SidebarInvoiceAddresses;
