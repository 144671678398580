import { HuiIcon, HuiInput, HuiPanel, HuiRadio, HuiRadioOption } from 'handle-ui';
import { capitalize, size, startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import HuiPaymentMethodIcon from '../../../../components/payment/HuiPaymentMethodIcon';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';

const ACH_MAP = {
  CHECKING: 'Checking account',
  SAVINGS: 'Savings account',
};

const OneClickPay = (props) => {
  const portalInfo = useSelector(selectPortalInfo);
  const { settings: { isCardOnFileEnabled = false } = {} } = portalInfo || {};

  const [fields, setFields] = useState({
    cvv: '',
    cardOnFile: null,
    state: '',
    cardType: null,
  });

  const { cardOnFileList: cardOnFileListProps = {}, type } = props;
  const [cardOnFileList, setCardOnFileList] = useState(cardOnFileListProps);
  const isACH = UtilPaymentMethods.isACH(type);
  const paymentType = isACH ? 'account' : 'card';
  const paymentTypeLabel = `${paymentType}${cardOnFileList?.count > 0 ? 's' : ''}`;

  useEffect(() => {
    if (size(cardOnFileListProps)) {
      setCardOnFileList(cardOnFileListProps);
    }
  }, [cardOnFileListProps, setCardOnFileList]);

  const showCardOnFileList = isCardOnFileEnabled && cardOnFileList?.count > 0;

  const showCards = showCardOnFileList && fields.cardOnFile !== '';
  const [expanded, setExpanded] = useState(true);

  function openCards() {
    const _expanded = !expanded;
    setExpanded(_expanded);
    setFields({ ...fields, cardOnFile: '', expanded: _expanded, showCards });
  }

  function handleChange(e) {
    const state = {
      ...fields,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      showCards,
    };

    if (e.target.name === 'cardOnFile') {
      const selectedCardOnFile = cardOnFileList.rows.find((c) => c.id.toString() === e.target.value);
      state.state = selectedCardOnFile?.state || '';
      state.cardType = selectedCardOnFile?.cardType || null;
    }

    const { onChange } = props;
    setFields(state);
    onChange?.(state);
  }

  function addNewCardChange() {
    setExpanded(false);
    const { onChange } = props;
    const state = { cardOnFile: '', state: '', cardType: null, expanded: false, cvv: '', showCards };
    setFields(state);
    onChange?.(state);
  }

  return (
    <div>
      <div hidden={!showCardOnFileList}>
        <HuiPanel className="mt-4">
          <div className="d-flex align-items-center justify-content-between mt-3 mb-2 px-3">
            <h4 className="hui-text-lg fw-bold mb-0">Your saved {paymentTypeLabel}</h4>
            <div>
              <div role="button" id="asdasd" onClick={openCards} hidden={showCards}>
                {!expanded ? <HuiIcon name="plus" size="sm" /> : <HuiIcon name="minus" size="sm" />}
              </div>
            </div>
          </div>

          <HuiRadio id="cardOnFile" name="cardOnFile" onChange={handleChange}>
            <div hidden={!expanded} className="pb-4" key="card-header">
              <div className="mx-2 mb-2 px-3 py-2 rounded-2 hui-bg-gray-light d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <span className="rounded-pill hui-bg-black me-2 hui-color-white py-1 px-2 hui-text-xs">
                    {size(cardOnFileList?.rows)}
                  </span>
                  <span className="hui-text fw-bold">{capitalize(paymentTypeLabel)}</span>
                </div>

                <div hidden={isACH}> Expires on</div>
              </div>

              {cardOnFileList?.rows?.map((cardOnFile) => {
                const cards = (
                  <div className="d-flex align-items-center w-100">
                    <div className="flex-grow-1 d-flex align-items-center hui-text hui-color-black-light">
                      <HuiPaymentMethodIcon hidden={isACH} size="md" className="me-2" brand={cardOnFile.brand} />
                      <strong hidden={!isACH} className="hui-color-black">
                        {startCase(toLower(ACH_MAP[cardOnFile.accountType]))}
                      </strong>
                      <strong hidden={isACH} className="hui-color-black">
                        {startCase(toLower(cardOnFile.brand))}
                      </strong>
                      {`: ending in ${cardOnFile.last4} `}
                    </div>
                    <div hidden={isACH} className="hui-text hui-color-black-light">
                      {cardOnFile.expiry}
                    </div>
                  </div>
                );

                return (
                  <HuiRadioOption
                    className="w-100 mb-2 px-4 hui-select-payment-method"
                    key={cardOnFile.id}
                    label={cards}
                    value={cardOnFile.id}
                  />
                );
              })}

              <div className="col-12 mt-3 px-4" hidden={isACH || !fields.cardOnFile}>
                <HuiInput
                  label="Confirm CVV"
                  id="cvv"
                  name="cvv"
                  className="mb-0"
                  value={fields.cvv}
                  onChange={handleChange}
                  topLabel
                  required={!isACH && !!fields.cardOnFile}
                  size="large"
                  dataPrivate
                />
              </div>
            </div>

            <HuiRadioOption
              className="hui-add-payment-method border-top p-3"
              key="add-new-card"
              label={`Add new ${paymentType}`}
              value=""
              onClick={() => addNewCardChange()}
            />
          </HuiRadio>
        </HuiPanel>
      </div>
    </div>
  );
};

export default OneClickPay;
