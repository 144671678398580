import { getInvoice } from '../../actions/invoices/getInvoice';
import { getInvoiceEvents } from '../../actions/invoices/getInvoiceEvents';
import { closeSidebar, openSidebar } from '../../redux/SidebarSlice';
import { updateInvoiceEvents } from '../../redux/InvoiceEventsSlice';
import { useDispatch } from 'react-redux';
import { addSelectedInvoiceClass } from '../../utils/utilsTable';
import { Tooltip } from '@mui/material';

const ViewInvoice = (props) => {
  const dispatch = useDispatch();
  const { rowIndex, hidden, className, icon, id } = props;

  return (
    !hidden &&
    (icon ? (
      <Tooltip arrow placement="top" title="View Invoice">
        <div className="d-flex mx-auto" role="button" onClick={() => handleChance()}>
          <i className="hui-i hui-svg-invoice-sm-icon mx-auto"></i>
        </div>
      </Tooltip>
    ) : (
      <button className={className} onClick={() => handleChance()}>
        {props.children}
      </button>
    ))
  );

  async function handleChance() {
    if (rowIndex) {
      addSelectedInvoiceClass(rowIndex);
    }

    await dispatch(openSidebar({ loading: true }));

    try {
      const invoice = await getInvoice(id);
      const invoiceEvents = await getInvoiceEvents(id);
      await dispatch(openSidebar({ ...invoice, type: 'invoice', loading: false }));
      await dispatch(updateInvoiceEvents(invoiceEvents));
    } catch (e) {
      await dispatch(closeSidebar({}));
      console.log('Error loading invoice modal');
    }
  }
};

export default ViewInvoice;
