import React from 'react';
import * as PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
};

const HuiCardHeader = (props) => {
  const { title, icon } = props;

  const huiCardIcon = `${icon ? 'hui-card-header-icon' : ''}`;

  const huiBoxClass = `hui-card-header d-flex align-items-center ${huiCardIcon}`;

  const huiCardHeaderTitle = `p-3 ${icon ? 'hui-text fw-bold' : 'hui-text-lg'}`;

  return (
    <div className={huiBoxClass}>
      {!!icon && (
        <div className="hui-card-icon d-flex align-items-center justify-content-center">
          {icon}
        </div>
      )}
      <div className={huiCardHeaderTitle}>{title}</div>
    </div>
  );
};

HuiCardHeader.propTypes = propTypes;

export default HuiCardHeader;
