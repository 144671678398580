import { HuiLoading } from 'handle-ui';
import { size } from 'lodash';
import moment from 'moment/moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPayment } from '../../../actions/payment/getPayment';
import ButtonDownload from '../../../components/buttons/ButtonDownload';
import ButtonEmail from '../../../components/buttons/ButtonEmail';
import ButtonPrint from '../../../components/buttons/ButtonPrint';
import CancelPayment from '../../../components/payment/CancelPayment';
import { selectLoading } from '../../../redux/LoadingSlice';
import { selectPayment, updatePayment } from '../../../redux/PaymentSlice';
import HuiFullPaymentsDetail from './HuiFullPaymentsDetail';

const HistoryPaymentsDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payment = useSelector(selectPayment);
  const loading = useSelector(selectLoading);

  const { pathname } = useLocation();
  const paymentId = pathname.substring(pathname.lastIndexOf('/') + 1);

  const { code, status, scheduledAt, recurrenceId } = payment || {};

  useEffect(() => {
    async function fetchPaymentInfo() {
      if (!size(payment) && !loading) {
        try {
          const paymentThanks = await getPayment(paymentId);
          if (paymentThanks) {
            await dispatch(updatePayment(paymentThanks));
          }
        } catch (e) {
          await dispatch(updatePayment({ notFound: true }));
        }
      }
    }

    fetchPaymentInfo();
  }, [payment, dispatch, paymentId, loading]);

  const isScheduled = status === 'SCHEDULED';

  const showCancel = status === 'SCHEDULED' && !recurrenceId;

  return size(payment) && !payment.notFound ? (
    <div className="p-4 hui-dashboard overflow-hidden d-flex flex-column w-100">
      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <button type="button" onClick={() => navigate(-1)} className="hui-btn hui-btn me-2">
            Back to Payments
          </button>
        </div>

        <div className="d-flex justify-content-center text-center">
          <ButtonDownload id={code} type="payment" className="hui-btn hui-btn mx-2">
            Download Receipt
          </ButtonDownload>
          <ButtonPrint id={code} type="payment" className="hui-btn hui-btn mx-2">
            Print Receipt
          </ButtonPrint>
          <ButtonEmail id={code} type="payment" className="hui-btn hui-btn mx-2">
            Email a copy
          </ButtonEmail>
          {!!showCancel && (
            <CancelPayment className="hui-btn hui-btn-outline hui-btn-red" payment={payment} onComplete={reloadPayment}>
              Cancel Payment
            </CancelPayment>
          )}
        </div>
      </div>

      <div className="hui-history-content">
        <div className="hui-bg-gray-light rounded-3 p-3 hui-text-lg mb-4 d-flex align-items-center">
          <div className="d-flex me-2">
            <i className="hui-i hui-svg-toastr-success"></i>
          </div>
          <div className="flex-grow-1">
            Payment confirmation code: <br />
            <strong>{code}</strong>
          </div>
        </div>

        <div
          className="my-4 hui-bg-yellow-light p-3 rounded-3 d-flex align-items-center hui-thanks-info"
          hidden={!isScheduled}
        >
          <div className="d-flex me-2">
            <i className="hui-i hui-svg-warning-sm"></i>
          </div>
          <div className="flex-grow-1">
            This payment has been scheduled for processing on &nbsp;
            <strong>{moment(scheduledAt).format('MMM D, YYYY')}</strong>.
          </div>
        </div>

        <HuiFullPaymentsDetail payment={payment} />
      </div>
    </div>
  ) : (
    <div>
      <div hidden={!payment.notFound}>Payment Not Found</div>
      <div hidden={payment.notFound}>
        <HuiLoading className="hui-credit-card-loading" isOpen />
      </div>
    </div>
  );

  function reloadPayment() {
    dispatch(updatePayment({}));
  }
};

export default HistoryPaymentsDetail;
