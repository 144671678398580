import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Menu, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/UserSlice';
import { toUpper } from 'lodash';
import { selectAuth } from '../../../redux/AuthSlice';

const HeaderAvatar = () => {
  const auth = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const name = user?.name || auth?.displayName || '';
  const email = user?.email || auth?.email || '';

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip>
        <div className="hui-header-profile d-flex align-items-center" role="button" onClick={handleOpenUserMenu}>
          <Avatar className="hui-header-avatar mx-3">{toUpper(name && name[0])}</Avatar>
          <div className="hui-header-user flex-grow-1 overflow-hidden d-none d-md-block">
            <div className="hui-color-black hui-text text-truncate">{name || ''}</div>
            <div className="hui-text-sm text-truncate">{email || ''}</div>
          </div>
          <div className="hui-header-dropdown ms-md-3">
            <i className="hui-i hui-svg-dropdown"></i>
          </div>
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 50,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <div className="list-group list-group-flush hui-header-menu" onClick={handleCloseUserMenu}>
          <Link className="list-group-item list-group-item-action" to="/settings/profile">
            Settings
          </Link>
          <Link className="list-group-item list-group-item-action" to="/logout">
            Logout
          </Link>
        </div>
      </Menu>
    </>
  );
};

export default HeaderAvatar;
