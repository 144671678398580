import { axiosInstance } from '../../utils/utilsAxios';

export async function emailPayment(id, emails) {
  const url = `v1/payments/${id}/email`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.post(url, emails);
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
}
