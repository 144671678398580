import React from 'react';

const navPanelPosition = {
  left: 'hui-nav-left',
  right: 'hui-nav-right',
};

const NavPanel = (props) => {
  const { position, hidden = false } = props;

  const getPosition = navPanelPosition[position] || '';

  const navPanelClass = `hui-sidebar-panel ${getPosition} position-sticky border-end hui-bg-white d-flex overflow-auto`;
  return (
    <aside className={navPanelClass} hidden={hidden}>
      {props.children}
    </aside>
  );
};

export default NavPanel;
