import { createSlice } from '@reduxjs/toolkit';

export const portalInfoSlice = createSlice({
	name: 'portal',
	initialState: {
		value: {
      theme: 'blue',
      organization: {
        logo: '/images/loading.gif',
      }
    }
	},
	reducers: {
    updateInfoPortal: (state, action) => {
			state.promise = null;
			state.value = action.payload;
		},
		// Saves a reference to the portal promise
		// To use its value before its ready on the utilsAxios.js file
		// It would be better to have a lib handle this
    updatePortalPromise: (state, action) => {
			state.promise = action.payload;
		},
	},
});

export const { updateInfoPortal, updatePortalPromise } = portalInfoSlice.actions;

export const selectPortalInfo = (state) => state.portal.value;

export default portalInfoSlice.reducer;
