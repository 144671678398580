import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

import { getFirebaseConfig } from '../../config/firebase';

firebase.initializeApp(getFirebaseConfig());

const storage = firebase.storage();
storage.setMaxOperationRetryTime(600000);
storage.setMaxUploadRetryTime(600000);

export {
  firebase
};


