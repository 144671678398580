import { combineReducers, createStore } from 'redux';

import { authSlice } from '../redux/AuthSlice';
import { creditDataSlice } from '../redux/CreditDataSlice';
import { creditsSlice } from '../redux/CreditsSlice';
import { invoiceEventsSlice } from '../redux/InvoiceEventsSlice';
import { InvoicesPaymentSlice } from '../redux/InvoicesPaymentSlice';
import { invoicesSlice } from '../redux/InvoicesSlice';
import { loadingSlice } from '../redux/LoadingSlice';
import { modalSlice } from '../redux/ModalSlice';
import { paymentDataSlice } from '../redux/PaymentDataSlice';
import { paymentReasonsSlice } from '../redux/PaymentReasonsSlice';
import { paymentSlice } from '../redux/PaymentSlice';
import { portalInfoSlice } from '../redux/PortalInfoSlice';
import { portalSettingsSlice } from '../redux/PortalSettingsSlice';
import { portalsSlice } from '../redux/PortalsSlice';
import { sidebarSlice } from '../redux/SidebarSlice';
import { userCompanyBalanceSlice } from '../redux/UserCompanyBalanceSlice';
import { userCompanySlice } from '../redux/UserCompanySlice';
import { userSlice } from '../redux/UserSlice';

const reducer = combineReducers({
  auth: authSlice.reducer,
  portal: portalInfoSlice.reducer,
  portalSettings: portalSettingsSlice.reducer,
  user: userSlice.reducer,
  userCompany: userCompanySlice.reducer,
  userCompanyBalance: userCompanyBalanceSlice.reducer,
  invoices: invoicesSlice.reducer,
  credits: creditsSlice.reducer,
  sidebar: sidebarSlice.reducer,
  invoiceEvents: invoiceEventsSlice.reducer,
  paymentData: paymentDataSlice.reducer,
  creditData: creditDataSlice.reducer,
  invoicesPayment: InvoicesPaymentSlice.reducer,
  payment: paymentSlice.reducer,
  portals: portalsSlice.reducer,
  paymentReasons: paymentReasonsSlice.reducer,
  loading: loadingSlice.reducer,
  modal: modalSlice.reducer,
});

export const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
