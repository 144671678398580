import { axiosInstance } from '../../utils/utilsAxios';

export async function signUpHandle(form) {
  const url = `/v1/auth/signup`;

  try {
    const response = await axiosInstance.post(url, form);
    const handleUser = response.data;
    return handleUser;
  } catch (e) {
    throw new Error(e.message);
  }
}


