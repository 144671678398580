import * as React from 'react';
import SidebarHeader from './SidebarHeader';
import HuiFilters from '../../table/components/HuiFilters';

const SidebarAdvancedFilters = (props) => {
  const { availableFilters } = props;

  return (
    <>
      <SidebarHeader>
        <div className="flex-grow-1">
          <h2 className="hui-title d-flex align-items-center mb-0">Advanced Filters</h2>
        </div>
      </SidebarHeader>

      <div className="hui-bg-gray-light p-4 d-flex flex-column">
        <HuiFilters
          className="mb-3"
          availableFilters={availableFilters}
          showForm
          showSubmit
        />
      </div>
    </>
  );
};

export default SidebarAdvancedFilters;
