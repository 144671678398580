import { HuiPanel, HuiPanelContent, HuiPanelHeader } from 'handle-ui';
import { useSelector } from 'react-redux';
import { selectInvoicesPayment } from '../../../../redux/InvoicesPaymentSlice';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import HuiPaymentForm from '../payment-form/HuiPaymentForm';
import HuiConfirmPaymentInvoices from './HuiConfirmPaymentInvoices';

const HuiConfirmPayment = (props) => {
  const payment = useSelector(selectInvoicesPayment);
  const { isPayWithCreditOnly, edit } = props;

  return (
    <div className="hui-confirm-payment mx-auto">
      <HuiPanel boxShadow>
        <HuiPanelHeader title="Payment Details" borderBottom />
        <HuiPanelContent className="p-0">
          <HuiConfirmPaymentInvoices
            edit={edit}
            paymentType={payment.paymentType}
            cardType={payment.cardType}
          />
        </HuiPanelContent>
      </HuiPanel>
      {payment.paymentType === UtilPaymentMethods.CREDIT && isPayWithCreditOnly && <HuiPaymentForm />}
      {payment.paymentType === UtilPaymentMethods.CREDIT_CARD && !isPayWithCreditOnly && <HuiPaymentForm />}
      {payment.paymentType === UtilPaymentMethods.ACH && !isPayWithCreditOnly && <HuiPaymentForm />}
    </div>
  );
};

export default HuiConfirmPayment;
