import { axiosInstance } from '../../utils/utilsAxios';

export async function getInvoiceEvents(id) {
  const url = `v1/invoices/${id}/events`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url);
    const events = response.data;
    return events;
  } catch (e) {
    throw new Error(e.message);
  }
}

