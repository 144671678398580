import { first } from 'lodash';
import moment from 'moment';
import React from 'react';
import { DateRangePicker } from 'react-date-range';
import HuiDate from './HuiDate';

const HuiDatePicker = (props) => {
  const {
    label,
    id,
    name,
    onChange,
    size,
    hidden,
    disabled,
    error,
    topLabel,
    className,
    ranges,
    onClear,
    handleOnClick,
    onClickDisabled,
    staticRanges,
  } = props;

  const { startDate, endDate, value } = first(ranges);

  const startDateFormatted = moment(startDate).format('MMM D, YYYY');
  const endDateFormatted = moment(endDate).format('MMM D, YYYY');

  const isToday = startDateFormatted === endDateFormatted;

  const selectedRange = first(
    staticRanges.filter((staticRange) => {
      return (
        staticRange.range().startDate === startDate &&
        staticRange.range().endDate === endDate
      );
    }),
  );

  let dateValue = '';

  if (selectedRange) {
    dateValue = selectedRange.label;
  } else if (value) {
    const endDateFinal = isToday ? '' : ` - ${endDateFormatted}`;
    dateValue = `${startDateFormatted} ${endDateFinal}`;
  }

  return (
    <HuiDate
      error={error}
      topLabel={topLabel}
      className={className}
      size={size}
      id={id}
      name={name}
      value={dateValue}
      label={label}
      disabled={!!disabled}
      hidden={hidden}
      onClear={onClear}
      onChange={onChange}
      handleOnClick={handleOnClick}
      onClickDisabled={onClickDisabled}
      ranges={ranges}
    >
      <DateRangePicker
        preventSnapRefocus={true}
        className="hui-date-picker border-bottom mb-3"
        inputRanges={[]}
        onChange={onChange}
        months={2}
        showDateDisplay={false}
        showSelectionPreview={false}
        ranges={ranges}
        direction="horizontal"
        staticRanges={staticRanges}
        calendarFocus="backwards"
      />
    </HuiDate>
  );
};

export default HuiDatePicker;
