import { HuiIcon } from 'handle-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const NavMenu = () => {
  const portalInfo = useSelector(selectPortalInfo);
  const location = useLocation();

  const { isPaymentEnabled = false, isWaiverEnabled = false } = portalInfo?.settings || {};

  const isDashboard = location.pathname.includes('/dashboard');
  const isPay =
    (location.pathname.includes('/pay') || location.pathname.includes('/prepay')) &&
    !location.pathname.includes('/history');
  const isWaiver = location.pathname.includes('/request-waiver');
  const isHistory = location.pathname.includes('/history/invoices');
  const isPaymentReport =
    location.pathname.includes('/history/payments') || location.pathname.includes('/history/recurrences');
  const isWaiverHistory = location.pathname.includes('/history/waivers');

  const menuItemClass = 'hui-nav-menu-item px-2';

  return (
    <div className="hui-nav-menu flex-grow-1 overflow-auto">
      <div className={isDashboard ? `${menuItemClass} active` : `${menuItemClass}`}>
        <Link to="/dashboard" className="hui-nav-menu-link">
          <div className="hui-nav-menu-icon">
            <HuiIcon name="dashboard" size="sm" />
          </div>
          <div>Dashboard</div>
        </Link>
      </div>
      <div className={isPay ? `${menuItemClass} active` : `${menuItemClass}`} hidden={!isPaymentEnabled}>
        <Link to="/pay" className="hui-nav-menu-link">
          <div className="hui-nav-menu-icon">
            <HuiIcon name="pay" size="sm" />
          </div>
          <div>Make a Payment</div>
        </Link>
      </div>
      <div className={isPaymentReport ? `${menuItemClass} active` : `${menuItemClass}`} hidden={!isPaymentEnabled}>
        <Link to="/history/payments" className="hui-nav-menu-link">
          <div className="hui-nav-menu-icon">
            <HuiIcon name="card" size="sm" />
          </div>
          <div>Payment Activity</div>
        </Link>
      </div>
      <div className={isWaiver ? `${menuItemClass} active` : `${menuItemClass}`} hidden={!isWaiverEnabled}>
        <Link to="/request-waiver" className="hui-nav-menu-link">
          <div className="hui-nav-menu-icon">
            <HuiIcon name="waiver" size="sm" />
          </div>
          <div>Request Waiver</div>
        </Link>
      </div>
      <div className={isWaiverHistory ? `${menuItemClass} active` : `${menuItemClass}`} hidden={!isWaiverEnabled}>
        <Link to="/history/waivers" className="hui-nav-menu-link">
          <div className="hui-nav-menu-icon">
            <HuiIcon name="waiverFile" size="sm" />
          </div>
          <div>Waiver History</div>
        </Link>
      </div>
      <div
        className={isHistory ? `${menuItemClass} active` : `${menuItemClass}`}
        hidden={!isWaiverEnabled && !isPaymentEnabled}
      >
        <Link to={'/history/invoices'} className="hui-nav-menu-link">
          <div className="hui-nav-menu-icon">
            <HuiIcon name="reports" size="sm" />
          </div>
          <div>Reports</div>
        </Link>
      </div>
    </div>
  );
};

export default NavMenu;
