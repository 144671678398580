import { SvgIcon } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { forwardRef } from 'react';

function CustomSvgIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.7075 9.20747C15.9841 8.93084 16.4326 8.93084 16.7092 9.20747C16.9858 9.48409 16.9858 9.93258 16.7092 10.2092L12.4592 14.4592C12.1826 14.7358 11.7341 14.7358 11.4575 14.4592L7.20747 10.2092C6.93084 9.93258 6.93084 9.48409 7.20747 9.20747C7.48408 8.93084 7.93258 8.93084 8.2092 9.20747L11.9583 12.9566L15.7075 9.20747Z"
        fill="#9e9e9e"
      />
    </SvgIcon>
  );
}

const HuiSelect = forwardRef((props, ref) => {
  const {
    options,
    label,
    id,
    name,
    value = '',
    onChange,
    size,
    hidden,
    disabled,
    error,
    topLabel,
    className,
    required,
    hideRequired,
  } = props;
  const { values } = options;

  const selectOptions = options.map((option, i) => {
    const { title, id } = option;
    return (
      <MenuItem
        key={i}
        value={id}
      >
        {title}
      </MenuItem>
    );
  });

  const huiSelectClass = `${className || 'mb-3'}`;
  const showRequired = hideRequired ? false : required;
  
  return (
    <FormControl
      error={error}
      size={size}
      className={huiSelectClass}
      required={showRequired}
    >
      {!!topLabel && <FormLabel htmlFor={id}>{label}</FormLabel>}

      <Select
        IconComponent={CustomSvgIcon}
        ref={ref}
        size={size}
        id={id}
        name={name}
        disabled={!!disabled}
        hidden={hidden}
        value={value}
        onChange={onChange}
        required={required}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <span>{label}</span>
        </MenuItem>
        {selectOptions}
      </Select>
    </FormControl>
  );
});

export default HuiSelect;
