import HuiTablePagination from '../HuiTablePagination';
import HuiTableSize from '../HuiTableSize';

const HuiTableFooter = ({ tableManager }) => {
  return (
    <div className="d-flex align-items-center justify-content-end">
      <HuiTableSize tableManager={tableManager} />
      <HuiTablePagination tableManager={tableManager} />
    </div>
  );
};

export default HuiTableFooter;
