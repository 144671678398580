import { Link } from 'react-router-dom';

const HuiPaymentTypeSelect = (props) => {
  const {
    icon,
    type,
    title,
    to,
    active,
    onClick,
    name,
    hidden
  } = props;

  const isActive = active ? 'hui-payment-type-active' : '';

  const selectPaymentClass = `hui-payment-type-option hui-payment-type-${type} ${isActive} d-flex align-items-center px-3 py-2`;
  return (
    <Link
      to={to}
      className={selectPaymentClass}
      onClick={handleOnClick}
      hidden={hidden}>
      <div className="hui-payment-type-icon me-3">
        {icon}
      </div>
      <div className="hui-text text-center">
        {title}
      </div>
    </Link>
  );

  function handleOnClick() {
    onClick?.({
      target: {
        name: name || 'paymentTypeSelect',
        value: to,
      }
    });
  };
};

export default HuiPaymentTypeSelect;
