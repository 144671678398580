import { axiosUnauthenticated } from '../../utils/utilsAxios';

export async function getPortal(subdomain) {
	const url = `v1/portals/info/${subdomain}`;

	console.log('fetching: ', url);

	try {
		// No need to send login info for this request
		// sending it will cause a loop, since it also required portal info
		// which is what we are trying to get.
		const response = await axiosUnauthenticated.get(url);
		const portal = response.data;
		return portal;
	} catch (e) {
    throw new Error(e.message);
	}
}


