import { axiosInstance } from '../../utils/utilsAxios';

export async function getPaymentReasons() {
  const url = `v1/payment-reasons`;

  try {
    const response = await axiosInstance.get(url);
    const reasons = response.data;
    return reasons;
  } catch (e) {
    throw new Error(e.message);
  }
}


