import { HuiInput } from 'handle-ui';

const HuiInputSearch = (props) => {
  const { value, onChange, className, label = 'Search' } = props;

  return (
    <>
      <HuiInput
        size="small"
        type="search"
        label={label}
        value={value}
        className={className}
        onChange={onChange}
      />
    </>
  );
};

export default HuiInputSearch;
