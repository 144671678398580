import React from 'react';
import HeaderAvatar from './HeaderAvatar';

const HeaderProfile = () => {
  return (
    <div className="ms-auto">
      <HeaderAvatar />
    </div>
  );
};

export default HeaderProfile;
