import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentType: '',
  total: 0,
  currencyCode: 'USD',
  isPrepaid: false,
  checkoutToken: '',
  paymentInvoices: [],
  state: '',
  cardType: null,
};
export const InvoicesPaymentSlice = createSlice({
  name: 'invoicesPayment',
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.checkoutToken = action.payload;
    },
    updatePaymentMethod: (state, action) => {
      state.paymentType = action.payload;
    },
    updatePaymentState: (state, action) => {
      state.state = action.payload;
    },
    updatePaymentCardType: (state, action) => {
      state.cardType = action.payload;
    },
    updatePaymentPrepay: (state, action) => {
      state.isPrepaid = action.payload;
    },
    resetInvoicesPayment: (state, action) => {
      return initialState;
    },
  },
});

export const {
  updateToken,
  updatePaymentMethod,
  updatePaymentState,
  updatePaymentCardType,
  updatePaymentPrepay,
  resetInvoicesPayment,
} = InvoicesPaymentSlice.actions;

export const selectInvoicesPayment = (state) => state.invoicesPayment;

export default InvoicesPaymentSlice.reducer;
