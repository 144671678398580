import { createSlice } from '@reduxjs/toolkit';
import { map, reject, uniqBy } from 'lodash';
import { subtract, sum } from '../utils/utilsMath';

const initialState = {
  creditOverpay: false,
  creditApplied: 0,
  credits: [],
  availableCredits: [],
  creditToAppliedHasError: false,
};

export const creditDataSlice = createSlice({
  name: 'creditData',
  initialState,
  reducers: {
    setCreditsFromInvoice: (state, action) => {
      const { creditInvoice, allCredits } = action.payload;
      const creditsCurrentlyApplied = map(creditInvoice?.credits || [], (oldCredit) => {
        const creditUpdated = allCredits.find(c => c.id === oldCredit.id);
        if (creditUpdated) {
          return {
            ...oldCredit,
            balanceLeft: creditUpdated.balanceLeft,
          }
        }
        return oldCredit;
      });
      state.credits = creditsCurrentlyApplied;
      const availableCredits = allCredits?.filter(c => creditInvoice?.availableCreditIds?.includes(c.id));
      const usedCreditIds = map(uniqBy(creditsCurrentlyApplied, 'id'), 'id');
      state.availableCredits = availableCredits.filter(credit => usedCreditIds.includes(credit.id) || credit.balanceLeft > 0);
    },

    addCredit: (state, action) => {
      const { credits, creditApplied } = state;
      const { creditInvoice, credit } = action.payload;

      const invoiceBalanceLeft = subtract(creditInvoice.balance, creditApplied);
      const balanceLeft = Math.max(subtract(credit.balanceLeft, invoiceBalanceLeft), 0);
      const amount = subtract(credit.balanceLeft, balanceLeft);

      const newCredit = {
        ...credit,
        balanceLeft,
        amount,
      };

      const otherCredits = reject(credits, { id: credit.id });
      state.credits = [...otherCredits, newCredit];

      return state;
    },

    removeCredit: (state, action) => {
      const { credit } = action.payload;
      const creditIndex = state.credits.findIndex(i => i.id === credit.id);
      state.credits.splice(creditIndex, 1);
      const newCredit = {
        ...credit,
        balanceLeft: credit.amount + credit.balanceLeft,
        amount: undefined,
      };
      const otherCredits = reject(state.availableCredits, { id: credit.id });
      state.availableCredits = [...otherCredits, newCredit];
      return state;
    },

    updateCredit: (state, action) => {
      const credit = action.payload;
      state.credits = state.credits?.map((i) =>
        i.id === credit?.id ? { ...i, hasError: credit.hasError } : i,
      );
      return state;
    },

    changeCreditTotal: (state, action) => { // TODO: fix this
      const { credit, newAmount = 0 } = action.payload;
      const balanceLeft = (credit.balanceLeft + credit.amount) - newAmount;
      state.credits = state.credits?.map((i) =>
        i.id === credit.id ? { ...i, amount: newAmount, balanceLeft, hasError: credit.hasError } : i,
      );
      const creditsCurrentlyAvailable = state.availableCredits || [];
      const availableCredits = map(uniqBy([...state.credits || [], ...creditsCurrentlyAvailable], 'id'));
      const usedCreditIds = map(uniqBy(creditsCurrentlyAvailable, 'id'), 'id');
      state.availableCredits = availableCredits.filter(credit => usedCreditIds.includes(credit.id) || credit.balanceLeft > 0);
    },

    resetCredits: (state, action) => {
      state.creditOverpay = false;
      state.creditApplied = 0;
      state.credits = [];
      state.creditToAppliedHasError = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => action.type.startsWith('creditData/'),
        (state, action) => {
          updateCreditApplied(state, action);
          calcCreditsApplied(state, action);
          updateCreditAppliedHasError(state, action);
        },
      );
  },
});

function calcCreditsApplied(state, action) {
  const { creditApplied } = state;
  const { creditInvoice } = action.payload;

  if (!creditInvoice?.id) {
    return;
  }
  state.creditOverpay = creditApplied >= creditInvoice.balance;
}

function updateCreditApplied(state, action) {
  const { credits } = state;
  state.creditApplied = credits?.reduce((a, b) => sum(a, b.amount), 0);
}

function updateCreditAppliedHasError(state, action) {
  const { credits } = state;
  state.creditToAppliedHasError = credits?.some(c => c.hasError);
}

export const {
  addCredit,
  checkAddedCredit,
  removeCredit,
  changeCreditTotal,
  calcCreditApplied,
  resetCredits,
  setCreditsFromInvoice,
  updateCredit,
} = creditDataSlice.actions;

export const selectCreditData = (state) => state.creditData;

export default creditDataSlice.reducer;
