import { FormControl, FormLabel, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { HuiIcon } from 'handle-ui';
import { size as _size, compact } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const GoogleAddressInput = (props) => {
  const {
    className,
    id: searchInputId = 'google-address',
    label,
    required,
    hideRequired,
    value = '',
    topLabel,
    formatted = true,
    size,
    inputProps,
    placeholder,
    hidden = false,
    countryList
  } = props || {};
  const huiInputClass = `${className || 'mb-3'}`;

  const [street, setStreet] = useState(value);
  const [state, setState] = useState({});
  const [autocompleteGoogle, setAutocompleteGoogle] = useState(null);

  const loadGoogle = useCallback(() => {
    const input = document.getElementById(searchInputId);
    const countries = countryList.replace(/\s/g, '').split(',');
    const options = {
      componentRestrictions: { country: countries },
      fields: ['address_component', 'formatted_address'],
    };

    const autocomplete = new window.google.maps.places.Autocomplete(input, options);
    autocomplete.setFields(['address_components', 'formatted_address']);
    return autocomplete;
  }, [searchInputId, countryList]);

  useEffect(() => {
    const autocomplete = loadGoogle();
    setAutocompleteGoogle(autocomplete);
  }, [loadGoogle]);

  useEffect(() => {
    if (autocompleteGoogle) {
      bindListeners();
    }
  }, [autocompleteGoogle, bindListeners]);

  const textFieldProps = getTextFieldProps();
  const showRequired = hideRequired ? false : required;
  const inputSize = size === 'large' ? '' : 'small';

  return (
    <div>
      <FormControl 
        className={huiInputClass} 
        required={showRequired} 
        hidden={hidden}
      >
        {!!topLabel && <FormLabel htmlFor={searchInputId}>{label}</FormLabel>}
        <TextField
          {...textFieldProps}
          placeholder={placeholder}
          required={required}
          size={inputSize}
          label={topLabel ? '' : label}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <HuiIcon name="x" size="sm" hidden={!_size(state) || !street} onClick={reset} />
              </InputAdornment>
            ),
          }}
          inputProps={inputProps}
          onKeyUp={handleStreetChange}
        />
      </FormControl>
    </div>
  );

  function reset() {
    setStreet('');
    setState({});

    if (formatted) {
      const input = document.getElementById(searchInputId);
      input.value = '';
    }
    const { resetOnChange } = props;
    resetOnChange?.();
  }

  function getTextFieldProps() {
    if (formatted) {
      return {
        id: searchInputId,
      };
    }
    return {
      value: street || '',
      id: searchInputId,
      onChange: (e) => setStreet(e.target.value),
    };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function bindListeners() {
    window.google.maps.event.clearListeners(autocompleteGoogle, 'place_changed');
    window.google.maps.event.addListener(autocompleteGoogle, 'place_changed', () => placeChanged(autocompleteGoogle));
  }

  function handleOnChange(state) {
    const { onChange, name = 'address' } = props;
    setState(state);
    onChange?.({
      target: {
        name,
        value: state,
      },
    });
  }

  function placeChanged(autoComplete) {
    const place = autoComplete.getPlace();
    if (!place) {
      return;
    }
    const brokenDownAddress = handleGooglePlacesPress([place]);
    brokenDownAddress.street = getStreet(brokenDownAddress);
    const { formatted_address: formattedAddress } = place;
    brokenDownAddress.formattedAddress = formattedAddress;
    setStreet(brokenDownAddress.street);
    handleOnChange(brokenDownAddress);
  }

  function handleStreetChange(e) {
    const street = e.target.value;
    const newState = {
      ...state,
      street,
      streetName: street,
    };
    setState(newState);
    setStreet(street);
    handleOnChange(newState);
  }

  function handleGooglePlacesPress(results) {
    const map = {
      street_number: 'streetNumber',
      route: 'streetName',
      locality: 'city',
      administrative_area_level_1: 'state',
      postal_code: 'postalCode',
      country: 'country',
      neighborhood: 'neighborhood',
    };
    const brokenDownAddress = {};
    results[0].address_components.forEach((component) => {
      if (!map[component.types[0]]) return;
      const fieldName = map[component.types[0]];
      const fieldSize = fieldName === 'country' || fieldName === 'state' ? 'short_name' : 'long_name';
      brokenDownAddress[fieldName] = component[fieldSize];
    });
    return brokenDownAddress;
  }

  function getStreet(address) {
    const { streetNumber, streetName, neighborhood, city } = address || {};
    const street = compact([streetNumber, streetName]).join(' ');
    if (!_size(street) && neighborhood) {
      return neighborhood;
    }
    if (!_size(street) && !neighborhood && city) {
      return city;
    }
    return street;
  }
};

export default GoogleAddressInput;
