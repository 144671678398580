import { axiosInstance } from '../../utils/utilsAxios';

export async function getUserCompany() {
  const url = `/v1/users/company`;

  try {
    const response = await axiosInstance.get(url);
    const company = response.data;
    return company;
  } catch (e) {
    throw new Error(e.message);
  }
}


