import { map, uniqBy } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import HuiTable from '../../../../components/table/HuiTable';
import HuiTableCheckBoxCredit from '../../../../components/table/HuiTableCheckBoxCredit';
import HuiTableCreditReference from '../../../../components/table/HuiTableCreditReference';
import HuiTableCreditRestriction from '../../../../components/table/HuiTableCreditRestriction';
import HuiTableInvoiceNumber from '../../../../components/table/HuiTableInvoiceNumber';
import CreditMemoInputAmount from './CreditMemoInputAmount';
import { selectCreditData } from '../../../../redux/CreditDataSlice';
import { selectSidebar } from '../../../../redux/SidebarSlice';

const CreditMemoTable = () => {
  const creditInvoice = useSelector(selectSidebar);
  const creditData = useSelector(selectCreditData);
  const [tableManager, setTableManager] = useState({});

  const availableCredits = creditData.availableCredits;
  const creditsCurrentlyApplied = creditInvoice.credits || [];
  const creditsToBeApplied = creditData.credits || [];
  const usedCreditIds = map(uniqBy([...creditsCurrentlyApplied, ...creditsToBeApplied], 'id'), 'id');
  const _availableCredits = map(uniqBy([...creditsToBeApplied, ...availableCredits], 'id'));
  const remainingCredits = _availableCredits.filter(
    (credit) => usedCreditIds.includes(credit.id) || credit.balanceLeft > 0,
  );

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  const columns = [
    {
      pinned: true,
      id: 'checkbox',
      width: '50px',
      sortable: false,
      cellRenderer: (props) => <HuiTableCheckBoxCredit {...props} />,
      headerCellRenderer: () => {},
    },
    {
      id: '2',
      field: 'invoiceNumber',
      label: 'Credit Memo',
      width: '120px',
      sortable: false,
      cellRenderer: (props) => <HuiTableInvoiceNumber {...props} />,
    },
    {
      id: '3',
      field: 'creditRestrictionType',
      label: 'Restriction',
      width: '120px',
      sortable: false,
      cellRenderer: (props) => <HuiTableCreditRestriction {...props} />,
    },
    {
      id: '4',
      field: 'id',
      label: 'Reference',
      width: 'auto',
      sortable: false,
      cellRenderer: (props) => <HuiTableCreditReference {...props} />,
    },
    {
      pinned: true,
      id: '5',
      width: '250px',
      field: 'balanceLeft',
      label: 'Credit Balance',
      className: 'text-end',
      sortable: false,
      headerCellRenderer: ({ column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      cellRenderer: (props) => <CreditMemoInputAmount {...props} />,
    },
  ];

  return (
    <HuiTable
      isLoading={false}
      sort={{ colId: '2', isAsc: false }}
      className="hui-table hui-table-no-border hui-table-fluid"
      columns={columns}
      rows={remainingCredits}
      totalRows={remainingCredits?.length}
      isPaginated={false}
      onRowsRequest={null}
      onLoadTableManager={onLoadTableManager}
    />
  );
};

export default CreditMemoTable;
