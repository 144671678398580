import React from 'react';
import * as PropTypes from 'prop-types';


const propTypes = {
  borderBottom: PropTypes.bool,
  className: PropTypes.string,
  stepNumber: PropTypes.number,
  title: PropTypes.string,
};

const HuiPanelHeader = (props) => {
  const { title, className, borderBottom, stepNumber } = props;

  const huiPanelClass = `hui-panel-header px-3 d-flex flex-column justify-content-center w-100 m-0 ${
    borderBottom ? 'border-bottom' : ''
  } ${className || ``}`;

  const num = stepNumber && (
    <div className="hui-panel-header-num me-2">{stepNumber}</div>
  );

  return (
    <div className={huiPanelClass}>
      {!!title && (
        <div className="hui-text-lg fw-bold d-flex align-items-center px-2">
          {num}
          {title}
        </div>
      )}
      {props.children}
    </div>
  );
};

HuiPanelHeader.propTypes = propTypes;

export default HuiPanelHeader;
