import React from 'react';
import { resetPaymentData } from '../../redux/PaymentDataSlice';
import { useDispatch } from 'react-redux';

const HuiTableInfo = ({
                        tableManager,
                        totalCount = tableManager.rowsApi.totalRows,
                        pageSize = tableManager.paginationApi.pageSize,
                        pageCount = tableManager.paginationApi.pageRows.length,
                        selectedCount = tableManager.rowSelectionApi.selectedRowsIds.length,
                      }) => {
  const dispatch = useDispatch();
  const {
    config: {
      isPaginated,
      tableHasSelection,
      texts: { totalRows: totalRowsText, rows: rowsText, selected: selectedText },
      icons: { clearSelection: clearSelectionIcon },
      additionalProps: { information: additionalProps = {} },
    },
    paginationApi: { page },
    rowSelectionApi: { setSelectedRowsIds },
  } = tableManager;

  let classNames = ('' + (additionalProps.className || '')).trim();

  return (
    <div {...additionalProps} className={classNames}>
      {totalRowsText} {totalCount || 0}&nbsp;
      {!isPaginated
        ? ''
        : `| ${rowsText} ${
          !pageCount ? '0' : `${pageSize * (page - 1) + 1} - ${pageSize * (page - 1) + pageCount}`
        }`}{' '}
      {tableHasSelection ? (
        <>
          {`| ${selectedCount} ${selectedText}`}
          {selectedCount ? (
            <span className="rgt-footer-clear-selection-button rgt-clickable" onClick={() => clearSelection()}>
              {clearSelectionIcon}
            </span>
          ) : null}
        </>
      ) : (
        ''
      )}
    </div>
  );

  async function clearSelection() {
    await dispatch(resetPaymentData({}));
    setSelectedRowsIds([]);
  }
};

export default HuiTableInfo;
