import { axiosInstance } from '../../utils/utilsAxios';

export async function getInvoice(id) {
  const url = `v1/invoices/${id}`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url);
    const invoice = response.data;
    return invoice;
  } catch (e) {
    throw new Error(e.message);
  }
}

