import React from 'react';

const HuiModalActions = (props) => {
  const { modal, close } = props;

  const {
    confirmAction,
    confirmLabel,
    cancelLabel,
    showCancelButton,
    cancelAction,
    hideActions,
    showConfirmButton,
    hideClose,
  } = modal || {};

  return (
    <div
      className="hui-modal-actions d-flex align-items-center mt-3"
      hidden={hideActions}
    >
      <div className="me-2 flex-grow-1">
        <button
          hidden={hideClose || !showCancelButton}
          onClick={() => close()}
          className="hui-btn hui-btn-outline hui-btn-red"
        >
          Close
        </button>

        <button
          hidden={!showCancelButton}
          onClick={cancelAction}
          className="hui-btn hui-btn-outline hui-btn-red"
        >
          {cancelLabel}
        </button>
      </div>
      <div className="ms-auto">
        <button
          hidden={!showConfirmButton}
          onClick={confirmAction}
          className="hui-btn hui-btn-green"
        >
          {confirmLabel}
        </button>
      </div>
    </div>
  );
};

export default HuiModalActions;
