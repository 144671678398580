import HuiTableCell from './HuiTableCell';

 const HuiTableInvoiceDate = (props) => {

  return (
    <HuiTableCell {...props} />
  );
};

export default HuiTableInvoiceDate;
