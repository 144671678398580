import { formatNumber } from './utilsMath';

export const checkIfPaymentHasError = (
  payment = {
    invoices: [],
    subtotal: 0,
    hasShortpayReasons: false,
    hasOverpayReasons: false,
  },
  settings = {
    isShortpayEnabled: false,
    isShortpayAdminEnabled: false,
    isOverpayEnabled: false,
    isOverpayAdminEnabled: false,
  },
) => {
  const { isOverpayEnabled, isOverpayAdminEnabled, isShortpayEnabled, isShortpayAdminEnabled } = settings;
  const { subtotal = 0, invoices = [], hasShortpayReasons, hasOverpayReasons } = payment;

  if (subtotal < 1) {
    return true;
  }

  const _isShortpayEnabled = checkIfShortpayEnabled(isShortpayEnabled, isShortpayAdminEnabled);

  const _isOverpayEnabled = checkIfOverpayEnabled(isOverpayEnabled, isOverpayAdminEnabled);

  return invoices.some((invoice) => {
    return checkIfInvoiceHasError(
      invoice,
      hasShortpayReasons,
      hasOverpayReasons,
      _isShortpayEnabled,
      _isOverpayEnabled,
    );
  });
};

export const checkIfInvoiceHasError = (
  invoice,
  hasShortpayReasons,
  hasOverpayReasons,
  isShortpayEnabled,
  isOverpayEnabled,
) => {
  const { paymentType, balance = 0, amount = 0, creditApplied = 0 } = invoice || {};
  const total = formatNumber(amount + creditApplied, 2);

  if (total < balance) {
    return checkIfInvoiceHasShortPaymentError(invoice, hasShortpayReasons, isShortpayEnabled);
  }

  if (total > balance) {
    return checkIfInvoiceHasOverPaymentError(invoice, hasOverpayReasons, isOverpayEnabled);
  }

  return total === balance && paymentType !== 'complete';
};

export const checkIfShortpayEnabled = (isShortpayEnabled, isShortpayAdminEnabled) => {
  const isWithinIframe = window.parent !== window;
  return isShortpayEnabled || (isWithinIframe && isShortpayAdminEnabled);
};

export const checkIfOverpayEnabled = (isOverpayEnabled, isOverpayAdminEnabled) => {
  const isWithinIframe = window.parent !== window;
  return isOverpayEnabled || (isWithinIframe && isOverpayAdminEnabled);
};

const checkIfInvoiceHasOverPaymentError = (invoice, hasOverpayReasons, isOverpayEnabled) => {
  const { paymentType, paymentReasonId } = invoice || {};

  const isMissingPaymentReason = isOverpayEnabled && !paymentReasonId && hasOverpayReasons;
  const isOverpayInvalid = !isOverpayEnabled || paymentType !== 'overpay';
  return isMissingPaymentReason || isOverpayInvalid;
};

const checkIfInvoiceHasShortPaymentError = (invoice, hasShortpayReasons, isShortpayEnabled) => {
  const { paymentType, paymentReasonId } = invoice || {};

  const isMissingPaymentReason = isShortpayEnabled && !paymentReasonId && hasShortpayReasons;
  const isShortPaymentInvalid = !isShortpayEnabled || paymentType !== 'shortpay';
  return isMissingPaymentReason || isShortPaymentInvalid;
};
