import currencyFormatter from 'currency-formatter';
import { HuiCalendar, HuiCheckbox, HuiInput, HuiRadio, HuiRadioOption, HuiSelect } from 'handle-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import toastr from 'toastr';
import { createPayment } from '../../../../actions/payment/createPayment';
import { createPrepay } from '../../../../actions/payment/createPrepay';
import { getPayment } from '../../../../actions/payment/getPayment';
import { resetInvoices } from '../../../../redux/InvoicesSlice';
import { forceCloseLoading, openLoading } from '../../../../redux/LoadingSlice';
import { closeModal, openModal } from '../../../../redux/ModalSlice';
import { resetPaymentData, selectPaymentData } from '../../../../redux/PaymentDataSlice';
import { updatePayment } from '../../../../redux/PaymentSlice';
import { store } from '../../../../store/store';
/* eslint import/no-webpack-loader-syntax: off */
import style from '!!raw-loader!./HuiCardPointeForm.css';
import styleSmall from '!!raw-loader!./HuiCardPointeFormSmall.css';
import { addDays } from 'date-fns';
import { isEmpty, size } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { createOneTimePay } from '../../../../actions/payment/createOneTimePay';
import { getCardOnFile } from '../../../../actions/payment/getCardOnFile';
import GoogleAddressForm from '../../../../components/google-maps/GoogleAddressForm';
import SecurePaymentMessage from '../../../../components/payment/SecurePaymentMessage';
import SubmitPaymentMessage from '../../../../components/payment/SubmitPaymentMessage';
import { resetCredits } from '../../../../redux/CreditsSlice';
import {
  resetInvoicesPayment,
  selectInvoicesPayment,
  updatePaymentCardType,
  updatePaymentState,
} from '../../../../redux/InvoicesPaymentSlice';
import { selectPaymentReasons } from '../../../../redux/PaymentReasonsSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { getAddressFromGoogleMap } from '../../../../utils/utilsGoogleMap';
import { getCustomerName } from '../../../../utils/utilsOneTimePayment';
import { paymentOriginOptions } from '../../../../utils/utilsPaymentOriginOptions';
import { calculateSurcharge } from '../../../../utils/utilsSurcharge';
import HuiPaymentSummary, { surchargeEnabled } from '../confirm-payment/HuiPaymentSummary';
import OneClickPay from './OneClickPay';
import {
  checkPaymentFormForError,
  generateIdempotencyKey,
  hasPaymentReasons,
  prepareInvoicesToPay,
} from './utilPaymentForm';

const POSTAL_CODE_LENGTH = 5;

const HuiCardPointeForm = (props) => {
  const { token, className, cardOnFileSearchDisabled = false, zipOnly = false, small, hidden } = props;
  const { pathname } = useLocation();
  const portalInfo = useSelector(selectPortalInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const paymentData = useSelector(selectPaymentData);
  const paymentReasons = useSelector(selectPaymentReasons);
  const invoicesPayment = useSelector(selectInvoicesPayment);

  const {
    settings: { isCardOnFileEnabled = false, isScheduledPaymentEnabled = false, isSurchargeEnabled = false } = {},
  } = portalInfo || {};
  const { isPrepaid, isOneTimePay } = paymentData || {};

  const payment = {
    invoices: paymentData.paymentInvoices,
    subtotal: paymentData.subtotal,
    hasOverpayReasons: hasPaymentReasons(paymentReasons, 'overpay'),
    hasShortpayReasons: hasPaymentReasons(paymentReasons, 'shortpay'),
    paymentMethod: UtilPaymentMethods.CREDIT_CARD,
    info: paymentData.info,
    isOneTimePay: paymentData.isOneTimePay,
    paymentRequestToken: paymentData.paymentRequestToken,
  };

  const isSlim = pathname.endsWith('-slim');
  const isWithinIframe = window.parent !== window;
  const isRequestWaiver = !!searchParams.get('requestWaiver');

  const [iframeSrc, setIframeSrc] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [loadedForm, setLoadedForm] = useState(false);
  const [idempotencyKey, setIdempotencyKey] = useState(null);
  const [fields, setFields] = useState({
    scheduled: false,
    recurring: false,
    postalCode: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    origin: '',
    savePaymentToken: false,
    cvv: '',
    cardOnFile: null,
    scheduledAt: null,
    holderName: '',
    cardType: null,
  });
  const [cardOnFileList, setCardOnFileList] = useState({});
  const [scheduledCalendar, setScheduledCalendar] = useState({
    dateSelected: null,
    value: false,
  });

  function convertCss() {
    const css = small ? styleSmall : style;
    const styleProperties = css.replace(/(\r\n|\n|\r|\s)/gm, '');
    const encoded = encodeURIComponent(styleProperties);
    return encoded;
  }

  const buildIframe = useCallback((site) => {
    if (!site) return;
    return buildIframeUrl(site);
  }, []);

  function buildIframeUrl(subdomain) {
    const url = `https://${subdomain}.cardconnect.com/itoke/ajax-tokenizer.html?
    useexpiry=true
    &usecvv=true
    &cardinputmaxlength=19
    &cardnumbernumericonly=true
    &usemonthnames=false
    &expirylabel=Expiration%20Date%20MM/YYYY
    &formatinput=true
    &css=${convertCss()}`;
    const finalUrl = url.replace(/(\n|\s)/gm, '');
    return setIframeSrc(finalUrl);
  }

  function iframeMessageHandler() {
    window.addEventListener(
      'message',
      function (event) {
        const cardToken = document.getElementById('card_token');
        const cardExpiry = document.getElementById('card_expiry');
        let token;
        try {
          token = JSON.parse(event.data);
          cardToken.value = token.message;
          cardExpiry.value = token.expiry;
        } catch (error) {}
      },
      false,
    );
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (hasPaymentError()) {
      toastr.warning(`Please review your payments details and the invoices to proceed.`);
      return;
    }
    store.dispatch(openLoading());
    const cardToken = e.target.elements.card_token.value;
    const cardExpiry = e.target.elements.card_expiry.value;
    try {
      await preparePayment(cardToken, cardExpiry);
    } catch (e) {
      console.log('Error processing payment token', e);
    }
  };

  async function preparePayment(cardToken, cardExpiry) {
    const payload = await createPaymentPayload(cardToken, cardExpiry);
    await processPayment(payload);
  }

  async function createPaymentPayload(cardToken, cardExpiry) {
    const paymentData = await store.getState().paymentData;
    const { paymentRequestToken, isOneTimePay, info: paymentDataInfo } = paymentData;

    if (isOneTimePay) {
      const info = {
        ...(paymentDataInfo || {}),
        name: getCustomerName(paymentDataInfo),
        notes: fields.notes,
      };
      return {
        paymentType: UtilPaymentMethods.CREDIT_CARD,
        subtotal: parseFloat(paymentData.subtotal),
        currencyCode: 'USD',
        token: cardToken,
        expiry: cardExpiry,
        paymentRequestToken: paymentRequestToken || undefined,
        billingAddress: {
          address: fields.address || '',
          address2: fields.address2 || '',
          city: fields.city || '',
          state: fields.state || '',
          postalCode: fields.postalCode,
        },
        info,
        isOneTimePay: true,
        holderName: fields.holderName,
        cardType: fields.cardType,
      };
    }

    const { subtotal, isPrepaid, credit, balance } = paymentData;

    const total = subtotal - credit - balance;

    const shouldAddRecurrence = isPrepaid && paymentData?.recurrence?.frequency === 'MONTHLY';

    const paymentJson = {
      paymentType: UtilPaymentMethods.CREDIT_CARD,
      subtotal: parseFloat(subtotal),
      balance: parseFloat(balance),
      credit: parseFloat(credit),
      total: total,
      currencyCode: 'USD',
      invoices: !isPrepaid ? prepareInvoicesToPay(paymentData.paymentInvoices) : [],
      info: isPrepaid ? paymentData.info : {},
      recurrence: shouldAddRecurrence ? paymentData.recurrence : {},
      isPrepaid,
      token: cardToken,
      expiry: cardExpiry,
      billingAddress: {
        address: fields.address,
        address2: fields.address2,
        city: fields.city,
        state: fields.state,
        postalCode: fields.postalCode,
      },
      origin: isEmpty(fields.origin) ? null : fields.origin,
      savePaymentToken: fields.savePaymentToken,
      scheduledAt: isPrepaid ? paymentData.scheduledAt : fields.scheduledAt,
      holderName: fields.holderName,
      cardType: fields.cardType,
    };
    if (fields.cardOnFile) {
      paymentJson.cvv = fields.cvv;
      paymentJson.cardOnFile = Number.parseInt(fields.cardOnFile);
    }
    return paymentJson;
  }

  function switchCardType(cardType, payment) {
    const newPayment = { ...payment, cardType: cardType };
    setFields({ ...fields, cardType: cardType });
    dispatch(updatePaymentCardType(cardType));
    store.dispatch(closeModal());
    return processPayment(newPayment);
  }

  async function processPayment(payment) {
    try {
      const submitPayment = await handleCreatePayment(payment);
      await paymentSuccess(submitPayment);
    } catch (e) {
      store.dispatch(forceCloseLoading({}));

      let modal = {};
      const { subtotal = 0, credit = 0, balance = 0 } = payment;
      const total = subtotal - credit - balance;
      const totalSurchargeFormatted = currencyFormatter.format(total + calculateSurcharge(total), {
        code: 'USD',
      });
      const totalFormatted = currencyFormatter.format(total, {
        code: 'USD',
      });

      const { state } = invoicesPayment;
      const showSurcharge = surchargeEnabled(isSurchargeEnabled, UtilPaymentMethods.CREDIT_CARD, 'CREDIT', state);
      if (e?.error?.code === 'token_is_a_credit_card') {
        const _total = showSurcharge ? totalSurchargeFormatted : totalFormatted;
        modal = {
          type: 'creditCardSurcharge',
          title: 'Incompatible Card Type',
          message: total,
          size: 'md',
          confirmLabel: `Pay ${_total} Now`,
          confirmAction: () => switchCardType('CREDIT', payment),
          cancelLabel: `I Will Fix The Card Type`,
          cancelAction: () => store.dispatch(closeModal()),
          showConfirmButton: true,
          showCancelButton: true,
          hideClose: true,
          showSurcharge: showSurcharge,
        };
      } else if (e?.error?.code === 'token_is_a_debit_card') {
        modal = {
          type: 'debitCard',
          title: 'Incompatible Card Type',
          message: total,
          size: 'md',
          confirmLabel: `Pay ${totalFormatted} Now`,
          confirmAction: () => switchCardType('DEBIT', payment),
          cancelLabel: `I Want to Change my Card Type`,
          cancelAction: () => store.dispatch(closeModal()),
          showConfirmButton: true,
          showCancelButton: true,
          hideClose: true,
          showSurcharge: showSurcharge,
        };
      } else if (e?.error?.code === 'amount_over_maximum') {
        modal = {
          type: 'amountOverMaximum',
          message: '',
          size: 'md',
          confirmLabel: 'Ok, I Will Fix It',
          confirmAction: () => store.dispatch(closeModal()),
          showConfirmButton: true,
          hideClose: true,
        };
      } else {
        modal = {
          type: 'alerts',
          title: 'Error processing payment',
          message: e.message,
          confirmLabel: 'Ok, I Will Fix It',
          confirmAction: () => store.dispatch(closeModal()),
          showConfirmButton: true,
        };
      }
      return store.dispatch(openModal(modal));
    }
  }

  async function handleCreatePayment(payment) {
    const { isPrepaid, isOneTimePay } = payment;

    if (isPrepaid) {
      return createPrepay(payment, idempotencyKey);
    }

    if (isOneTimePay) {
      return createOneTimePay(payment, idempotencyKey);
    }

    return createPayment(payment, idempotencyKey);
  }

  async function paymentSuccess(payment) {
    try {
      if (isOneTimePay) {
        return await redirectToConfirmationPage(payment);
      }
      const { code } = payment;
      const result = await getPayment(code);
      await store.dispatch(updatePayment(result));
      await store.dispatch(forceCloseLoading({}));
      await redirectToThanksPage(code);
    } catch (e) {
      toastr.error('Error processing payment');
    }
  }

  async function redirectToConfirmationPage(payment) {
    let path = '/checkout-confirmation';
    navigate(path, {
      state: {
        payment,
      },
    });
  }

  async function redirectToThanksPage(code) {
    let path = isSlim ? `/thanks-slim/${code}` : `/thanks/${code}`;
    if (isRequestWaiver) {
      path += `?requestWaiver=true`;
    }
    navigate(path);
    await resetPayment();
  }

  async function resetPayment() {
    await store.dispatch(resetPaymentData({}));
    await store.dispatch(resetInvoices({}));
    await store.dispatch(resetInvoicesPayment({}));
    await store.dispatch(resetCredits({}));
  }

  function hasPaymentError() {
    const cardToken = document.getElementById('card_token');
    const _fields = {
      ...fields,
      cardToken,
    };
    return checkPaymentFormForError(_fields, payment, portalInfo.settings);
  }

  useEffect(() => {
    async function loadCardOnFile() {
      const cardOnFileList = await getCardOnFile(UtilPaymentMethods.CREDIT_CARD);
      setCardOnFileList(cardOnFileList);
    }

    if (!size(cardOnFileList) && !cardOnFileSearchDisabled) {
      loadCardOnFile();
    }
  }, [cardOnFileList, setCardOnFileList, cardOnFileSearchDisabled]);

  useEffect(() => {
    async function mountPaymentForm() {
      if (loadedForm) {
        return;
      }

      const { checkoutToken } = await store.getState().invoicesPayment;
      buildIframe(token || checkoutToken);

      store.dispatch(forceCloseLoading({}));
      if (typeof window != 'undefined') {
        iframeMessageHandler();
        setLoadedForm(true);
        setIdempotencyKey(generateIdempotencyKey());
      }
    }

    mountPaymentForm();
  }, [fields, buildIframe, loadedForm, token]);

  useEffect(() => {
    async function mountUrl() {
      if (token) {
        buildIframeUrl(token);
        return buildIframe(token);
      }
    }
    mountUrl();
  }, [token, buildIframe]);

  const showCardOnFileList = isCardOnFileEnabled && cardOnFileList?.count > 0;

  const showCards = showCardOnFileList && fields.cardOnFile !== '';

  const isScheduled = fields.scheduled && !paymentData.isOneTimePay;

  const sizeClass = small ? 'small' : 'large';
  const CCFormSize = small ? '180px' : '230px';

  function oneClickPayChange(e) {
    const { expanded: expandedChange, cardOnFile, cvv, state, cardType } = e;
    setExpanded(expandedChange);
    setFields({ ...fields, cvv, cardOnFile, cardType });
    dispatch(updatePaymentState(state));
    dispatch(updatePaymentCardType(cardType));
  }

  return (
    <form onSubmit={formSubmitHandler} method="post" id="payment-form" hidden={hidden}>
      <OneClickPay
        type={UtilPaymentMethods.CREDIT_CARD}
        cardOnFileList={cardOnFileList || {}}
        onChange={oneClickPayChange}
      />

      <div className="col-12 mb-1 mt-3" hidden={showCards}>
        <div className="mb-3">
          <HuiRadio id="cardType" label="Card Type *" name="cardType" required={!showCards} onChange={handleChange}>
            <span key="cardTypeDiv"></span>
            <div key="cardTypeElm" className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <HuiRadioOption
                  name="cardType"
                  id="DEBIT"
                  key="DEBIT"
                  className="text-nowrap"
                  label="Debit Card"
                  value="DEBIT"
                  checked={fields.cardType === 'DEBIT'}
                />
              </div>
              <div className="w-25 d-flex align-items-center mx-2 d-xl-none d-xxl-block">
                <div className="hui-or">Or</div>
              </div>
              <div className="d-flex align-items-center">
                <HuiRadioOption
                  id="CREDIT"
                  key="CREDIT"
                  name="cardType"
                  className="text-nowrap"
                  label="Credit Card"
                  value="CREDIT"
                  checked={fields.cardType === 'CREDIT'}
                />
              </div>
            </div>
          </HuiRadio>
        </div>

        <HuiInput
          label="Name on card"
          id="holderName"
          name="holderName"
          className="mb-0"
          value={fields.holderName}
          onChange={handleChange}
          topLabel
          size={sizeClass}
          dataPrivate
          required={!showCards}
        />
      </div>

      <div className={className}>
        <iframe
          hidden={showCards}
          id="tokenFrame"
          name="tokenFrame"
          title="credit card iframe"
          src={iframeSrc}
          width="100%"
          height={CCFormSize}
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          scrolling="no"
        />
      </div>

      {!showCards && (
        <>
          <div
            className="col-12 mb-3 border p-2 rounded hui-bg-gray-light hui-border-light"
            hidden={!isCardOnFileEnabled}
          >
            <HuiCheckbox
              className="m-0"
              name="savePaymentToken"
              onChange={handleChange}
              size="small"
              label="Store card data for future use"
              showLabel
              checked={fields.savePaymentToken}
            />
          </div>

          <div className="hui-text-lg fw-bold mb-4">Billing Address</div>
          <GoogleAddressForm
            label="Address"
            id="creditCardAddress"
            name="creditCardAddress"
            onChange={handleGoogleAddressChange}
            required={!fields.cardOnFile}
            topLabel
            size={sizeClass}
            requireAddress2={false}
            inputProps={{
              role: 'presentation',
              autoComplete: 'nope',
            }}
            zipOnly={zipOnly}
          />
        </>
      )}

      <div hidden={!isWithinIframe} className="mt-4">
        <div className="row">
          <div className="col-12">
            <HuiSelect
              topLabel
              required={isWithinIframe}
              size={sizeClass}
              value={fields.origin}
              id="origin"
              name="origin"
              label="Request Method"
              onChange={handleChange}
              options={paymentOriginOptions}
            />
          </div>
        </div>
      </div>

      <div
        className="col-12 border p-2 rounded hui-bg-gray-light hui-border-light mt-4"
        hidden={isPrepaid || !(isScheduledPaymentEnabled && isCardOnFileEnabled)}
      >
        <HuiCheckbox
          className="m-0"
          name="scheduled"
          onChange={handleChange}
          size="small"
          label="Schedule payment (Optional)"
          showLabel
          checked={fields.scheduled}
        />

        <div hidden={!isScheduled}>
          <h2 className="hui-text fw-bold my-3">Please select a date to process the payment.</h2>
          <HuiCalendar
            onChange={(item) => {
              setScheduledCalendar({ dateSelected: item, value: true });
              setFields({ ...fields, scheduledAt: item.toISOString() });
            }}
            onClear={() => {
              setScheduledCalendar({ dateSelected: null, value: false });
              setFields({ ...fields, scheduledAt: null });
            }}
            handleOnClick={() => {}}
            onClickDisabled={true}
            className="mb-0 hui-calendar"
            size="small"
            label="Scheduled Date"
            id="scheduledAt"
            name="scheduledAt"
            minDate={addDays(new Date(), 1)}
            date={scheduledCalendar}
          />
          <h2 className="hui-text-sm mt-3 mb-0">
            You can review or cancel this transaction on the payments report page.
          </h2>
        </div>
      </div>

      <div className="col-12 mt-2" hidden={!isOneTimePay}>
        <HuiInput
          multiline
          maxRows={3}
          minRows={3}
          label="Notes"
          id="notes"
          name="notes"
          type="text"
          value={fields.notes}
          onChange={handleChange}
          size={sizeClass}
          topLabel
          placeholder="Please provide information for this payment"
        />
        <HuiPaymentSummary hideTitle />
      </div>

      <input type="hidden" name="card_token" id="card_token" />
      <input type="hidden" name="card_expiry" id="card_expiry" />

      <div className="button-container mt-4">
        <button
          type="submit"
          className="hui-btn hui-btn-green hui-btn-xl w-100"
          hidden={isScheduled}
          disabled={hasPaymentError()}
        >
          Submit Payment
        </button>
        <button className="hui-btn hui-btn-yellow hui-btn-xl w-100" hidden={!isScheduled} disabled={hasPaymentError()}>
          Schedule Payment Now
        </button>
        <SecurePaymentMessage />
        <SubmitPaymentMessage isPaidOnBehalf={isWithinIframe} />
      </div>
    </form>
  );

  function handleChange(e) {
    const { name, value, checked, type } = e.target || {};
    let newFields = {};
    if (name === 'scheduled') {
      setScheduledCalendar({ dateSelected: null, value: false });
      newFields = { scheduledAt: null };
    }

    setFields({ ...fields, ...newFields, [name]: type === 'checkbox' ? checked : value });

    if (name === 'cardType') {
      dispatch(updatePaymentCardType(value));
    }
  }

  async function handleGoogleAddressChange(e) {
    const { target: { value: creditCardAddress = {} } = {} } = e;
    const { city, state, street: address, postalCode, address2, country } = creditCardAddress || {};
    if (zipOnly && size(postalCode) === POSTAL_CODE_LENGTH) {
      const zipAddress = await getAddressFromGoogleMap(postalCode);
      dispatch(updatePaymentState(zipAddress?.state));
      setFields({ ...fields, state: zipAddress?.state, postalCode });
      return;
    }
    setFields({ ...fields, city, state, address, address2, postalCode, country });
    dispatch(updatePaymentState(state));
  }
};

export default HuiCardPointeForm;
