export const firebaseExceptionMessages = {
  'auth/user-not-found': 'User not found, please verify your email.',
  'auth/invalid-email': 'Invalid email, please verify your email.',
  'auth/wrong-password': 'The password is invalid or the user does not have a password.',
  'auth/user-token-expired': 'Token expired. Try to login again.',
  'auth/email-already-in-use': 'Email is already in use. Change email or request password recovery.',
};

export function getFirebaseMessage(error) {
  const firebaseMessage = firebaseExceptionMessages[error.code];
  if (firebaseMessage) {
    return firebaseMessage;
  }

  if (error.message) {
    return error.message?.replace('Firebase:', '').replace(/ *\([^)]*\). */g, '');
  } else {
    return 'Unexpected exception.';
  }
}
