import { createSlice } from '@reduxjs/toolkit';

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {
    value: {},
  },
  reducers: {
    updateInvoices: (state, action) => {
      state.value = action.payload;
    },
    resetInvoices: (state, action) => {
      state.value = {};
    },
  },
});

export const { updateInvoices, resetInvoices } = invoicesSlice.actions;

export const selectInvoices = (state) => state.invoices.value;

export default invoicesSlice.reducer;
