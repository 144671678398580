import { Link } from 'react-router-dom';
import * as currencyFormatter from 'currency-formatter';

const SummaryPanelItem = (props) => {
  const { hidden, link, color, amount, title, label, className, textClass, disabled } = props;

  const amountFormatted = currencyFormatter.format(amount, { code: 'USD' });

  const summaryClass = className || 'hui-dashboard-summary d-flex align-items-center px-3 py-2';
  const summaryTextClass = textClass || `hui-subtitle hui-color-${color} mb-0`;
  const summarySubTextClass = textClass
    ? `hui-subtitle hui-color-${color} mb-0`
    : 'hui-text-xs fw-bold text-uppercase hui-color-gray mb-0';

  const SummaryLink = disabled ? 'div' : Link;
  const linkProps = disabled ? {} : { to: link, title: 'View' };

  return (
    <>
      <SummaryLink
        {...linkProps}
        className={`${summaryClass} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
        hidden={hidden}
      >
        <div className="flex-grow-1">
          <div className={`${summaryTextClass} ${disabled ? 'hui-dashboard-summary-disabled' : ''}`}>
            {textClass ? title : amountFormatted}
          </div>
          <div className={`${summarySubTextClass} ${disabled ? 'hui-dashboard-summary-disabled' : ''}`}>
            {textClass ? amountFormatted : title}
          </div>
        </div>

        {!!label && (
          <span
            className={`hui-btn hui-btn-rounded hui-btn-${amount === '0' ? 'gray' : 'green'}-light ${
              disabled ? 'hui-btn-disabled' : ''
            }`}
          >
            {label}
          </span>
        )}
      </SummaryLink>
    </>
  );
};

export default SummaryPanelItem;
