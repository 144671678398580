import currencyFormatter from 'currency-formatter';
import { find, map, size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import toastr from 'toastr';
import ViewInvoice from '../../../../components/invoice/ViewInvoice';
import ViewInvoiceCredit from '../../../../components/invoice/ViewInvoiceCredit';
import { selectPaymentReasons } from '../../../../redux/PaymentReasonsSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import {
  checkIfInvoiceHasError,
  checkIfOverpayEnabled,
  checkIfShortpayEnabled,
} from '../../../../utils/utilCheckIfPaymentHasError';
import { formatNumber } from '../../../../utils/utilsMath';
import { hasPaymentReasons } from '../payment-form/utilPaymentForm';

const HuiPaymentInvoices = (props) => {
  const { payment, showAll, edit, hidden } = props;

  const paymentReasons = useSelector(selectPaymentReasons);
  const portalInfo = useSelector(selectPortalInfo);

  const { currencyCode, code } = payment || {};
  const {
    settings: {
      isCreditMemoEnabled = false,
      isShortpayEnabled = false,
      isShortpayAdminEnabled = false,
      isOverpayEnabled = false,
      isOverpayAdminEnabled = false,
    } = {},
  } = portalInfo;

  const _isShortpayEnabled = checkIfShortpayEnabled(isShortpayEnabled, isShortpayAdminEnabled);
  const _isOverpayEnabled = checkIfOverpayEnabled(isOverpayEnabled, isOverpayAdminEnabled);
  const hasOverpayReasons = hasPaymentReasons(paymentReasons, 'overpay');
  const hasShortpayReasons = hasPaymentReasons(paymentReasons, 'shortpay');

  const invoicesListClass = `hui-pay-confirm-invoices mb-0 ${
    size(payment.paymentInvoices) > 11 && !showAll ? `hui-pay-confirm-invoices-scroll` : ``
  }`;

  const reviewPayment = (invoiceNumber) => {
    edit?.();
    toastr.warning(`Please correct the invoice ${invoiceNumber} to proceed.`);
  };

  const listInvoices = map(payment?.paymentInvoices, (invoice, i) => {
    const { invoiceNumber, balance, amount, paymentReasonId, id, invoiceId, credits, credit, creditApplied } =
      invoice || '';
    const hasInvoiceError =
      checkIfInvoiceHasError(invoice, hasShortpayReasons, hasOverpayReasons, _isShortpayEnabled, _isOverpayEnabled) &&
      !code;

    const getPaymentReason = find(paymentReasons, (p) => p.id === paymentReasonId) || {};
    const { title: paymentReasonTitle } = getPaymentReason || '';

    const hasTotal = balance === parseFloat(amount);
    const creditUsed = creditApplied || credit || 0;

    const total = formatNumber(amount + creditUsed, 2);
    const totalFormatted = currencyFormatter.format(total, { code: currencyCode });
    const balanceFormatted = currencyFormatter.format(balance, { code: currencyCode });
    const amountFormatted = currencyFormatter.format(amount, { code: currencyCode });

    const showOverpayBadge = total > balance;
    const isShortPay = total < balance;

    const sidebarInvoiceId = invoiceId ? invoiceId : id;
    const overPayDiff = currencyFormatter.format(total - balance, { code: currencyCode });
    const hasCredits = size(credits);
    const listCredits = credits?.map((c, i) => {
      const { amount, invoiceId, invoiceNumber } = c;

      const creditFormatted = currencyFormatter.format(amount * -1, { code: currencyCode });

      return (
        <div key={i} className="d-flex align-items-center ps-3 my-1 hui-pay-confirm-credit">
          <div className="flex-grow-1">
            <ViewInvoice id={invoiceId} className="hui-btn hui-btn-link text-decoration-none">
              {invoiceNumber}
            </ViewInvoice>
          </div>
          <div className="text-end hui-text-sm hui-color-gray">{creditFormatted}</div>
        </div>
      );
    });

    const warning = hasInvoiceError ? 'hui-color-yellow-dark' : '';

    return (
      <React.Fragment key={i}>
        <div className="d-flex flex-wrap align-items-center mb-1 pb-1 hui-pay-confirm-invoices-item">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              <ViewInvoice id={sidebarInvoiceId} className="hui-btn hui-btn-link text-decoration-none me-1">
                {invoiceNumber}
              </ViewInvoice>
              {!!isCreditMemoEnabled && (
                <ViewInvoiceCredit type="icon" hidden={!size(invoice.availableCreditIds)} id={id} invoice={invoice} />
              )}
            </div>

            <div className="d-flex align-items-center pt-2 " hidden={!hasInvoiceError}>
              <div className="d-flex me-2 cursor-pointer" onClick={() => reviewPayment(invoiceNumber)}>
                <i className="hui-i hui-svg-warning-sm"></i>
              </div>
              <span
                hidden={!hasInvoiceError}
                className="hui-text-sm cursor-pointer"
                onClick={() => reviewPayment(invoiceNumber)}
              >
                Click here to review
              </span>
            </div>

            {!!paymentReasonTitle && (
              <div className="hui-text-sm hui-color-gray">Adjustment Reason: {paymentReasonTitle}</div>
            )}

            {showOverpayBadge && (
              <div>
                <span className="rounded-pill hui-bg-green hui-color-white py-0 px-2 hui-text-xs">OVERPAY</span>
              </div>
            )}
          </div>
          <div className={`text-end fw-bold ${warning}`}>
            <div className="hui-text-sm" hidden={hasTotal}>
              <span className="hui-text-sm hui-color-gray" hidden={!isShortPay}>
                <del>{balanceFormatted}</del>{' '}
              </span>
              {!showOverpayBadge ? totalFormatted : balanceFormatted}
              <div className="hui-text-sm hui-color-gray" hidden={!showOverpayBadge}>
                +{overPayDiff}
              </div>
            </div>
            {!hasCredits && amountFormatted}
          </div>
          <div className="hui-pay-confirm-credits" hidden={!hasCredits}>
            {listCredits}
            <div className="d-flex align-items-center">
              <div className="text-end ms-auto fw-bold">{amountFormatted}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
  return (
    !hidden && (
      <>
        <div className={invoicesListClass}>
          <div className="px-3 hui-pay-confirm-invoices-list">{listInvoices}</div>
        </div>
      </>
    )
  );
};

export default HuiPaymentInvoices;
