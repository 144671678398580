import React from 'react';
import toastr from 'toastr';
import { signInWithGoogle } from '../../../actions/auth/signIn';

const SignInWithGoogle = (props) => {
  return (
    <>
      <button onClick={handleSignIn} className="hui-btn hui-btn-xl hui-btn-icon">
        <span className="me-2">
          <i className="hui-i hui-svg-google"> </i>
        </span>
        Sign in with Google
      </button>
    </>
  );

  async function handleSignIn(e) {
    e.preventDefault();
    try {
      await signInWithGoogle();
    } catch (error) {
      toastr.error(error);
    }
  }
};

export default SignInWithGoogle;
