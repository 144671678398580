import { createSlice } from '@reduxjs/toolkit';

export const paymentReasonsSlice = createSlice({
  name: 'paymentReasons',
  initialState: {
    value: null,
  },
  reducers: {
    updatePaymentReasons: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updatePaymentReasons } = paymentReasonsSlice.actions;

export const selectPaymentReasons = (state) => state.paymentReasons.value;

export default paymentReasonsSlice.reducer;
