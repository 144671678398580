import React from 'react';
import PortalLogo from '../portal/PortalLogo';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';
import { useSelector } from 'react-redux';

const HuiPanelPayment = (props) => {
  const portalInfo = useSelector(selectPortalInfo);

  const {
    children,
    title,
    color,
    className = 'hui-bg-gray-light py-md-4 py-3 px-4 px-md-5 h-100 d-flex flex-grow-1',
  } = props || {};
  const { settings } = portalInfo || {};
  const { themeColor } = settings || {};

  const panelColor = color || themeColor;

  return (
    <>
      <div className="hui-payment-panel-logo">
        {!color ? <PortalLogo noName /> : <div className={`hui-payment-panel-theme-color hui-bg-${color}`}></div>}
      </div>
      <div className={`hui-payment-panel-theme-color hui-payment-panel-theme hui-bg-${panelColor}`}></div>
      <div className={`${className}`}>
        <div className="d-flex flex-column flex-grow-1">
          {title && <h2 className="hui-title-lg mb-0 fw-bold mb-3">{title}</h2>}
          {children}
        </div>
      </div>
    </>
  );
};

export default HuiPanelPayment;
