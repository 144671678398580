import React from 'react';
import { Divider } from '@mui/material';
import ProfileEmail from './ProfileEmail';
import ProfileInfo from './ProfileInfo';
import ProfilePassword from './ProfilePassword';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';

const Profile = (props) => {  
  const portalInfo = useSelector(selectPortalInfo);
  const { settings } = portalInfo || {};
  const { 
    isPortalSingleSignOnEnabled = false, 
    portalSignInProvider,
  } = settings || {};

  const isSingleSignOnEnabled = portalSignInProvider && isPortalSingleSignOnEnabled;

  return (
    <div>
      <ProfileInfo />
      <Divider hidden={isSingleSignOnEnabled} className="mt-5" />
      <ProfilePassword hidden={isSingleSignOnEnabled} />
      <Divider hidden={isSingleSignOnEnabled} className="mt-5" />
      <ProfileEmail hidden={isSingleSignOnEnabled} />
    </div>
  );
}

export default Profile;