import { HuiBadge, HuiIcon } from 'handle-ui';
import { getBadgeColorByStatus } from '../../utils/utilsColors';
import { toLower } from 'lodash';
import React from 'react';

const HuiTablePaidInvoices = ({ data, value }) => {
  const { isPrepaid } = data;
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();

  const badgeColor = getBadgeColorByStatus[toLower(value)] || {};

  return (
    <div className={classNames} title={value}>
      {isPrepaid ? (
        <HuiBadge
          bgColor="green-light"
          textColor="green-dark"
          uppercase
          icon={<HuiIcon name="pay" size="sm" color="#1BA548" />}
        >
          Prepayment
        </HuiBadge>
      ) : (
        <HuiBadge
          bgColor={badgeColor?.bg}
          textColor={badgeColor?.text}
          uppercase
          icon={<HuiIcon name="invoice" size="sm" />}
        >
          {value}
        </HuiBadge>
      )}
    </div>
  );
};

export default HuiTablePaidInvoices;
