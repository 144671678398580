import { HuiAlert, HuiBadge } from 'handle-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const RequestInformation = (props) => {
  const portalInfo = useSelector(selectPortalInfo) || {};

  const { className, fields } = props;
  const { createdBy } = fields || {};
  const { name, title, email, phoneNumber } = createdBy || '';

  const iconVerified = (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7447 7.23953C13.0851 6.75248 13.0851 6.11501 12.7447 5.62796C12.3546 5.06928 11.8156 4.43898 11.7376 4.23843C11.6596 4.03788 11.5957 3.21419 11.4823 2.54091C11.383 1.95358 10.9291 1.50234 10.3546 1.40207C9.68794 1.28747 8.86525 1.223 8.67376 1.14421C8.49645 1.07259 7.8227 0.62135 7.2766 0.241736C6.80851 -0.0805785 6.19858 -0.0805785 5.7305 0.241736C5.1844 0.62135 4.51064 1.07259 4.33333 1.14421C4.13475 1.223 3.31206 1.28747 2.64539 1.40207C2.06383 1.50234 1.60993 1.96074 1.51773 2.54807C1.40426 3.22135 1.34752 4.04504 1.26241 4.24559C1.1773 4.44614 0.64539 5.07645 0.255319 5.62796C-0.0851064 6.11501 -0.0851064 6.75964 0.255319 7.24669C0.64539 7.80537 1.1844 8.43568 1.26241 8.62906C1.34043 8.82245 1.40426 9.65331 1.51064 10.3266C1.60993 10.9139 2.05674 11.3723 2.6383 11.4726C3.30496 11.5872 4.12057 11.6517 4.31915 11.7304C4.51773 11.8092 5.14184 12.3536 5.69504 12.7475C6.1773 13.0913 6.8156 13.0913 7.29078 12.7475C7.84397 12.3536 8.46809 11.8092 8.66667 11.7304C8.84397 11.6588 9.6383 11.4941 10.2908 11.3723C10.844 11.2649 11.2766 10.828 11.383 10.2693C11.5106 9.61033 11.6667 8.80813 11.7376 8.62906C11.8156 8.42851 12.3546 7.79821 12.7447 7.23953ZM5.10638 8.74366L3.58156 7.20372C3.32624 6.94587 3.32624 6.53044 3.58156 6.27259C3.83688 6.01474 4.24823 6.01474 4.50355 6.27259L5.41844 7.19656C5.46809 7.24669 5.5461 7.24669 5.59574 7.19656L8.19149 4.34587C8.43262 4.08085 8.85106 4.05937 9.11348 4.31006C9.37589 4.55358 9.39007 4.96901 9.14894 5.23402L5.97872 8.72934C5.74468 8.98719 5.35461 8.99435 5.10638 8.75083V8.74366Z"
        fill="white"
      />
    </svg>
  );

  return (
    <div className={className}>
      <HuiAlert radius="xl" className="mb-2" background gray inline>
        <div className="me-3">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M60 29.427C59.698 13.3102 46.6898 0.30195 30.573 0C30.1913 0 29.8087 0 29.427 0C13.3252 0.30195 0.326332 13.2839 0 29.3802C0 29.8003 0 30.2204 0 30.6395C0.157539 38.1864 3.10109 45.0478 7.84415 50.2372C8.08327 48.1339 8.97974 46.2753 10.3104 44.5893C13.0176 41.1581 16.7264 39.3867 20.858 38.3552C22.7185 37.891 24.608 37.6088 26.5257 37.4869C27.9257 37.3978 29.3248 37.2759 30.7258 37.3228C34.2367 37.439 37.7166 37.7907 41.0775 38.9C43.4068 39.669 45.5908 40.7305 47.4812 42.3162C49.9691 44.4027 51.6767 46.9617 52.1746 50.2185C56.9205 45.0159 59.8584 38.1377 60.0009 30.5748V29.4289L60 29.427ZM30.0103 34.5377C22.8545 34.5077 16.6345 28.5099 16.6598 21.0803C16.6842 14.0126 22.6378 7.51031 30.5589 7.76725C37.5263 7.99325 43.5634 13.9047 43.4115 21.1393C43.6018 28.4012 37.3415 34.5686 30.0103 34.5377Z"
              fill="#D3D5DD"
            />
            <path
              d="M52.1732 50.2176C51.6744 46.9618 49.9677 44.4027 47.4799 42.3153C45.5894 40.7306 43.4064 39.6681 41.0761 38.8992C37.7162 37.7898 34.2362 37.4382 30.7244 37.3219C29.3225 37.275 27.9234 37.3969 26.5243 37.486C24.6066 37.6079 22.7171 37.8902 20.8567 38.3544C16.7259 39.3859 13.0172 41.1572 10.309 44.5884C8.97837 46.2745 8.0819 48.1331 7.84277 50.2364C13.1738 56.07 20.7779 59.791 29.2531 59.9973H30.7432C39.2287 59.79 46.8403 56.0616 52.1723 50.2158L52.1732 50.2176Z"
              fill="white"
            />
            <path
              d="M30.5584 7.76616C22.6373 7.50922 16.6836 14.0115 16.6593 21.0792C16.6339 28.5088 22.8539 34.5066 30.0098 34.5366C37.341 34.5675 43.6013 28.4001 43.4109 21.1382C43.5629 13.9036 37.5257 7.99121 30.5584 7.76616Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex-grow-1">
          <HuiBadge
            size="sm"
            bgColor="green"
            textColor="white"
            icon={iconVerified}
            value={`Payment Requested by ${name}`}
            uppercase
          />
          <div>
            {title} at {portalInfo?.name}
          </div>
          <div>
            {email} - {phoneNumber}
          </div>
        </div>
      </HuiAlert>
    </div>
  );
};

export default RequestInformation;
