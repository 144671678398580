import { createSlice } from '@reduxjs/toolkit';

export const portalSettingsSlice = createSlice({
	name: 'portalSettings',
	initialState: {
		value: {}
	},
	reducers: {
    updatePortalSettings: (state, action) => {
			state.value = action.payload;
		},
	},
});

export const { updatePortalSettings } = portalSettingsSlice.actions;

export const selectPortalSettings = (state) => state.portalSettings.value;

export default portalSettingsSlice.reducer;
