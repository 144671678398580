import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import toastr from 'toastr';
import App from './App';
import { store } from './store/store';
import { AppTheme } from './styles/appTheme';
import { toastrOptions } from './styles/toastrOptions';
// Import Fonts
import './styles/index.fonts';
// Import Styles
import './styles/index.styles';
import { FIELDS_TO_REDACT, sanitizeRequest } from './utils/utilsLoggers';
import { firebase } from './actions/auth/firebase';

const theme = createTheme(AppTheme);

const initLoggers = () => {
  const appEnv = process.env.REACT_APP_ENV;

  if (appEnv !== 'local') {
    LogRocket.init('lshmr6/payment-portal-yrii8', {
      network: {
        requestSanitizer: (request) => {
          if (request.method === 'POST') {
            request = sanitizeRequest(request, FIELDS_TO_REDACT);
          }
          return request;
        },
        responseSanitizer: (response) => {
          if (response.method === 'POST') {
            response = sanitizeRequest(response, FIELDS_TO_REDACT);
          }
          return response;
        },
      },
    });
    setupLogRocketReact(LogRocket);

  }
  // Initialize analytics
  firebase.analytics().setAnalyticsCollectionEnabled(true);

  Bugsnag.start({
    releaseStage: appEnv,
    enabledReleaseStages: ['production', 'development'],
    apiKey: 'c03aeb0464a9c2d39cebbbf7aa49bdfb',
    plugins: [new BugsnagPluginReact()],
    redactedKeys: FIELDS_TO_REDACT,
  });

  Bugsnag.beforeNotify = function (data) {
    data.metaData.sessionURL = LogRocket.sessionURL;
    return data;
  };
};

initLoggers();

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

toastr.options = {
  ...toastrOptions,
};

const RootApp = () => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </ErrorBoundary>
);

const renderRoot = () => {
  ReactDOM.createRoot(document.getElementById('root')).render(<RootApp />);
};

renderRoot();

export default renderRoot;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
