import { HuiInput } from 'handle-ui';
import { size } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { changePassword } from '../../../actions/auth/changePassword';
import { signOut } from '../../../actions/auth/signOut';
import { logoutAuth, selectAuth } from '../../../redux/AuthSlice';
import { updateUser } from '../../../redux/UserSlice';

const ChangePasswordForm = (props) => {
  const { newUser } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const auth = useSelector(selectAuth);
  const query = queryString.parse(search);
  const { oobCode } = query || '';

  const [form, setForm] = useState({ password: '', confirmPassword: '' });
  const [isPasswordDifferent, setIsPasswordDifferent] = useState(false);
  const [newPassword, setNewPassword] = useState(false);

  if (size(auth)) {
    dispatch(logoutAuth());
    dispatch(updateUser({}));
    localStorage.removeItem('user');
  }

  useEffect(() => {
    async function changePassword() {
      const { password, confirmPassword } = form;
      await signOut();

      if (!confirmPassword) return false;

      if (password && confirmPassword && password === confirmPassword) {
        setIsPasswordDifferent(false);
        return true;
      } else {
        setIsPasswordDifferent(true);
        return false;
      }
    }

    changePassword();
  }, [form, setIsPasswordDifferent]);

  return (
    <>
      {newPassword ? (
        <>
          Your password has been changed successfully.
          <Link className="" to="/login">
            Log in
          </Link>
        </>
      ) : (
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <HuiInput
                label="New Password"
                id="password"
                name="password"
                type="password"
                value={form.password}
                onChange={handleChange}
                inputProps={{ minLength: 6 }}
                size="large"
                required
                topLabel
              />
            </div>

            <div className="col-12">
              <HuiInput
                label="Confirm Password"
                id="confirmPassword"
                name="confirmPassword"
                inputProps={{ minLength: 6 }}
                type="password"
                value={form.confirmPassword}
                onChange={handleChange}
                size="large"
                required
                topLabel
                error={isPasswordDifferent}
              />
              <div className="hui-color-red hui-text" hidden={!isPasswordDifferent}>
                Please make sure your passwords match.
              </div>
            </div>

            <div className="col-12 d-flex flex-column mt-3">
              <button type="submit" className="hui-btn hui-btn-xl hui-btn-green" disabled={!size(oobCode)}>
                {newUser ? 'Create Password' : 'Reset Password'}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { password } = form;
    if (!isPasswordDifferent) {
      try {
        await changePassword(oobCode, password);
        setNewPassword(true);
        toastr.success(
          newUser ? 'Your password was created successfully.' : 'Your password has been changed successfully.',
        );
        navigate('/login');
      } catch (error) {
        toastr.error(error);
      }
    }
  }
};

export default ChangePasswordForm;
