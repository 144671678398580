import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: null
  },
  reducers: {
    updateAuth: (state, action) => {
      state.value = action.payload;
    },
    logoutAuth: (state, action) => {
      state.value = null;
    },
  },
});

export const { updateAuth, logoutAuth } = authSlice.actions;

export const selectAuth = (state) => state.auth.value;

export default authSlice.reducer;

export const TRY_SIGN_WITH_SSO = 'trySignInWithSSO';

export const TRY_SIGNUP = 'trySignUp';