import { debounce, filter } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import HuiTableInfo from '../HuiTableInfo';
import HuiFilters from './HuiFilters';

const HuiTableHeader = ({ tableManager, selectedCount }) => {
  const {
    config: {
      showColumnVisibilityManager,
      components: { ColumnVisibility },
      additionalProps: {
        header: { title, availableFilters, showTableCountInfo },
      },
    },
    columnsApi: { columns },
    columnsVisibilityApi: { toggleColumnVisibility },
    searchApi: { setSearchText, searchText },
  } = tableManager;

  const [text, setText] = useState(searchText);

  const onSearchChange = useMemo(
    () =>
      debounce((searchText, setSearchText) => {
        setSearchText(searchText);
      }, 1000),
    [],
  );

  useEffect(() => {
    setText(searchText);
  }, [setText, searchText]);

  const getColumns = () => {
    return filter(columns, column => {
      const { showColumnVisibility = true } = column;
      return showColumnVisibility;
    })
  };

  return (
    <div className="hui-table-header-actions pb-1 pt-3 d-lg-flex align-items-center">
      <div className="flex-grow-1">
        {!!title && <h2 className="hui-text-lg fw-bold mb-0">{title}</h2>}

        {!!showTableCountInfo && (
          <div className="ps-3">
            <HuiTableInfo tableManager={tableManager} selectedCount={selectedCount} />
          </div>
        )}
      </div>

      <div className="d-flex">
        <HuiFilters
          searchText={text}
          handleSearch={(e) => {
            setText(e.target.value);
            onSearchChange(e.target.value, setSearchText);
          }}
          availableFilters={availableFilters}
        />

        {!!showColumnVisibilityManager && (
          <ColumnVisibility columns={getColumns()} onChange={toggleColumnVisibility} tableManager={tableManager} />
        )}
      </div>
    </div>
  );
};

export default HuiTableHeader;
