import React from 'react';
import { HuiInputField } from 'handle-ui';
import FormControl from '@mui/material/FormControl';

const HuiInput = (props) => {
  const { className, required, hideRequired = false } = props;
  const huiInputClass = `${className || 'mb-3'}`;
  const showRequired = hideRequired ? false : required;

  return (
    <>
      <FormControl className={huiInputClass} required={showRequired}>
        <HuiInputField {...props} />
      </FormControl>
    </>
  );
};
export default HuiInput;
