import { axiosInstance } from '../../utils/utilsAxiosMain';

export async function getOpenProjects(params) {
  const url = `/api/projects-t2/open`;

  try {
    const response = await axiosInstance.get(url, { params });
    const projects = response.data;
    return projects;
  } catch (e) {
    throw new Error(e.message);
  }
}
