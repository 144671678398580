import React from 'react';

const HuiCard = (props) => {
  const { className, boxShadow, border, hidden } = props;

  const huiBoxClass = `hui-card ${className || ``} ${
    !!boxShadow && 'hui-shadow-sm'
  } ${!!border && 'border'}`;

  return (
    <div
      className={huiBoxClass}
      hidden={hidden}
    >
      {props.children}
    </div>
  );
};

export default HuiCard;
