import { firebaseConfig as firebaseDevConfig } from './firebase.development';
import { firebaseConfig as firebaseProdConfig } from './firebase.production';

export const getFirebaseConfig = () => {
  const config = process.env.REACT_APP_ENV === 'production' ? firebaseProdConfig : firebaseDevConfig;
  if (process.env.REACT_APP_ENV !== 'local') {
    config.authDomain = window.location.hostname;
  }

  return config;
};
