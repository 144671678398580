import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

// Hui Icons XS
import { ReactComponent as HuiIconXsX } from './xs/x.svg';
import { ReactComponent as HuiIconXsNext } from './xs/next.svg';
import { ReactComponent as HuiIconXsPrev } from './xs/prev.svg';
import { ReactComponent as HuiIconXsToggle } from './xs/toggle.svg';
import { ReactComponent as HuiIconXsInfo } from './xs/info.svg';
import { ReactComponent as HuiIconXsInfoGray } from './xs/infoGray.svg';
import { ReactComponent as HuiIconXsCredit } from './xs/credit.svg';
import { ReactComponent as HuiIconXsCheck } from './xs/check.svg';
import { ReactComponent as HuiIconXsFilter } from './xs/filter.svg';
import { ReactComponent as HuiIconXsCalendar } from './xs/calendar.svg';
import { ReactComponent as HuiIconXsReload } from './xs/reload.svg';
import { ReactComponent as HuiIconXsTimer } from './xs/timer.svg';
import { ReactComponent as HuiIconXsCalendarDate } from './xs/calendarDate.svg';
import { ReactComponent as HuiIconXsAch } from './xs/ach.svg';
import { ReactComponent as HuiIconXsCard } from './xs/card.svg';
import { ReactComponent as HuiIconXsCardVisaColored } from './xs/cardVisaColored.svg';
import { ReactComponent as HuiIconXsCardMasterColored } from './xs/cardMasterColored.svg';
import { ReactComponent as HuiIconXsCardDinersColored } from './xs/cardDinersColored.svg';
import { ReactComponent as HuiIconXsCardJcbColored } from './xs/cardJcbColored.svg';
import { ReactComponent as HuiIconXsCardAmexColored } from './xs/cardAmexColored.svg';
import { ReactComponent as HuiIconXsCardDiscoverColored } from './xs/cardDiscoverColored.svg';

// Hui Icons SM
import { ReactComponent as HuiIconSmLock } from './sm/lock.svg';
import { ReactComponent as HuiIconSmDashboard } from './sm/home.svg';
import { ReactComponent as HuiIconSmPay } from './sm/pay.svg';
import { ReactComponent as HuiIconSmDollarSign } from './sm/dollarSign.svg';
import { ReactComponent as HuiIconSmWaiver } from './sm/waiver.svg';
import { ReactComponent as HuiIconSmWaiverFile } from './sm/waiverFile.svg';
import { ReactComponent as HuiIconSmReports } from './sm/reports.svg';
import { ReactComponent as HuiIconSmEmail } from './sm/email.svg';
import { ReactComponent as HuiIconSmDownload } from './sm/download.svg';
import { ReactComponent as HuiIconSmPrint } from './sm/print.svg';
import { ReactComponent as HuiIconSmError } from './sm/error.svg';
import { ReactComponent as HuiIconSmX } from './sm/x.svg';
import { ReactComponent as HuiIconSmPlus } from './sm/plus.svg';
import { ReactComponent as HuiIconSmMinus } from './sm/minus.svg';
import { ReactComponent as HuiIconSmInvoice } from './sm/invoice.svg';
import { ReactComponent as HuiIconSmCard } from './sm/card.svg';
import { ReactComponent as HuiIconSmBank } from './sm/bank.svg';
import { ReactComponent as HuiIconSmCalendar } from './sm/calendar.svg';
import { ReactComponent as HuiIconSmReload } from './sm/reload.svg';
import { ReactComponent as HuiIconSmEye } from './sm/eye.svg';
import { ReactComponent as HuiIconSmEyeOff } from './sm/eyeOff.svg';

// Hui Icons MD
import { ReactComponent as HuiIconMdWaiver } from './md/waiver.svg';
import { ReactComponent as HuiIconMdPayment } from './md/payment.svg';
import { ReactComponent as HuiIconMdHistory } from './md/history.svg';
import { ReactComponent as HuiIconMdBank } from './md/bank.svg';
import { ReactComponent as HuiIconMdCard } from './md/card.svg';
import { ReactComponent as HuiIconMdCredit } from './md/credit.svg';

// Hui Icons LG
import { ReactComponent as HuiIconLgMarker } from './lg/marker.svg';

const allIcons = {
  xs: {
    toggle: HuiIconXsToggle,
    info: HuiIconXsInfo,
    infoGray: HuiIconXsInfoGray,
    prev: HuiIconXsPrev,
    next: HuiIconXsNext,
    x: HuiIconXsX,
    credit: HuiIconXsCredit,
    check: HuiIconXsCheck,
    filter: HuiIconXsFilter,
    calendar: HuiIconXsCalendar,
    calendarDate: HuiIconXsCalendarDate,
    timer: HuiIconXsTimer,
    reload: HuiIconXsReload,
    ach: HuiIconXsAch,
    card: HuiIconXsCard,
    cardVisaColored: HuiIconXsCardVisaColored,
    cardMasterColored: HuiIconXsCardMasterColored,
    cardDinersColored: HuiIconXsCardDinersColored,
    cardJcbColored: HuiIconXsCardJcbColored,
    cardAmexColored: HuiIconXsCardAmexColored,
    cardDiscoverColored: HuiIconXsCardDiscoverColored,
  },
  sm: {
    lock: HuiIconSmLock,
    dashboard: HuiIconSmDashboard,
    eye: HuiIconSmEye,
    eyeOff: HuiIconSmEyeOff,
    pay: HuiIconSmPay,
    dollarSign: HuiIconSmDollarSign,
    waiver: HuiIconSmWaiver,
    waiverFile: HuiIconSmWaiverFile,
    invoice: HuiIconSmInvoice,
    card: HuiIconSmCard,
    bank: HuiIconSmBank,
    reports: HuiIconSmReports,
    email: HuiIconSmEmail,
    download: HuiIconSmDownload,
    print: HuiIconSmPrint,
    error: HuiIconSmError,
    x: HuiIconSmX,
    plus: HuiIconSmPlus,
    minus: HuiIconSmMinus,
    reload: HuiIconSmReload,
    calendar: HuiIconSmCalendar,
  },
  md: {
    waiver: HuiIconMdWaiver,
    pay: HuiIconMdPayment,
    history: HuiIconMdHistory,
    bank: HuiIconMdBank,
    card: HuiIconMdCard,
    credit: HuiIconMdCredit,
  },
  lg: {
    marker: HuiIconLgMarker,
  },
};

const propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

const HuiIcon = (props) => {
  const { name, size, ariaLabel, color, ...svgProps } = props;

  const iconsSizes = allIcons[size] || Fragment;
  const iconName = iconsSizes[name];
  const Icon = iconName || Fragment;

  const iconColor = css`
    stroke: ${color};

    path {
      stroke: ${color} !important;
    }
  `;
  const hasIcon = iconName ? (
    <Icon
      css={color && iconColor}
      {...svgProps}
      aria-label={ariaLabel || name}
    />
  ) : (
    <></>
  );

  return hasIcon;
};

HuiIcon.propTypes = propTypes;

export default HuiIcon;
