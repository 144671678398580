import * as React from 'react';
import moment from 'moment/moment';
import { calculateRecurrenceInstallments } from '../../utils/utilsCalculateRecurrenceInstallments';
import { first, last, size } from 'lodash';

const RecurrenceInfo = (props) => {
  const { recurrence } = props;

  const { id, startDate, endDate } = recurrence || '';

  const installments = calculateRecurrenceInstallments(startDate, endDate);

  const firstPayment = first(installments);
  const lastPayment = last(installments);

  const singleInstallment = size(installments) === 1;

  const recurrenceEnded = moment().isAfter(lastPayment);

  return (
    <>
      <div className="d-flex p-3 border-top">
        <div className="flex-grow-1 hui-text-lg fw-bold mb-2">Recurrence</div>
      </div>

      <div className="d-flex px-3">
        <div className="flex-grow-1 hui-text fw-bold mb-2">Frequency</div>
        <div>Monthly</div>
      </div>

      <div className="d-flex px-3">
        <div className="flex-grow-1 hui-text fw-bold mb-2">Duration</div>
        <div>
          {size(installments)} {singleInstallment ? 'month' : 'months'}
        </div>
      </div>

      <div className="d-flex px-3" hidden={singleInstallment}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">First Payment Date</div>
        <div>{moment(firstPayment).format('MMM D, YYYY')}</div>
      </div>

      <div className="d-flex px-3" hidden={singleInstallment}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Last Payment Date</div>
        <div>{moment(lastPayment).format('MMM D, YYYY')}</div>
      </div>

      <div className="d-flex px-3" hidden={singleInstallment || recurrenceEnded}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Next payment</div>
        <div>{size(installments) && moment(installments[1]).format('MMM D, YYYY')}</div>
      </div>
    </>
  );
};

export default RecurrenceInfo;
