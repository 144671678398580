const UtilPaymentMethods = {
  ACH: 'ACH',
  CREDIT: 'CREDIT',
  CREDIT_CARD: 'CREDIT_CARD',
  isACH: (method) => {
    return UtilPaymentMethods.ACH === method;
  },
  isCredit: (method) => {
    return UtilPaymentMethods.CREDIT === method;
  },
  isCreditCard: (method) => {
    return UtilPaymentMethods.CREDIT_CARD === method;
  },
};

export default UtilPaymentMethods;