import { memoize } from 'lodash';
import { axiosInstance } from '../../utils/utilsAxiosMain';

export const loginHistory = memoize(createLoginHistory, (params) => {
  const date = new Date();

  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}-${params.portalId}-${params.userId}-${
    params.action
  }`;;
});

export async function createLoginHistory(params) {
  const url = `/api/user/login-history/t2`;

  try {
    await axiosInstance.post(url, params);
    return { isLogged: true };
  } catch (e) {
    const { message } = e || {};
    console.log(`Error login history: ${message}`);
  }
}

export function getProviderId(firebaseUser) {
  try {
    const { providerId: authDataProviderId, operationType, user } = firebaseUser || {};
    const { providerId: userProviderId } = user || {};
    const providerId = authDataProviderId || operationType || userProviderId || 'firebase';
    return providerId;
  } catch (error) {
    console.log(error);
  }
  return null;
}
