import React from 'react';

const HuiTabsItem = (props) => {
  const { className, hidden } = props;

  const huiTabsItemClass = `hui-tabs-item ${className || ``}`;

  return (
    <div
      className={huiTabsItemClass}
      hidden={hidden}
    >
      {props.children}
    </div>
  );
};

export default HuiTabsItem;
