import React from 'react';
import HuiModalHeader from './HuiModalHeader';
import HuiModalActions from './HuiModalActions';
import HuiModalContent from './HuiModalContent';

const HuiModal = (props) => {
  const { modal, hideClose, type } = props;

  const { size } = modal || {};

  const getModalSize = {
    sm: 'hui-modal-sm',
    md: 'hui-modal-md',
    lg: 'hui-modal-lg',
    full: 'hui-modal-full',
  };

  const modalSize = getModalSize[size];
  const modalContentClass = `hui-modal-content ${modalSize}`;

  return (
    <div className={modalContentClass}>
      {!!type && <HuiModalHeader {...props} />}

      <HuiModalContent {...props} />
      
      {!!type && <HuiModalActions {...props} />}
    </div>
  );
};

export default HuiModal;
