import React from 'react';
import Header from '../components/layout/header/Header';
import Modal from '../components/layout/modal/Modal';
import Nav from '../components/layout/nav/Nav';
import Sidebar from '../components/layout/sidebar/Sidebar';

const LayoutSlim = (props) => {
  return (
    <>
      <Modal />
      <Sidebar />
      {props.children}
    </>
  );
};

export default LayoutSlim;
