import React from 'react';
import currencyFormatter from 'currency-formatter';

const HuiPanelPaymentAmount = (props) => {
  const { amount = 0 } = props;

  const [amountText, centsText] = getCentSplitted(currencyFormatter.format(amount, { code: 'USD' }));

  return (
    <>
      <div className="hui-text hui-color-black-light me-3 text-nowrap">You have to pay:</div>
      <div className="text-nowrap mb-3">
        <span className="hui-title-lg fw-normal hui-color-black ms-1">{amountText}</span>
        <span className="hui-subtitle hui-color-black-light">{centsText}</span>
      </div>
    </>
  );

  function getCentSplitted(string) {
    const centSeparator = string.charAt(string.length - 3);
    const hasCentSeparator = centSeparator === ',' || centSeparator === '.';
    return hasCentSeparator
      ? [string.substring(0, string.length - 3), string.substring(string.length - 3)]
      : [string, ''];
  }
};

export default HuiPanelPaymentAmount;
