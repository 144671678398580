import { createSlice } from '@reduxjs/toolkit';

const initialStateModal = {
  title: '',
  message: '',
  icon: '',
  keepOpen: false,
  draggable: false,
  isOpen: false,
  type: 'alerts',
  size: 'sm',
  hideActions: false,
  confirmLabel: 'Confirm',
  confirmAction: null,
  showConfirmButton: false,
  cancelLabel: 'Close',
  cancelAction: null,
  showCancelButton: false,
  hideClose: false,
  sendEmailType: '',
  sendEmailId: '',
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    value: initialStateModal,
  },
  reducers: {
    openModal: (state, action) => {
      state.value = {
        ...initialStateModal,
        isOpen: true,
        ...action.payload,
      };
    },
    closeModal: (state, action) => {
      state.value = initialStateModal;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectModal = (state) => state.modal.value;

export default modalSlice.reducer;
