import { HuiIcon } from 'handle-ui';
import * as React from 'react';

export const getUtilsIconsByStatus = {
  EMAIL_SENT: <HuiIcon name="email" size="sm" />,
  PRINTED: <HuiIcon name="print" size="sm" />,
  DOWNLOADED: <HuiIcon name="download" size="sm" />,
  SHORT_PAY: <HuiIcon name="pay" size="sm" />,
  OVER_PAY: <HuiIcon name="pay" size="sm" />,
  COMPLETE_PAY: <HuiIcon name="pay" size="sm" />,
  REFUNDED: <HuiIcon name="pay" size="sm" />,
  CANCELED: <HuiIcon name="error" size="sm" />,
  VIEWED: <HuiIcon name="eye" size="sm" />,
};

export const getUtilsIconsByPaymentStatus = {
  STARTED: <HuiIcon name="pay" size="sm" />,
  PENDING: <HuiIcon name="pay" size="sm" />,
  APPROVED: <HuiIcon name="pay" size="sm" />,
  CANCELED: <HuiIcon name="error" size="sm" />,
  REFUNDED: <HuiIcon name="error" size="sm" />,
  CHARGEBACK: <HuiIcon name="error" size="sm" />,
  VOIDED: <HuiIcon name="error" size="sm" />,
  SCHEDULED: <HuiIcon name="pay" size="sm" />,
};
