import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import React from 'react';
import HuiRadioGroup from './HuiRadioGroup';
import * as PropTypes from 'prop-types';


const propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  row: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  label: PropTypes.string,
};

const HuiRadio = (props) => {
  const { id, label, className, groupClassName, children, row, onChange, required = false } = props;
  return (
    <FormControl className={className}>
      {!!label && (
        <FormLabel>{label}</FormLabel>
      )}
      <HuiRadioGroup
        className={groupClassName}
        id={id}
        onChange={onChange}
        children={children}
        required={required}
        row={row}
      />
    </FormControl>
  );
};

HuiRadio.propTypes = propTypes;

export default HuiRadio;
