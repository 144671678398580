import { axiosInstance } from '../../utils/utilsAxios';

export async function getRecurrence(id) {
  const url = `v1/recurrences/${id}`;

  try {
    const response = await axiosInstance.get(url);
    const recurrence = response.data;
    return recurrence;
  } catch (e) {
    throw new Error(e.message);
  }
}
