import React from 'react';
import { useLocation } from 'react-router-dom';
import PortalLogo from '../../../components/portal/PortalLogo';
import { HuiAlert, HuiPanel } from 'handle-ui';
import HuiPanelPayment from '../../../components/checkout/HuiPanelPayment';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const defaultError = {
  message: 'The payment link does not exist',
  error: 'Bad Request',
  statusCode: 404,
};

const InvalidRequest = () => {
  const location = useLocation();
  const portalInfo = useSelector(selectPortalInfo);

  const { name } = portalInfo || {};
  const state = location?.state || {};

  const { fields } = state || {};
  const { hasError } = fields || {};

  const { statusCode, message, paymentRequestToken } = hasError ? fields : defaultError;

  const isPendingOrComplete = statusCode === 401 ? 'yellow' : '';
  const isInvalidOrExpired = statusCode === 400 ? 'gray' : '';
  const isNotFound = statusCode === 404 ? 'red' : '';

  const color = isPendingOrComplete || isInvalidOrExpired || isNotFound;

  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-12 text-center pb-5">
          <PortalLogo className="hui-payment-logo" />
        </div>
        <div className="col-lg-10 offset-lg-1 mb-4">
          <HuiPanel className="overflow-hidden position-relative d-flex flex-column mb-4">
            <HuiPanelPayment color={color} className="hui-bg-gray-light h-100 d-flex flex-grow-1">
              <div className="text-center p-5">
                <div className="d-flex justify-content-center mb-4">
                  <img src={`/images/svg/checkout-error-${statusCode}.svg`} alt={message} width={100} />
                </div>
                <h1 className={`text-center hui-title mb-2 hui-color-${color}`}>{message}</h1>
                {statusCode === 400 && <div className="hui-subtitle mb-2" hidden={!paymentRequestToken}>Token: {paymentRequestToken}</div>}
                <div className="hui-text hui-color-black-light">Please contact the {name} support. </div>
              </div>
            </HuiPanelPayment>
          </HuiPanel>

          <div className="d-flex justify-content-center">
            <HuiAlert className="mb-4" title="Error code:" radius="xl" icon="info" inline background gray>
              {statusCode}
            </HuiAlert>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvalidRequest;
