import RGridTable from '@nadavshaar/react-grid-table';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const HuiTable = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableManager, setTableManager] = useState();
  const [columns, setColumns] = useState(props.columns || []);

  useEffect(() => {
    setColumns(props.columns || []);
  }, [props.columns]);

  const onSortChangeDefault = (sort, tableManager) => {
    setSearchParams((searchParams) => {
      const column = tableManager.columnsApi.columns[sort.colId];
      if (sort?.colId !== null && column) {
        searchParams.set('sort', column.field);
        searchParams.set('direction', sort.isAsc ? 'asc' : 'desc');
      } else {
        searchParams.delete('sort');
        searchParams.delete('direction');
      }
      return searchParams;
    });
  };

  const onSearchTextChangeDefault = (searchText) => {
    setSearchParams((searchParams) => {
      isEmpty(searchText) ? searchParams.delete('search') : searchParams.set('search', searchText);
      searchParams.set('page', 1);
      return searchParams;
    });
  };

  const onRowsRequestDefault = (requestData, tableManager) => {};

  const {
    rows,
    onRowClick,
    className,
    components,
    isPaginated,
    totalRows,
    pageSize,
    sort,
    additionalProps,
    showColumnVisibilityManager = false,
    isLoading = false,
    contentName = 'Rows',
    onRowsRequest = onRowsRequestDefault,
    onRowsReset,
    onLoad,
    onSortChange = onSortChangeDefault,
    onSearchTextChange = onSearchTextChangeDefault,
  } = props;

  const onLoadDefault = (tableManager) => {
    setTableManager(tableManager);
    if (onLoad) {
      onLoad(tableManager);
    }
  };

  useEffect(() => {
    function loadParamsFromUrl() {
      if (searchParams.get('sort') && !tableManager?.sortApi?.sort.colId) {
        const colId = columns.find((column) => column.field === searchParams.get('sort'))?.id;
        if (colId) {
          tableManager?.sortApi.setSort({
            colId,
            isAsc: searchParams.get('direction') === 'asc' ? true : false,
          });
        }
      }

      if (searchParams.get('search') && !tableManager?.searchApi.searchText) {
        tableManager?.searchApi.setSearchText(searchParams.get('search'));
      }

      const page = (searchParams.get('page') || 1) * 1;
      tableManager?.paginationApi.setPage(page);
      if (searchParams.get('size')) {
        tableManager?.paginationApi.setPageSize(searchParams.get('size') * 1);
      }
    }
    if (tableManager) {
      loadParamsFromUrl();
    }
  }, [searchParams, tableManager]);

  const texts = {
    search: `Search ${contentName}:`,
    totalRows: `Total ${contentName}:`,
    rows: `${contentName}:`,
    selected: `Selected ${contentName}`,
    rowsPerPage: `${contentName} per page:`,
    page: 'Page:',
    of: 'of',
    prev: 'Prev',
    next: 'Next',
    columnVisibility: 'Column visibility',
  };

  const handleOnColumnsChange = (columns) => {
    const { onColumnsChange } = props;
    onColumnsChange?.(columns);
    setColumns(columns);
  };

  return (
    <>
      <RGridTable
        isLoading={isLoading}
        additionalProps={additionalProps}
        texts={texts}
        sort={sort}
        highlightSearch={false} //If you enable this, you should check all custom columns and update {value} to {textValue}
        className={className}
        showSearch={false}
        enableColumnsReorder={false}
        showRowsInformation={false}
        isPaginated={isPaginated}
        pageSizes={[20, 50, 100]}
        isVirtualScroll={true}
        showColumnVisibilityManager={showColumnVisibilityManager}
        columns={columns}
        pageSize={pageSize}
        components={components}
        rows={isLoading ? [] : rows}
        totalRows={totalRows}
        onRowClick={onRowClick}
        onRowsRequest={onRowsRequest}
        onRowsReset={onRowsReset}
        onLoad={onLoadDefault}
        onSortChange={onSortChange}
        onSearchTextChange={onSearchTextChange}
        onColumnsChange={handleOnColumnsChange}
      />
    </>
  );
};

export default HuiTable;
