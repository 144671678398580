import React from 'react';
import { SvgIcon } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { first, get, isEmpty } from 'lodash';
import { HuiIcon } from '../../index';

function CustomSvgIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M15.7075 9.20747C15.9841 8.93084 16.4326 8.93084 16.7092 9.20747C16.9858 9.48409 16.9858 9.93258 16.7092 10.2092L12.4592 14.4592C12.1826 14.7358 11.7341 14.7358 11.4575 14.4592L7.20747 10.2092C6.93084 9.93258 6.93084 9.48409 7.20747 9.20747C7.48408 8.93084 7.93258 8.93084 8.2092 9.20747L11.9583 12.9566L15.7075 9.20747Z"
        fill="#9e9e9e"
      />
    </SvgIcon>
  );
}

class HuiDate extends React.Component {

  state = {
    isOpen: false,
  };

  render() {
    const { isOpen } = this.state;

    const {
      label,
      id,
      name,
      onChange,
      size,
      hidden,
      disabled,
      error,
      topLabel,
      className,
      value,
      children,
      onClickDisabled,
      onClear,
      open,
      onOpen,
      onClose,
      required,
      hideRequired,
      ranges,
      hasDate,
      setHasDate,
      handleOnClick,
      hideActions,
      onReset,
      hideConfirm,
    } = this.props;

    const huiDateClass = `${className || 'mb-3'}`;

    const getRanges = first(ranges);
    const hasValue = get(getRanges, 'value');
    const showRequired = hideRequired ? false : required;

    return (
      <FormControl
        error={error}
        size={size}
        className={huiDateClass}
        required={showRequired}
      >
        {!!topLabel && <FormLabel htmlFor={id}>{label}</FormLabel>}

        <Select
          IconComponent={CustomSvgIcon}
          size={size}
          id={id}
          name={name}
          value={value || ''}
          disabled={!!disabled}
          open={isOpen}
          onClick={() => this.handleOnOpen(true)}
          onClose={() => this.handleOnOpen(false)}
          hidden={hidden}
          onChange={onChange}
          required={required}
          displayEmpty
          MenuProps={{
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
          }}
        >
          {isEmpty(value) ? (
            <MenuItem
              value=""
              className="d-none"
            >
            <span className="d-flex align-items-center hui-color-black-light">
              <HuiIcon
                name="calendar"
                size="xs"
                className="me-2"
              />
              {label}
            </span>
            </MenuItem>
          ) : (
            <MenuItem
              value={value}
              className="d-none"
            >
            <span className="d-flex align-items-center hui-color-black-light">
              <HuiIcon
                name="calendar"
                size="xs"
                className="me-2"
              />
              {value}
            </span>
            </MenuItem>
          )}

          <div className="w-100">{children}</div>

          <div className="px-3 pb-2 text-end d-flex" hidden={hideActions}>
            <div className="ms-auto">
              <button
                className="hui-btn hui-btn-outline"
                onClick={onClear}
              >
                Clear Selection
              </button>
            </div>
            <div className="ms-2" hidden={hideConfirm}>
              <button
                disabled={!onClickDisabled}
                className="hui-btn hui-btn-green"
                onClick={this.handleClick}
              >
                Select Date
              </button>
            </div>
          </div>
        </Select>
      </FormControl>
    );

  }

  handleClick = () => {
    this.handleOnOpen(false);
    this.props.handleOnClick();
  };

  handleOnOpen = (isOpen) => {
    const { onOpen } = this.props;
    onOpen?.(this.state.isOpen);
    this.setState({ isOpen });
  };

  componentDidUpdate() {
    const { isOpen, hideActions } = this.props;
    if (!isOpen && this.state.isOpen && hideActions) {
      this.setState({ isOpen: false });
    }
  }
};

export default HuiDate;
