import * as React from 'react';
import SidebarRecurrencePayments from '../layout/sidebar/SidebarRecurrencePayments';
import { HuiCard, HuiCardContent } from 'handle-ui';
import { size } from 'lodash';

const RecurrencePayments = (props) => {
  const { recurrence, hideButton, alert } = props;

  const hasRecurrence = !!size(recurrence.payments);

  return (
    <>
      {hasRecurrence && (
        <>
          <HuiCard className="recurrences-list p-0" boxShadow border>
            <HuiCardContent>
              <SidebarRecurrencePayments type="processed" recurrence={recurrence} hideButton={hideButton} />

              {!!alert && (
                <div className="my-4 hui-bg-gray-light p-3 rounded-3 d-flex align-items-center">
                  <div className="d-flex me-2">
                    <i className="hui-i hui-svg-toastr-warning"></i>
                  </div>
                  We'll only cancel payments that are scheduled for the future.
                </div>
              )}

              <SidebarRecurrencePayments
                type="future"
                recurrence={recurrence}
                hideButton={hideButton}
                hideTitle={alert}
              />
            </HuiCardContent>
          </HuiCard>
        </>
      )}
    </>
  );
};

export default RecurrencePayments;
