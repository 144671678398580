import { HuiTabs, HuiTabsContent, HuiTabsItem } from 'handle-ui';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import HistoryPayments from './HistoryPayments';
import HistoryRecurrences from './HistoryRecurrences';

const HistoryPaymentsPage = () => {
  const location = useLocation();
  const portalInfo = useSelector(selectPortalInfo);
  const [tab, setTab] = useState('');

  const { isPaymentEnabled = false, isRecurrencePaymentEnabled = false } = portalInfo?.settings || {};

  useEffect(() => {
    const activeTab = location.pathname.replace('/history/', '');
    setTab(activeTab);
  }, [setTab, location]);

  return (
    <div className="p-4 hui-dashboard flex-grow-1 overflow-hidden">
      <h1 className="hui-title mb-2">Payment Activity</h1>

      <HuiTabs>
        <HuiTabsItem className={tab === 'payments' ? 'active' : ''} link="/history/payments" hidden={!isPaymentEnabled}>
          <Link to="/history/payments">Payments</Link>
        </HuiTabsItem>

        <HuiTabsItem
          className={tab === 'recurrences' ? 'active' : ''}
          link="/history/recurrences"
          hidden={!isPaymentEnabled || !isRecurrencePaymentEnabled}
        >
          <Link to="/history/recurrences">Upcoming Payments</Link>
        </HuiTabsItem>
      </HuiTabs>

      <HuiTabsContent>
        {tab === 'payments' && <HistoryPayments />}

        {tab === 'recurrences' && <HistoryRecurrences />}
      </HuiTabsContent>
    </div>
  );
};

export default HistoryPaymentsPage;
