import { axiosInstance } from '../../utils/utilsAxios';

export async function getPayment(id) {
  const url = `/v1/payments/${id}`;

  try {
    const response = await axiosInstance.get(url);
    const payment = response.data;
    return payment;
  } catch (e) {
    throw new Error(e.message);
  }
}


