import React from 'react';

const ModalAlerts = (props) => {
  const { modal } = props;

  const { message } = modal;

  return (
    <>
      <div>{message}</div>
    </>
  );
};

export default ModalAlerts;
