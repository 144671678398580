import { HuiPanel, HuiPanelContent, HuiPanelHeader, HuiRadio, HuiRadioOption } from 'handle-ui';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentMethod } from '../../../redux/InvoicesPaymentSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';
import HuiPaymentAch from '../../private/payment/payment-form/HuiPaymentAch';
import HuiPaymentCard from '../../private/payment/payment-form/HuiPaymentCard';

const OneTimePaymentCheckout = (props) => {
  const { token } = props;
  const dispatch = useDispatch();
  const portalInfo = useSelector(selectPortalInfo);
  const [fields, setFields] = useState({});

  const { isAchEnabled, isCreditCardEnabled } = portalInfo?.settings || {};

  useEffect(() => {
    function getInitialPaymentMethod() {
      const { isAchEnabled, isCreditCardEnabled } = portalInfo?.settings || {};

      if (isCreditCardEnabled) {
        return UtilPaymentMethods.CREDIT_CARD;
      }

      if (isAchEnabled) {
        return UtilPaymentMethods.ACH;
      }
    }

    if (!fields.paymentMethod && portalInfo?.settings) {
      const initialPaymentMethod = getInitialPaymentMethod();

      setFields({
        ...fields,
        paymentMethod: getInitialPaymentMethod(),
      });
      dispatch(updatePaymentMethod(initialPaymentMethod));
    }
  }, [fields, portalInfo?.settings, dispatch]);

  const paymentMethod = {
    CREDIT_CARD: (
      <HuiPaymentCard token={token} cardOnFileSearchDisabled zipOnly small={true} hidden={!isCreditCardEnabled} />
    ),
    ACH: <HuiPaymentAch token={token} cardOnFileSearchDisabled small={true} hidden={!isAchEnabled} />,
  };

  const getPaymentMethod = paymentMethod[fields.paymentMethod];

  const isCreditCard = UtilPaymentMethods.isCreditCard(fields.paymentMethod);
  const isACH = UtilPaymentMethods.isACH(fields.paymentMethod);

  return (
    <HuiPanel boxShadow>
      <HuiPanelHeader title="Payment Method" borderBottom />
      <HuiPanelContent className="p-4">
        <div className="mb-3 hui-color-black-light">Complete your payment by choosing a payment method.</div>

        <HuiRadio
          className="hui-payment-method d-flex"
          groupClassName="hui-payment-method-group d-flex align-items-center flex-grow-1"
          id="paymentMethod"
          name="paymentMethod"
          onChange={handleChange}
          row
          required
        >
          <HuiRadioOption
            className={`hui-payment-method-option flex-fill ${isCreditCard ? 'active' : ''}`}
            labelClassName="hui-text"
            label="Credit or Debit Card"
            value={UtilPaymentMethods.CREDIT_CARD}
            checked={isCreditCard}
            hidden={!isCreditCardEnabled}
          />
          <HuiRadioOption
            className={`hui-payment-method-option flex-fill ${isACH ? 'active' : ''}`}
            labelClassName="hui-text"
            label="ACH bank transfer"
            value={UtilPaymentMethods.ACH}
            checked={isACH}
            hidden={!isAchEnabled}
          />
        </HuiRadio>
        {getPaymentMethod}
      </HuiPanelContent>
    </HuiPanel>
  );

  function handleChange(e) {
    const { onChange } = props;
    setFields({ ...fields, [e.target.name]: e.target.value });
    onChange?.(e);
    dispatch(updatePaymentMethod(e.target.value));
  }
};

export default OneTimePaymentCheckout;
