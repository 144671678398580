import { Grid } from '@mui/material';
import { HuiAutocomplete, HuiCheckbox, HuiInput } from 'handle-ui';
import { isEmpty, size } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { signUp } from '../../../actions/auth/signUp';
import { signUpHandle } from '../../../actions/auth/signUpHandle';
import { TRY_SIGNUP, selectAuth, updateAuth } from '../../../redux/AuthSlice';
import { utilsGoogleAutocomplete } from '../../../utils/utilsGoogleAutocomplete';

function loadAuthInfo(auth) {
  const defaultEmail = auth?.email || '';
  let defaultFirstName = '';
  let defaultLastName = '';
  const nameSplit = auth?.displayName?.split(' ');
  if (size(nameSplit) > 0) {
    defaultFirstName = nameSplit[0];
    defaultLastName = size(nameSplit) > 1 ? nameSplit.slice(-1) : '';
  }

  return { defaultEmail, defaultFirstName, defaultLastName };
}

const SignUpForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);

  const { settings: { isPortalSignupCompanyNameRequired = true } = {} } = props || {};

  const [predictions, setPredictions] = useState([]);

  const { defaultFirstName, defaultLastName, defaultEmail } = loadAuthInfo(auth);

  const [form, setForm] = useState({
    firstName: defaultFirstName,
    lastName: defaultLastName,
    email: defaultEmail,
    password: '',
    companyName: '',
    companyAddress: '',
    companyPhone: '',
    companyCode: '',
    agreeTerms: false,
  });

  const agreementLabel = (
    <>
      Creating an account means you’re okay with our{' '}
      <a href="https://www.handle.com/terms/" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{' '}
      and you acknowledge our{' '}
      <a href="https://www.handle.com/privacy/" target="_blank" rel="noopener noreferrer">
        Privacy Statement
      </a>
      .
    </>
  );

  const companyNameLabel = isPortalSignupCompanyNameRequired ? 'Company Name' : 'Company Name (Optional)';

  return (
    <form className="d-flex flex-column" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-6">
          <HuiInput
            label="First name"
            id="firstName"
            name="firstName"
            type="text"
            value={form.firstName}
            onChange={handleChange}
            size="large"
            topLabel
            required
          />
        </div>

        <div className="col-6">
          <HuiInput
            label="Last name"
            id="lastName"
            name="lastName"
            type="text"
            value={form.lastName}
            onChange={handleChange}
            size="large"
            topLabel
            required
          />
        </div>

        <div className="col-12">
          <HuiInput
            label="Email"
            id="email"
            name="email"
            type="email"
            value={form.email}
            onChange={handleChange}
            size="large"
            disabled={!isEmpty(auth?.email)}
            required
            topLabel
          />
        </div>

        <div className="col-12" hidden={auth?.email}>
          <HuiInput
            label="Password"
            id="password"
            name="password"
            type="password"
            value={form.password}
            onChange={handleChange}
            size="large"
            required={!auth?.email}
            topLabel
          />
        </div>

        <div className="col-12">
          <HuiInput
            label={companyNameLabel}
            id="companyName"
            name="companyName"
            type="text"
            value={form.companyName}
            onChange={handleChange}
            size="large"
            required={isPortalSignupCompanyNameRequired}
            topLabel
          />
        </div>

        <div className="col-12">
          <HuiAutocomplete
            label="Address"
            id="companyAddress"
            name="companyAddress"
            onInputChange={onChangeAddress}
            options={form.companyAddress ? predictions : []}
            getOptionLabel={size(predictions) ? getOptionLabelAddress : undefined}
            renderOption={renderOptionAddress}
            filterOptions={filterOptionsAddress}
            placeholder=""
            size="large"
            topLabel
            freeSolo
            required
          />
        </div>

        <div className="col-6">
          <HuiInput
            label="Phone"
            id="companyPhone"
            name="companyPhone"
            type="text"
            value={form.companyPhone}
            onChange={handleChange}
            size="large"
            topLabel
          />
        </div>

        <div className="col-6">
          <HuiInput
            label="Customer # (Company Code)"
            id="companyCode"
            name="companyCode"
            type="text"
            value={form.companyCode}
            onChange={handleChange}
            size="large"
            topLabel
          />
        </div>

        <div>
          <HuiCheckbox
            id="agreement"
            onChange={handleCheckboxChange}
            required
            label={agreementLabel}
            size="small"
            showLabel
          />
        </div>

        <div className="col-12 d-flex flex-column mt-3">
          <button type="submit" className="hui-btn hui-btn-xl hui-btn-green" disabled={!form.agreeTerms}>
            Create Account
          </button>
        </div>
      </div>
    </form>
  );

  function renderOptionAddress(props, option) {
    const { description, place_id } = option || {};

    return (
      <li {...props} key={place_id}>
        <Grid container alignItems="center">
          <Grid item xs>
            {description}
          </Grid>
        </Grid>
      </li>
    );
  }

  async function onChangeAddress(event, value) {
    setForm({ ...form, companyAddress: value });
    if (size(value) >= 1) {
      const results = await utilsGoogleAutocomplete(value);
      if (results) {
        setPredictions(results);
      }
    }
  }

  function filterOptionsAddress(x) {
    return x;
  }

  function getOptionLabelAddress(option) {
    const { description } = option || {};
    return description || '';
  }

  function handleCheckboxChange() {
    setForm((prevState) => ({
      ...prevState,
      agreeTerms: !prevState.agreeTerms,
    }));
  }

  function handleChange(e) {
    const { name, value: rawValue } = e.target;
    const value = name === 'email' ? rawValue.toLowerCase() : rawValue;

    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { email, password, firstName, lastName, companyName, companyAddress, companyPhone, companyCode } = form || '';

    if (email && (auth?.email || password) && firstName && lastName && companyAddress) {
      const name = `${firstName} ${lastName}`;

      const submittedForm = {
        email,
        name,
        companyName,
        companyAddress,
        companyPhone,
        companyCode,
      };

      try {
        //Prevent login call after signup
        sessionStorage.setItem(TRY_SIGNUP, true);

        const user = auth?.email ? auth : await signUp(form);
        localStorage.setItem('signInWithLinkEmail', user.email);

        if (size(user)) {
          const result = await signUpHandle(submittedForm);
          if (result) {
            await dispatch(updateAuth(user));

            if (result.isLogged === true) {
              return navigate(`/dashboard`);
            } else if (result.isEmailVerified === false) {
              navigate(`/confirm-email`);
            } else if (result.isApproved === false) {
              navigate(`/pending-approval`);
            }
          }
        }
      } catch (error) {
        toastr.error(error);
      }
      sessionStorage.setItem(TRY_SIGNUP, false);
    }
  }
};

export default SignUpForm;
