import { axiosInstance } from '../../utils/utilsAxios';

export async function cancelPayment(paymentCode) {
  const url = `/v1/payments/${paymentCode}`;

  try {
    const response = await axiosInstance.delete(url);
    const payment = response.data;
    return payment;
  } catch (e) {
    throw new Error(e.response?.data?.message || e.message);
  }
}
