import { first } from 'lodash';
import React from 'react';
import { DefinedRange } from 'react-date-range';
import HuiDate from './HuiDate';

const HuiDateRange = (props) => {
  const {
    label,
    id,
    name,
    onChange,
    onClick,
    size,
    hidden,
    disabled,
    error,
    topLabel,
    className,
    ranges,
    onClear,
    staticRanges,
  } = props;

  const { startDate, endDate } = first(ranges);

  const selectedRange = first(
    staticRanges.filter((staticRange) => {
      return (
        staticRange.range().startDate.getTime() === startDate.getTime() &&
        staticRange.range().endDate.getTime() === endDate.getTime()
      );
    }),
  );
  const dateValue = selectedRange ? selectedRange.label : '';

  return (
    <HuiDate
      error={error}
      topLabel={topLabel}
      className={className}
      size={size}
      id={id}
      name={name}
      value={dateValue}
      label={label}
      disabled={!!disabled}
      hidden={hidden}
      onChange={onChange}
      onClear={onClear}
      onClick={onClick}
      ranges={ranges}
      hideActions
    >
      <DefinedRange
        className="hui-date-range"
        onChange={onChange}
        ranges={ranges}
        inputRanges={[]}
        staticRanges={staticRanges}
      />
    </HuiDate>
  );
};

export default HuiDateRange;
