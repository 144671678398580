import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import { firebase } from '../../../actions/auth/firebase';
import { getProviderId, loginHistory } from '../../../actions/auth/loginHistory';
import { signOut } from '../../../actions/auth/signOut';
import { logoutAuth, selectAuth } from '../../../redux/AuthSlice';
import { resetInvoices } from '../../../redux/InvoicesSlice';
import { resetPaymentData } from '../../../redux/PaymentDataSlice';
import { updatePaymentReasons } from '../../../redux/PaymentReasonsSlice';
import { resetPayment } from '../../../redux/PaymentSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { updatePortals } from '../../../redux/PortalsSlice';
import { selectUser, updateUser } from '../../../redux/UserSlice';
import { resetCredits } from '../../../redux/CreditsSlice';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const user = useSelector(selectUser);
  const portalInfo = useSelector(selectPortalInfo);
  const query = useQuery();
  const redirectTo = query.get('redirect') || 'login';
  const { portalLogoutRedirectUrl } = portalInfo?.settings;

  useEffect(() => {
    async function userSignOut() {
      try {
        await loginHistory({
          userId: user.id,
          portalId: portalInfo?.id,
          providerId: getProviderId(auth),
          action: 'logout',
        });
        await dispatch(logoutAuth());
        await signOut();
        await dispatch(updateUser({}));
        await dispatch(resetInvoices({}));
        await dispatch(resetCredits({}))
        await dispatch(resetPaymentData({}));
        await dispatch(updatePaymentReasons([]));
        await dispatch(updatePortals([]));
        await dispatch(resetPayment({}));
        await firebase.auth().signOut();
        localStorage.removeItem('user');
        const hasCustomRedirect = redirectTo !== 'login';
        if (!hasCustomRedirect && !isEmpty(portalLogoutRedirectUrl)) {
          window.location.replace(portalLogoutRedirectUrl);
          return;
        } else {
          return navigate(`/${redirectTo}`);
        }
      } catch (error) {
        toastr.error(error);
      }
    }

    userSignOut();
  }, [dispatch, navigate, auth, portalInfo, user, portalLogoutRedirectUrl, redirectTo]);

  return <></>;
};

export default Logout;
