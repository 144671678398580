import * as React from 'react';
import { useState } from 'react';
import toastr from 'toastr';
import { cancelPayment } from '../../actions/payment/cancelPayment';
import Modal from '../layout/modal/Modal';

const CancelPayment = (props) => {
  const { className, payment, onComplete } = props;
  const { code, status } = payment;

  const [isOpen, setIsOpen] = useState(false);

  const isCanceled = status === 'CANCELED';

  const hide = false;

  return (
    !hide && (
      <>
        {!!isOpen && (
          <Modal isOpen={isOpen} hideClose keepOpen title="Cancel Payment" size="md">
            <h3 className="my-3 flex-grow-1 fw-bold hui-text-lg">Confirm Cancel Payment</h3>
            <div>Are you sure you want to cancel this payment?</div>

            <div className="hui-bg-gray-light rounded-3 p-2 hui-text-sm mb-2 d-flex align-items-center my-3">
              <div className="d-flex me-1">
                <i className="hui-i hui-svg-info-gray-sm"></i>
              </div>
              <div className="flex-grow-1">
                Press "Confirm" to proceed with the cancellation or "Close" to return to the payment detail.
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
              <button className="hui-btn hui-btn-outline" onClick={() => toggleCancel()}>
                Close
              </button>

              <button className="hui-btn hui-btn-red " onClick={() => handleCancelPayment()}>
                Confirm
              </button>
            </div>
          </Modal>
        )}
        <button className={className} disabled={isCanceled} onClick={() => toggleCancel()}>
          {props.children}
        </button>
      </>
    )
  );

  function toggleCancel() {
    setIsOpen(!isOpen);
  }

  async function handleCancelPayment() {
    try {
      const canceledPayment = await cancelPayment(code);
      if (canceledPayment.status === 'CANCELED') {
        toastr.success(`Payment Canceled Successfully`);
      }
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      toastr.error(`An error occurred during canceling the payment`);
      console.error(`An error occurred during canceling the payment`, e);
    } finally {
      toggleCancel();
    }
  }
};

export default CancelPayment;
