import React from 'react';
import currencyFormatter from 'currency-formatter';
import { calculateSurcharge, surchargeRateStr } from '../../../utils/utilsSurcharge';
import SurchargeMessage from '../../../pages/private/payment/surcharge/SurchargeMessage';

const ModalSurcharge = (props) => {
  const { modal } = props;

  const { message } = modal;

  const icon = (
    <svg height="150" viewBox="0 0 150 150" width="150" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <circle cx="75" cy="75" fill="#f7f7f8" r="75" />
        <rect fill="#cacaca" height="57" rx="8" width="84" x="33" y="47" />
        <g fill="#f9f9fc" transform="translate(42 86)">
          <path d="m5.4213 5.5896c.0408 0 .0816.00765.1224.02295s.0799.04165.1173.07905l.6834.7191c-.2992.391-.67405.68595-1.12455.88485s-.98345.29835-1.59885.29835c-.5644 0-1.07015-.09605-1.51725-.28815s-.8262-.4573-1.1373-.7956-.54995-.7395-.71655-1.2036-.2499-.96815-.2499-1.51215c0-.5542.0952-1.06335.2856-1.52745s.45645-.86445.79815-1.20105.75055-.5984 1.22655-.7854 1.0013-.2805 1.5759-.2805c.2788 0 .54145.02465.78795.07395s.47685.1173.69105.204.4114.1904.5916.3111.34.25415.4794.40035l-.5814.7803c-.0374.0476-.0816.09095-.1326.13005s-.1224.05865-.2142.05865c-.0612 0-.119-.0136-.1734-.0408s-.1122-.06035-.1734-.09945-.12835-.0816-.20145-.1275-.1598-.0884-.2601-.1275-.2176-.07225-.3519-.09945-.29155-.0408-.47175-.0408c-.3128 0-.5984.05525-.8568.16575s-.48025.2686-.66555.4743-.3298.45475-.4335.74715-.15555.6205-.15555.9843c0 .3808.05185.7191.15555 1.0149s.2448.54485.4233.74715.38675.35615.62475.46155.493.1581.765.1581c.1564 0 .2992-.00765.4284-.02295s.24905-.04165.35955-.07905.2159-.08585.3162-.14535.20145-.13345.30345-.22185c.0408-.034.085-.06205.1326-.08415s.0969-.03315.1479-.03315z" />
          <path d="m8.5836 4.7634v2.7489h-1.7238v-7.4307h2.4174c.5372 0 .99535.05525 1.37445.16575s.6885.26435.9282.46155.41395.4301.52275.6987.1632.561.1632.8772c0 .2414-.0323.4692-.0969.6834s-.16065.41225-.28815.59415-.2839.34425-.4692.48705-.39865.2601-.64005.3519c.1156.0578.22355.1292.32385.2142s.18785.1887.26265.3111l1.581 2.5857h-1.5606c-.289 0-.4964-.1088-.6222-.3264l-1.2342-2.1573c-.0544-.0952-.11815-.1632-.19125-.204s-.17595-.0612-.30855-.0612zm0-1.1883h.6936c.2346 0 .43435-.02975.59925-.08925s.30005-.14195.40545-.24735.1819-.22865.2295-.36975.0714-.29325.0714-.45645c0-.3264-.10625-.5814-.31875-.765s-.54145-.2754-.98685-.2754h-.6936z" />
          <path d="m18.1566.0816v1.326h-3.0498v1.734h2.3358v1.275h-2.3358v1.7697h3.0498v1.326h-4.7838v-7.4307z" />
          <path d="m25.608 3.7944c0 .5372-.09265 1.03275-.27795 1.48665s-.44625.8466-.78285 1.1781-.74205.5899-1.21635.7752-1.00045.27795-1.57845.27795h-2.8866v-7.4307h2.8866c.578 0 1.10415.0935 1.57845.2805s.87975.4454 1.21635.7752.59755.72165.78285 1.17555.27795.94775.27795 1.48155zm-1.7646 0c0-.3672-.0476-.6987-.1428-.9945s-.23205-.54655-.41055-.75225-.39695-.3638-.65535-.4743-.5525-.16575-.8823-.16575h-1.1526v4.7787h1.1526c.3298 0 .6239-.05525.8823-.16575s.47685-.2686.65535-.4743.31535-.45645.41055-.75225.1428-.629.1428-.9996z" />
          <path d="m28.0512 7.5123h-1.734v-7.4307h1.734z" />
          <path d="m34.554.0816v1.3668h-2.091v6.0639h-1.7238v-6.0639h-2.091v-1.3668z" />
        </g>
        <path d="m33 57h84v11h-84z" fill="#24262b" />
        <rect fill="#efefef" height="11" rx="3" width="15" x="94" y="84" />
        <g transform="translate(103 89)">
          <path
            d="m14 28c7.7777778 0 14-6.2222222 14-14 0-7.77777778-6.2222222-14-14-14-7.77777778 0-14 6.22222222-14 14 0 7.7777778 6.22222222 14 14 14z"
            fill="#ffbb52"
          />
          <g fill="#fff" transform="translate(12 5)">
            <path d="m0 1.83050847c0-.91525423.83333333-1.83050847 2-1.83050847s2 .76271186 2 1.83050847v8.38983053c0 .9152542-.83333333 1.8305085-2 1.8305085s-2-.9152543-2-1.8305085z" />
            <path d="m4 16.1694915c0 .9152543-.83333333 1.8305085-2 1.8305085s-2-.7627119-2-1.8305085c0-.9152542.83333333-1.8305084 2-1.8305084s2 .7627118 2 1.8305084z" />
          </g>
        </g>
      </g>
    </svg>
  );

  const totalFormatted = currencyFormatter.format(message, {
    code: 'USD',
  });

  const surchargeFormatted = currencyFormatter.format(calculateSurcharge(message), {
    code: 'USD',
  });

  const totalSurchargeFormatted = currencyFormatter.format(message + calculateSurcharge(message), {
    code: 'USD',
  });

  return (
    <>
      <div className="text-center mb-3">{icon}</div>

      <div className="hui-subtitle fw-bold text-center px-5 mb-3">
        The card type you've selected does not match with your card.
      </div>

      <div className=" px-3 mb-3 pb-3 border-bottom">
        <span hidden={!modal.showSurcharge}>
          Please note, a {surchargeRateStr} surcharge fee for <strong>CREDIT CARD</strong> processing may be applied
          depending on your state.
        </span>
      </div>

      <div className="d-flex px-3">
        <div className="flex-grow-1 hui-text fw-bold mb-2">Subtotal</div>
        <div>
          <strong>{totalFormatted}</strong>
        </div>
      </div>

      <div className="d-flex px-3" hidden={!modal.showSurcharge}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Credit Card Surcharge ({surchargeRateStr}):</div>
        <div>
          <strong>{surchargeFormatted}</strong>
        </div>
      </div>

      <SurchargeMessage showSurcharge={modal.showSurcharge} />

      <div className="d-flex px-3 mb-3">
        <div className="flex-grow-1 hui-text-lg fw-bold mb-2">
          Total<span hidden={!modal.showSurcharge}>+ Surcharge Fee</span>
        </div>
        <div className="hui-text-lg">
          <strong>{modal.showSurcharge ? totalSurchargeFormatted : totalFormatted}</strong>
        </div>
      </div>

      <div hidden={!modal.showSurcharge}>
        To avoid this fee, please adjust your card type, or you may proceed with the payment to accept the surcharge.
      </div>
    </>
  );
};

export default ModalSurcharge;
