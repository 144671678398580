import React from 'react';
import PaymentMethodsTable from './PaymentMethodsTable';

const PaymentMethods = (props) => {
  return (
    <div className='pt-2'>
      <PaymentMethodsTable />
    </div>
  );
}

export default PaymentMethods;