import React from 'react';
import { Tooltip } from '@mui/material';
import ButtonDownload from '../buttons/ButtonDownload';
import ButtonPrint from '../buttons/ButtonPrint';

const HuiTableActions = (props) => {
  const { rowIndex, data, disabled } = props;
  const title = disabled ? 'You can download once the document has been reviewed and signed' : '';
  const params = {
    documentId: data.id,
  };

  return (
    <div className="d-flex align-items-center">
      <Tooltip arrow placement="top" title={title}>
        <div>
          <ButtonDownload type="waiver" params={params} rowIndex={rowIndex} id={data.waiverRequest?.id || null} disabled={disabled} className="hui-btn hui-btn-sm">
            Download
          </ButtonDownload>
          <ButtonPrint type="waiver" params={params} rowIndex={rowIndex} id={data.waiverRequest?.id || null} disabled={disabled} className="hui-btn hui-btn-sm ms-2">
            Print
          </ButtonPrint>
        </div>
      </Tooltip>
    </div>
  );
};

export default HuiTableActions;
