export function validateRoutingNumber(routingNumber) {
  if (!/^\d{9}$/.test(routingNumber)) {
    return false;
  }

  const weights = [3, 7, 1];
  let sum = 0;

  for (let i = 0; i < routingNumber.length; i++) {
    sum += parseInt(routingNumber[i]) * weights[i % 3];
  }

  return sum % 10 === 0;
}

export function validateAccountNumber(accountNumber) {
  return /^\d{4,17}$/.test(accountNumber);
}
