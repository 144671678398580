import React from 'react';
import Header from '../../../components/layout/header/Header';
import ChangePasswordForm from './ChangePasswordForm';

const ChangePassword = (props) => {
  const { newUser } = props;
  const title = newUser ? 'Create your password' : 'Change Password';

  return (
    <>
      <Header onlyLogo />
      <div className="hui-header-padding position-relative">
        <div className="p-5">
          <h1 className="text-center hui-title mb-4">{title}</h1>
          <div className="w-50 mx-auto mb-5">
            <ChangePasswordForm newUser={newUser} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
