import { HuiCard, HuiCardActions, HuiCardHeader, HuiIcon, HuiLinkArrow, HuiLoading, HuiPanel } from 'handle-ui';
import { size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { getPayment } from '../../../actions/payment/getPayment';
import ButtonDownload from '../../../components/buttons/ButtonDownload';
import ButtonEmail from '../../../components/buttons/ButtonEmail';
import ButtonPrint from '../../../components/buttons/ButtonPrint';
import { selectLoading } from '../../../redux/LoadingSlice';
import { selectPayment, updatePayment } from '../../../redux/PaymentSlice';
import HuiFullPaymentsDetail from '../history/HuiFullPaymentsDetail';
import moment from 'moment';
import CancelPayment from '../../../components/payment/CancelPayment';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';

const Thanks = () => {
  const dispatch = useDispatch();
  const payment = useSelector(selectPayment);
  const loading = useSelector(selectLoading);
  const [searchParams] = useSearchParams();

  const isRequestWaiver = !!searchParams.get('requestWaiver');

  const { pathname } = useLocation();
  const paymentId = pathname.substring(pathname.lastIndexOf('/') + 1);
  const isSlim = pathname.startsWith('/thanks-slim');
  const payLink = isSlim ? '/pay-slim' : '/pay';

  const [paymentNotFound, setPaymentNotFound] = useState(false);

  const { code, paymentType, status, scheduledAt, recurrenceId } = payment || {};

  const isACH = UtilPaymentMethods.isACH(paymentType);

  useEffect(() => {
    async function fetchPaymentInfo() {
      if (!size(payment) && !loading && !paymentNotFound) {
        try {
          const paymentThanks = await getPayment(paymentId);
          await dispatch(updatePayment(paymentThanks));
          setPaymentNotFound(false);
        } catch (e) {
          setPaymentNotFound(true);
        }
      }
    }

    fetchPaymentInfo();
  }, [payment, dispatch, paymentId, paymentNotFound, setPaymentNotFound, loading]);

  const isScheduled = !!scheduledAt && status !== 'APPROVED' && status !== 'PENDING' ;

  const heroBgColor = `text-center p-5 ${isScheduled ? 'hui-bg-gray-light' : 'hui-bg-green-light'}`;
  const heroTextColor = `text-center hui-title mb-2 ${isScheduled ? 'hui-color-yellow-dark' : 'hui-color-green'}`;
  const heroText = isScheduled && status !== 'APPROVED' ? 'You have successfully scheduled a payment!' : 'Thank you for your payment!';

  const showCancel = status === 'SCHEDULED' && !recurrenceId;

  return (
    <>
      <div className="d-flex flex-column hui-thanks-content p-4">
        {size(payment) && !paymentNotFound ? (
          <>
            <div className="">
              <HuiPanel className="w-100 mb-4" boxShadow>
                <div className={heroBgColor}>
                  <h1 className={heroTextColor}>{heroText}</h1>
                  <div className="hui-text-lg">
                    Payment confirmation code: <br />
                    <strong>{code}</strong>
                  </div>
                </div>

                <div className="px-4">
                  <div className="mx-auto  hui-thanks-info">
                    <div
                      className="my-4 hui-bg-yellow-light p-3 rounded-3 d-flex align-items-center hui-thanks-info"
                      hidden={!isScheduled}
                    >
                      <div className="d-flex me-2">
                        <i className="hui-i hui-svg-toastr-warning"></i>
                      </div>
                      <div className="flex-grow-1">
                        This payment has been scheduled for processing on &nbsp;
                        <strong>{moment(scheduledAt).format('MMM D, YYYY')}</strong>.
                      </div>
                      <div className="ms-2">
                        {!!showCancel && (
                          <CancelPayment className="hui-btn " payment={payment} onComplete={reloadPayment}>
                            Cancel Payment
                          </CancelPayment>
                        )}
                      </div>
                    </div>

                    <div className="my-4 hui-bg-gray-light p-3 rounded-3 d-flex align-items-center hui-thanks-info" hidden={isScheduled}>
                      <div className="d-flex me-2">
                        <i className="hui-i hui-svg-toastr-success"></i>
                      </div>
                      A confirmation email has been sent to the email on file
                    </div>
                  </div>
                </div>

                <div className="px-4" hidden={!isACH}>
                  <div className="mx-auto  hui-thanks-info">
                    <div className="my-4 hui-bg-gray-light p-3 rounded-3 d-flex align-items-center hui-thanks-info">
                      <div className="d-flex me-2">
                        <i className="hui-i hui-svg-toastr-info"></i>
                      </div>
                      This is an ACH payment, it can take up to 5 days to be fully processed
                    </div>
                  </div>
                </div>
                <div className="mb-4 d-flex justify-content-center text-center" hidden={!isRequestWaiver}>
                  <Link to="/request-waiver?restore=true" className="hui-btn hui-btn-lg mx-2 hui-btn-green">
                    Continue your Waiver Request
                  </Link>
                </div>
              </HuiPanel>

              <div className="mb-4 d-flex justify-content-center text-center">
                <ButtonDownload type="payment" id={code} className="hui-btn hui-btn-lg mx-2">
                  Download Receipt
                </ButtonDownload>
                <ButtonPrint type="payment" id={code} className="hui-btn hui-btn-lg mx-2">
                  Print Receipt
                </ButtonPrint>
                <ButtonEmail type="payment" id={code} className="hui-btn hui-btn-lg mx-2">
                  Email a copy
                </ButtonEmail>
              </div>
            </div>

            <div className="hui-thanks-payment">
              <div>
                <HuiFullPaymentsDetail payment={payment} />
              </div>

              <div className="hui-thanks-actions">
                <div className="hui-dashboard-cards">
                  <HuiCard className="hui-theme-card mb-4">
                    <HuiCardHeader title="Payments" icon={<HuiIcon name="pay" size="md" />} />
                    <HuiCardActions
                      actionsRight
                      actions={
                        <Link to={payLink}>
                          <HuiLinkArrow label="Make Another Payment" arrowRight />
                        </Link>
                      }
                    />
                  </HuiCard>

                  <HuiCard className="hui-theme-card mb-4" hidden={isSlim}>
                    <HuiCardHeader title="Waivers" icon={<HuiIcon name="waiver" size="md" />} />
                    {/*<HuiCardContent>*/}
                    {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit*/}
                    {/*</HuiCardContent>*/}
                    <HuiCardActions
                      actionsRight
                      actions={
                        <Link to="/request-waiver">
                          <HuiLinkArrow label="Submit a Waiver Request" arrowRight />
                        </Link>
                      }
                    />
                  </HuiCard>

                  <HuiCard className="hui-theme-card" hidden={isSlim}>
                    <HuiCardHeader title="Reports" icon={<HuiIcon name="history" size="md" />} />
                    <HuiCardActions
                      actionsRight
                      actions={
                        <Link to="/history/payments">
                          <HuiLinkArrow label="View Payments History" arrowRight />
                        </Link>
                      }
                    />
                  </HuiCard>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div hidden={!paymentNotFound}>
            <HuiLoading className="hui-credit-card-loading" isOpen />
          </div>
        )}
      </div>
    </>
  );

  function reloadPayment() {
    dispatch(updatePayment({}));
  }
  
};
export default Thanks;
