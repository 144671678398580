import { createSlice } from '@reduxjs/toolkit';

export const portalsSlice = createSlice({
  name: 'portals',
  initialState: {
    value: [],
  },
  reducers: {
    updatePortals: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updatePortals } = portalsSlice.actions;

export const selectPortals = (state) => state.portals.value;

export default portalsSlice.reducer;
