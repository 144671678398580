import { axiosInstance } from '../../utils/utilsAxios';
import { axiosInstance as axiosExternal } from '../../utils/utilsAxiosExternal';
import { encryptWithRSA } from '../../utils/utilsCrypto';

export async function getPaymentToken(site, accountNumber, routingNumber) {
  if (site === 'HANDLE') {
    return getPaymentTokenHandle(site, accountNumber, routingNumber);
  }

  return getPaymentTokenCardPointe(site, accountNumber, routingNumber);
}

async function getPaymentTokenCardPointe(site, accountNumber, routingNumber) {
  const url = process.env.REACT_APP_CARD_POINTE_TOKENIZE_API_URL.replace('{{site}}', site);

  try {
    const data = { account: `${routingNumber}/${accountNumber}` };
    const response = await axiosExternal.post(url, data);
    return response.data.token;
  } catch (error) {
    throw new Error(error);
  }
}

async function getPaymentTokenHandle(site, accountNumber, routingNumber) {
  const publicKey = await getPublicKey();
  const encryptedData = encryptWithRSA({ accountNumber, routingNumber }, publicKey);

  const url = `v1/tokenize`;

  try {
    const response = await axiosInstance.post(url, { token: encryptedData });
    return response.data.token;
  } catch (error) {
    throw new Error(error);
  }
}

async function getPublicKey() {
  const url = `v1/tokenize/public-key`;

  try {
    const response = await axiosInstance.get(url);
    return response.data.publicKey;
  } catch (error) {
    throw new Error(error);
  }
}
