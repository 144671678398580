import { HuiCheckbox } from 'handle-ui';
import { filter, map, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectCredits } from '../../redux/CreditsSlice';
import {
  addInvoice,
  addInvoices,
  removeInvoice,
  resetPaymentData,
  selectPaymentData,
  updateCreditsToInvoice,
} from '../../redux/PaymentDataSlice';
import { store } from '../../store/store';

const HuiTableCheckBox = (props) => {
  const { data, tableManager, value, header = false, checked = false } = props;
  const dispatch = useDispatch();
  const paymentData = useSelector(selectPaymentData);
  const allCredits = useSelector(selectCredits);
  const { config: { isPaginated = false } = {} } = tableManager;

  const onHandleChange = header ? handleAllChange : handleChange;
  const onChecked = header ? !!checked : value;
  return <HuiCheckbox className="mb-0" onChange={onHandleChange} size="small" showLabel checked={onChecked} />;

  async function handleChange(e) {
    e.stopPropagation();
    tableManager.rowSelectionApi.toggleRowSelection(data.id);

    if (e.target.checked) {
      await dispatch(addInvoice({ data, allCredits }));
    }

    if (!e.target.checked) {
      const invoice = paymentData.paymentInvoices.find((i) => i.id === data.id);
      await dispatch(removeInvoice(invoice));
      await updateCreditMemoInAllPaymentData();
    }
  }

  async function updateCreditMemoInAllPaymentData() {
    const paymentData = await store.getState().paymentData;
    const creditsData = await store.getState().credits;
    const {
      value: { rows: allCreditsUpdated = [] },
    } = creditsData;
    for (const paymentInvoice of paymentData.paymentInvoices) {
      const { credits: oldCredits = [] } = paymentInvoice;
      const credits = map(oldCredits, (credit) => {
        const creditUpdated = allCreditsUpdated.find((c) => c.id === credit.id);
        if (creditUpdated) {
          return {
            ...credit,
            balanceLeft: creditUpdated.balanceLeft,
          };
        }
        return credit;
      });
      dispatch(updateCreditsToInvoice({ ...paymentInvoice, credits }));
    }
  }

  async function handleAllChange(e) {
    e.stopPropagation();
    if (!header) return;
    const rows = getRowsData();
    if (e.target.checked) {
      const invoicesToAddInPaymentData = filter(rows, (r) => !isInvoiceSelected(r));
      const prevSelectedIds = tableManager.rowSelectionApi.selectedRowsIds;
      const selectedIds = prevSelectedIds.concat(map(rows, (r) => r.id));
      tableManager.rowSelectionApi.setSelectedRowsIds(uniq(selectedIds));
      await dispatch(addInvoices({ invoices: invoicesToAddInPaymentData, allCredits }));
    }

    if (!e.target.checked) {
      dispatch(resetPaymentData());
      tableManager.rowSelectionApi.setSelectedRowsIds([]);
    }
  }

  function isInvoiceSelected(invoice) {
    return paymentData.paymentInvoices.find((i) => i.id === invoice.id);
  }

  function getRowsData() {
    return isPaginated ? tableManager.paginationApi.pageRows : tableManager.rowsApi.rows;
  }
};

export default HuiTableCheckBox;
