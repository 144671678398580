import ConfirmEmail from '../pages/private/confirmEmail/ConfirmEmail';
import Dashboard from '../pages/private/dashboard/Dashboard';
import History from '../pages/private/history/History';
import HistoryPaymentsDetail from '../pages/private/history/HistoryPaymentsDetail';
import HistoryPaymentsPage from '../pages/private/history/HistoryPaymentsPage';
import HistoryWaiversPage from '../pages/private/history/HistoryWaiversPage';
import Logout from '../pages/private/logout/Logout';
import Pay from '../pages/private/payment/Pay';
import Thanks from '../pages/private/payment/Thanks';
import PendingApproval from '../pages/private/pendingApproval/PendingApproval';
import Settings from '../pages/private/settings/Settings';
import RequestWaiver from '../pages/private/waiver/RequestWaiver';
import RequestWaiverConfirmation from '../pages/private/waiver/RequestWaiverConfirmation';
import NotFound from '../pages/public/404/NotFound';
import ChangePassword from '../pages/public/changePassword/ChangePassword';
import ForgotPassword from '../pages/public/forgotPassword/ForgotPassword';
import PublicPay from '../pages/public/pay/PublicPay';
import PublicThanks from '../pages/public/pay/PublicThanks';

import Action from '../pages/public/signIn/Action';
import SignIn from '../pages/public/signIn/SignIn';
import SignUp from '../pages/public/signUp/SignUp';
import InvalidRequest from '../pages/public/pay/InvalidRequest';

export const PrivateRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    template: Dashboard,
  },
  {
    name: 'Pay',
    path: '/pay',
    template: Pay,
  },
  {
    name: 'Pay',
    path: '/prepay',
    template: Pay,
  },
  {
    name: 'Pay',
    path: '/pay-slim',
    template: Pay,
    slim: true,
  },
  {
    name: 'Payments Thanks Page',
    path: '/thanks/:id',
    template: Thanks,
  },
  {
    name: 'Request Waiver',
    path: '/request-waiver',
    template: RequestWaiver,
  },
  {
    name: 'Request Waiver Confirmation',
    path: '/request-waiver-confirmation',
    template: RequestWaiverConfirmation,
  },
  {
    name: 'Processed Payments',
    path: '/history/payments',
    template: HistoryPaymentsPage,
  },
  {
    name: 'Payment Details by ID',
    path: '/history/payments/:id',
    template: HistoryPaymentsDetail,
  },
  {
    name: 'Recurrences',
    path: '/history/recurrences',
    template: HistoryPaymentsPage,
  },
  {
    name: 'Waiver History',
    path: '/history/waivers',
    template: HistoryWaiversPage,
  },
  {
    name: 'History Waiver by ID',
    path: '/history/waivers/:id',
    template: History,
  },
  {
    name: 'History Invoice',
    path: '/history/invoices',
    template: History,
  },
  {
    name: 'Settings',
    path: '/settings',
    template: Settings,
  },
  {
    name: 'Profile',
    path: '/settings/profile',
    template: Settings,
  },
  {
    name: 'Payment Methods',
    path: '/settings/payment-methods',
    template: Settings,
  },
  {
    name: 'Logout',
    path: '/logout',
    template: Logout,
    allowFirebaseUser: true,
  },
  {
    name: 'Pending User',
    path: '/pending-approval',
    template: PendingApproval,
    blank: true,
    allowFirebaseUser: true,
  },
  {
    name: 'Confirm Email',
    path: '/confirm-email',
    template: ConfirmEmail,
    blank: true,
    allowFirebaseUser: true,
  },
];

export const PublicRoutes = [
  {
    name: 'Login',
    path: '/login',
    template: SignIn,
    onlyUnauthenticated: true,
  },
  {
    name: 'AuthSSO',
    path: '/auth/sso',
    template: SignIn,
    onlyUnauthenticated: true,
  },
  {
    name: 'Signup',
    path: '/signup',
    template: SignUp,
    onlyUnauthenticated: true,
  },
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    template: ForgotPassword,
    onlyUnauthenticated: true,
  },
  {
    name: 'Change Password',
    path: '/change-password',
    template: ChangePassword,
  },
  {
    name: 'Not Found',
    path: '/404',
    template: NotFound,
  },
  {
    name: 'Sign In Action',
    path: '/action',
    template: Action,
  },
  // {
  //   name: 'Pay',
  //   path: '/pay-slim',
  //   template: Pay,
  //   slim: true,
  // },
  {
    name: 'Payments Thanks Page',
    path: '/thanks-slim/:id',
    template: Thanks,
    slim: true,
  },
  {
    name: 'Pay',
    path: '/prepay-slim',
    template: Pay,
    slim: true,
  },
  {
    name: 'Checkout',
    path: '/checkout',
    template: PublicPay,
  },
  {
    name: 'Public Thanks',
    path: '/checkout-confirmation',
    template: PublicThanks,
  },
  {
    name: 'Invalid Request',
    path: '/invalid-request',
    template: InvalidRequest,
  },
];
