import currencyFormatter from 'currency-formatter';
import { HuiIcon } from 'handle-ui';
import moment from 'moment/moment';
import HuiPaymentMethodIcon from '../../../components/payment/HuiPaymentMethodIcon';

const DashboardScheduledItem = (props) => {
  const { payment } = props;
  const { scheduledAt, total, currencyCode, isPrepaid, brand } = payment;

  let label = 'Scheduled';

  return (
    <div className="d-flex mb-3">
      <div className="flex-grow-1">
        <div className="hui-text fw-bold">{isPrepaid ? 'Prepay' : 'Invoice'}</div>
        <div className="hui-text hui-color-gray d-flex align-items-center">
          <div className="d-flex me-1">
            <HuiIcon name="calendarDate" size="xs" />
          </div>
          {label}
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="hui-text d-flex align-items-center">
          {currencyFormatter.format(total, { code: currencyCode })}
          <HuiPaymentMethodIcon brand={brand} className="ms-2" />
        </div>
        <div className="hui-text hui-color-gray d-flex align-items-center">{moment(scheduledAt).format('MMM DD')}</div>
      </div>
    </div>
  );
};

export default DashboardScheduledItem;
