import { HuiLinkArrow, HuiPanel, HuiPanelActions, HuiPanelContent } from 'handle-ui';
import { size } from 'lodash';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { getWaiverRequestsHistory } from '../../../actions/waiver/getWaiversHistory';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableAddress from '../../../components/table/HuiTableAddress';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTableWaiverStatus from '../../../components/table/HuiTableWaiverStatus';
import HuiTableWaiverType from '../../../components/table/HuiTableWaiverType';
import { defaultDateRange } from '../../../components/table/components/HuiFilters';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';
import { selectLoading } from '../../../redux/LoadingSlice';
import { dateRangeFilter } from '../../../utils/utilsFilters';

const paymentsDefaultFilter = {
  startDate: defaultDateRange[0].startDate.toISOString(),
  endDate: defaultDateRange[0].endDate.toISOString(),
};

let columns = [
  {
    field: 'waiverRequestStatus',
    label: 'Waiver Status',
    width: '111px',
    cellRenderer: (props) => <HuiTableWaiverStatus {...props} />,
  },
  {
    field: 'createdAt',
    label: 'Request Date',
    width: '95px',
    getValue: ({ value }) => (value !== null && moment(value).format('M/DD/YYYY')) || '',
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },

  {
    field: 'project',
    label: 'Project Name',
    width: 'auto',
    getValue: ({ value }) => value?.jobName,
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'project',
    label: 'Project Address',
    width: 'auto',
    getValue: ({ value }) => value?.address,
    cellRenderer: (props) => <HuiTableAddress {...props} />,
  },
  {
    field: 'waiverType',
    label: 'Waiver Type',
    width: '200px',
    cellRenderer: (props) => <HuiTableWaiverType {...props} />,
  },
];

columns = columns.map((column, i) => {
  return { ...column, id: i, sortable: false };
});

const DashboardHistoryWaivers = () => {
  const loading = useSelector(selectLoading);
  const [searchParams, setSearchParams] = useSearchParams();
  const [historyWaivers, setHistoryWaivers] = useState([]);

  const { rows } = historyWaivers || {};

  const dashBoardWaiversFilters = {
    filters: [
      {
        ...dateRangeFilter,
        name: 'dashboardWaivers',
      },
    ],
  };

  useEffect(() => {
    async function findWaivers() {
      if (!size(historyWaivers) && !loading) {
        try {
          const result = await getWaiverRequestsHistory(new URLSearchParams(paymentsDefaultFilter));
          setHistoryWaivers(result);
        } catch (error) {
          console.error(error);
          setHistoryWaivers(['error']);
        }
      }
    }

    findWaivers();
  }, [loading, historyWaivers, setHistoryWaivers]);

  useEffect(() => {
    async function filterWaivers() {
      const startDate = searchParams.get('dashboardWaiversStartDate');
      const endDate = searchParams.get('dashboardWaiversEndDate');

      if (startDate && endDate) {
        setHistoryWaivers(['filter']);

        const waiversFilter = {
          startDate,
          endDate,
        };

        const result = await getWaiverRequestsHistory(new URLSearchParams(waiversFilter));

        if (result) {
          setHistoryWaivers(result);

          searchParams.delete('dashboardWaiversStartDate');
          searchParams.delete('dashboardWaiversEndDate');

          setSearchParams(searchParams);
        }
      }
    }
    filterWaivers();
  }, [setHistoryWaivers, searchParams]);

  const isLoading = !size(historyWaivers);

  return (
    <HuiPanel className="mb-5" boxShadow>
      <HuiPanelContent className="px-3">
        <HuiTable
          additionalProps={{
            header: {
              title: 'Executed Waiver Requests',
              availableFilters: { ...dashBoardWaiversFilters },
            },
          }}
          components={{ Header: HuiTableHeader }}
          isLoading={isLoading}
          pageSize={5}
          className="hui-table hui-table-no-border hui-table-fluid"
          columns={columns}
          rows={rows}
          totalRows={rows?.length}
          isPaginated={false}
        />
      </HuiPanelContent>
      <HuiPanelActions
        actionsRight
        actions={
          <Link to="/history/waivers">
            <HuiLinkArrow label="View all Requests" arrowRight />
          </Link>
        }
      />
    </HuiPanel>
  );
};

export default DashboardHistoryWaivers;
