import { createSlice } from '@reduxjs/toolkit';
import { find, map } from 'lodash';
import { sum } from '../utils/utilsMath';
import { paymentDataSlice } from './PaymentDataSlice';

const initialState = {
  value: { rows: [], count: 0 },
};

export const creditsSlice = createSlice({
  name: 'credits',
  initialState,
  reducers: {
    resetCredits: (state, action) => {
      return initialState;
    },
    updateCredits: (state, action) => {
      state.value = action.payload;
      state.value.rows = map(state.value.rows, (c) => ({ ...c, balanceLeft: c.balance }));
    },
    saveAppliedCredits: (state, action) => {
      const { newCredits, oldCredits } = action.payload;
      state.value.rows = map(state.value.rows, (c) => {
        const usedCredit = find(newCredits, { id: c.id });
        const removedCredit = !usedCredit && find(oldCredits, { id: c.id });
        if (removedCredit) {
          const balanceLeft = sum(c.balanceLeft, removedCredit.amount);
          return { ...removedCredit, balanceLeft };
        }
        return usedCredit || removedCredit || c;
      });
    },
    removeCreditsUsage: (state, action) => {
      return restoreCreditsUsedToAllCredits(state, action);
    },
  },
  extraReducers(builder) {
    builder.addCase(paymentDataSlice.actions.resetPaymentData, (state, action) => {
      state.value.rows = map(state.value.rows, (c) => {
        return { ...c, balanceLeft: c.balance, amount: undefined };
      });
      return state;
    });
    builder.addCase(paymentDataSlice.actions.removeInvoice, (state, action) => {
      return restoreCreditsUsedToAllCredits(state, action);
    });
  },
});

export const { updateCredits, saveAppliedCredits, removeCreditsUsage, resetCredits } = creditsSlice.actions;

export const selectCredits = (state) => state.credits.value;

export default creditsSlice.reducer;

const restoreCreditsUsedToAllCredits = (state, action) => {
  const { credits } = action.payload;
  state.value.rows = map(state.value.rows, (c) => {
    const usedCredit = find(credits, { id: c.id });
    const balanceLeft = usedCredit?.id ? c.balanceLeft + usedCredit.amount : c.balanceLeft;
    return { ...c, balanceLeft, amount: undefined };
  });
  return state;
};
