import { HuiIcon, HuiInputAmount, HuiSelect } from 'handle-ui';
import { filter, isNil, size } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentReasons } from '../../pages/private/payment/payment-form/utilPaymentForm';
import { selectCredits } from '../../redux/CreditsSlice';
import {
  addInvoice,
  addPaymentReason,
  changeInvoiceTotal,
  removePaymentReason,
  selectPaymentData,
} from '../../redux/PaymentDataSlice';
import { selectPaymentReasons } from '../../redux/PaymentReasonsSlice';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';
import { checkIfOverpayEnabled, checkIfShortpayEnabled } from '../../utils/utilCheckIfPaymentHasError';
import { formatNumber } from '../../utils/utilsMath';
import ViewInvoiceCredit from '../invoice/ViewInvoiceCredit';

const HuiTablePay = (props) => {
  const fieldRef = useRef();
  const dispatch = useDispatch();
  const paymentData = useSelector(selectPaymentData);
  const portalInfo = useSelector(selectPortalInfo);
  const paymentReasons = useSelector(selectPaymentReasons);
  const allCredits = useSelector(selectCredits);
  const { rowIndex, data, tableManager } = props;
  const { balance, id } = data;

  const getInvoicesIds = tableManager.rowSelectionApi.selectedRowsIds;
  const isInvoiceAdded = size(filter(getInvoicesIds, (i) => i === data.id));

  const {
    settings: {
      isOverpayEnabled = false,
      isOverpayAdminEnabled = false,
      isShortpayEnabled = false,
      isShortpayAdminEnabled = false,
    } = {},
  } = portalInfo;

  const [invoice, setInvoice] = useState({});

  const { paymentReasonId, hasInvoiceError, paymentType, amount = null, credits, creditApplied = 0 } = invoice || {};

  let totalToPay;

  const credit =
    invoice?.credits?.reduce((creditAmount, credit) => {
      return creditAmount + credit.amount;
    }, 0) || 0;

  totalToPay = amount;

  useEffect(() => {
    function findInvoice() {
      const invoice = paymentData?.paymentInvoices?.find((i) => i.id === id) || {};
      setInvoice(invoice);
    }

    findInvoice();
  }, [paymentData, id, setInvoice]);

  const filteredPaymentReasons = getPaymentReasons(paymentReasons, paymentType);
  const _isShortpayEnabled = checkIfShortpayEnabled(isShortpayEnabled, isShortpayAdminEnabled);
  const _isOverpayEnabled = checkIfOverpayEnabled(isOverpayEnabled, isOverpayAdminEnabled);
  let showPaymentReasons = size(filteredPaymentReasons) > 0 && !hasInvoiceError;

  const allowZeroAmount = (size(credits) && !amount) || (amount && !hasInvoiceError);
  const hasCredits = size(invoice?.credits);
  const canEditAmount = (_isOverpayEnabled || _isShortpayEnabled) && !hasCredits;

  if (hasCredits) {
    showPaymentReasons = formatNumber(credit + amount, 2) !== formatNumber(balance, 2);
  }

  const maxInvoiceBalance = _isOverpayEnabled ? undefined : balance;

  return (
    <div className="rgt-cell-inner text-truncate d-flex align-items-center">
      {isInvoiceAdded ? (
        <>
          <div className="d-flex align-items-center hui-pay-invoices-total">
            {amount !== null && (
              <HuiInputAmount
                disabled={!canEditAmount}
                error={!allowZeroAmount}
                prefix="$"
                className="mb-0"
                id="amount"
                name="amount"
                placeholder="$ Type Amount"
                value={totalToPay || '0'}
                onBlur={handleUpdateAmount}
                allowNegative={false}
                max={maxInvoiceBalance}
              />
            )}
          </div>

          <div className="ms-2 d-flex align-items-center" hidden={!hasCredits}>
            <ViewInvoiceCredit
              type="button"
              label="Edit"
              invoice={invoice}
              className="hui-btn hui-btn-link hui-text-sm"
            />
          </div>

          <div className="ms-2 d-flex align-items-center hui-pay-invoices-reason" hidden={!showPaymentReasons}>
            <HuiSelect
              error={!paymentReasonId}
              size="small"
              ref={fieldRef}
              disabled={hasCredits}
              value={paymentReasonId}
              id={`hui-select-${data.id}`}
              className="mb-0"
              label="Adjustment reason"
              onChange={handleChangePaymentReason}
              options={filteredPaymentReasons}
            />

            <button className="hui-btn hui-btn-transparent" onClick={cancelPaymentReason}>
              <HuiIcon name="x" size="xs" />
            </button>
          </div>
        </>
      ) : (
        <>
          <button className=" hui-btn hui-btn-link hui-text-sm" onClick={handleAddInvoice}>
            + Add
          </button>
        </>
      )}
    </div>
  );

  function cancelPaymentReason() {
    const { balance, creditApplied = 0 } = invoice || {};
    const newAmount = balance - creditApplied;
    const { paymentType, paymentTypeError } = getPaymentTypeInfo(newAmount);
    const payment = {
      ...paymentData,
      ...data,
      newAmount,
      paymentType,
      paymentTypeError,
      shortPayHasError: false,
    };

    dispatch(changeInvoiceTotal(payment));
    dispatch(removePaymentReason({ id, paymentType }));
  }

  function handleChangePaymentReason(e) {
    e.stopPropagation();
    const reasonId = e.target.value;
    handleAddPaymentReason(reasonId);
  }

  function handleUpdateAmount(e) {
    const value = e.target.value;
    const newAmount = parseFloat(value.replace(/\$|,/g, '')) || 0;

    updateAmount(newAmount);
  }

  function updateAmount(newAmount) {
    const { paymentType, paymentTypeError } = getPaymentTypeInfo(newAmount);

    const payment = {
      ...paymentData,
      ...data,
      newAmount,
      paymentTypeError,
      paymentType,
    };
    dispatch(changeInvoiceTotal(payment));

    const hasValidPaymentReason = paymentReasons.some(
      (p) => p.id === paymentReasonId && p.paymentType.replace('_', '').toLowerCase() === paymentType,
    );

    if (paymentType === 'complete' || (paymentReasonId && !hasValidPaymentReason)) {
      dispatch(removePaymentReason({ id, paymentType }));
    }
  }

  function handleAddPaymentReason(reasonId) {
    const content = {
      ...data,
      paymentReasonId: reasonId,
      shortPayHasError: isNil(reasonId) && showPaymentReasons,
    };
    dispatch(addPaymentReason(content));
  }

  function handleAddInvoice(e) {
    e.stopPropagation();
    tableManager.rowSelectionApi.toggleRowSelection(data.id);
    dispatch(addInvoice({ data, rowIndex, allCredits }));
  }

  function getPaymentTypeInfo(newAmount) {
    let paymentTypeError;
    let paymentType = 'complete';

    const total = newAmount + creditApplied;

    if (total > balance) {
      paymentType = 'overpay';
      if (!_isOverpayEnabled) {
        paymentTypeError = true;
      }
    }

    if (total < balance) {
      paymentType = 'shortpay';
      if (!_isShortpayEnabled) {
        paymentTypeError = true;
      }
    }

    return {
      paymentType,
      paymentTypeError,
    };
  }
};

export default HuiTablePay;
