import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';

const NavFooter = () => {
  const portalInfo = useSelector(selectPortalInfo);

  const { name } = portalInfo || {};
  return (
    <footer className="hui-nav-footer px-4 pb-3">
      <div className="p-4 rounded-3 hui-bg-gray-light d-flex flex-column hui-text-xs">
        <div className="hui-nav-footer-icon">
          <i className="hui-i hui-svg-handle-icon"></i>
        </div>

        <div className="mt-3 mb-2 hui-color-black-light hui-nav-footer-text">
          {name} Portal is powered by{' '}
          <a href="https://www.handle.com" target="_blank" rel="noopener noreferrer" className="hui-color-black">
            handle.com
          </a>
          {', '}
          Leader in Construction Payment Compliance.
        </div>

        <div className="d-lg-inline d-block">
          <a
            href="https://www.handle.com/terms/"
            target="_blank"
            rel="noopener noreferrer"
            className="hui-color-black me-2"
          >
            Terms of Use
          </a>
          <a
            href="https://www.handle.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className="hui-color-black"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default NavFooter;
