import moment from 'moment';
import * as React from 'react';

const SidebarRecurrenceInfo = (props) => {
  const { recurrence } = props;

  const { startDate, endDate, createdAt, amount, quantity, paymentsCount, createdBy } = recurrence || '';

  return (
    <>
      <div className="d-flex">
        <div className="flex-grow-1">
          <div>
            <strong className="hui-color-black-light">Start Date: </strong>
            <span className="hui-color-gray">{moment(startDate).format('MMMM D, YYYY') || ''}</span>
          </div>
          <div>
            <strong className="hui-color-black-light">End Date: </strong>
            <span className="hui-color-gray">{moment(endDate).format('MMMM D, YYYY') || ''}</span>
          </div>
        </div>
        <div className="text-end">
          <div className="text-nowrap">
            <strong className="hui-color-black-light">Created at: </strong>
            <span className="hui-color-gray">{moment(createdAt).format('MMMM D, YYYY') || ''}</span>
          </div>
          <div className="text-nowrap">
            <strong className="hui-color-black-light">Created By: </strong>
            <span className="hui-color-gray">{createdBy?.name || ''}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarRecurrenceInfo;
