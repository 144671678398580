import { HuiInput, HuiPanel, HuiPanelContent, HuiPanelHeader } from 'handle-ui';
import { toLower } from 'lodash';
import { useState } from 'react';
import GoogleAddressForm from '../../../components/google-maps/GoogleAddressForm';

const ContactInformation = (props) => {
  const { initialValue, title = 'Contact Information', hidden } = props;
  const [fields, setFields] = useState(initialValue);

  if (hidden) {
    return <></>;
  }

  return (
    <HuiPanel boxShadow>
      <HuiPanelHeader title={title} borderBottom />
      <HuiPanelContent className="px-4 py-3">
        <form id="payment-form">
          <div className="d-flex flex-column">
            <div className="row">
              <div className="col-sm-6">
                <HuiInput
                  label="First Name"
                  id="first_name"
                  name="firstName"
                  type="text"
                  placeholder="Type your first name"
                  value={fields?.firstName}
                  onChange={handleChange}
                  topLabel
                  required
                />
              </div>
              <div className="col-sm-6">
                <HuiInput
                  label="Last Name"
                  id="last_name"
                  name="lastName"
                  placeholder="Type your last name"
                  type="text"
                  value={fields?.lastName}
                  onChange={handleChange}
                  topLabel
                  required
                />
              </div>
              <div className="col-12">
                <HuiInput
                  label="Email Address"
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Type your email address"
                  value={fields?.email}
                  onChange={handleChange}
                  topLabel
                  required
                />
              </div>
              <div className="col-12">
                <HuiInput
                  label="Company Name"
                  id="company_name"
                  name="companyName"
                  type="text"
                  placeholder="Type your company name"
                  value={fields?.companyName}
                  onChange={handleChange}
                  topLabel
                  required
                />
              </div>
              <div className="col-12">
                <GoogleAddressForm
                  label="Company Address"
                  id="company_address"
                  name="companyAddress"
                  value={{ street: fields?.companyAddress || fields?.street }}
                  placeholder="Type your company address"
                  onChange={onChangeAddress}
                  required
                  compacted
                  topLabel
                  inputProps={{
                    role: 'presentation',
                    autoComplete: 'nope',
                  }}
                  size="small"
                />
              </div>
            </div>
          </div>
        </form>
      </HuiPanelContent>
    </HuiPanel>
  );

  function handleChange(e) {
    const { onChange } = props;
    const { name, value } = e.target;

    const _value = name === 'email' ? toLower(value) : value;

    setFields({ ...fields, [name]: _value });
    onChange?.(e);
  }

  function onChangeAddress(event) {
    const { target: { value, name } = {} } = event;
    const { formattedAddress, street } = value;
    handleChange({
      target: {
        name,
        value: formattedAddress || street,
      },
    });
  }
};

export default ContactInformation;
