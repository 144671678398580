import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    value: {},
  },
  reducers: {
    updatePayment: (state, action) => {
      state.value = action.payload;
    },
    resetPayment: (state, action) => {
      state.value = {};
    },
  },
});

export const { updatePayment, resetPayment } = paymentSlice.actions;

export const selectPayment = (state) => state.payment.value;

export default paymentSlice.reducer;
