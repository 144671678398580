import { HuiIcon } from 'handle-ui';
import * as React from 'react';

export const getPaymentMethodIcon = {
  VISA: <HuiIcon name="cardVisaColored" size="xs" />,
  MASTERCARD: <HuiIcon name="cardMasterColored" size="xs" />,
  JCB: <HuiIcon name="cardJcbColored" size="xs" />,
  DISCOVER: <HuiIcon name="cardDiscoverColored" size="xs" />,
  AMEX: <HuiIcon name="cardAmexColored" size="xs" />,
  DINERS: <HuiIcon name="cardDinersColored" size="xs" />,
  ACH: <HuiIcon name="ach" size="xs" />,
  CREDIT_CARD: <HuiIcon name="card" size="xs" />,
};
