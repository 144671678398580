import { size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentData } from '../../../../redux/PaymentDataSlice';
import HuiPaymentInfo from './HuiPaymentInfo';
import HuiPaymentInvoices from './HuiPaymentInvoices';
import HuiPaymentSummary from './HuiPaymentSummary';
import HuiRecurrenceInfo from './HuiRecurrenceInfo';

const HuiConfirmPaymentInvoices = (props) => {
  const { preview, payment, edit } = props;

  const dispatchInvoices = useSelector(selectPaymentData);

  const paymentData = payment || dispatchInvoices || {};

  const { isPrepaid } = paymentData;

  const invoiceWord = size(paymentData.paymentInvoices) > 1 ? 'Invoices' : 'Invoice';

  const title = preview
    ? `${size(paymentData.paymentInvoices)} ${invoiceWord}`
    : `${size(paymentData.paymentInvoices)} ${invoiceWord} Selected`;

  const isRecurrence = size(paymentData.recurrence);

  return (
    <div className="mb-3">
      <div className="d-flex align-items-center p-3" hidden={isPrepaid}>
        <div className="flex-grow-1 hui-text-lg fw-bold mb-0">{title}</div>
        <div hidden={preview}>
          <button onClick={edit} className="hui-btn hui-btn-sm">
            Edit
          </button>
        </div>
      </div>

      <HuiPaymentInvoices payment={paymentData} edit={edit} />

      <HuiPaymentInfo payment={paymentData} />

      {!!isRecurrence && <HuiRecurrenceInfo payment={paymentData} />}

      <HuiPaymentSummary payment={paymentData} />
    </div>
  );
};

export default HuiConfirmPaymentInvoices;
