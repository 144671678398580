import * as currencyFormatter from 'currency-formatter';
import { Link } from 'react-router-dom';

const HuiTableProjectPay = (props) => {
  const { data, daysPastDue, total, count, hasProject } = props;
  const { id: projectId } = data;

  const invoiceWord = count > 1 ? 'Invoices' : 'Invoice';
  const valueFormatted = currencyFormatter.format(total, { code: 'USD' });
  const textClass = count > 0 ? 'hui-color-black ' : 'hui-color-gray';

  const url = new URL('/pay', document.baseURI);

  if (projectId) {
    url.searchParams.append('projectId', projectId);
  } else {
    url.searchParams.append('hasProject', hasProject);
  }
  if (daysPastDue) {
    url.searchParams.append('daysPastDue', daysPastDue);
  }

  const payLink = url.pathname + url.search;

  return (
    <div className="rgt-cell-inner text-truncate hui-text-sm d-flex align-items-center">
      <div>
        <div className={`${textClass} fw-bold text-decoration-none px-1 pb-1 `}>{valueFormatted}</div>
        <div className="hui-color-default px-1 pb-2" hidden={count <= 0}>
          {count} {invoiceWord}
        </div>
      </div>
      <Link to={payLink} className="hui-btn hui-btn-rounded hui-btn-green-light" hidden={count <= 0}>
        Pay
      </Link>
    </div>
  );
};

export default HuiTableProjectPay;
