import currencyFormatter from 'currency-formatter';
import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectInvoicesPayment } from '../../../../redux/InvoicesPaymentSlice';
import { selectPaymentData } from '../../../../redux/PaymentDataSlice';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';

const getMinimumPayment = {
  ACH: 'settings.minAchPayment',
  CREDIT_CARD: 'settings.minCreditCardPayment',
};

const HuiSelectPaymentType = (props) => {
  const portalInfo = useSelector(selectPortalInfo);
  const payment = useSelector(selectInvoicesPayment);
  const paymentData = useSelector(selectPaymentData);

  const { subtotal = 0, total = 0 } = paymentData;

  const getPaymentMethodEnabled = {
    ACH: 'settings.isAchEnabled',
    CREDIT_CARD: 'settings.isCreditCardEnabled',
  };

  const { icon, type, title, description, buttonLabel, onClick, disabled, hidden, isPayWithBalanceEnabled, active } =
    props;

  const minimumPaymentType = getMinimumPayment[type] || '';
  const minimumPaymentSubtotal = get(portalInfo, minimumPaymentType);
  const minimumPaymentSubtotalFormatted = currencyFormatter.format(minimumPaymentSubtotal, {
    code: 'USD',
  });

  const isMinimumPayment = minimumPaymentSubtotal > total && subtotal > 0;
  const hasPaymentSubtotal = minimumPaymentSubtotal > 0;

  const paymentMethodEnabledType = getPaymentMethodEnabled[type] || '';
  const showPaymentMethodEnabled = get(portalInfo, paymentMethodEnabledType) || isPayWithBalanceEnabled || !disabled;

  const isPaymentMethodActive = !isMinimumPayment && payment.paymentType === type;

  const showMinimumPaymentAlert = isMinimumPayment ? 'hui-select-payment-minimum-alert' : '';
  const isPaymentMethodDisabled =
    !showPaymentMethodEnabled || disabled ? 'hui-select-payment-disabled' : 'hui-select-payment-type';
  const paymentMethodActiveClass = isPaymentMethodActive && !disabled ? 'hui-select-payment-active' : '';
  const isPaymentMethodNotSelected =
    showPaymentMethodEnabled && !isPaymentMethodActive && !isPaymentMethodActive && !isMinimumPayment && !disabled
      ? `hui-select-payment-not-selected`
      : ``;

  const selectPaymentClass = `d-flex flex-column justify-content-center py-4 py-xl-5 px-4 hui-select-payment-option hui-select-payment-${type} ${isPaymentMethodDisabled} ${isPaymentMethodNotSelected} ${showMinimumPaymentAlert} ${paymentMethodActiveClass}`;

  const showMinimumPayment = hasPaymentSubtotal && isMinimumPayment && showPaymentMethodEnabled;

  const btnClass = `hui-btn hui-btn-lg hui-btn-outline hui-btn-green hui-btn w-100 hui-color-white`;

  return (
    <div className={selectPaymentClass} hidden={!showPaymentMethodEnabled || hidden}>
      <div className="hui-select-payment-icon mx-auto mb-3">{icon}</div>
      <div className="hui-title text-center mb-4">{title}</div>
      <div className="text-center mb-4">{description}</div>
      <div>
        <button
          disabled={!showPaymentMethodEnabled || isMinimumPayment || disabled}
          onClick={onClick}
          className={btnClass}
        >
          {buttonLabel}
        </button>

        <div
          hidden={!showMinimumPayment}
          className="d-flex hui-text-sm align-items-center justify-content-center mt-2 hui-color-red"
        >
          <div className="d-flex me-2">
            <i className="hui-i hui-svg-toastr-error"></i>
          </div>
          <div>Minimum payment total is {minimumPaymentSubtotalFormatted}</div>
        </div>
      </div>
    </div>
  );
};

export default HuiSelectPaymentType;
