import { forEach, isNil, size } from "lodash";

export const getAddressFromGoogleMap = async (text) => {
  if (!text) {
    throw new Error('Need valid text input');
  }

  if (isNil(window)) {
    throw new Error('Need valid window object');
  }

  return getAddressDetails(text);
};

function getPredictions(text) {
  return new Promise((resolve, reject) => {
    if (!text) {
      return reject('Need valid text input');
    }

    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: text, componentRestrictions: { country: 'us' } },
      (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(predictions);
      },
    );
  });
}

function getPlaceDetails(placeId) {
  const div = document.createElement('div');

  return new Promise((resolve, reject) => {
    const service = new window.google.maps.places.PlacesService(div);
    service.getDetails({ placeId, fields: ['address_components', 'formatted_address'] }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        resolve({ place, div });
      } else {
        div.remove();
        reject(status);
      }
    });
  });
}

async function getAddressDetails(inputText) {
  try {
    const predictions = await getPredictions(inputText);
    if (size(predictions)) {
      const { place, div } = await getPlaceDetails(predictions[0].place_id);
      div.remove();
      return handleGooglePlaceComponent(place);
    }
  } catch (error) {
    console.error(error);
  }
}

function handleGooglePlaceComponent(place) {
  const map = {
    street_number: 'streetNumber',
    route: 'streetName',
    locality: 'city',
    administrative_area_level_1: 'state',
    postal_code: 'postalCode',
    country: 'country',
    neighborhood: 'neighborhood',
  };
  const addressComponents = place.address_components;
  const brokenDownAddress = { address: place.formatted_address };
  forEach(addressComponents, (component) => {
    if (!map[component.types[0]]) return;
    const fieldName = map[component.types[0]];
    const fieldSize = fieldName === 'country' || fieldName === 'state' ? 'short_name' : 'long_name';
    brokenDownAddress[fieldName] = component[fieldSize];
  });
  return brokenDownAddress;
}
