import { HuiLoading } from 'handle-ui';
import { useSelector } from 'react-redux';
import { selectInvoicesPayment } from '../../../../redux/InvoicesPaymentSlice';
import { selectLoading } from '../../../../redux/LoadingSlice';
import HuiPaymentAch from './HuiPaymentAch';
import HuiPaymentCard from './HuiPaymentCard';
import HuiPaymentWithCredits from './HuiPaymentWithCredits';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';

const getPaymentTitle = {
  [UtilPaymentMethods.CREDIT]: 'Pay with Credit',
  [UtilPaymentMethods.CREDIT_CARD]: 'Credit or Debit Card Info',
  [UtilPaymentMethods.ACH]: UtilPaymentMethods.ACH,
};

const getPaymentForm = {
  [UtilPaymentMethods.CREDIT]: <HuiPaymentWithCredits />,
  [UtilPaymentMethods.CREDIT_CARD]: <HuiPaymentCard />,
  [UtilPaymentMethods.ACH]: <HuiPaymentAch />,
};

const Loading = () => {
  const loading = useSelector(selectLoading);
  return loading ? <HuiLoading className="hui-payment-form-loading" isOpen /> : null;
};

const HuiPaymentForm = () => {
  const payment = useSelector(selectInvoicesPayment);

  const title = getPaymentTitle[payment.paymentType] || '';
  const paymentForm = getPaymentForm[payment.paymentType] || '';

  return (
    <div className="payment-form">
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 hui-title">{title}</div>
        <div className="d-flex">
          <img src="/images/svg/secure.svg" alt="secure" />
        </div>
      </div>
      <Loading />
      {paymentForm}
    </div>
  );
};

export default HuiPaymentForm;
