import { Divider } from '@mui/material';
import { HuiCard, HuiCardContent } from 'handle-ui';
import * as React from 'react';
import ButtonDownload from '../../buttons/ButtonDownload';
import ButtonEmail from '../../buttons/ButtonEmail';
import ButtonPrint from '../../buttons/ButtonPrint';
import ButtonViewInvoice from '../../buttons/ButtonViewInvoice';
import SidebarHeader from './SidebarHeader';
import SidebarInvoiceAddresses from './SidebarInvoiceAddresses';
import SidebarInvoiceBalance from './SidebarInvoiceBalance';
import SidebarInvoiceEvents from './SidebarInvoiceEvents';
import SidebarInvoiceInfo from './SidebarInvoiceInfo';

const SidebarInvoice = (props) => {
  const { invoice } = props || {};
  const { invoiceNumber, pdfLink, id } = invoice || {};

  return (
    <>
      <SidebarHeader>
        <div className="flex-grow-1">
          <h2 className="hui-title mb-0">Invoice {invoiceNumber}</h2>
        </div>
        <div className="d-flex align-items-center">
          <ButtonViewInvoice id={id} className="hui-btn hui-btn-green me-2" link={pdfLink} hidden={!pdfLink}>
            View Invoice
          </ButtonViewInvoice>
          <ButtonDownload id={id} type="invoice" className="hui-btn" hidden={!!pdfLink}>
            Download
          </ButtonDownload>
          <ButtonPrint id={id} type="invoice" className="hui-btn ms-2" hidden={!!pdfLink}>
            Print
          </ButtonPrint>
          <ButtonEmail id={id} type="invoice" className="hui-btn ms-2">
            Email
          </ButtonEmail>
        </div>
      </SidebarHeader>

      <div className="hui-bg-gray-light p-4">
        <HuiCard boxShadow border>
          <HuiCardContent>
            <SidebarInvoiceInfo />
            <Divider className="my-1" />
            <SidebarInvoiceAddresses />
            <Divider className="my-1" />
            <SidebarInvoiceBalance />
          </HuiCardContent>
        </HuiCard>

        <SidebarInvoiceEvents />
      </div>
    </>
  );
};

export default SidebarInvoice;
