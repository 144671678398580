import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const HuiCheckboxIcon = styled('span')(({ theme }) => ({
  backgroundColor: 'white',
  borderColor: '#E1E1E1',
  borderWidth: 1,
  borderStyle: 'solid',
  '&[font-size="large"]': {
    width: 32,
    height: 32,
    borderRadius: 7,
  },
  '&[font-size="medium"]': {
    width: 26,
    height: 26,
    borderRadius: 5,
  },
  '&[font-size="small"]': {
    width: 22,
    height: 22,
    borderRadius: 4,
  },
  '.Mui-focusVisible &': {
    outline: '2px auto #367ef9',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    borderColor: '#202020',
  },
  'input:disabled ~ &': {
    borderColor: '#E1E1E1',
    background: '#F7F7F8',
  },
  'input:disabled:hover ~ &': {
    borderColor: '#E1E1E1',
    background: '#F7F7F8',
  },
}));

const HuiCheckedIcon = styled(HuiCheckboxIcon)({
  backgroundColor: 'white',
  borderColor: '#E1E1E1',
  borderWidth: 1,
  borderStyle: 'solid',
  '&:before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23202020'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    borderColor: '#202020',
  },
});

const HuiCheckboxField = (props) => {
  const { label } = props;

  return (
    <Checkbox
      {...props}
      inputProps={{ 'aria-label': label }}
      disableRipple
      checkedIcon={<HuiCheckedIcon />}
      icon={<HuiCheckboxIcon />}
    />
  );
};
export default HuiCheckboxField;
