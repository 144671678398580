import * as React from 'react';
import { useDispatch } from 'react-redux';
import { HuiIcon } from 'handle-ui';
import { Drawer } from '@mui/material';
import { removeSelectedInvoiceClass } from '../../../utils/utilsTable';
import { resetCredits } from '../../../redux/CreditDataSlice';
import { closeSidebar } from '../../../redux/SidebarSlice';

const SidebarControl = (props) => {
  const { button, isOpen, icon, className, forceCloseSidebar } = props;
  const dispatch = useDispatch();
  const sidebarCloseClassName = className || `ms-2`;

  return button ? (
    <div className={sidebarCloseClassName} role="button" onClick={close}>
      {icon ? <HuiIcon name="x" size="sm" /> : props.children}
    </div>
  ) : (
    <Drawer anchor="right" open={isOpen} onClose={close}>
      {props.children}
    </Drawer>
  );

  async function close() {
    if (!!forceCloseSidebar) {
      return forceCloseSidebar();
    }
    removeSelectedInvoiceClass();
    await dispatch(closeSidebar({}));
    await dispatch(resetCredits({}));
  }
};

export default SidebarControl;
