import axios from 'axios';
import { store } from '../store/store';
import { closeLoading, openLoading } from "../redux/LoadingSlice";
import { updateAuth } from '../redux/AuthSlice';

export const API_URL = process.env.REACT_APP_PAY_API_URL;
export const APP_URL = process.env.REACT_APP_MAIN_API_URL;

let cachedAdminToken;
let cachedAccountId;

export function getAxiosForSupplierPage() {
  const tokenPromise = new Promise((resolve, reject) => {
    window.addEventListener('message', (event) => {
      const { accountId, adminToken } = event.data || {};
      // TODO: Add REACT_APP_HOST_URL that points to the main app's url
      // if (event.origin !== APP_URL) {
      //   return;
      // }
      if (adminToken && accountId) {
        if (!cachedAdminToken) {
          resolve(event.data);
        }
        cachedAdminToken = adminToken;
        cachedAccountId = accountId;
        store.dispatch(updateAuth({adminToken, accountId}));
        console.log(`Got token for accountId ${accountId}`);
      }
    });
    setTimeout(reject, 5000);
  });

  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: API_URL + '/',
  });
  
  const axiosUnauthenticated = axios.create({
    withCredentials: true,
    baseURL: API_URL + '/',
  });

  axiosInstance.interceptors.request.use(async function(config) {
    store.dispatch(openLoading());
    await tokenPromise;
    config.headers.Authorization = `Bearer ${cachedAdminToken}`;
    config.headers['Account-Id'] = cachedAccountId;
    const impersonate = getImpersonate();
    if (impersonate) {
      config.headers['impersonate'] = impersonate;
    }
    const [urlParts, ...urlRest] = config.url.split('?');
    config.url = [`${urlParts}/admin`, ...urlRest].join('?');
    return config;
  });

  function removeLoading() {
    store.dispatch(closeLoading());
  }

  axiosInstance.interceptors.response.use(function(response) {
    removeLoading();
    return response;
  }, function(error) {
    removeLoading();
    return Promise.reject(error);
  });

  return {
    axiosInstance,
    axiosUnauthenticated,
  };
}

function getImpersonate() {
  try {
    const loginAs = localStorage.getItem('loginAs');
    return loginAs === 'none' ? undefined : loginAs;
  } catch (error) {
    return;    
  }
}