import React from 'react';
import { downloadInvoice } from '../../actions/invoices/downloadInvoice';

const ButtonViewInvoice = (props) => {
  const { id, className, disabled = false, link, hidden } = props || {};

  return (
    !hidden && (
      <>
        <a href={link} target="_blank" onClick={handleViewInvoice} className={className} disabled={disabled}>
          {props.children}
        </a>
      </>
    )
  );

  async function handleViewInvoice() {
    try {
      await downloadInvoice(id, false, true);
    } catch (error) {
      console.error(error);
    }
  }
};

export default ButtonViewInvoice;
