import { HuiIcon, HuiLoading, HuiPanel } from 'handle-ui';
import { isNil, size } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectLoading } from '../../../redux/LoadingSlice';
import DashboardScheduledItem from './DashboardScheduledItem';
import EmptyComponent from '../../../components/general/EmptyComponent';
import { getPaymentsHistory } from '../../../actions/payment/getPaymentsHistory';

const DashboardScheduled = (props) => {
  const loading = useSelector(selectLoading);
  const [payments, setPayments] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function findPayments() {
      if (isNil(payments) && !loading) {
        try {
          const result = await getPaymentsHistory({ size: 5, paymentStatus: 'SCHEDULED' });
          setPayments(result?.rows);
          setIsLoading(!size(result));
        } catch (error) {
          console.error(error);
          setPayments(['error']);
        }
      }
    }

    findPayments();
  }, [loading, payments, setPayments]);

  const scheduledPayments = payments?.map((payment, i) => {
    return <DashboardScheduledItem key={i} payment={payment} />;
  });

  const isEmpty = size(payments) === 0;

  const createPayment = (
    <EmptyComponent
      className="pb-3"
      title="Schedule a Payment"
      subtitle="Set Payment Schedules in Seconds!"
      buttonTitle="Schedule a Payment"
      link="/pay"
    />
  );

  return (
    <>
      <HuiPanel className="overflow-hidden position-relative px-3 pt-3 hui-text-lg d-flex flex-column flex-grow-1">
        <div className="hui-text-lg fw-bold mb-3 d-flex align-items-center">
          <div className="d-flex me-2">
            <HuiIcon name="calendar" size="sm" />
          </div>
          Scheduled Payments
        </div>
        {isLoading ? (
          <div className="d-flex align-items-center flex-grow-1 justify-content-center pb-4">
            <HuiLoading size="sm" fixed isOpen />
          </div>
        ) : isEmpty ? (
          createPayment
        ) : (
          scheduledPayments
        )}
      </HuiPanel>
    </>
  );
};

export default DashboardScheduled;
