import { isBoolean, isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import toastr from 'toastr';
import NavPanel from '../../../components/layout/nav/NavPanel';
import PortalCopy from '../../../components/portal/PortalCopy';
import PortalLogo from '../../../components/portal/PortalLogo';
import { selectAuth } from '../../../redux/AuthSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import SignUpForm from './SignUpForm';

const SignUp = () => {
  const navigate = useNavigate();
  const portalInfo = useSelector(selectPortalInfo);
  const auth = useSelector(selectAuth);
  const { name, settings } = portalInfo || {};
  const { isPortalSignUpEnabled } = settings || {};

  useEffect(() => {
    if (isPortalSignUpEnabled === false) {
      toastr.warning(`New sign-ups are disabled.`);
      navigate('/login');
    }
  }, [isPortalSignUpEnabled, navigate]);

  return (
    <>
      <div className="hui-main d-flex pb-0">
        <NavPanel position="left">
          <div className="d-flex flex-column p-5 my-auto w-100">
            <div className="mb-2 text-center mx-auto hui-portal-logo">
              <Link to="/login" title={name} className="hui-portal-link">
                <PortalLogo noName />
              </Link>
            </div>

            <div hidden={portalInfo.logoUrl}>
              <h2 className="hui-title text-center mb-4">{name}</h2>
            </div>

            <div className="mb-3 text-center">
              <Link to="/logout?redirect=signup" hidden={isEmpty(auth?.email)}>
                Use a different account
              </Link>
              <span hidden={isEmpty(auth?.email)}> or </span>
              <span hidden={!isEmpty(auth?.email)}>Already a customer? </span>
              <Link to="/logout">Click here to login</Link>
            </div>

            <SignUpForm settings={settings} />
          </div>
        </NavPanel>

        <PortalCopy />
      </div>
    </>
  );
};

export default SignUp;
