import { HuiIcon, HuiPanel, HuiPanelContent } from 'handle-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';

const PortalCopy = (props) => {
  const { isLoadingSSO } = props;

  const portalInfo = useSelector(selectPortalInfo);

  const { settings } = portalInfo || {};
  const { isWaiverOnly, isWaiverEnabled } = settings || {};

  const isLoaded = portalInfo && settings;

  const headlineCopy = isWaiverOnly
    ? 'Waiver Request Exchange Portal'
    : isWaiverEnabled
    ? 'Waiver and Payment Exchange Portal'
    : 'Payment Exchange Portal';

  const waiversCopy = isWaiverEnabled ? ['Track Status of Waivers', 'Request Waivers'] : [];

  const waiverOnlyCopy = isWaiverOnly && isWaiverEnabled ? ['View Executed Waiver Documents'] : [];

  const dashboardCopy = !isWaiverOnly && !isWaiverEnabled ? ['Interactive Dashboard'] : [];

  const paymentCopy = !isWaiverOnly ? ['View Invoice Details', 'Make Payments'] : [];

  const features = [...waiverOnlyCopy, ...waiversCopy, ...paymentCopy, ...dashboardCopy];

  const featuresList = features.map((feature, i) => (
    <li key={i} className="hui-portal-features-item mb-3 d-flex align-items-center">
      <div className="d-flex me-2">
        <HuiIcon name="check" size="xs" color="#1BA548" />
      </div>
      {feature}
    </li>
  ));

  const containerClass = `d-flex flex-grow-1 w-100 align-items-center justify-content-center hui-portal-copy-bg`;

  return (
    isLoaded && (
      <div hidden={isLoadingSSO} className={containerClass}>
        <HuiPanel className="hui-portal-copy position-relative overflow-visible" boxShadow>
          <HuiPanelContent className="p-0">
            <div className="px-5 pt-5">
              <h1 className="hui-title-lg mb-4">{headlineCopy}</h1>

              <ul className="hui-portal-features mb-5">{featuresList}</ul>
            </div>
            <div className="hui-portal-copy-laptop mt-4 d-none d-xl-block">
              <svg height="262" viewBox="0 0 309 262" width="309" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd" transform="translate(-.003011 .5)">
                  <g fillRule="nonzero">
                    <path
                      d="m182.049796 203.702221s5.403255 37.398815 37.130336 49.819312l-64.193252.090345-64.1932531.090343c31.6732641-12.510841 36.9975871-49.924111 36.9975871-49.924111z"
                      fill="#e0e0e0"
                    />
                    <path
                      d="m91.5897741 253.590905 126.4849749.000727c1.325483.00001 2.400005 1.068677 2.400005 2.386944v.000004 3.043595c0 1.318266-1.074463 2.38692-2.399945 2.38692l-126.4849751-.000727c-1.3254834-.00001-2.4000657-1.068675-2.4000657-2.386944 0-.000001 0-.000003 0-.000004v-3.043595c0-1.318266 1.0745242-2.38692 2.4000059-2.38692z"
                      fill="#000"
                      fillOpacity=".1"
                      transform="matrix(.99999903 -.00139626 .00139626 .99999903 -.359387 .216438)"
                    />
                    <path
                      d="m91.4273699 254.38627 126.4839381.000726c.883656.000007 1.599997.712451 1.599997 1.591297v.000002 2.850385c0 .878845-.716296 1.591281-1.599951 1.591281l-126.483938-.000726c-.8836556-.000007-1.6000565-.712451-1.6000565-1.591296 0-.000001 0-.000002 0-.000003v-2.850385c0-.878845.716356-1.591281 1.6000104-1.591281z"
                      fill="#f5f5f5"
                      transform="matrix(.99999903 -.00139626 .00139626 .99999903 -.359252 .21621)"
                    />
                    <path
                      d="m302.815057 0-296.86217023.41388874c-1.6155796.03750346-3.15006126.71654261-4.26565175 1.88763709-1.11559048 1.17109449-1.72083879 2.73824334-1.68723502 4.35646212l.23428672 173.48580705v5.779113c0 13.193343 10.20267638 23.852255 22.80298158 23.836974l263.2290477-.36284c12.574799 0 22.75707-10.730448 22.736756-23.898242v-5.748455l-.229652-173.48580684c.036946-1.61821029-.56961-3.184803-1.686151-4.35493222s-2.651541-1.84787808-4.26711-1.88405725z"
                      fill="#000"
                      fillOpacity=".051"
                    />
                    <path
                      d="m9.89676872 3.20770323h289.20738828c3.256416 0 5.896263 2.72614681 5.896263 6.08901932v170.03994345h-300.99481852v-170.04520621c0-3.36287252 2.63984705-6.08901933 5.8962634-6.08901933z"
                      fill="#fff"
                    />
                    <path
                      d="m284.01508 206.197039-258.973223.360403c-11.601947.014161-21.0193803-9.473466-21.03625552-21.193116v-7.20677l300.99486252-.42211v7.20677c.008027 5.62894-2.197823 11.030589-6.132375 15.016643s-9.275507 6.229995-14.847913 6.23818z"
                      fill="#f5f5f5"
                    />
                    <rect
                      fill="#fff"
                      height="144.109821"
                      rx="4"
                      stroke="#e0e0e0"
                      strokeWidth=".8"
                      width="272.176166"
                      x="18.414928"
                      y="19.214643"
                    />
                    <circle cx="33.003011" cy="31" fill="#1ba548" r="7" id="portal-copy-image-logo" />
                    <rect
                      fill="#fff"
                      height="11.208542"
                      rx="3.2"
                      stroke="#ededed"
                      strokeWidth=".8"
                      width="52.834197"
                      x="202.534099"
                      y="49.637827"
                    />
                    <rect
                      fill="#fff"
                      height="11.208542"
                      rx="3.2"
                      stroke="#ededed"
                      strokeWidth=".8"
                      width="17.611399"
                      x="263.373477"
                      y="49.637827"
                    />
                  </g>
                  <path d="m18.815187 41.231421h271.375648" stroke="#e0e0e0" strokeLinecap="square" strokeWidth=".8" />
                  <path d="m84.457674 41.231421v122.493348" stroke="#e0e0e0" strokeLinecap="square" strokeWidth=".8" />
                  <path
                    d="m95.264669 54.92773 39.625648-.085937"
                    stroke="#e4e5eb"
                    id="portal-copy-image-title"
                    strokeLinecap="round"
                    strokeWidth="3"
                  />
                  <path
                    d="m252.566483 30.022879h28.018134"
                    stroke="#e4e5eb"
                    strokeLinecap="round"
                    strokeWidth="1.9404"
                  />
                  <rect
                    fill="#f6f7f9"
                    fillRule="nonzero"
                    height="13.410982"
                    rx="6.705491"
                    width="189.613636"
                    x="91.989822"
                    y="66.45064"
                  />
                  <rect
                    fill="#f6f7f9"
                    fillRule="nonzero"
                    height="13.410982"
                    rx="6.705491"
                    width="189.613636"
                    x="91.989822"
                    y="85.665282"
                  />
                  <rect
                    fill="#f6f7f9"
                    fillRule="nonzero"
                    height="13.410982"
                    rx="6.705491"
                    width="189.613636"
                    x="91.989822"
                    y="104.879925"
                  />
                  <rect
                    fill="#f6f7f9"
                    fillRule="nonzero"
                    height="13.410982"
                    rx="6.705491"
                    width="189.613636"
                    x="91.989822"
                    y="124.094568"
                  />
                  <rect
                    fill="#f6f7f9"
                    fillRule="nonzero"
                    height="13.410982"
                    rx="6.705491"
                    width="189.613636"
                    x="91.989822"
                    y="143.309211"
                  />
                </g>
              </svg>
            </div>

            {!isWaiverOnly && (
              <div className="hui-portal-copy-payment d-none d-xl-block">
                <img src="/images/svg/login-payment.svg" alt="Make a Payment" />
              </div>
            )}

            {isWaiverOnly && (
              <div className="hui-portal-copy-payment d-none d-xl-block">
                <img src="/images/svg/login-waiver-file.svg" alt="Make a Payment" />
              </div>
            )}

            {!isWaiverEnabled && (
              <div className="hui-portal-copy-waiver d-none d-xl-block">
                <img src="/images/svg/login-payment-only.svg" alt="Request a Waiver" />
              </div>
            )}

            {isWaiverEnabled && !isWaiverOnly && (
              <div className="hui-portal-copy-waiver d-none d-xl-block">
                <img src="/images/svg/login-waiver.svg" alt="Request a Waiver" />
              </div>
            )}

            {isWaiverEnabled && isWaiverOnly && (
              <div className="hui-portal-copy-waiver d-none d-xl-block">
                <img src="/images/svg/login-waiver-only.svg" alt="Request a Waiver" />
              </div>
            )}
          </HuiPanelContent>
        </HuiPanel>
      </div>
    )
  );
};

export default PortalCopy;
