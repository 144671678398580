import React from 'react';

const HuiCardContent = (props) => {
  const { className } = props;
  const huiBoxClass = `hui-card-content ${className}`;

  return <div className={huiBoxClass}>{props.children}</div>;
};

export default HuiCardContent;
