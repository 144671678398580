import React, { useEffect } from 'react';
import PortalLogo from '../../portal/PortalLogo';
import { useDispatch, useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { closeModal } from '../../../redux/ModalSlice';

const ModalConfirmEmail = (props) => {
  const { modal } = props;
  const { confirmAction } = modal;
  const dispatch = useDispatch();
  const portalInfo = useSelector(selectPortalInfo);

  const { name } = portalInfo || {};

  useEffect(() => {
    return () => {
      dispatch(closeModal({}));
    };
  }, [dispatch]);

  return (
    <div className="row">
      <div className="d-flex flex-column col-xl-3 col-lg-4 col-md-6 col-sm-8 col-12 mx-auto">
        <div className="p-3 border-bottom mb-4">
          <div className="hui-header-logo header-logo-lg">
            <PortalLogo />
          </div>
        </div>

        <div className="mb-3 d-flex">
          <div className="d-flex me-2">
            <i className="hui-i hui-svg-toastr-success"></i>
          </div>
          <h3 className="hui-subtitle mb-0">Thanks for verifying your email address!</h3>
        </div>

        <div className="mb-4">
          You account is now verified. You can now log in to your account or wait for the approval by your {name}{' '}
          account manager. You will receive an email once your account is approved.
          <br />
          <br />
          If you have any questions, please contact the {name} support.
        </div>

        <button onClick={confirmAction} className="hui-btn hui-btn-xl hui-btn-green">
          Login to Payment Portal
        </button>
      </div>
    </div>
  );
};

export default ModalConfirmEmail;
