import { axiosInstance } from '../../utils/utilsAxios';

export async function getUnpaidInvoices(params) {
  const url = `v1/invoices/unpaid/all?isPaid=false&type=INVOICE`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url, { params });
    const invoices = { ...response.data || {}, isLoading: false };
    return invoices;
  } catch (e) {
    throw new Error(e.message);
  }
}
