import { axiosInstance } from '../../utils/utilsAxios';

export async function getPaymentRequestByToken(token) {
  const url = `v1/payment-requests/${token}`;

  try {
    const response = await axiosInstance.get(url);
    const payment = response.data;
    return payment;
  } catch (e) {
    throw e.response.data;
  }
}
