import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import React from 'react';

const HuiAutocomplete = (props) => {
  const {
    className,
    id,
    name,
    freeSolo,
    label,
    options,
    value,
    onChange,
    inputValue,
    onInputChange,
    size = 'small',
    topLabel,
    required,
    hideRequired,
    getOptionLabel,
    renderOption,
    filterOptions,
    isOptionEqualToValue,
    placeholder = 'Select an option',
  } = props;
  const huiInputClass = `${className || 'mb-3'}`;
  const showRequired = hideRequired ? false : required;
  return (
    <>
      <FormControl className={huiInputClass} required={showRequired}>
        {!!topLabel && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <Autocomplete
          id={id}
          autoComplete={true}
          includeInputInList={true}
          name={name}
          label={topLabel ? '' : label}
          options={options}
          value={value}
          freeSolo={freeSolo}
          getOptionLabel={getOptionLabel}
          onChange={onChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          renderInput={renderInput}
          isOptionEqualToValue={isOptionEqualToValue}
          renderOption={renderOption}
          filterOptions={filterOptions}
        />
      </FormControl>
    </>
  );

  function renderInput(params) {
    return (
      <TextField
        {...params}
        placeholder={placeholder}
        required={required}
        error={false}
        size={size}
      />
    );
  }
};
export default HuiAutocomplete;
