import { axiosInstance } from '../../utils/utilsAxios';

export async function signInHandle() {
  const url = `/v1/auth/login`;

  try {
    await axiosInstance.post(url);
    return { isLogged: true };
  } catch (e) {
    const { response: { data: { message } } } = e;
    if (message === 'Email not verified') {
      return { isEmailVerified: false };
    } else if(message === 'Invitation is awaiting approval'){
      return { isInviteApproved: false };
    } else if (message === 'User not found'){
      return { userNotFound: true };
    } else if (message === 'Error trying login using SSO' || message === 'No customer account was found!') {
      return { noCustomerAccount: true };
    } else {
      console.log(`API Error Sign In: ${message}`);
      throw new Error(e.message);
    }
  }
}


