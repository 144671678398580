import { axiosInstance } from '../../utils/utilsAxios';

export async function getCardOnFile(paymentType) {
  const params = { paymentType };
  
  if (paymentType === 'ALL') {
    delete params.paymentType;
  }

  const url = `/v1/card-on-file/`;

  try {
    const response = await axiosInstance.get(url, { params });
    const cardOnFileList = response.data;
    return cardOnFileList;
  } catch (e) {
    throw new Error(e.message);
  }
}
