import { HuiTabs, HuiTabsContent, HuiTabsItem } from 'handle-ui';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PaymentMethods from './payment-methods/PaymentMethods';
import Profile from './profile/Profile';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';


const Settings = () => {
  const location = useLocation();
  const [tab, setTab] = useState('');

  useEffect(() => {
    const activeTab = location.pathname.replace('/settings/', '');
    setTab(activeTab);
  }, [setTab, location]);

  return (
    <div className="p-4 hui-dashboard flex-grow-1 overflow-hidden">
      <h1 className="hui-title mb-2">Settings</h1>
      <HuiTabs>
        <HuiTabsItem className={tab === 'profile' ? 'active' : ''} link="/settings/profile">
          <Link to="/settings/profile">Profile</Link>
        </HuiTabsItem>

        <HuiTabsItem
          className={tab === 'payment-methods' ? 'active' : ''}
          link="/settings/payment-methods"
        >
          <Link to={`/settings/payment-methods?paymentType=ALL`}>Payment Methods</Link>
        </HuiTabsItem>
      </HuiTabs>

      <HuiTabsContent>
        {tab === 'profile' && <Profile />}

        {tab === 'payment-methods' && <PaymentMethods />}
      </HuiTabsContent>
    </div>
  );
};

export default Settings;
