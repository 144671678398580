import Tooltip from '@mui/material/Tooltip';
import { HuiInput } from 'handle-ui';
import { isNil } from 'lodash';
import React from 'react';
import NumberFormat from 'react-number-format';

const AmountFormatter = React.forwardRef(function AmountFormatter(props, ref) {
  const {
    onChange,
    inputRef,
    prefix,
    decimalSeparator = '.',
    thousandSeparator = ',',
    isNumericString = true,
    min,
    max,
    allowNegative = true,
    decimalScale,
    defaultValue,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value || '',
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString={isNumericString}
      prefix={prefix}
      allowNegative={allowNegative}
      decimalScale={decimalScale}
    />
  );
});

const isAllowed = (value, min, max) => {
  if (isNil(value)) return true;

  const isValidMinimumAmount = !isNaN(min) && !isNil(min);
  const isValidMaximumAmount = !isNaN(max) && !isNil(max);

  if (isValidMinimumAmount && isValidMaximumAmount) {
    return value >= min && value <= max;
  }
  if (isValidMinimumAmount) {
    return value >= min;
  }
  if (isValidMaximumAmount) {
    return value <= max;
  }
  return true;
};

const DECIMAL_SCALE = 2;
const DECIMAL_SEPARATOR = '.';

class HuiInputAmount extends React.PureComponent {
  state = {
    value: undefined,
    min: undefined,
    max: undefined,
  };

  render() {
    const {
      label,
      className,
      placeholder,
      id,
      name,
      prefix,
      variant,
      disabled,
      isNumericString,
      decimalSeparator = DECIMAL_SEPARATOR,
      thousandSeparator,
      topLabel,
      noLabel,
      size = 'small',
      required,
      min,
      max,
      allowKeyDown = true,
      allowNegative,
      decimalScale = DECIMAL_SCALE,
      onClickHelperTextDisabled = false,
      descriptionHelperText = 'Apply this value',
      customHelperTextComponent,
      defaultValue,
      inputClassName,
    } = this.props;
    const { value } = this.state;

    const _value = isNil(value) ? '' : parseFloat(value).toFixed(decimalScale);
    const error = this.getError();
    const errorMsg = this.getErrorMsg();
    const helperTextComponent =
      customHelperTextComponent ||
      (errorMsg ? (
        <Tooltip title={onClickHelperTextDisabled ? '' : descriptionHelperText}>
          <span
            onClick={
              onClickHelperTextDisabled ? () => {
              } : this.onClickHelperText
            }
            style={{
              cursor: onClickHelperTextDisabled ? 'auto' : 'pointer',
              fontSize: '12px',
            }}
          >
            {errorMsg}
          </span>
        </Tooltip>
      ) : (
        ''
      ));

    return (
      <>
        <HuiInput
          label={noLabel ? '' : label}
          topLabel={topLabel}
          size={size}
          error={error}
          helperText={helperTextComponent}
          disabled={disabled}
          inputClassName={inputClassName}
          id={id}
          variant={variant}
          name={name}
          value={_value}
          className={className}
          onChange={this.handleOnChange}
          placeholder={placeholder}
          onBlur={this.handleOnBlur}
          onKeyDown={allowKeyDown ? this.handleOnKeyDown : () => {
          }}
          decimalScale={decimalScale}
          allowKeyDown={allowKeyDown}
          required={required}
          InputProps={{
            prefix,
            inputComponent: AmountFormatter,
            inputProps: {
              pattern: '^\\$\\d{1,3}(,\\d{3})*(\\.\\d+)?$',
              prefix,
              decimalSeparator,
              thousandSeparator,
              isNumericString,
              min,
              max,
              decimalScale,
              allowNegative,
              defaultValue,
            },
          }}
        />
      </>
    );
  }

  validateValue = (floatValue) => {
    const { min, max } = this.state;
    this.setState({ value: floatValue, isAllowedValue: false });
    if (isAllowed(floatValue, min, max) && !isNil(floatValue)) {
      const event = this.getEvent(floatValue, false);
      this.setState({
        event,
        value: floatValue,
        isAllowedValue: true,
        error: false,
        helperText: undefined,
      });
      return true;
    }

    const { defaultValue, onChange } = this.props;
    if (isNil(floatValue) && !isNil(defaultValue) && isAllowed(defaultValue, min, max)) {
      const event = this.getEvent(defaultValue, false);
      onChange?.(event);
      return;
    }

    this.validateError(floatValue, min, max);
  };

  validateError(floatValue, min, max) {
    if (!isNil(min) && floatValue < min) {
      const event = this.getEvent(floatValue, true);
      this.setState({ event, error: true, helperText: `Min value: ${min}` });
    } else if (!isNil(max) && floatValue > max) {
      const event = this.getEvent(floatValue, true);
      this.setState({ event, error: true, helperText: `Max value: ${max}` });
    }
  }

  handleOnChange = (event) => {
    const { floatValue } = event.target;
    delete event.target.floatValue;
    this.setState({ event, isAllowedValue: false });
    const isValid = this.validateValue(floatValue);
    if (isValid) {
      this.props.onChange?.(event);
    }
    this.handleOnValidationChange();
  };

  handleOnBlur = () => {
    const { event, isAllowedValue } = this.state;
    if (isAllowedValue) {
      this.props.onBlur?.(event);
      this.setState({ isAllowedValue: false, event: undefined });
    }
  };

  handleOnKeyDown = (event) => {
    const {
      onKeyDown,
      decimalScale = DECIMAL_SCALE,
      decimalSeparator = DECIMAL_SEPARATOR,
    } = this.props;

    if (onKeyDown) {
      onKeyDown(event);
      return;
    }

    const regexWithDecimals = /\.\d{2}$/;

    const { value = 0 } = this.state;

    let newValue = String(value.toFixed(decimalScale)).replace(
      decimalSeparator,
      '',
    );

    if (
      regexWithDecimals.test(event.target.value) &&
      event.target.selectionStart === event.target.value.length &&
      event.target.selectionEnd === event.target.value.length &&
      event['key'].match(/\d/g)
    ) {
      event.preventDefault();
      newValue += event['key'];
      const parsedValue = parseInt(newValue) / Math.pow(10, decimalScale);
      this.validateValue(parsedValue);
    } else {
      return;
    }
  };

  componentDidMount() {
    this.setPropsToState();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value ||
      prevProps.min !== this.props.min ||
      prevProps.max !== this.props.max
    ) {
      this.setPropsToState();
    }
  }

  setPropsToState = () => {
    const { value: initialValue, min, max } = this.props;
    const value = parseFloat(initialValue);
    this.setState({ value, min, max });
  };

  getError = () => {
    const { error: errorProps } = this.props;
    const { error } = this.state;
    return errorProps || error;
  };

  getErrorMsg = () => {
    const { helperText: errorProps } = this.props;
    const { helperText } = this.state;
    return errorProps || helperText;
  };

  handleOnValidationChange = () => {
    const { name, id } = this.props;
    const { error } = this.state;
    this.props.onValidationChange?.({
      target: {
        name: name || id,
        hasError: error,
      },
    });
  };

  onClickHelperText = () => {
    const { value, min, max } = this.state;
    if (isNil(max) && isNil(min)) return;

    let floatValue;
    if (!isNil(max) && value > max) {
      floatValue = max;
    } else if (!isNil(min)) {
      floatValue = min;
    }
    if (!isNil(floatValue)) {
      const event = this.getEvent(floatValue);
      this.handleOnChange(event);
      this.props?.onBlur?.(event);
      this.setState({ isAllowedValue: false, event: undefined });
    }
  };

  getEvent = (value, hasError = false) => {
    const { name } = this.props;
    return {
      target: {
        name,
        value: `${value}`,
        floatValue: value,
        hasError,
      },
    };
  };
}

export default HuiInputAmount;
