import React from 'react';
import NavPortal from './NavPortal';
import NavMenu from './NavMenu';
import NavFooter from './NavFooter';

const Nav = () => {
  return (
    <nav className="hui-nav position-sticky border-end hui-bg-white d-flex flex-column">
      <NavPortal />
      <NavMenu />
      <NavFooter />
    </nav>
  );
};


export default Nav;
