import { getUser } from '../user/getUser';
import { identifyUser } from './identifyUser';
import { getProviderId, loginHistory } from './loginHistory';
import { signInHandle } from './signInHandle';

export async function signInHandleAndIdentifyUser(portalInfo, firebaseUser, ignoreHistory = false) {
  try {
    const loggedUser = await signInHandle();
    if (loggedUser.isLogged) {
      localStorage.setItem('user', JSON.stringify(loggedUser));
      const user = await getUser();
      identifyUser(user, portalInfo);
      user.isLogged = true;
      if (!ignoreHistory) {
        await loginHistory({
          userId: user.id,
          portalId: portalInfo?.id,
          providerId: getProviderId(firebaseUser),
          action: 'login',
        });
      }

      return user;
    }
    return loggedUser;
  } catch (e) {
    throw new Error(e.message);
  }
}
