import React from 'react';

const HuiCardActions = (props) => {
  const { actions, actionsRight } = props;

  const huiBoxClass = `hui-card-actions d-flex ${
    !!actionsRight && 'justify-content-end'
  }`;

  return <div className={huiBoxClass}>{actions}</div>;
};

export default HuiCardActions;
