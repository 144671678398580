import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    value: 0,
  },
  reducers: {
    openLoading: (state) => {
      state.value += 1;
    },
    closeLoading: (state) => {
      state.value -= 1;
      if(state.value < 0) {
        state.value = 0;
      }
    },
    forceCloseLoading: (state) => {
      state.value = 0;
    },
  },
});

export const { openLoading, closeLoading, forceCloseLoading} = loadingSlice.actions;

export const selectLoading = (state) => state.loading.value;

export default loadingSlice.reducer;
