export function getPortalUrl(subdomain, path) {
  const currentPath = `${window.location.origin}/${path}`;

  const url = replaceSubdomain(currentPath, subdomain);

  return url;
}

export function replaceSubdomain(url, toSubdomain) {
  const replace = '://' + toSubdomain + '.';

  // Prepend https://
  if (!/^\w*:\/\//.test(url)) {
    url = 'https://' + url;
  }

  // Check if we got a subdomain in url
  if (url.match(/\.\w*\b/g).length > 1) {
    return url.replace(/(:\/\/\w+\.)/, replace);
  }

  return url.replace(/:\/\/(\w*\.)/, `${replace}$1`);
}
