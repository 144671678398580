import { Link } from 'react-router-dom';

const HuiTableProjectRequestWaiver = (props) => {
  const { data } = props;
  const { id: projectId } = data;

  const url = new URL('/request-waiver', document.baseURI);

  if (projectId) {
    url.searchParams.append('projectId', projectId);
  }

  const requestLink = url.pathname + url.search;

  return (
    <div className="rgt-cell-inner text-truncate hui-text-sm d-flex align-items-center">
      <Link to={requestLink} className="hui-btn hui-btn-sm hui-btn-green">
        Request Waiver
      </Link>
    </div>
  );
};

export default HuiTableProjectRequestWaiver;
