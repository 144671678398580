import { addDays, addMonths, endOfDay, endOfMonth, isSameDay, startOfDay, startOfMonth } from 'date-fns';
import UtilPaymentMethods from './utilPaymentMethods';

// Text fields
export const invoiceFilter = {
  name: 'invoiceNumber',
  type: 'input',
  label: 'Invoice Number',
};

export const poNumberFilter = {
  name: 'poNumber',
  type: 'input',
  label: 'PO Number',
};
export const idFilter = {
  name: 'id',
  type: 'input',
  label: 'ID',
};

export const jobIdFilter = {
  name: 'jobId',
  type: 'input',
  label: 'Job ID',
};

export const jobNameFilter = {
  name: 'jobName',
  type: 'input',
  label: 'Job Name',
};

export const jobAddressFilter = {
  name: 'jobAddress',
  type: 'input',
  label: 'Job Address',
};

export const submitterNameFilter = {
  name: 'submitterName',
  type: 'input',
  label: 'Originator Name',
};

export const submitterEmailFilter = {
  name: 'submitterEmail',
  type: 'input',
  label: 'Originator Email',
};

// Button fields
export const downloadCsvButtonFilter = {
  name: 'downloadCsv',
  type: 'button',
  label: 'Download CSV',
};

// Search field
export const searchFilter = {
  name: 'search',
  type: 'search',
  label: 'Search by Job Id, Customer Name, or Address',
};

// Date field
export const waiverThroughDateFilter = {
  name: 'throughDate',
  label: 'Waiver Through Date',
  type: 'calendar',
};

export const datePickerWithRangeFilter = {
  name: 'datePicker',
  label: 'Select Date',
  type: 'datePicker',
};

export const dateRangeFilter = {
  name: 'dateRange',
  label: 'Time Range',
  type: 'dateRange',
};

// Select Fields
export const paymentStatusFilter = {
  name: 'paymentStatus',
  label: 'Payment Status',
  type: 'select',
  options: [
    {
      id: 'STARTED',
      title: 'Started',
    },
    {
      id: 'PENDING',
      title: 'Pending',
    },
    {
      id: 'SCHEDULED',
      title: 'Scheduled',
    },
    {
      id: 'APPROVED',
      title: 'Approved',
    },
    {
      id: 'CANCELED',
      title: 'Canceled',
    },
    {
      id: 'REFUNDED',
      title: 'Refunded',
    },
    {
      id: 'CHARGEBACK',
      title: 'Chargeback',
    },
    {
      id: 'VOIDED',
      title: 'Voided',
    },
  ],
};

export const paymentTypeFilter = {
  name: 'paymentType',
  label: 'Payment Type',
  type: 'select',
  options: [
    {
      id: 'ALL',
      title: 'All',
    },
    {
      id: UtilPaymentMethods.ACH,
      title: 'ACH',
    },
    {
      id: UtilPaymentMethods.CREDIT_CARD,
      title: 'Credit Card',
    },
  ],
};

export const recurrenceStatusFilter = {
  name: 'status',
  label: 'Recurrence Status',
  type: 'select',
  options: [
    {
      id: 'PENDING',
      title: 'Pending',
    },
    {
      id: 'COMPLETED',
      title: 'Completed',
    },
    {
      id: 'CANCELED',
      title: 'Canceled',
    },
  ],
};

export const waiverTypeFilter = {
  name: 'waiverType',
  label: 'Waiver Type',
  type: 'select',
  options: [
    {
      id: 'waiver.conditional.progress',
      title: 'Conditional Progress',
    },
    {
      id: 'waiver.conditional.final',
      title: 'Conditional Final',
    },
    {
      id: 'waiver.unconditional.progress',
      title: 'Unconditional Progress',
    },
    {
      id: 'waiver.unconditional.final',
      title: 'Unconditional Final',
    },
  ],
};

export const invoiceDaysPastDue = {
  name: 'daysPastDue',
  label: 'Days Past Due',
  type: 'select',
  options: [
    {
      id: '0',
      title: 'Current',
    },
    {
      id: '30',
      title: '1-30',
    },
    {
      id: '60',
      title: '31-60',
    },
    {
      id: '90',
      title: '61-90',
    },
    {
      id: '91',
      title: '91+',
    },
  ],
};

export const invoiceTypeFilter = {
  name: 'type',
  label: 'Invoice Type',
  type: 'select',
  options: [
    {
      id: '',
      title: 'All Records',
    },
    {
      id: 'CREDIT',
      title: 'Credit',
    },
    {
      id: 'INVOICE',
      title: 'Invoice',
    },
  ],
};

// ========
// define static ranges

const defines = {
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),

  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),

  startOfLast7Days: startOfDay(addDays(new Date(), -6)),
  startOfLast14Days: startOfDay(addDays(new Date(), -13)),

  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defines.startOfToday,
      endDate: defines.endOfToday,
    }),
  },
  {
    label: 'Last Day',
    range: () => ({
      startDate: defines.startOfYesterday,
      endDate: defines.endOfYesterday,
    }),
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: defines.startOfLast7Days,
      endDate: defines.endOfToday,
    }),
  },
  {
    label: 'Last 14 Days',
    range: () => ({
      startDate: defines.startOfLast14Days,
      endDate: defines.endOfToday,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defines.startOfLastMonth,
      endDate: defines.endOfLastMonth,
    }),
  },
]);
