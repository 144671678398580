import { getAxiosForPortal } from './utilsAxiosPortal';
import { getAxiosForSupplierPage } from './utilsAxiosSupplier';

const isWithinIframe = window.parent !== window;
const axiosGetter = isWithinIframe ? getAxiosForSupplierPage : getAxiosForPortal;

console.log(`isWithinIframe`, isWithinIframe);

export const { axiosInstance, axiosUnauthenticated } = axiosGetter();

export const addIdempotencyKeyHeader = (idempotencyKey) => {
  return {
    headers: {
      'Idempotency-Key': idempotencyKey,
    },
  };
};
