import * as React from 'react';
import { HuiCard, HuiCardContent, HuiIcon } from 'handle-ui';
import SidebarInvoiceInfo from './SidebarInvoiceInfo';
import SidebarHeader from './SidebarHeader';
import CreditMemoTable from '../../../pages/private/payment/credit-memo-sidebar/CreditMemoTable';
import CreditMemoSummary from '../../../pages/private/payment/credit-memo-sidebar/CreditMemoSummary';

const SidebarCredit = () => {
  return (
    <>
      <SidebarHeader>
        <div className="flex-grow-1">
          <h2 className="hui-title d-flex align-items-center mb-0">
            <HuiIcon name="credit" size="md" className="me-2" /> Apply Credit
          </h2>
        </div>
      </SidebarHeader>

      <div className="hui-bg-gray-light p-4">
        <HuiCard className="p-0 mb-3" boxShadow border>
          <HuiCardContent className="p-3">
            <SidebarInvoiceInfo />
          </HuiCardContent>
        </HuiCard>

        <HuiCard className="p-0 mb-3" boxShadow border>
          <HuiCardContent className="m-0 pb-0 px-0">
            <CreditMemoTable />
          </HuiCardContent>
        </HuiCard>

        <CreditMemoSummary />
      </div>
    </>
  );
};

export default SidebarCredit;
