import { isEmpty } from 'lodash';
import React from 'react';

const HuiPaymentInfo = (props) => {
  const {
    payment: { info },
  } = props;

  const { reference, projectName, projectAddress, notes } = info || {};
  const showInfo = !isEmpty(reference) || !isEmpty(projectName) || !isEmpty(projectAddress) || !isEmpty(notes);

  return (
    showInfo && (
      <div>
        <div className="d-flex p-3 border-top">
          <div className="flex-grow-1 hui-text-lg fw-bold mb-2">Info</div>
        </div>

        <div className="d-flex px-3" hidden={isEmpty(reference)}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Reference</div>
          <div>{reference}</div>
        </div>

        <div className="d-flex px-3" hidden={isEmpty(projectName)}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Project Name</div>
          <div>{projectName}</div>
        </div>

        <div className="d-flex px-3" hidden={isEmpty(projectAddress)}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Project Address</div>
          <div>{projectAddress}</div>
        </div>

        <div className="d-flex px-3" hidden={isEmpty(notes)}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Notes</div>
          <div>{notes}</div>
        </div>
      </div>
    )
  );
};

export default HuiPaymentInfo;
