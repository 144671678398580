import { isEmpty } from 'lodash';
import React from 'react';

const HuiPaymentRequestDetails = (props) => {
  const {
    payment: { paymentRequest },
  } = props;

  const { description, createdBy: { name } = {} } = paymentRequest || {};
  const showInfo = !isEmpty(description) || !isEmpty(name);

  return (
    showInfo && (
      <div>
        <div className="d-flex p-3 border-top">
          <div className="flex-grow-1 hui-text-lg fw-bold mb-2">Payment Request Details</div>
        </div>

        <div className="d-flex px-3" hidden={isEmpty(name)}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Requested By</div>
          <div>{name}</div>
        </div>

        <div className="d-flex px-3" hidden={isEmpty(description)}>
          <div className="flex-grow-1 hui-text fw-bold mb-2">Notes</div>
          <div>{description}</div>
        </div>
      </div>
    )
  );
};

export default HuiPaymentRequestDetails;
