import React from 'react';

const HuiTabsContent = (props) => {
  const { className } = props;

  const huiTabsContentClass = `hui-tabs-content ${className || ``}`;

  return <div className={huiTabsContentClass}>{props.children}</div>;
};

export default HuiTabsContent;
