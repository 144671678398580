import { HuiBadge } from 'handle-ui';
import { toLower } from 'lodash';
import { getBadgeColorByWaiverStatus } from '../../utils/utilsColors';
import { getUtilsWaiverRequestStatus } from '../../utils/getUtilsWaiverRequestStatus';

const HuiTableWaiverStatus = ({ value }) => {
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();

  const badgeColor = getBadgeColorByWaiverStatus[toLower(value)] || {};

  const status = getUtilsWaiverRequestStatus[value] || value;

  return (
    <div className={classNames} title={status}>
      <HuiBadge value={status} bgColor={badgeColor?.bg} textColor={badgeColor?.text} uppercase fullWidth>
        {status}
      </HuiBadge>
    </div>
  );
};

export default HuiTableWaiverStatus;
