import { createSlice } from '@reduxjs/toolkit';

export const userCompanySlice = createSlice({
  name: 'userCompany',
  initialState: {
    value: {},
  },
  reducers: {
    updateUserCompany: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateUserCompany } = userCompanySlice.actions;

export const selectUserCompany = (state) => state.userCompany.value;

export default userCompanySlice.reducer;
