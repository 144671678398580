import { HuiIcon } from 'handle-ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updatePaymentPrepay } from '../../../../redux/InvoicesPaymentSlice';
import { resetPaymentData, updatePrepay } from '../../../../redux/PaymentDataSlice';
import HuiPaymentTypeSelect from './HuiPaymentTypeSelect';

const HuiPaymentType = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { activeType, onClick, isPrepayEnabled } = props || {};

  const isPayActive = activeType === 'pay';
  const isPrepayActive = activeType === 'prepay';

  const isSlim = pathname.endsWith('-slim');
  const payPath = isSlim ? '/pay-slim' : '/pay';
  const prepayPath = isSlim ? '/prepay-slim' : '/prepay';

  const singleClass = !isPrepayEnabled ? 'hui-payment-type-single' : '';
  const mainClass = `hui-payment-type ${singleClass} mt-2`;

  useEffect(() => {
    dispatch(updatePaymentPrepay(isPrepayActive));
    dispatch(updatePrepay(isPrepayActive));
    return () => {
      dispatch(resetPaymentData());
    };
  }, [dispatch, isPrepayActive]);

  return (
    <>
      <div className={mainClass}>
        <HuiPaymentTypeSelect
          icon={<HuiIcon name="bank" size="md" />}
          title="Select the invoices to be paid"
          to={payPath}
          active={isPayActive}
          type="pay"
          onClick={onClick}
        />
        <HuiPaymentTypeSelect
          icon={<HuiIcon name="card" size="md" />}
          title="Pre-pay - Add funds to your account"
          to={prepayPath}
          active={isPrepayActive}
          type="prepay"
          onClick={onClick}
          hidden={!isPrepayEnabled}
        />
      </div>
    </>
  );
};

export default HuiPaymentType;
