import { axiosInstance } from '../../utils/utilsAxios';

export async function getAllInvoices(params) {
  const url = `/v1/invoices/filter/all`;

  try {
    const response = await axiosInstance.get(url, { params });
    const invoices = response.data;
    return invoices;
  } catch (e) {
    throw new Error(e.message);
  }
}


