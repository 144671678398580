import React, { Fragment } from 'react';
import { isToday } from 'date-fns';
import { isArray, size } from 'lodash';
import { calculateRecurrenceInstallments } from '../../utils/utilsCalculateRecurrenceInstallments';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';
import currencyFormatter from 'currency-formatter';
import { calculateRecurrenceDuration } from '../../utils/utilsCalculateRecurrenceDuration';
import { Tooltip } from '@mui/material';
import { HuiIcon } from 'handle-ui';
import { surchargeRateStr } from '../../utils/utilsSurcharge';

const RecurrenceInstallments = (props) => {
  const portalInfo = useSelector(selectPortalInfo);
  const { recurrence, payment } = props;
  const { total, amount, startDate, endDate } = recurrence || '';

  const { settings } = portalInfo || {};
  const { maximumRecurrenceInstallments } = settings || {};

  const installments = calculateRecurrenceInstallments(startDate, endDate);
  const hasInstallments = !!size(installments);

  const duration = calculateRecurrenceDuration(startDate, endDate);

  const checkMaxInstallments = duration === parseInt(maximumRecurrenceInstallments);

  const installmentsMessage = checkMaxInstallments ? `(up to ${maximumRecurrenceInstallments} Installments)` : '';

  const hasRecurrencePayments = !!payment.recurrenceId;

  const paymentInfo = (date, total, hidden, i) => {
    const totalFormatted = currencyFormatter.format(total, { code: 'USD' });

    return (
      !hidden && (
        <Fragment key={i}>
          <div className="col-6 hui-text-sm">{moment(date).format('MMM D, YYYY')}</div>
          <div className="col-6 hui-text-sm text-end">{totalFormatted}</div>
        </Fragment>
      )
    );
  };

  const installmentsFromPaymentData = installments?.map((installment, i) => {
    const paymentDate = new Date(installment);
    const isSameDate = isToday(paymentDate);
    const paymentTotal = amount || total;
    return paymentInfo(installment, paymentTotal, isSameDate, i);
  });

  const installmentsFromPayment =
    isArray(recurrence) &&
    recurrence?.map((installment, i) => {
      const { scheduledAt, total } = installment;
      return paymentInfo(scheduledAt, total, false, i);
    });

  const payments = hasRecurrencePayments ? installmentsFromPayment : installmentsFromPaymentData;

  return hasInstallments || hasRecurrencePayments ? (
    <>
      <div className="row px-3 py-2 border-top" hidden={!size(recurrence)}>
        <div className="col-12 hui-bold mb-2">Other Payments {installmentsMessage}</div>
        <div className="col-6 hui-text-sm hui-bold mb-2">Payment date </div>
        <div className="col-6 hui-text-sm hui-bold mb-2 d-flex align-items-center justify-content-end">
          Payment amount
          <Tooltip
            arrow
            placement="top"
            title={`A ${surchargeRateStr} surcharge fee may apply based on card type and location.`}
          >
            <div className="d-flex ms-1">
              <HuiIcon size="xs" name="infoGray" width="16" height="16" />
            </div>
          </Tooltip>
        </div>

        {payments}
      </div>
    </>
  ) : null;
};

export default RecurrenceInstallments;
