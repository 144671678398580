export const getAvailableCredits = (data, allCredits) => {
  const { rows } = allCredits || {};

  let creditList = [];

  rows?.forEach((c) => {
    if (c.creditRestrictionType === 'NONE') {
      creditList.push(c.id);
    }

    if (c.creditRestrictionType === 'PROJECT' && !!c.projectId && c.projectId === data.projectId) {
      creditList.push(c.id);
    }

    if (c.creditRestrictionType === 'INVOICE' && !!c.relatedInvoiceNumber && c.relatedInvoiceNumber === data.invoiceNumber) {
      creditList.push(c.id);
    }

    if (c.creditRestrictionType === 'JOB' && !!c.jobId && c.jobId === data.jobId) {
      creditList.push(c.id);
    }
  });

  return creditList;
};
