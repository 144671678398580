import { axiosInstance } from '../../utils/utilsAxios';

export async function getCreditInvoices(params) {
  const url = `v1/invoices/unpaid/all?isPaid=false&type=CREDIT`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url, { params });
    const credits = response.data;
    return credits;
  } catch (e) {
    throw new Error(e.message);
  }
}
