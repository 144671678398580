import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRecurrence } from '../../../../actions/recurrence/getRecurrence';
import RecurrenceInfo from '../../../../components/recurrence/RecurrenceInfo';
import RecurrenceInstallments from '../../../../components/recurrence/RecurrenceInstallments';

const HuiRecurrenceInfo = (props) => {
  const { payment } = props;
  const { recurrenceId, recurrenceNumber } = payment;

  const [newRecurrence, setNewRecurrence] = useState([]);

  const newPayment = { ...payment, ...payment.recurrence };

  const recurrence = recurrenceId ? newRecurrence : newPayment || {};

  const recurrenceFiltered =
    recurrence?.payments?.filter((installment) => installment?.recurrenceNumber > recurrenceNumber) || {};

  const recurrences = recurrenceId ? recurrenceFiltered : recurrence;

  useEffect(() => {
    async function loadRecurrence() {
      const recurrence = await getRecurrence(recurrenceId);
      setNewRecurrence(recurrence);
    }
    if (recurrenceId) {
      loadRecurrence();
    }
  }, [recurrenceId, setNewRecurrence]);

  return (
    <>
      <RecurrenceInfo recurrence={recurrence} />
      <RecurrenceInstallments recurrence={recurrences} payment={payment} />
    </>
  );
};

export default HuiRecurrenceInfo;
