import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { closeModal } from '../../../redux/ModalSlice';
import currencyFormatter from 'currency-formatter';

const ModalAmountOverMaximum = () => {
  const dispatch = useDispatch();
  const portalInfo = useSelector(selectPortalInfo);

  const { settings } = portalInfo || {};
  const { maxAchPayment, maxCreditCardPayment } = settings || {};

  const isSameMax = maxAchPayment === maxCreditCardPayment;

  const maxAchPaymentFormatted = currencyFormatter.format(maxAchPayment, { code: 'USD' });
  const maxCreditCardPaymentFormatted = currencyFormatter.format(maxCreditCardPayment, { code: 'USD' });

  const AchMessage = maxAchPayment && maxAchPayment > 0 ? `for ACH Payment is ${maxAchPaymentFormatted}` : '';

  const cardMessage =
    maxCreditCardPayment && maxCreditCardPayment > 0
      ? `for Credit or Debit Card Payments is ${maxCreditCardPaymentFormatted}`
      : '';

  const and = !!AchMessage && !!cardMessage ? ' and ' : '';

  const text = isSameMax
    ? `per payment is ${maxAchPaymentFormatted}`
    : `${AchMessage} ${and} ${cardMessage} per transaction`;

  useEffect(() => {
    return () => {
      dispatch(closeModal({}));
    };
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="mb-3 d-flex">
          <div className="d-flex me-2">
            <i className="hui-i hui-svg-toastr-warning"></i>
          </div>
          <h3 className="hui-subtitle mb-0">Transaction Alert</h3>
        </div>

        <div className="mb-4 hui-text-lg">
          The maximum limit {text}. <br />
          <strong>You can make additional payments if you need to exceed this limit.</strong>
        </div>

        <div className="hui-text hui-color-black-light">Keep in mind, your bank or card limits may also apply.</div>
      </div>
    </div>
  );
};

export default ModalAmountOverMaximum;
