import Bugsnag from '@bugsnag/js';
import LogRocket from 'logrocket';

export function identifyUser(user, portal) {
  if (!user && !portal) {
    return;
  }
  const { id: userId, name, email, organizationId: orgId } = user || {};
  const { id: portalId, domain, organization: { id: portalOrgId } = {} } = portal || {};

  const portalData = {
    portalId,
    portalDomain: domain,
    portalOrgId,
  };

  const userData = {
    name,
    email,
    orgId: orgId,
    ...portalData,
  };

  /*
  LogRocket.identify only accepts strings as UIDs. Make sure to cast your UID to a string before calling this function.
  reference: https://docs.logrocket.com/reference/identify
  */
  LogRocket.identify(String(userId), userData);
  Bugsnag.addMetadata('portal', portalData);
  Bugsnag.setUser(userId, email, name);
};