import { isAHoliday } from '@18f/us-federal-holidays';
import moment from 'moment';

export function getDateInTimeZone(date, timezone = 'America/Los_Angeles') {
  if (!date) {
    return null;
  }
  const dateOnly = moment(date).format('YYYY-MM-DD');
  const result = moment.tz(dateOnly, ['YYYY-MM-DD'], timezone);
  return result.format();
}

export function isWeekend(date) {
  const dayOfWeek = moment(date).day();
  return dayOfWeek === 6 || dayOfWeek === 0;
}

export function isBusinessDay(date) {
  if (!date) {
    return null;
  }

  return !isWeekend(date) && !isAHoliday(date, { shiftSaturdayHolidays: true, shiftSundayHolidays: true });
}
