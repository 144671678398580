import { axiosInstance } from '../../utils/utilsAxios';

export async function downloadInvoice(id, print = false, view = false) {
  const url = `v1/invoices/${id}/download?print=${print}&view=${view}`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
}
