import { HuiBadge } from 'handle-ui';
import { getBadgeColorByStatus } from '../../utils/utilsColors';
import { toLower } from 'lodash';

const HuiTableStatus = ({ value }) => {
  const classNames = ('rgt-cell-inner text-truncate hui-text-sm').trim();

  const badgeColor = getBadgeColorByStatus[toLower(value)] || {};

  return (
    <div className={classNames} title={value}>
      <HuiBadge
        value={value}
        bgColor={badgeColor?.bg}
        textColor={badgeColor?.text}
        uppercase
        fullWidth>
        {value}
      </HuiBadge>
    </div>
  );
};

export default HuiTableStatus;
