import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { PublicRoutes } from './containers/Routes';
import { useDispatch, useSelector } from 'react-redux';
import Containers from './containers/Containers';
import { updateInfoPortal, updatePortalPromise } from './redux/PortalInfoSlice';
import { getPortal } from './actions/portal/getPortal';
import ScrollToTop from './components/layout/ScrollToTop';
import { selectAuth } from './redux/AuthSlice';
import { ProvideAuth } from './actions/auth/useAuth';
import { selectLoading } from './redux/LoadingSlice';
import { size } from 'lodash';
import LayoutSlim from './containers/LayoutSlim';

function PublicTemplate({ route }) {
  const auth = useSelector(selectAuth);
  const isLoggedIn = auth?.uid;
  const redirectToMain = isLoggedIn && route.onlyUnauthenticated;
  const hasUser = JSON.parse(localStorage.getItem('user'));

  return (
    <>
      {
        // TODO: only do it if has localStorage
        // <Outlet />
        redirectToMain && size(hasUser) ? <Navigate to="/" /> : <Outlet />
      }
    </>
  );
}

const Loading = () => {
  const loading = useSelector(selectLoading);
  return loading ? <LinearProgress /> : null;
};

const App = () => {
  const dispatch = useDispatch();
  const url = window.location.host;
  const urlParts = url.split('.');
  const subdomain = urlParts[0];
  const queryParams = new URLSearchParams(window.location.search);
  const loginAs = queryParams.get('impersonated');
  if (loginAs === 'none') {
    localStorage.removeItem('loginAs');
  } else if (loginAs) {
    localStorage.setItem('loginAs', loginAs);
  }

  useEffect(() => {
    async function startPortal() {
      const portalPromise = getPortal(subdomain);
      dispatch(updatePortalPromise(portalPromise));
      const portal = await portalPromise;
      const {
        theme,
        settings: { themeColor },
      } = portal || '';

      const color = themeColor || theme;
      await dispatch(updateInfoPortal(portal));
      portalTheme(color);
    }

    startPortal();
  }, [dispatch, subdomain]);

  return (
    <ProvideAuth>
      <Router>
        <Loading />
        <ScrollToTop />
        <Routes>
          {PublicRoutes.map((route, index) => {
            const TemplateLayout = route.slim ? LayoutSlim : Fragment;
            return (
              <Route path={route.path} key={route.name} element={<PublicTemplate route={route} />}>
                <Route
                  title={route.name}
                  path={route.path}
                  element={
                    <TemplateLayout>
                      <route.template />
                    </TemplateLayout>
                  }
                />
              </Route>
            );
          })}
          <Route path="*" element={<Containers />} />
        </Routes>
      </Router>
    </ProvideAuth>
  );

  function portalTheme(color) {
    return document.body.classList.add(`hui-theme-${color}`);
  }
};

export default App;
