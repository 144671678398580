import { HuiIcon } from 'handle-ui';
import moment from 'moment/moment';
import currencyFormatter from 'currency-formatter';
import HuiPaymentMethodIcon from '../../../components/payment/HuiPaymentMethodIcon';

const DashboardPaymentsItem = (props) => {
  const { payment } = props;
  const { date, type, brand, amount, recurrent } = payment;

  const today = moment();
  const isSameAsToday = today.isSame(date, 'day');

  let label;
  if (isSameAsToday) {
    label = 'Processing';
  } else {
    label = recurrent ? 'Recurrent' : 'Scheduled';
  }

  return (
    <div className="d-flex mb-3">
      <div className="flex-grow-1">
        <div className="hui-text fw-bold">{type}</div>
        <div className="hui-text hui-color-gray d-flex align-items-center">
          <div className="d-flex me-1">
            {label === 'Processing' ? <HuiIcon name="timer" size="xs" /> : <HuiIcon name="calendarDate" size="xs" />}
          </div>
          {label}
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        <div className="hui-text d-flex align-items-center">
          {currencyFormatter.format(amount, { code: 'USD' })}
          <HuiPaymentMethodIcon brand={brand} className="ms-2" />
        </div>
        <div className="hui-text hui-color-gray d-flex align-items-center">
          <div className="d-flex me-2" hidden={!recurrent}>
            <HuiIcon name="reload" size="xs" />
          </div>
          {moment(date).format('MMM DD')}
        </div>
      </div>
    </div>
  );
};

export default DashboardPaymentsItem;
