import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../redux/PortalInfoSlice';

const PortalLogo = (props) => {
  const { className = '', logoUrl, noName } = props;

  const portalInfo = useSelector(selectPortalInfo);

  const { name } = portalInfo || {};

  const logo = logoUrl || portalInfo.logoUrl;

  const supplierLogoUrl = `${logo ? `${logo}` : `/images/svg/loading-logo.svg`}`;
  const noLogo = noName ? <></> : name;

  return logo ? <img src={supplierLogoUrl} alt={name} className={className} /> : noLogo;
};

export default PortalLogo;
