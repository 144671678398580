import React from 'react';

const HuiTableCreditReference = ({ data, value }) => {
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();
  let reference;

  if (data.creditRestrictionType === 'INVOICE') {
    reference = data.relatedInvoiceNumber;
  } else if (data.creditRestrictionType === 'JOB') {
    reference = data.jobId;
  } else if (data.creditRestrictionType === 'PROJECT') {
    reference = data.projectId;
  }

  return (
    <div className={classNames} title={value}>
      {reference}
    </div>
  );
};

export default HuiTableCreditReference;
