import React from 'react';
import { HuiIcon } from '../../index';

const HuiModalHeader = (props) => {
  const { modal, close, hideClose } = props;

  const { draggable, title, icon } = modal || {};

  return (
    <div className="hui-modal-header d-flex align-items-center">
      {!!draggable && <div className="hui-modal-move me-2"></div>}

      {!!icon && <div className="mb-3">{icon}</div>}

      {!!title && (
        <h3 className="mb-0 flex-grow-1 fw-bold hui-text-lg">{title}</h3>
      )}

      {!hideClose &&
        <button
          className="hui-btn hui-btn-transparent"
          onClick={() => close()}>
          <HuiIcon
            name="x"
            size="sm"
          />
        </button>
      }
    </div>
  );
};

export default HuiModalHeader;
