import { HuiBadge } from 'handle-ui';
import { capitalize, toLower } from 'lodash';
import { getBadgeColorByWaiverType } from '../../utils/utilsColors';

const HuiTableWaiverType = ({ value }) => {
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();

  const badgeColor = getBadgeColorByWaiverType[toLower(value)] || {};
  let formattedValue = value;

  if (value && value !== 'other') {
    const valueArray = value.split('.');
    formattedValue = `${capitalize(valueArray[0])} ${capitalize(valueArray[1])}`;
  }

  return (
    <div className={classNames} title={value}>
      <HuiBadge value={formattedValue} bgColor={badgeColor?.bg} textColor={badgeColor?.text}>
        {value}
      </HuiBadge>
    </div>
  );
};

export default HuiTableWaiverType;
