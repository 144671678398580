import { axiosInstance } from '../../utils/utilsAxios';

export async function getPortals() {
  const url = `v1/portals`;

  try {
    const response = await axiosInstance.get(url);
    const portals = response.data;
    return portals;
  } catch (e) {
    throw new Error(e.message);
  }
}


