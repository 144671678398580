import * as React from 'react';
import moment from 'moment';
import { startCase, toLower } from 'lodash';
import { useSelector } from 'react-redux';
import { selectSidebar } from '../../../redux/SidebarSlice';
import { HuiCard, HuiCardContent, HuiIcon } from 'handle-ui';
import { selectInvoiceEvents } from '../../../redux/InvoiceEventsSlice';
import { getUtilsIconsByStatus } from '../../../utils/utilsIcons';
import { getUtilsTextColorByStatus } from '../../../utils/utilsTexts';

const SidebarInvoiceEvents = () => {
  const creditInvoice = useSelector(selectSidebar);
  const invoiceEvents = useSelector(selectInvoiceEvents);
  const { invoiceCreatedAt } = creditInvoice || '';

  return (
    <>
      <h2 className="hui-subtitle mt-3 mb-2">Invoice History</h2>

      <HuiCard boxShadow border>
        <HuiCardContent>
          <div className="revision-history d-flex flex-column flex-column-reverse">
            <div className="revision-history-item d-flex flex-column flex-md-row">
              <div className="revision-history-inner pr-3 pr-md-0">
                <div className="revision-history-icon d-flex align-items-center justify-content-center">
                  <HuiIcon name="reports" size="sm" />
                </div>
                <h3 className="hui-text fw-bold hui-color-black-light mb-0">Invoice Created</h3>
                <div className="hui-text-sm hui-color-gray">
                  {moment(invoiceCreatedAt).format('M/DD/YYYY - h:mm:ss a')}
                </div>
              </div>
            </div>

            {invoiceEvents.map((e, i) => {
              const paymentEvents = ['SHORT_PAY', 'OVER_PAY', 'COMPLETE_PAY', 'REFUNDED', 'CANCELED'];

              const emailEvents = ['EMAIL_SENT'];

              const { user } = e;
              const { email, name } = user || '';

              const hasPayment = paymentEvents.includes(e.type);
              const hasEmail = emailEvents.includes(e.type);

              const eventIcon = getUtilsIconsByStatus[e.type];
              const eventTextColor = `hui-text-sm fw-bold ${getUtilsTextColorByStatus[e.type] || 'hui-color-gray'}`;

              return (
                <div key={i} className="revision-history-item d-flex flex-column flex-md-row ">
                  <div className="revision-history-inner d-flex pr-3 pr-md-0 align-items-center">
                    <div className="flex-grow-1">
                      <div className="revision-history-icon d-flex align-items-center justify-content-center">
                        {eventIcon}
                      </div>
                      <h3 className="hui-text fw-bold hui-color-black-light mb-0">
                        {startCase(toLower(e.type))} {e.type === 'VIEWED' ? 'Invoice' : ''}
                      </h3>
                      <div className={eventTextColor} hidden={!hasPayment}>
                        {e.data}
                      </div>
                      <div className={eventTextColor} hidden={!hasEmail}>
                        {e.data}
                      </div>
                      <div className="hui-text-sm hui-color-gray">
                        {moment(e.createdAt).format('M/DD/YYYY - h:mm:ss a')} <br />
                        By: {name} - {email}
                      </div>
                    </div>

                    <div hidden={!hasPayment}>
                      <a
                        href={`/history/payments/${e.data}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hui-btn hui-btn hui-color-black ms-2"
                      >
                        View Payment
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </HuiCardContent>
      </HuiCard>
    </>
  );
};

export default SidebarInvoiceEvents;
