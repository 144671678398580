import HuiTableCell from './HuiTableCell';

const HuiTablePoNumber = (props) => {

  return (
    <HuiTableCell {...props} />
  );
};

export default HuiTablePoNumber;
