import { HuiIcon } from 'handle-ui';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

const HuiTablePagination = ({ tableManager, onChange = tableManager.paginationApi.setPage }) => {
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const currentPage = tableManager.paginationApi.page;

  const setCurrentPage = (page) => {
    tableManager.paginationApi.setPage(page);
  };

  const {
    config: {
      texts: { prev: prevText, page: pageText, next: nextText, of: ofText },
      additionalProps: { pagination: additionalProps = {} },
    },
    paginationApi: { page, totalPages },
  } = tableManager;

  const backButtonDisabled = currentPage === 1;
  const nextButtonDisabled = currentPage === totalPages;

  let classNames = (
    'rgt-footer-pagination d-flex align-items-center justify-content-end my-2 mx-3' + (additionalProps.className || '')
  ).trim();

  const buttonClass = `hui-btn hui-btn-sm hui-btn-green hui-btn-icon`;
  const nextButtonBgClass = `${nextButtonDisabled ? 'hui-btn-outline' : ''}`;
  const prevButtonBgClass = `${backButtonDisabled ? 'hui-btn-outline' : ''}`;
  const nextButtonIconColor = `${nextButtonDisabled ? '#8E909E' : '#FFFFFF'}`;
  const prevButtonIconColor = `${backButtonDisabled ? '#8E909E' : '#FFFFFF'}`;

  const handleOnChange = (name, value) => {
    onChange(value);
    urlSearchParams.set(name, value);
    setSearchParams(new URLSearchParams(urlSearchParams.toString()));
  };

  const increasePage = () => {
    let _currentPage = currentPage + 1;
    _currentPage = _currentPage > totalPages ? totalPages : _currentPage;
    setCurrentPage(_currentPage);
    handleOnChange('page', _currentPage);
  };

  const decreasePage = () => {
    let _currentPage = currentPage - 1;
    _currentPage = _currentPage < 1 ? 1 : _currentPage;
    setCurrentPage(_currentPage);
    handleOnChange('page', _currentPage);
  };

  const setPageMannually = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      handleOnChange('page', page);
    }
  };

  return (
    <div {...additionalProps} className={classNames}>
      <button
        className={`${buttonClass} ${prevButtonBgClass}`}
        disabled={backButtonDisabled}
        onClick={() => decreasePage()}
      >
        <HuiIcon name="prev" size="xs" className="hui-i" color={prevButtonIconColor} />
      </button>

      <div className="d-flex align-items-center mx-2">
        <div className="hui-text-xs fw-bold text-uppercase hui-color-gray"> {pageText} </div>
        <input
          className="rgt-footer-page-input"
          type="number"
          value={currentPage}
          onChange={(event) => setPageMannually(event.target.value * 1)}
        />
        <div className="hui-text-xs fw-bold text-uppercase hui-color-gray">
          {ofText} {totalPages}
        </div>
      </div>

      <button
        className={`${buttonClass} ${nextButtonBgClass}`}
        disabled={nextButtonDisabled}
        onClick={() => increasePage()}
      >
        <HuiIcon name="next" size="xs" className="hui-i" color={nextButtonIconColor} />
      </button>
    </div>
  );
};

export default HuiTablePagination;
