import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  link: PropTypes.string,
};

const EmptyComponent = (props) => {
  const { title, subtitle, buttonTitle, link, className } = props;

  const componentClass = `d-flex flex-column flex-grow-1 h-100 justify-content-center text-center ${className}`;

  return (
    <div className={componentClass}>
      <div className="hui-subtitle hui-color-gray">{title}</div>

      <div className="hui-text hui-color-gray py-3">{subtitle}</div>

      <div>
        <Link to={link} className="hui-btn hui-btn-outline hui-btn-black">
          {buttonTitle}
        </Link>
      </div>
    </div>
  );
};

EmptyComponent.propTypes = propTypes;

export default EmptyComponent;
