import { isBoolean, isNumber } from 'lodash';
import SummaryPanelItem from './SummaryPanelItem';

const componentType = {
  pay: { link: '/pay?size=100' },
  view: { label: 'View', link: '/history/invoices?type=INVOICE&size=100' },
  viewCredit: { link: '/history/invoices?type=CREDIT&size=100' },
};

const SummaryPanelTotal = (props) => {
  const { title, amount, amountColor = 'black', type, daysPastDue, hasProject, isPaid, hidden } = props;

  const selectedType = componentType[type];

  const url = new URL(selectedType.link, document.baseURI);

  const newAmountColor = amount === '0' ? 'gray' : amountColor;

  if (isBoolean(hasProject)) {
    url.searchParams.append('hasProject', hasProject);
  }
  if (isNumber(daysPastDue)) {
    url.searchParams.append('daysPastDue', daysPastDue);
  }
  if (isBoolean(isPaid) && type !== 'pay') {
    url.searchParams.append('isPaid', isPaid);
  }

  const link = url.pathname + url.search;
  const { label } = selectedType;

  return (
    <>
      <SummaryPanelItem
        link={link}
        hidden={hidden}
        title={title}
        label={label}
        color={newAmountColor}
        amount={amount}
        disabled={amount === 0}
        className="border-top px-3 py-2 hui-dashboard-summary d-flex align-items-center hui-bg-gray-light"
        textClass="hui-text-xs fw-bold text-uppercase hui-color-gray mb-0"
      />
    </>
  );
};

export default SummaryPanelTotal;
