import currencyFormatter from 'currency-formatter';
import { HuiIcon } from 'handle-ui';
import { size } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentData } from '../../redux/PaymentDataSlice';
import ViewInvoiceCredit from '../invoice/ViewInvoiceCredit';

const HuiTableApplyCredit = (props) => {
  const paymentData = useSelector(selectPaymentData);
  const { rowIndex, data } = props;
  const { id } = data;
  const [invoice, setInvoice] = useState({});

  useEffect(() => {
    function findInvoice() {
      const invoice = paymentData?.paymentInvoices?.find((i) => i.id === id) || {};
      setInvoice(invoice);
    }

    findInvoice();
  }, [paymentData, id, setInvoice]);

  const credit =
    invoice?.credits?.reduce((creditAmount, credit) => {
      return creditAmount + credit.amount;
    }, 0) || 0;

  const creditFormatted = currencyFormatter.format(credit, { code: 'USD' });

  const label = size(invoice.credits) ? `Credit Applied ${creditFormatted}` : 'Apply Credit';
  const appliedIcon = size(invoice.credits) ? <HuiIcon name="check" size="xs" /> : <HuiIcon name="credit" size="xs" />;

  return (
    <div className="d-flex ms-auto">
      <ViewInvoiceCredit
        type="badge"
        label={label}
        icon={appliedIcon}
        hidden={!size(invoice.availableCreditIds)}
        rowIndex={rowIndex}
        invoice={invoice}
      />
    </div>
  );
};

export default HuiTableApplyCredit;
