import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import { size } from 'lodash';

const cloneElement = (element, required) => {
  let newProps = {
    required,
    key: element.key ? element.key : element.props.value,
  };

  return React.cloneElement(element, { ...newProps });
};

const HuiRadioGroup = (props) => {
  const { row, id, children, onChange, required, className } = props;

  if (!Array.isArray(children)) {
    return null;
  }

  const newChildren = children.map((option) => {
    if (!option.key && !size(option.props)) {
      return;
    }

    if (Array.isArray(option)) {
      const elements = option.map((subOption) => {
        return cloneElement(subOption, required);
      });
      return elements;
    } else {
      return cloneElement(option, required);
    }
  });

  return (
    <RadioGroup
      key={`radio-group-${id}`}
      onChange={onChange}
      row={row}
      name={id}
      className={className}
    >
      {newChildren}
    </RadioGroup>
  );
};
export default HuiRadioGroup;
