import moment from 'moment';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import HuiDate from './HuiDate';

class HuiCalendar extends React.PureComponent {
  state = { isOpen: false };

  render() {
    const {
      label,
      id,
      name,
      size,
      hidden,
      disabled,
      error,
      topLabel,
      className,
      date,
      onChange,
      onClear,
      required,
      hideRequired,
      onClickDisabled,
      handleOnClick,
      hideActions,
      hideConfirm,
      prefix = 'Selected: ',
      minDate,
      maxDate,
      hidePassiveDays = false,
      onlyDayPicker,
    } = this.props;
  
    const { value, dateSelected } = date;
  
    const dateFormatted = moment(dateSelected).format('M/DD/YYYY');
  
    let dateValue = value ? `${prefix}${dateFormatted}` : '';
  
    const hidePassiveDaysClass = hidePassiveDays
      ? 'hui-calendar-hide-passive'
      : '';
  
    let onlyDayPickerProps = {};
  
    if (onlyDayPicker) {
      onlyDayPickerProps = {
        navigatorRenderer: () => {},
        weekdayDisplayFormat: ' ',
        minDate: new Date(2023, 1, 1),
        maxDate: new Date(2023, 1, 28),
        shownDate: new Date(2023, 1, 1),
      };
      dateValue = value ? moment(dateSelected).format('D') : '';
    }
  
    return (
      <HuiDate
        hideActions={hideActions}
        onClear={onClear}
        handleOnClick={handleOnClick}
        onClickDisabled={onClickDisabled}
        error={error}
        topLabel={topLabel}
        className={className}
        size={size}
        id={id}
        label={label}
        name={name}
        value={dateValue}
        disabled={!!disabled}
        hidden={hidden}
        onChange={onChange}
        required={required}
        hideRequired={hideRequired}
        hideConfirm={hideConfirm}
        isOpen={this.state.isOpen}
        onOpen={() => this.setState({ isOpen: !this.state.isOpen })}
      >
        <Calendar
          className={`hui-calendar ${hidePassiveDaysClass}`}
          onChange={this.handleOnChange.bind(this)}
          showDateDisplay={false}
          date={date?.dateSelected}
          minDate={minDate}
          maxDate={maxDate}
          {...onlyDayPickerProps}
        />
      </HuiDate>
    );
  }

  handleOnChange = (e) => {
    this.props.onChange?.(e);
    const { closeByDefault = true, hideActions } = this.props;
    if (closeByDefault && this.state.isOpen && hideActions) {
      this.setState({ isOpen: false });
    }
  };
};

export default HuiCalendar;
