import HuiTableCell from './HuiTableCell';

const HuiTableDueDate = (props) => {

  return (
    <HuiTableCell {...props} />
  );
};

export default HuiTableDueDate;
