import React from 'react';
import currencyFormatter from 'currency-formatter';
import { Tooltip } from '@mui/material';
import { HuiIcon } from 'handle-ui';

const PaymentTotal = (props) => {
  const { title, description, amount, hidden } = props;

  const amountFormatted = currencyFormatter.format(amount, { code: 'USD' });

  const amountClass = `hui-subtitle ${amount === 0 ? 'text-end' : ''}`;
  return (
    !hidden && (
      <>
        <div className="hui-text-lg d-flex align-items-center justify-content-end">
          {title}

          {!!description && (
            <Tooltip arrow placement="top" title={description}>
              <div className="d-flex ms-2">
                <HuiIcon size="xs" name="info" />
              </div>
            </Tooltip>
          )}
        </div>

        <div className={amountClass}>{amountFormatted}</div>
      </>
    )
  );
};

export default PaymentTotal;
