import { createSlice } from '@reduxjs/toolkit';

export const invoiceEventsSlice = createSlice({
  name: 'invoiceEvents',
  initialState: {
    value: [],
  },
  reducers: {
    updateInvoiceEvents: (state, action) => {
      state.value = action.payload;
    },
    resetInvoiceEvents: (state, action) => {
      state.value = [];
    },
  },
});

export const { updateInvoiceEvents, resetInvoiceEvents } = invoiceEventsSlice.actions;

export const selectInvoiceEvents = (state) => state.invoiceEvents.value;

export default invoiceEventsSlice.reducer;
