import { size, toUpper } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getInvoicesSummary } from '../../../actions/invoices/getInvoicesSummary';
import { getOpenProjects } from '../../../actions/project/getOpenProjects';
import { getUserCompany } from '../../../actions/user/getUserCompany';
import { selectAuth } from '../../../redux/AuthSlice';
import { selectLoading } from '../../../redux/LoadingSlice';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import DashboardHistoryWaivers from './DashboardHistoryWaivers';
import DashboardInvoiceSummary from './DashboardInvoiceSummary';
import DashboardOpenProjects from './DashboardOpenProjects';
import DashboardPayments from './DashboardPayments';

const Dashboard = () => {
  const auth = useSelector(selectAuth);
  const loading = useSelector(selectLoading);
  const portalInfo = useSelector(selectPortalInfo);
  const [company, setCompany] = useState({});
  const [invoicesSummary, setInvoicesSummary] = useState({});
  const [openProjects, setOpenProjects] = useState({});

  const {
    isWaiverEnabled = false,
    isExecutedWaiverWidgetEnabled = false,
    isOpenProjectWidgetEnabled = false,
    isOpenProjectSummaryEnabled = false,
    openProjectSummaryLabel = 'Open Project Invoices',
    isOpenWithoutProjectSummaryEnabled = false,
    openWithoutProjectSummaryLabel = 'Open Non-Project Invoices',
    isOpenAccountSummaryEnabled = false,
    openAccountSummaryLabel = 'Open Account Invoices',
    isInvoicesPaidSummaryEnabled = false,
    invoicesPaidSummaryLabel = 'Paid invoices',
    isRecurrencePaymentEnabled = false,
    isScheduledPaymentEnabled = false,
    isPaymentEnabled = false,
  } = portalInfo?.settings || {};

  useEffect(() => {
    async function fetchCompany() {
      try {
        setCompany(['loading']);
        const companyResult = (await getUserCompany()) || {};
        setCompany(companyResult);
      } catch (error) {
        setCompany(['error']);
      }
    }

    if (size(auth) && !loading && !size(company)) {
      fetchCompany();
    }
  }, [company, setCompany, auth, loading]);

  useEffect(() => {
    async function fetchInvoicesSummary() {
      try {
        setInvoicesSummary(['loading']);
        const invoicesResult = (await getInvoicesSummary()) || {};
        setInvoicesSummary(invoicesResult);
      } catch (error) {
        setInvoicesSummary(['error']);
      }
    }

    if (size(auth) && !loading && !size(invoicesSummary)) {
      fetchInvoicesSummary();
    }
  }, [invoicesSummary, setInvoicesSummary, auth, loading]);

  useEffect(() => {
    async function fetchProjects() {
      try {
        setOpenProjects(['loading']);
        const projectsResult = (await getOpenProjects()) || {};
        setOpenProjects(projectsResult);
      } catch (error) {
        setOpenProjects(['error']);
      }
    }

    if (size(auth) && !loading && !size(openProjects)) {
      fetchProjects();
    }
  }, [auth, openProjects, setOpenProjects, loading]);

  return (
    <>
      <div className="p-4 hui-dashboard flex-grow-1 overflow-hidden">
        <h1 className="hui-title mb-1 hui-dashboard-company-name">HELLO, {toUpper(company.name)}</h1>
        <h2 className="hui-text hui-color-black-light fw-bold mb-4">Here is your Dashboard</h2>
        <DashboardInvoiceSummary
          invoicesSummary={invoicesSummary}
          isPaymentEnabled={isPaymentEnabled}
          isOpenProjectSummaryEnabled={isOpenProjectSummaryEnabled}
          openProjectSummaryLabel={openProjectSummaryLabel}
          isOpenWithoutProjectSummaryEnabled={isOpenWithoutProjectSummaryEnabled}
          openWithoutProjectSummaryLabel={openWithoutProjectSummaryLabel}
          isOpenAccountSummaryEnabled={isOpenAccountSummaryEnabled}
          openAccountSummaryLabel={openAccountSummaryLabel}
          isInvoicesPaidSummaryEnabled={isInvoicesPaidSummaryEnabled}
          invoicesPaidSummaryLabel={invoicesPaidSummaryLabel}
        />

        <DashboardPayments
          isRecurrencePaymentEnabled={isRecurrencePaymentEnabled}
          isScheduledPaymentEnabled={isScheduledPaymentEnabled}
        />

        {isOpenProjectWidgetEnabled && (
          <DashboardOpenProjects
            openProjects={openProjects}
            setOpenProjects={setOpenProjects}
            isWaiverEnabled={isWaiverEnabled}
            isPaymentEnabled={isPaymentEnabled}
          />
        )}

        {isWaiverEnabled && isExecutedWaiverWidgetEnabled && <DashboardHistoryWaivers />}
      </div>
    </>
  );
};

export default Dashboard;
