import { axiosInstance } from '../../utils/utilsAxios';

export async function getWaiverRequestsHistory(params) {
  const url = `/v1/waivers`;

  try {
    const response = await axiosInstance.get(url, { params });
    const waivers = response.data;
    return waivers;
  } catch (e) {
    throw new Error(e.message);
  }
}

