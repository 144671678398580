import { firebase } from './firebase';

export async function updateUserPassword(password) {
  const user = firebase.auth().currentUser;
  return user.updatePassword(password).then(() => {
    return true;
  })
  .catch(() => {
    return false;
  });
}