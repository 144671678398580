import React from 'react';

const HuiPanelActions = (props) => {
  const { actions, children, actionsRight } = props;

  const huiPanelClass = `hui-panel-actions d-flex ${
    !!actionsRight && 'justify-content-end'
  }`;

  return <div className={huiPanelClass}>{actions || children}</div>;
};

export default HuiPanelActions;
