import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import React from 'react';
import * as PropTypes from 'prop-types';


const propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.any,
  groupClassName: PropTypes.string,
  label: PropTypes.any,
};

const HuiRadioOption = (props) => {
  const { label, labelClassName, value, required, checked, className, onClick, hidden } = props;

  return (
    <FormControlLabel
      className={className}
      value={value}
      onClick={onClick}
      label={<div className={labelClassName}> {label} </div>}
      control={
        <Radio
          required={required}
          checked={checked}
        />
      }
      hidden={hidden}
    />
  );
};

HuiRadioOption.propTypes = propTypes;

export default HuiRadioOption;
