import HuiTableCell from './HuiTableCell';
import HuiPaymentMethodIcon from '../payment/HuiPaymentMethodIcon';
import UtilPaymentMethods from '../../utils/utilPaymentMethods';

const HuiTableTotal = (props) => {
  const { data } = props;
  const { paymentType, brand } = data;

  const cardBrand = UtilPaymentMethods.isACH(paymentType) ? paymentType : brand || paymentType;

  return (
    <div className="d-flex align-items-center w-100">
      <HuiTableCell {...props} className="fw-bold" />
      <div className="d-flex">
        <HuiPaymentMethodIcon className="me-2" brand={cardBrand} />
      </div>
    </div>
  );
};

export default HuiTableTotal;
