import React from 'react';
import Header from '../components/layout/header/Header';
import Nav from '../components/layout/nav/Nav';

const Layout = (props) => {
  return (
    <>
      <Header />
      <main className='hui-main hui-header-padding d-flex'>
        <Nav />
        {props.children}
      </main>
    </>
  );
};

export default Layout;
