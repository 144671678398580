import { createSlice } from '@reduxjs/toolkit';

export const userCompanyBalanceSlice = createSlice({
  name: 'userCompanyBalance',
  initialState: {
    value: {},
  },
  reducers: {
    updateUserCompanyBalance: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { updateUserCompanyBalance } = userCompanyBalanceSlice.actions;

export const selectUserCompanyBalance = (state) => state.userCompanyBalance.value;

export default userCompanyBalanceSlice.reducer;
