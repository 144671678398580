import currencyFormatter from 'currency-formatter';
import { size } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectPortalInfo } from '../../../../redux/PortalInfoSlice';
import HuiPaymentInvoices from './HuiPaymentInvoices';
import SurchargeMessage from '../surcharge/SurchargeMessage';

const HuiHistoryPaymentInvoices = (props) => {
  const { payment } = props;

  const portalInfo = useSelector(selectPortalInfo);

  const { surchargeRate, surcharge, currencyCode, credit, subtotal, total, isPrepaid } = payment || {};

  const { settings } = portalInfo || {};
  const { isCreditMemoEnabled = false } = settings || {};

  const invoiceWord = size(payment.paymentInvoices) > 1 ? 'Invoices' : 'Invoice';

  const creditFormatted = currencyFormatter.format(credit * -1, { code: currencyCode });
  const subtotalFormatted = currencyFormatter.format(subtotal, { code: currencyCode });
  const surchargeFormatted = currencyFormatter.format(surcharge, { code: currencyCode });

  const totalInvoicesFormatted = currencyFormatter.format(subtotal - credit, { code: currencyCode });
  const totalFormatted = currencyFormatter.format(total, { code: currencyCode });

  return (
    <>
      <div className="d-flex p-3 border-top" hidden={isPrepaid || !size(payment.paymentInvoices)}>
        <div className="flex-grow-1 hui-text-lg fw-bold">Paid {invoiceWord}</div>
      </div>

      <HuiPaymentInvoices hidden={!size(payment.paymentInvoices)} payment={payment} showAll preview />

      <div className="d-flex p-3 border-top">
        <div className="flex-grow-1 hui-text-lg fw-bold">Payment Summary</div>
      </div>

      <div className="d-flex px-3" hidden={isPrepaid}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Total {invoiceWord}</div>
        <div>
          <strong>{subtotalFormatted}</strong>
        </div>
      </div>

      <div className="d-flex px-3" hidden={!isCreditMemoEnabled || isPrepaid}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Total Credit Applied</div>
        <div>
          <strong>{creditFormatted}</strong>
        </div>
      </div>

      <div className="d-flex px-3">
        <div className="flex-grow-1 hui-text fw-bold mb-2">Subtotal</div>
        <div>
          <strong>{totalInvoicesFormatted}</strong>
        </div>
      </div>

      <div className="d-flex px-3" hidden={!surcharge}>
        <div className="flex-grow-1 hui-text fw-bold mb-2">Credit Card Surcharge ({surchargeRate}%):</div>
        <div>
          <strong>{surchargeFormatted}</strong>
        </div>
      </div>

      <SurchargeMessage showSurcharge={surcharge} />

      <div className="d-flex px-3 mb-3">
        <div className="flex-grow-1 hui-text-lg fw-bold mb-2">Total</div>
        <div className="hui-text-lg">
          <strong>{totalFormatted}</strong>
        </div>
      </div>
    </>
  );
};

export default HuiHistoryPaymentInvoices;
