export const paymentOriginOptions = [
  {
    title: 'In person',
    id: 'IN_PERSON',
  },
  {
    title: 'Over the phone',
    id: 'OVER_THE_PHONE',
  },
];
