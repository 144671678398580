import { axiosInstance } from '../../utils/utilsAxios';

export async function getCheckoutToken() {
  const url = `v1/payments/creditcard/publicapikey`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url);
    const token = response.data;
    return token;
  } catch (e) {
    throw new Error(e.message);
  }
}

