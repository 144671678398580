import { axiosInstance } from '../../utils/utilsAxios';

export async function downloadPaymentsCsv(params) {
  const url = `v1/payments/download/csv`;

  console.log('fetching: ', url);

  try {
    const response = await axiosInstance.get(url, {
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    });
    return response;
  } catch (e) {
    throw new Error(e.message);
  }
}
