import { firebase } from './firebase';

export async function reauthenticateWithCredential(password) {
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    firebase.auth().currentUser.email,
    password
  );

  return user.reauthenticateWithCredential(credential).then(function() {
    return true;
  }).catch(function(error) {
    return false;
  });
}