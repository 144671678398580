import { axiosInstance } from '../../utils/utilsAxios';

export async function deleteCardOnFile(id) {
  const url = `/v1/card-on-file/${id}`;

  try {
    const response = await axiosInstance.delete(url);
    const cardOnFileList = response.data;
    return cardOnFileList;
  } catch (e) {
    throw new Error(e.response?.data?.message || e.message);
  }
}
