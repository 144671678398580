import { HuiPanel, HuiPanelContent } from 'handle-ui';
import { size } from 'lodash';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getOpenProjects } from '../../../actions/project/getOpenProjects';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTableProjectPay from '../../../components/table/HuiTableProjectPay';
import HuiTableProjectRequestWaiver from '../../../components/table/HuiTableProjectRequestWaiver';
import HuiTableProjectView from '../../../components/table/HuiTableProjectView';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';
import { datePickerWithRangeFilter } from '../../../utils/utilsFilters';

const getColumns = (isWaiverEnabled, isPaymentEnabled) => {
  let columns = [
    {
      field: 'projectName',
      label: 'Project Name',
      width: 'auto',
      getValue: (obj) => {
        const data = obj?.rowData?.jobName ? `${obj?.rowData?.jobId}  - ${obj?.rowData?.jobName}` : obj?.rowData?.jobId;
        return data;
      },
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'address',
      label: 'Project Address',
      width: 'auto',
      cellRenderer: (props) => <HuiTableCell {...props} />,
    },
    {
      field: 'openInvoices',
      label: 'Total Open Invoices',
      width: '150px',
      visible: isPaymentEnabled,
      cellRenderer: (props) => (
        <HuiTableProjectPay total={props.data.openProjectTotal} count={props.data.openProjectCount} {...props} />
      ),
    },
    {
      field: 'openInvoices30',
      label: '30 Days Past Due',
      width: '150px',
      visible: isPaymentEnabled,
      cellRenderer: (props) => (
        <HuiTableProjectPay
          daysPastDue={30}
          total={props.data.openProject30Total}
          count={props.data.openProject30Count}
          {...props}
        />
      ),
    },
    {
      field: 'openInvoices60',
      label: '60 Days Past Due',
      width: '150px',
      visible: isPaymentEnabled,
      cellRenderer: (props) => (
        <HuiTableProjectPay
          daysPastDue={60}
          total={props.data.openProject60Total}
          count={props.data.openProject60Count}
          {...props}
        />
      ),
    },
    {
      field: 'openInvoices90',
      label: '90+ Days Past Due',
      width: '150px',
      visible: isPaymentEnabled,
      cellRenderer: (props) => (
        <HuiTableProjectPay
          daysPastDue={90}
          total={props.data.openProject90Total}
          count={props.data.openProject90Count}
          {...props}
        />
      ),
    },
    {
      field: 'paidCount',
      label: 'Invoices Paid',
      width: '150px',
      visible: isPaymentEnabled,
      cellRenderer: (props) => <HuiTableProjectView type="invoice" isPaid={true} {...props} />,
    },
    {
      field: 'waiversCount',
      label: 'Executed Waivers',
      width: '140px',
      pinned: true,
      visible: isWaiverEnabled,
      cellRenderer: (props) => <HuiTableProjectView isPaymentEnabled={isPaymentEnabled} type="waiver" {...props} />,
    },
    {
      field: 'requestWaivers',
      label: 'Request Waiver',
      width: '140px',
      pinned: true,
      visible: isWaiverEnabled && !isPaymentEnabled,
      cellRenderer: (props) => <HuiTableProjectRequestWaiver {...props} />,
    },
  ];

  columns = columns.map((column, i) => {
    return { ...column, id: i, sortable: false };
  });

  return columns;
};

const dashBoardPaymentsFilters = {
  filters: [
    {
      name: 'search',
      type: 'search',
      label: 'Search by Project Id, Project Name or Address',
    },
    {
      ...datePickerWithRangeFilter,
      name: 'dashboardOpenProject',
    },
  ],
};

const DashboardOpenProjects = (props) => {
  const { openProjects, setOpenProjects, isWaiverEnabled, isPaymentEnabled } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [tableFilters, setTableFilters] = useState({ startDate: null, endDate: null, search: null });

  useEffect(() => {
    async function filterOpenProjects() {
      const startDate = searchParams.get('startDate');
      const endDate = searchParams.get('endDate');
      const search = searchParams.get('search');

      if (tableFilters.startDate == startDate && tableFilters.endDate == endDate && tableFilters.search == search) {
        return;
      }

      setOpenProjects(['filter']);

      let openProjectsFilter = {};

      if (startDate && endDate) {
        openProjectsFilter = { ...openProjectsFilter, startDate, endDate };
      }

      if (search) {
        openProjectsFilter = { ...openProjectsFilter, search };
      }

      setTableFilters(openProjectsFilter);

      let result = await getOpenProjects(new URLSearchParams(openProjectsFilter));

      if (result) {
        setOpenProjects(result);
      }
    }
    filterOpenProjects();
  }, [setOpenProjects, setTableFilters, tableFilters, searchParams]);

  const isLoading = !size(openProjects);
  const columns = getColumns(isWaiverEnabled, isPaymentEnabled);

  return (
    <HuiPanel className="mb-4" boxShadow>
      <HuiPanelContent className="px-3 pt-0 pb-3">
        <HuiTable
          additionalProps={{
            header: {
              title: 'Open Projects',
              availableFilters: { ...dashBoardPaymentsFilters },
            },
          }}
          components={{ Header: HuiTableHeader }}
          isLoading={isLoading}
          className="hui-table hui-table-no-border hui-table-fluid"
          columns={columns}
          rows={openProjects.rows}
          totalRows={openProjects.count}
          isPaginated={false}
        />
      </HuiPanelContent>
    </HuiPanel>
  );
};

export default DashboardOpenProjects;
