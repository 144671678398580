import { isEmpty, size } from "lodash";

export function getTableConfigByPortal(name, portal) {
  if (isEmpty(name) || !size(portal)) {
    return {};
  }
  const tablesConfig = getPortalFeature('tablesConfig', portal) || {};
  return tablesConfig[name] || {};
}

export function getPortalFeature(name, portal) {
  if (isEmpty(name) || !size(portal)) {
    return;
  }
  
  return portal?.settings?.[name];
}