import { HuiBadge } from 'handle-ui';
import { Link } from 'react-router-dom';

const componentType = {
  invoice: { label: 'View', link: '/history/invoices' },
  waiver: { label: 'View', link: '/history/waivers' },
};

const HuiTableProjectView = (props) => {
  const { data, type, value, hasProject, isPaid } = props;
  const { id: projectId } = data;

  const textColor = value > 0 ? 'black' : 'default';
  const valueFormatted = value > 0 ? value : 'N/A';
  const selectedType = componentType[type];

  const url = new URL(selectedType.link, document.baseURI);

  if (projectId) {
    url.searchParams.append('projectId', projectId);
  } else {
    url.searchParams.append('hasProject', hasProject);
  }

  if (isPaid) {
    url.searchParams.append('isPaid', isPaid);
  }

  const viewLink = url.pathname + url.search;

  return (
    <div className="rgt-cell-inner text-truncate hui-text-sm d-flex align-items-center">
      <div>
        <HuiBadge value={valueFormatted} bgColor="gray-light" textColor={textColor} uppercase fullWidth>
          {value}
        </HuiBadge>
      </div>
      <div className="px-2">
        <Link to={viewLink} className="hui-color-black fw-bold text-decoration-none" hidden={value <= 0}>
          {selectedType.label}
        </Link>
      </div>
    </div>
  );
};

export default HuiTableProjectView;
