import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="h-100 h-window d-flex align-items-center">
      <div className="container text-center">
        <div className="col-6 offset-3">
          <img src="/images/svg/error-404.svg" alt="Oops, something went wrong" className="img-fluid" />
        </div>
        <h1 className="ck-title ck-bold my-4">404 Error - Page not found.</h1>
        <div className="">
          The page you requested could not be found. <Link to="/dashboard">Go home</Link>.
        </div>
      </div>
    </div>
  );
};
export default NotFound;
