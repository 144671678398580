import { axiosInstance } from '../../utils/utilsAxios';

export async function getPaymentsHistory(params) {
  const url = `/v1/payments`;

  try {
    const response = await axiosInstance.get(url, { params });
    const payments = response.data;
    return payments;
  } catch (e) {
    throw new Error(e.message);
  }
}


