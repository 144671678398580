import { HuiTabs, HuiTabsContent, HuiTabsItem } from 'handle-ui';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import HistoryInvoices from './HistoryInvoices';

const History = () => {
  const location = useLocation();
  const portalInfo = useSelector(selectPortalInfo);
  const [tab, setTab] = useState('');

  const { isPaymentEnabled, isWaiverEnabled } = portalInfo.settings || {};

  useEffect(() => {
    const activeTab = location.pathname.replace('/history/', '');
    setTab(activeTab);
  }, [setTab, location]);

  return (
    <div className="p-4 hui-dashboard flex-grow-1 overflow-hidden">
      <h1 className="hui-title mb-2">Reports</h1>

      <HuiTabs>
        <HuiTabsItem className={tab === 'invoices' ? 'active' : ''} link="/history/invoices">
          <Link to="/history/invoices">Invoices</Link>
        </HuiTabsItem>
      </HuiTabs>

      <HuiTabsContent>{tab === 'invoices' && <HistoryInvoices />}</HuiTabsContent>
    </div>
  );
};

export default History;
