import React from 'react';
import HuiPaymentMethodIcon from '../../../../components/payment/HuiPaymentMethodIcon';
import HuiTableCell from '../../../../components/table/HuiTableCell';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';

const PaymentMethodType = (props) => {
  const { data } = props;
  const { paymentType, accountType, brand } = data;
  const classNames = ('rgt-cell-inner text-truncate hui-text-sm').trim();

  const getIcon = {
    [UtilPaymentMethods.CREDIT_CARD]: <HuiPaymentMethodIcon className="me-2" brand={brand} />,
    [UtilPaymentMethods.ACH]: <HuiTableCell value={accountType} />,
  };
  const icon = getIcon[paymentType];

  return (
    <div className={classNames}>
      {icon}
    </div>
  );
};

export default PaymentMethodType;
