import React from 'react';
import { getPaymentMethodIcon } from '../../utils/utilsPaymentMethods';

const HuiPaymentMethodIcon = (props) => {
  const { brand, className } = props;
  const icon = getPaymentMethodIcon[brand];
  const iconClass = `d-flex hui-payment-method-icon ${className}`;
  return icon && <div className={iconClass}>{icon}</div>;
};

export default HuiPaymentMethodIcon;
