import { HuiCalendar, HuiDatePicker, HuiDateRange, HuiIcon, HuiInput, HuiInputSearch, HuiSelect } from 'handle-ui';
import { isEmpty, isEqual, size } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { forceCloseLoading } from '../../../redux/LoadingSlice';
import { closeSidebar, openSidebar } from '../../../redux/SidebarSlice';
import { defaultStaticRanges } from '../../../utils/utilsFilters';
import { addDays, endOfDay, startOfDay } from 'date-fns';

const defaultCalendar = {
  dateSelected: null,
  value: false,
};

export const defaultDatePicker = [
  {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    value: false,
  },
];

export const defaultDateRange = [
  {
    startDate: startOfDay(addDays(new Date(), -6)),
    endDate: endOfDay(new Date()),
    key: 'selection',
    value: true,
  },
];

const FiltersForm = ({ children, className }) => <div className={className}>{children}</div>;

const HuiFilters = (props) => {
  const dispatch = useDispatch();
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const [stateSearchParams, setParamsToSubmit] = useState(urlSearchParams);

  const { handleSearch, searchText, availableFilters, showForm, showSubmit, className } = props;

  const [paramsToUse, setParamsToUse] = showSubmit
    ? [stateSearchParams, setParamsToSubmit]
    : [urlSearchParams, setSearchParams];

  const { filters, advancedFilters, onRowsReset } = availableFilters;

  const [datePicker, setDatePicker] = useState(defaultDatePicker);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [calendarDate, setCalendarDate] = useState(defaultCalendar);

  const hasAdvanceFilters = size(advancedFilters);

  const currentUrl = new URL(window.location.href);
  const searchUrl = currentUrl.searchParams;

  let hasAdvancedFilters = [];

  for (const [key, value] of searchUrl) {
    const hasActiveFilter = advancedFilters?.find((filter) => filter.name === key);
    if (hasActiveFilter) {
      hasAdvancedFilters.push(hasActiveFilter);
    }
  }

  const advancedFilterButtonClass = `hui-btn hui-btn-icon ms-2 ${
    size(hasAdvancedFilters) ? 'hui-btn-green' : 'hui-btn-outline'
  }`;
  const advancedFilterIconColor = `${size(hasAdvancedFilters) ? 'white' : ''}`;

  const filterTypes = {
    select: (filter) => getSelect(filter),
    search: (filter) => getSearch(filter),
    button: (filter) => getButton(filter),
    input: (filter) => getInput(filter),
    datePicker: (filter) => getDatePicker(filter),
    dateRange: (filter) => getDateRange(filter),
    calendar: (filter) => getCalendar(filter),
  };

  const showFilters = filters?.map((filter, i) => getFilter(filter, i));

  return (
    <>
      {showFilters}

      {!!hasAdvanceFilters && (
        <div className={advancedFilterButtonClass} onClick={openAdvancedFilters}>
          <HuiIcon name="filter" size="xs" color={advancedFilterIconColor} />
        </div>
      )}

      {!!showSubmit && (
        <>
          <div onClick={advancedFiltersSubmit} className="hui-btn hui-btn-lg hui-btn-green text-center mb-2">
            Submit
          </div>
          <div onClick={clearAdvancedFilters} className="hui-btn hui-btn-lg hui-btn-outline text-center">
            Clear Filters
          </div>
        </>
      )}
    </>
  );

  async function clearAdvancedFilters() {
    for (const [key, value] of searchUrl) {
      const deleteActiveFilter = filters?.find((filter) => filter.name === key);
      if (deleteActiveFilter) {
        paramsToUse.delete(deleteActiveFilter.name);
      }
      setSearchParams(paramsToUse);
    }

    await dispatch(closeSidebar({}));
    onRowsReset?.();
  }

  async function advancedFiltersSubmit() {
    const allParams = [...paramsToUse];

    for (const [key, value] of allParams) {
      if (isEmpty(value)) {
        paramsToUse.delete(key);
      }
    }

    if (!isEqual(urlSearchParams.toString(), paramsToUse.toString())) {
      onRowsReset?.();
    }
    setSearchParams(paramsToUse);

    await dispatch(closeSidebar({}));
  }

  function handleClickEvent(event, { name, onClick }) {
    if (onClick) return onClick(event);

    const isSelected = paramsToUse.get(name) === 'true';
    const newValue = isSelected ? 'false' : 'true';
    paramsToUse.set(name, newValue);
    setParamsToUse(new URLSearchParams(paramsToUse.toString()));
  }

  function handleEvent(event) {
    paramsToUse.set(event.target.name, event.target.value);
    setParamsToUse(new URLSearchParams(paramsToUse.toString()));
  }

  function getFilter(filter, i) {
    const { type = {} } = filter;

    const formProps = {
      className: showForm ? className : 'ms-2',
    };

    return (
      <FiltersForm key={i} {...formProps}>
        {filterTypes[type]({
          ...filter,
        })}
      </FiltersForm>
    );
  }

  function getSelect(filter) {
    const { name, label, options } = filter;
    return (
      <HuiSelect
        onChange={handleEvent}
        className="mb-0"
        size="small"
        id={name}
        name={name}
        label={label}
        options={options}
        value={paramsToUse.get(name) || ''}
      />
    );
  }

  function getSearch(filter) {
    const { name, label } = filter;
    return (
      <HuiInputSearch
        name={name}
        className="mb-0 hui-filters-search"
        type="search"
        label={label}
        value={searchText}
        onChange={handleSearch}
      />
    );
  }

  function getButton(filter) {
    const { label } = filter;
    return (
      <button className="hui-btn text-center" onClick={(e) => handleClickEvent(e, filter)}>
        {label}
      </button>
    );
  }

  function getInput(filter) {
    const { name, label } = filter;
    return (
      <HuiInput
        onChange={handleEvent}
        value={paramsToUse.get(name) || ''}
        className="mb-0"
        label={label}
        id={name}
        name={name}
        type="text"
      />
    );
  }

  function getDatePicker(filter) {
    const { label, name } = filter;

    const startDateFromUrl = urlSearchParams.get(`startDate`);
    const endDateFromUrl = urlSearchParams.get(`endDate`);

    if (startDateFromUrl && endDateFromUrl) {
      const urlDates = {
        startDate: new Date(startDateFromUrl),
        endDate: new Date(endDateFromUrl),
      };

      const selected = {
        ...urlDates,
        key: 'selection',
        value: true,
      };
      setDatePicker([selected]);
      paramsToUse.set('startDate', startDateFromUrl);
      paramsToUse.set('endDate', endDateFromUrl);
      urlSearchParams.delete(`startDate`);
      urlSearchParams.delete(`endDate`);
    }

    return (
      <HuiDatePicker
        onChange={(item) => {
          const { selection } = item;
          const selected = { ...selection, value: true };
          setDatePicker([selected]);
        }}
        onClear={() => {
          paramsToUse.delete(`startDate`);
          paramsToUse.delete(`endDate`);
          setParamsToUse(new URLSearchParams(paramsToUse.toString()));
          setDatePicker(defaultDatePicker);
          onRowsReset?.();
        }}
        handleOnClick={() => {
          paramsToUse.set(`startDate`, datePicker[0].startDate.toISOString());
          paramsToUse.set(`endDate`, endOfDay(datePicker[0].endDate).toISOString());
          setParamsToUse(new URLSearchParams(paramsToUse.toString()));
          onRowsReset?.();
        }}
        onClickDisabled={datePicker[0].value}
        className="mb-0"
        size="small"
        id={name}
        name={name}
        ranges={datePicker}
        label={label}
        staticRanges={defaultStaticRanges}
      />
    );
  }

  function getDateRange(filter) {
    const { label, name } = filter;

    return (
      <HuiDateRange
        onChange={(item) => {
          const { selection } = item;
          const selected = { ...selection, value: true };
          setDateRange([selected]);
          paramsToUse.set(`${name}StartDate`, selected.startDate.toISOString());
          paramsToUse.set(`${name}EndDate`, endOfDay(selected.endDate).toISOString());
          setParamsToUse(new URLSearchParams(paramsToUse.toString()));
          onRowsReset?.();
        }}
        onClear={() => setDateRange(defaultDatePicker)}
        className="mb-0"
        size="small"
        id={name}
        name={name}
        ranges={dateRange}
        label={label}
        staticRanges={defaultStaticRanges}
      />
    );
  }

  function getCalendar(filter) {
    const { label, name } = filter;

    const dateFromUrl = urlSearchParams.get(name);

    if (dateFromUrl) {
      const selected = {
        dateSelected: new Date(dateFromUrl),
        value: true,
      };

      setCalendarDate(selected);
      paramsToUse.set(name, dateFromUrl);
      urlSearchParams.delete(name);
    }

    return (
      <HuiCalendar
        onChange={(item) => {
          setCalendarDate({ dateSelected: item, value: true });
          paramsToUse.set(name, item.toISOString());
          setParamsToUse(new URLSearchParams(paramsToUse.toString()));
          onRowsReset?.();
        }}
        onClear={() => {
          paramsToUse.delete(name);
          setParamsToUse(new URLSearchParams(paramsToUse.toString()));
          setCalendarDate(defaultCalendar);
          onRowsReset?.();
        }}
        className="mb-0"
        size="small"
        id={name}
        name={name}
        date={calendarDate}
        label={label}
        hideConfirm
      />
    );
  }

  async function openAdvancedFilters() {
    await dispatch(openSidebar({ type: 'advancedFilters', filters: advancedFilters, onRowsReset }));
    await dispatch(forceCloseLoading());
  }
};

export default HuiFilters;
