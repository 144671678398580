import { Tooltip } from '@mui/material';
import { HuiIcon } from 'handle-ui';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getRecurrence } from '../../actions/recurrence/getRecurrence';
import { closeSidebar, openSidebar } from '../../redux/SidebarSlice';
import { addSelectedInvoiceClass } from '../../utils/utilsTable';

const ViewRecurrence = (props) => {
  const dispatch = useDispatch();
  const { rowIndex, hidden, className, icon, id, onCancel } = props;

  return (
    !hidden &&
    (icon ? (
      <Tooltip arrow placement="top" title="View Details">
        <div className="d-flex mx-auto" role="button" onClick={() => handleChance()}>
          <HuiIcon name="eye" size="sm" />
        </div>
      </Tooltip>
    ) : (
      <button className={className} onClick={() => handleChance()}>
        {props.children}
      </button>
    ))
  );

  async function handleChance() {
    if (rowIndex) {
      addSelectedInvoiceClass(rowIndex);
    }

    await dispatch(openSidebar({ loading: true }));

    try {
      const recurrence = await getRecurrence(id);
      await dispatch(openSidebar({ ...recurrence, type: 'recurrence', loading: false, onCancel }));
    } catch (e) {
      await dispatch(closeSidebar({}));
      console.log('Error loading recurrence modal');
    }
  }
};

export default ViewRecurrence;
