import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { map, without } from 'lodash';
import HuiIcon from '../../icons/HuiIcons';

class HuiFileUpload extends React.PureComponent {
  state={};

  render() {
    const { 
      id = "inputFile",
      name = "inputFile",
      labelButton = "Choose File",
      label,
      required,
      accept,
      multiple = false,
      box = false,
      fileListDisabled = false,
      value = '',
      hidden,
      className = '',
    } = this.props || {};

    const borderCss = box ? {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '16px',
      borderColor: '#B7B7C8',
      borderStyle: 'dashed',
      backgroundColor: '#ffffff',
      color: '#666666',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    } : {};

    const buttonLabelStyle = {
      fontWeight: 'initial',
      cursor: 'inherit',
      padding: '0 12px',
    };

    return (
      <>
        <div
          hidden={hidden}
          className={`hui-file-upload ${className || ''}`}
          style={borderCss}>
          <div className="mb-3" hidden={!label}>
            {label}
          </div>
  
          <input
            accept={accept}
            hidden={true}
            id={id}
            name={name}
            type="file"
            onChange={this.handleOnChange}
            value={value}
            required={required}
            multiple={multiple}
          />
          <button
            type='button'
            className='hui-btn hui-btn-green hui-no-padding'
          >
            <label
              htmlFor={id}
              style={buttonLabelStyle}>
              {labelButton}
            </label>
          </button>

          <div hidden={fileListDisabled}>
            {
              this.getAcceptedFiles()
            }
          </div>
        </div>
      </>
    );
  }

  handleOnChange = (event) => {
    const { files, name, value } = event.target;
    this.setState({ files, [name]: value });
    this.props.onChange?.(event);
  };

  getAcceptedFiles = () => {
    const { files } = this.state;
    const { removeFileDisabled = false } = this.props;

    return map(files, file => {
      return (
        <div key={file.name} className="d-flex align-items-center mb-3">
          <div className="mr-2">
            <HuiIcon
              name='reports'
              size='sm'
            />
            <i className='ml-2' title={file.name} />
          </div>
  
          <div className="w-100">
            <div className="d-flex align-items-center">
              <div className="ck-text color-gray">
                {file.name} - {file.size} bytes
              </div>
              <div className="ml-auto">
                <Tooltip title="Remove file">
                  <Button
                    hidden={removeFileDisabled}
                    onClick={() => this.removeFile(file)}
                    className="btn btn-link no-height">
                    <HuiIcon
                      name='x'
                      size='sm'
                      className='me-2'
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
  
          </div>
        </div>
      );
    });
  };

  removeFile = (file) => {
    const { name = "inputFile" } = this.props;
    const { files, value } = this.state;
    const newFiles = without(files, file);
    this.setState({ files: newFiles });
    this.props.onChange?.({
      target: {
        name,
        files: newFiles,
        value,
      },
    });
  };
}

export default HuiFileUpload;