import currencyFormatter from 'currency-formatter';
import { concat, isNil, map, size, uniqBy } from 'lodash';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import ViewInvoice from '../../../components/invoice/ViewInvoice';
import HuiTable from '../../../components/table/HuiTable';
import HuiTableApplyCredit from '../../../components/table/HuiTableApplyCredit';
import HuiTableBalance from '../../../components/table/HuiTableBalance';
import HuiTableCell from '../../../components/table/HuiTableCell';
import HuiTableCheckBox from '../../../components/table/HuiTableCheckBox';
import HuiTableDueDate from '../../../components/table/HuiTableDueDate';
import HuiTableInvoiceDate from '../../../components/table/HuiTableInvoiceDate';
import HuiTableInvoiceNumber from '../../../components/table/HuiTableInvoiceNumber';
import HuiTableJobId from '../../../components/table/HuiTableJobId';
import HuiTablePay from '../../../components/table/HuiTablePay';
import HuiTablePoNumber from '../../../components/table/HuiTablePoNumber';
import HuiTableTotal from '../../../components/table/HuiTableTotal';
import HuiTableFooter from '../../../components/table/components/HuiTableFooter';
import HuiTableHeader from '../../../components/table/components/HuiTableHeader';
import { selectInvoices } from '../../../redux/InvoicesSlice';
import { resetPaymentData, selectPaymentData } from '../../../redux/PaymentDataSlice';
import {
  datePickerWithRangeFilter,
  invoiceDaysPastDue,
  invoiceFilter,
  jobAddressFilter,
  jobNameFilter,
  poNumberFilter,
} from '../../../utils/utilsFilters';
import { getColumnsByPortal } from '../../../utils/utilsTable';
import { selectPortalInfo } from '../../../redux/PortalInfoSlice';
import { getPortalFeature } from '../../../utils/utilsPortal';

const firstFixedColumns = [
  {
    pinned: true,
    width: '50px',
    showColumnVisibility: false,
    cellRenderer: (props) => <HuiTableCheckBox {...props} />,
    headerCellRenderer: ({ tableManager, checked }) => (
      <HuiTableCheckBox tableManager={tableManager} header={true} checked={checked} />
    ),
  },
];

const getLastFixedColumns = (portalInfo) => {
  return [
    {
      field: 'totalAmount',
      label: 'Total Amount',
      width: '100px',
      className: 'text-end',
      showColumnVisibility: false,
      headerCellRenderer: ({ tableManager, column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      getValue: ({ value }) => currencyFormatter.format(value, { code: 'USD' }),
      cellRenderer: (props) => <HuiTableTotal {...props} />,
    },
    {
      field: 'balance',
      label: 'Balance',
      width: '100px',
      className: 'text-end',
      sortable: false,
      showColumnVisibility: false,
      getValue: ({ value }) => currencyFormatter.format(value, { code: 'USD' }),
      headerCellRenderer: ({ tableManager, column }) => <div className="d-block w-100 text-end">{column.label}</div>,
      cellRenderer: (props) => <HuiTableBalance {...props} />,
    },
    {
      field: '',
      label: '',
      width: '130px',
      sortable: false,
      showColumnVisibility: false,
      visible: getPortalFeature('isCreditMemoEnabled', portalInfo),
      cellRenderer: (props) => <HuiTableApplyCredit {...props} />,
    },
    {
      field: 'pay',
      label: 'Total to pay',
      width: '328px',
      sortable: false,
      showColumnVisibility: false,
      cellRenderer: (props) => <HuiTablePay {...props} />,
    },
    {
      pinned: true,
      field: 'viewInvoice',
      label: '',
      width: '60px',
      sortable: false,
      showColumnVisibility: false,
      cellRenderer: (props) => <ViewInvoice id={props?.data?.id} icon {...props} />,
    },
  ];
};

let dynamicColumns = [
  {
    field: 'invoiceCreatedAt',
    label: 'Invoice Date',
    getValue: ({ value }) => moment(value).format('M/DD/YYYY'),
    cellRenderer: (props) => <HuiTableInvoiceDate {...props} />,
    width: '86px',
    sortable: true,
  },
  {
    field: 'dueDate',
    label: 'Due Date',
    width: '86px',
    getValue: ({ value }) => (value ? moment(value).format('M/DD/YYYY') : ''),
    cellRenderer: (props) => <HuiTableDueDate {...props} />,
  },
  {
    field: 'invoiceNumber',
    label: 'Invoice #',
    width: '160px',
    cellRenderer: (props) => <HuiTableInvoiceNumber {...props} />,
  },
  {
    field: 'jobId',
    label: 'Job ID',
    width: '100px',
    cellRenderer: (props) => <HuiTableJobId {...props} />,
  },
  {
    field: 'jobName',
    label: 'Job Name',
    width: 'auto',
    visible: false,
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'shipToAddress',
    label: 'Ship to Address',
    width: 'auto',
    visible: false,
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },
  {
    field: 'poNumber',
    label: 'P.O. #',
    width: '85px',
    cellRenderer: (props) => <HuiTablePoNumber {...props} />,
  },
  {
    field: 'orderNumber',
    label: 'Order Number',
    width: 'auto',
    visible: false,
    cellRenderer: (props) => <HuiTablePoNumber {...props} />,
  },
  {
    field: 'statementNumber',
    label: 'Statement #',
    width: '100px',
    visible: false,
    cellRenderer: (props) => <HuiTableCell {...props} />,
  },  
];

const getAllColumns = (portalInfo) => {
  const _columns = getColumnsByPortal('makePaymentTable', dynamicColumns, portalInfo);
  const lastFixedColumns = getLastFixedColumns(portalInfo);
  return concat(firstFixedColumns, _columns, lastFixedColumns).map((column, i) => {
    return { ...column, id: i === 0 ? 'checkbox' : i };
  });
};

const PayInvoices = () => {
  const dispatch = useDispatch();
  const invoices = useSelector(selectInvoices);
  const paymentData = useSelector(selectPaymentData);
  const portalInfo = useSelector(selectPortalInfo);
  const [mergedInvoices, setMergedInvoices] = useState([]);

  const [searchParams] = useSearchParams();

  const rowsRef = useRef([]);

  const [allRows, setAllRows] = useState(rowsRef.current);
  const [totalRows, setTotalRows] = useState(null);
  const [tableManager, setTableManager] = useState({});
  const [columns, setColumns] = useState(getAllColumns());

  useEffect(() => {
    setColumns(getAllColumns(portalInfo));
  }, [portalInfo]);

  const invoicesFilters = {
    filters: [
      {
        name: 'search',
        type: 'search',
        label: 'Search by Invoice #, Job Id, P.O. #, or Address',
      },
      datePickerWithRangeFilter,
    ],
    advancedFilters: [invoiceFilter, poNumberFilter, jobNameFilter, jobAddressFilter, invoiceDaysPastDue],
  };

  const invoicesPayable = (rows, payment) => {
    return uniqBy((rows || []).concat(payment?.paymentInvoices || []) || [], (i) => {
      return [i.id, i.invoiceNumber].join('-');
    });
  };

  const onRowsReset = () => {
    rowsRef.current = [];
    setAllRows(rowsRef.current);
    setTotalRows(null);
  };

  const onLoadTableManager = (tableManager) => {
    setTableManager(tableManager);
  };

  useEffect(() => {
    async function findInvoices() {
      const result = invoices;
      setMergedInvoices(invoices);

      const allInvoices = invoicesPayable(result.rows, []);

      rowsRef.current = tableManager.asyncApi.mergeRowsAt(
        rowsRef.current,
        allInvoices,
        (tableManager.paginationApi.page - 1) * tableManager.paginationApi.pageSize,
      );

      // console.log('Page:', tableManager.paginationApi.page );
      // console.log('Result Count:', result.rows?.length);
      // console.log('Page Size:', tableManager.paginationApi.pageSize);
      // console.log('Merge Position:', (tableManager.paginationApi.page - 1) * tableManager.paginationApi.pageSize);
      // console.log('RowsRef Length:', rowsRef.current.length);

      rowsRef.current = map(rowsRef.current, (c) => c || {});
      setAllRows(rowsRef.current);
      setTotalRows(result.count);
      const selectedInvoices = map(paymentData?.paymentInvoices, (i) => i.id);
      tableManager.rowSelectionApi.setSelectedRowsIds(selectedInvoices);
    }

    if (tableManager.asyncApi && invoices?.count >= 0 && invoices !== mergedInvoices) {
      findInvoices();
    }
  }, [invoices, searchParams, tableManager, paymentData, rowsRef, setMergedInvoices, mergedInvoices]);

  const isLoading = isNil(invoices.isLoading) || invoices.isLoading;

  const handleOnColumnsChange = (columns) => {
    setColumns(columns);
  };

  // reset invoices data when component unmount
  useEffect(() => {
    return () => {
      dispatch(resetPaymentData({}));
    };
  }, [dispatch]);

  return (
    <HuiTable
      additionalProps={{
        header: {
          showTableCountInfo: true,
          availableFilters: { ...invoicesFilters, onRowsReset: () => onRowsReset() },
        },
      }}
      components={{
        Header: (props) => <HuiTableHeader {...props} selectedCount={size(paymentData?.paymentInvoices)} />,
        Footer: HuiTableFooter,
      }}
      className="hui-table hui-pay-invoices-table"
      isLoading={isLoading}
      isPaginated={true}
      columns={columns}
      rows={allRows}
      totalRows={totalRows}
      contentName="Invoices"
      onLoad={onLoadTableManager}
      onRowsRequest={() => {}}
      onRowsReset={onRowsReset}
      showColumnVisibilityManager={true}
      onColumnsChange={handleOnColumnsChange}
    />
  );
};

export default PayInvoices;
