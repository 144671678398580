import * as React from 'react';

export const getUtilsWaiverRequestStatus = {
  'pending-notarization': 'pending',
  new: 'requested',
  'requested-approval': 'requested',
  confirmed: 'confirmed',
  notarized: 'notarized',
  ready: 'ready',
  rejected: 'rejected',
};
