import React from 'react';
import { surchargeRateStr } from '../../../../utils/utilsSurcharge';

const SurchargeMessage = (props) => {
  const { showSurcharge } = props;

  return (
    !!showSurcharge && (
      <div className="hui-bg-gray-light rounded-3 mx-3 p-2 hui-text-sm mb-2 d-flex align-items-center">
        <div className="d-flex me-1">
          <i className="hui-i hui-svg-info-gray-sm"></i>
        </div>
        <div className="flex-grow-1">
          To cover the cost of accepting credit cards, we collected a {surchargeRateStr} credit card surcharge.
        </div>
      </div>
    )
  );
};

export default SurchargeMessage;
