import { filter, isEmpty, size, some, toUpper, trim } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { checkIfPaymentHasError } from '../../../../utils/utilCheckIfPaymentHasError';
import UtilPaymentMethods from '../../../../utils/utilPaymentMethods';
import { isEmail } from '../../../../utils/utilsEmail';

export function checkPaymentFormForError(fields, payment, settings) {
  const { paymentMethod, isOneTimePay, info } = payment;

  const hasPaymentError = checkIfPaymentHasError(payment, settings);

  if (hasPaymentError || UtilPaymentMethods.isCredit(paymentMethod)) {
    return hasPaymentError;
  }

  if (isEmpty(payment) || isEmpty(settings)) {
    return true;
  }

  if (settings.isScheduledPaymentEnabled && fields.scheduled && isEmpty(fields.scheduledAt)) {
    return true;
  }

  if (isOneTimePay) {
    const hasContactInfoError = checkHasContactInfoError(info);
    if (hasContactInfoError) {
      return hasContactInfoError;
    }
  }

  if (UtilPaymentMethods.isACH(paymentMethod)) {
    return hasACHPaymentError(settings, fields);
  }

  if (UtilPaymentMethods.isCreditCard(paymentMethod)) {
    return hasCreditPaymentError(fields, payment);
  }

  return false;
}

function hasACHPaymentError(settings, fields) {
  const { isCardOnFileEnabled } = settings;

  if ((isCardOnFileEnabled && isEmpty(fields.cardOnFile)) || !isCardOnFileEnabled) {
    return (
      isEmpty(fields.holderName) ||
      isEmpty(fields.accountNumber) ||
      isEmpty(fields.routingNumber) ||
      isEmpty(fields.accountType) ||
      isEmpty(fields.accountHolderType)
    );
  }

  return false;
}

function hasCreditPaymentError(fields, payment) {
  if (isEmpty(fields.cardToken?.value) && isEmpty(fields.cardOnFile)) {
    return true;
  }

  if (fields.cardOnFile && isEmpty(fields.cvv)) {
    return true;
  }

  if (!fields.cardOnFile && !payment.isOneTimePay) {
    return (
      isEmpty(fields.cardType) ||
      isEmpty(fields.address) ||
      isEmpty(fields.city) ||
      isEmpty(fields.state) ||
      isEmpty(fields.postalCode)
    );
  }

  if (payment.isOneTimePay) {
    return isEmpty(fields.postalCode) || isEmpty(fields.cardType);
  }

  return false;
}

export function getPaymentReasons(paymentReasons, paymentType) {
  if (!paymentType || toUpper(paymentType) === 'COMPLETE') {
    return [];
  }

  const _paymentType = paymentType === 'shortpay' || paymentType === 'SHORT_PAY' ? 'SHORT_PAY' : 'OVER_PAY';

  return filter(paymentReasons, (r) => r.paymentType === _paymentType);
}

export function hasPaymentReasons(paymentReasons, paymentType) {
  const filteredPaymentReasons = getPaymentReasons(paymentReasons, paymentType);

  return size(filteredPaymentReasons) > 0;
}

function checkHasContactInfoError(info) {
  const defaultValues = {
    firstName: '',
    lastName: '',
    companyAddress: '',
    companyName: '',
    email: '',
    ...info,
  };

  const hasEmptyField = some(defaultValues, (field) => isEmpty(trim(field)));
  return hasEmptyField || !isEmail(info?.email);
}

function prepareInvoiceToPay(invoice) {
  const { id, amount, balance, credit, paymentReasonId, credits } = invoice;
  const creditsList = credits?.map((credit) => {
    return prepareInvoiceToPay(credit);
  });
  return { id, amount, balance, credit, paymentReasonId, credits: creditsList };
}

export function prepareInvoicesToPay(invoices) {
  const formattedInvoices = invoices?.map((invoice) => {
    return prepareInvoiceToPay(invoice);
  });

  return formattedInvoices;
}

export function generateIdempotencyKey() {
  return uuidv4();
}
