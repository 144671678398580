import { axiosInstance } from '../../utils/utilsAxios';

export async function cancelRecurrence(id) {
  const url = `/v1/recurrences/${id}`;

  try {
    const response = await axiosInstance.delete(url);
    const recurrence = response.data;
    return recurrence;
  } catch (e) {
    throw new Error(e.response?.data?.message || e.message);
  }
}
