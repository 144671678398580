import { pickBy, size } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllRecurrences } from '../../../actions/recurrence/getAllRecurrences';
import { selectLoading } from '../../../redux/LoadingSlice';
import DashboardPaymentsItem from './DashboardPaymentsItem';
import DashboardScheduled from './DashboardScheduled';
import DashboardRecurrences from './DashboardRecurrences';
import UtilPaymentMethods from '../../../utils/utilPaymentMethods';

const recurring = [
  { date: new Date(), type: 'Prepay', amount: 43323, brand: UtilPaymentMethods.ACH, recurrent: true },
  { date: new Date(2023, 11, 8), type: 'Prepay', amount: 15365, brand: 'MASTER', recurrent: true },
];

const scheduled = [
  { date: new Date(2023, 11, 8), type: 'Invoices', amount: 43323, brand: 'AMEX' },
  { date: new Date(2023, 11, 8), type: 'Invoices', amount: 2344, brand: 'DISCOVER' },
  { date: new Date(2023, 11, 8), type: 'Prepay', amount: 15365, brand: 'VISA' },
];

const DashboardPayments = (props) => {
  const { isRecurrencePaymentEnabled, isScheduledPaymentEnabled } = props;
  const loading = useSelector(selectLoading);
  const [recurrences, setRecurrences] = useState([]);

  useEffect(() => {
    async function findRecurrences() {
      if (!size(recurrences) && !loading) {
        try {
          const result = await getAllRecurrences({ status: 'PENDING', size: 5 });
          setRecurrences(result);
        } catch (error) {
          console.error(error);
          setRecurrences(['error']);
        }
      }
    }

    //findRecurrences();
  }, [loading, recurrences, setRecurrences]);

  const recurringPayments = recurring.map((payment, i) => {
    return <DashboardPaymentsItem key={i} payment={payment} />;
  });

  const scheduledPayments = scheduled.map((payment, i) => {
    return <DashboardPaymentsItem key={i} payment={payment} />;
  });

  const trueValues = pickBy(props, Boolean);

  const col = 12 / size(trueValues);

  const colClass = `col-12 col-md-${col} mb-4`;

  return (
    trueValues && (
      <>
        <div className="row">
          <div hidden={!isRecurrencePaymentEnabled} className={colClass}>
            <div className="h-100 d-flex flex-column">
              <DashboardRecurrences />
            </div>
          </div>

          <div hidden={!isScheduledPaymentEnabled} className={colClass}>
            <div className="h-100 d-flex flex-column">
              <DashboardScheduled />
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default DashboardPayments;
