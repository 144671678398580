import { getFirebaseMessage } from '../../utils/utilsFirebaseException';
import { firebase } from './firebase';

export async function signUp(form) {
  const { email, password, firstName, lastName } = form;

  const displayName = `${firstName} ${lastName}`;

  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(async (response) => {
      const user = await response.user;

      user.updateProfile({
        displayName,
      }).then(function() {
        firebase.analytics().logEvent('sign_up');
        return user;
      }, function(error) {
        throw new Error(getFirebaseMessage(error));
      });
      return user;
    })
    .catch(error => {
      throw new Error(getFirebaseMessage(error));
    });
};
