import { capitalize } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';
import { downloadDocument } from '../../actions/document/downloadDocument';
import { downloadInvoice } from '../../actions/invoices/downloadInvoice';
import { getInvoiceEvents } from '../../actions/invoices/getInvoiceEvents';
import { downloadPayment } from '../../actions/payment/downloadPayment';
import { downloadWaiver } from '../../actions/waiver/downloadWaiver';
import { updateInvoiceEvents } from '../../redux/InvoiceEventsSlice';
import { forceCloseLoading } from '../../redux/LoadingSlice';
import { addSelectedInvoiceClass, removeSelectedInvoiceClass } from '../../utils/utilsTable';

const ButtonPrint = (props) => {
  const dispatch = useDispatch();

  const { className, rowIndex, id, type, disabled = false, params, hidden } = props;
  const typeName = capitalize(type);

  return (
    !hidden && (
      <>
        <button type="button" onClick={handlePrint} className={className} disabled={disabled}>
          {props.children}
        </button>
      </>
    )
  );

  async function handlePrint() {
    toastr.info(`Preparing to print ${typeName}`);

    if (rowIndex) {
      addSelectedInvoiceClass(rowIndex);
    }

    try {
      let download;

      switch (type) {
        case 'invoice':
          download = await downloadInvoice(id);
          break;
        case 'payment':
          download = await downloadPayment(id);
          break;
        case 'waiver':
          download = await downloadWaiver(id, params);
          break;
        case 'document':
          download = await downloadDocument(id, params);
          break;
        default:
          break;
      }

      const data = download.data;

      const blob = new Blob([data], { type: 'application/pdf' });

      const oldIframe = document.getElementById('handle-print-doc');

      if (oldIframe) {
        oldIframe.remove();
      }

      const blobURL = URL.createObjectURL(blob);

      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.id = 'handle-print-doc';
      iframe.src = blobURL;
      iframe.onload = function () {
        setTimeout(() => {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };

      if (type === 'invoice') {
        const invoiceEvents = await getInvoiceEvents(id);
        await dispatch(updateInvoiceEvents(invoiceEvents));
      }
    } catch (e) {
      await dispatch(forceCloseLoading({}));
      console.log(`Error printing ${typeName}`);
    } finally {
      removeSelectedInvoiceClass();
    }
  }
};

export default ButtonPrint;
