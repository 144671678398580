import { HuiBadge } from 'handle-ui';

const HuiTableRecurrenceCount = ({ value }) => {
  const classNames = 'rgt-cell-inner text-truncate hui-text-sm'.trim();

  return (
    <div className={classNames} title={value}>
      <HuiBadge value={value} bgColor="yellow-light" textColor="black" uppercase>
        {value}
      </HuiBadge>
    </div>
  );
};

export default HuiTableRecurrenceCount;
