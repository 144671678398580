export const accountTypeOptions = [
  {
    title: 'Checking Account',
    id: 'CHECKING',
  },
  {
    title: 'Savings Account',
    id: 'SAVINGS',
  },
];

export const accountHolderTypeOptions = [
  {
    title: 'Business Account',
    id: 'BUSINESS',
  },
  {
    title: 'Personal Account',
    id: 'PERSONAL',
  },
];