import { HuiInput, HuiLoading } from 'handle-ui';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { signInWithEmailAndPassword } from '../../../../actions/auth/signIn';
import { selectAuth, updateAuth } from '../../../../redux/AuthSlice';
import { updateEmailInHandle } from '../../../../actions/auth/updateEmailInHandle';
import { getUser } from '../../../../actions/user/getUser';
import { updateUser } from '../../../../redux/UserSlice';
import { useEffect } from 'react';

const ProfileEmail = (props) => {
  const { hidden } = props;
  const dispatch = useDispatch();
  const [form, setForm] = useState({ password: '', email: '' });
  const [isLoading, setIsIsLoading] = useState(false);
  const auth = useSelector(selectAuth);

  useEffect(() => {
    const email = auth?.email || '';
    setForm({ ...form, email });
  }, [auth]);

  if (hidden) {
    return <></>;
  }

  return (
    <>
      <form className="d-flex  justify-content-center" onSubmit={handleSubmit}>
        <HuiLoading isOpen={isLoading} />
        <div className="col-6">
          <h2 className="hui-title mb-4 mt-4">Change Email</h2>
          <div className="col-12">
            <HuiInput
              label="Email"
              id="profile-email"
              name="email"
              type="email"
              value={form.email}
              onChange={handleChange}
              inputProps={{ minLength: 6 }}
              size="large"
              required
              topLabel
            />
          </div>

          <div className="col-12">
            <HuiInput
              label="Password"
              id="profile-email-password"
              name="password"
              inputProps={{ minLength: 6 }}
              type="password"
              value={form.password}
              onChange={handleChange}
              size="large"
              required
              topLabel
            />
          </div>

          <div className="col-12 d-flex flex-column mt-3">
            <button type="submit" className="hui-btn hui-btn-xl hui-btn-green" disabled={(!form.email || !form.password)}>
              Change Email
            </button>
          </div>
        </div>
      </form>
    </>
  );

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const { email, password } = form;
    if ((!email || !password) || auth.email === email) {
      return;
    }
    
    try {
      setIsIsLoading(true);
      const user = await signInWithEmailAndPassword(auth.email, password);
      if (!user) {
        toastr.error('Something went wrong, try again or contact support.');
        return;
      }

      const handleUser = await updateEmailInHandle({ email });
      if (!handleUser || handleUser.email !== email) {
        toastr.error('Something went wrong, try again or contact support.');
        return;
      }

     const userUpdated = await updateFirebaseEmail(user, email);
     if (!userUpdated) {
      toastr.error('Something went wrong, try again or contact support.');
      return;
     }
    } catch (error) {
      handleFirebaseError(error);
    } finally {
      setIsIsLoading(false);
    }
  }

  async function updateFirebaseEmail(user, email) {
    return user.updateEmail(email).then(async ()=> {
      dispatch(updateAuth(user));
      const myUser = await getUser();
      dispatch(updateUser(myUser));
      setForm({ password: '', email: '' });
      toastr.success('Your email has been changed successfully.');
      return true;
    });
  }

  function handleFirebaseError(error) {
    if (error.code === 'auth/requires-recent-login') {
      toastr.error('You need to logout and login again for this operation');
    } else {
      const { message = 'Failed to update your user data' } = error;
      toastr.error(message);
    }
  };
};

export default ProfileEmail;
