import React from 'react';
import { omit } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import HuiCheckboxField from './HuiCheckboxField';

const HuiCheckbox = (props) => {
  const { showLabel, label, className } = props;
  const checkBoxClass = `${className || 'mb-3'}`;
  return (
    <>
      {showLabel ? (
        <FormControlLabel
          label={label}
          className={checkBoxClass}
          control={<HuiCheckboxField {...omit(props, 'showLabel')} />}
        />
      ) : (
        <HuiCheckboxField {...omit(props, 'showLabel')} />
      )}
    </>
  );
};
export default HuiCheckbox;
