import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectSidebar } from '../../../redux/SidebarSlice';
import { HuiLoading } from 'handle-ui';
import SidebarInvoice from './SidebarInvoice';
import SidebarRecurrence from './SidebarRecurrence';
import SidebarCredit from './SidebarCredit';
import SidebarControl from './SidebarControl';
import SidebarAdvancedFilters from './SidebarAdvancedFilters';
import { size } from 'lodash';

const Sidebar = () => {
  const sidebarData = useSelector(selectSidebar);
  const { type, loading } = sidebarData;

  const showLoading = <HuiLoading className="hui-sidebar-loading " isOpen />;

  const getSidebarType = {
    invoice: <SidebarInvoice invoice={sidebarData} />,
    credit: <SidebarCredit />,
    recurrence: <SidebarRecurrence recurrence={sidebarData} />,
    advancedFilters: <SidebarAdvancedFilters availableFilters={sidebarData} />,
  };

  const sidebarType = getSidebarType[type];

  const showSidebar = (
    <div className="hui-sidebar-panel hui-sidebar-panel-lg" role="presentation">
      {loading ? showLoading : sidebarType}
    </div>
  );

  return <SidebarControl isOpen={!!size(sidebarData)}>{showSidebar}</SidebarControl>;
};

export default Sidebar;
